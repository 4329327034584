import React, { useEffect } from 'react';
import {
  Breadcrumbs,
  Grid,
  Link,
  PageHeader,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography
} from '@esgian/esgianui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cleanUpCurrentParams } from '@helpers';
import { VesselCo2EmissionsSection } from '@components/Sections';
import VesselCiiRatingSection from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselCiiRatingSection';
import { setVesselCompSection } from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getVesselCompSection } from '@store/features/filters/SectionSlice/SectionSlice';
import { useSegment } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import { OPERATOR_PROFILE_FLEET_CII_RATING_TEXT } from '@constants/tooltips';

function VesselComparisonPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const section = useSelector(getVesselCompSection);
  const { breadcrumbHome } = useSegment();

  const routeChange = (path) => {
    navigate(path);
  };

  const handleSectionChange = (_, val) => {
    const params = cleanUpCurrentParams(searchParams);
    dispatch(setVesselCompSection(val));
    params.set('section', val);
    setSearchParams(params);
  };

  useEffect(() => {
    let sectionParam = searchParams.has('section') ? parseInt(searchParams.get('section')) : null;
    if (sectionParam && sectionParam !== section) {
      dispatch(setVesselCompSection(sectionParam));
    }
  }, []);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item xs={12}>
        <PageHeader
          title={'Emissions Analytics'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange(breadcrumbHome)}>
                Home
              </Link>
              <Link underline="none" color="inherit">
                Emissions Analytics
              </Link>
              <Typography color="primary">Vessel Comparison</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Tabs onChange={handleSectionChange} value={section || 1}>
            <Tab
              value={1}
              label={
                <Typography variant={'subtitle2'}>
                  CO<sub>2</sub> emissions
                </Typography>
              }
            />
            <Tab
              value={2}
              label={
                <TextWithTooltipIcon
                  label={<Typography variant={'subtitle2'}>Fleet CII rating</Typography>}
                  tooltipText={OPERATOR_PROFILE_FLEET_CII_RATING_TEXT}
                />
              }
            />
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {section === 1 && <VesselCo2EmissionsSection />}
        {section === 2 && <VesselCiiRatingSection />}
      </Grid>
    </Grid>
  );
}

VesselComparisonPage.propTypes = {};

VesselComparisonPage.defaultProps = {};

export default VesselComparisonPage;
