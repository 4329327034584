import React from 'react';
import PropTypes from 'prop-types';
import DashboardActivityMapDrawerHeader from './DashboardActivityMapDrawerHeader';
import { Box } from '@esgian/esgianui';

const DashboardActivityMapMySave = ({ onClose }) => {
  return (
    <Box p={2}>
      <DashboardActivityMapDrawerHeader title="My Save" onClose={onClose} />
    </Box>
  );
};

DashboardActivityMapMySave.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DashboardActivityMapMySave;
