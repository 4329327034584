import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, formatAxisValue } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import {
  getOprCo2EmissionsFilters,
  getVslCo2EmissionsFilters
} from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { getUser } from '@store/features';

const getUnitName = (unit, assetType) => {
  let unitName = `${assetType === 'operator' ? 'Avg. ' : ''}tCO2/CEU`;
  if (unit === 2) {
    unitName = 'gCO2/(GT•NM)';
  }
  if (unit === 3) {
    unitName = 'gCO2/(DWT•NM)';
  }
  if (unit === 4) {
    unitName = 'Total tCO2';
  }
  return unitName;
};

const getChartOptions = (displayUnit, categories, theme, user, assetType) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, threeColors }
    }
  } = theme;

  return {
    chart: {
      type: 'line',
      background: background,
      height: 350,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: `${assetType}-emission-comparison`
          },
          svg: {
            filename: `${assetType}-emission-comparison`
          },
          png: {
            filename: `${assetType}-emission-comparison`
          }
        }
      }
    },
    colors: [twoColorsContrast[1], twoColorsContrast[0], threeColors[0]],
    theme: {
      mode: mode
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: { radius: [0, 12, 12] }
    },
    xaxis: {
      categories: categories,
      tickPlacement: 'between'
    },
    yaxis: [
      {
        min: 0,
        seriesName: getUnitName(displayUnit, assetType),
        forceNiceScale: true,
        title: {
          text: getUnitName(displayUnit, assetType),

          style: {
            color: twoColorsContrast[1]
          }
        },
        decimalsInFloat: 1,
        labels: {
          formatter: formatAxisValue
        }
      },
      {
        seriesName: getUnitName(displayUnit, assetType),
        forceNiceScale: true,
        show: false,
        decimalsInFloat: 1
      },
      {
        show: displayUnit === 1,
        opposite: true,
        seriesName: 'Distance',
        decimalsInFloat: 0,
        forceNiceScale: true,
        title: {
          text: 'Distance [NM]',
          style: {
            color: threeColors[0]
          }
        },
        labels: {
          formatter: formatAxisValue
        }
      }
    ],
    stroke: {
      width: [0, 2, 2],
      curve: 'straight',
      dashArray: [0, 5, 0]
    },
    markers: {
      size: [4, 0],
      strokeWidth: 0,
      hover: {
        size: 5
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatNumberReadable(value?.toFixed(1));
        }
      }
    }
  };
};

const getAverageLine = (avg, count) => {
  let data = [];
  for (let i = 0; i < count; i++) {
    data.push(avg || 0);
  }
  return data;
};

function EmissionComparisonChart({ loading, emissionsData, assetType }) {
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const oprCo2EmissionsFilters = useSelector(getOprCo2EmissionsFilters);
  const vslCo2EmissionsFilters = useSelector(getVslCo2EmissionsFilters);

  const { theme } = useTheme();

  const displayUnit =
    assetType === 'operator'
      ? oprCo2EmissionsFilters.displayUnit
      : vslCo2EmissionsFilters.displayUnit;

  const { categories, data } = useMemo(() => {
    if (!emissionsData) return { ceu: [], gtnm: [], dwtnm: [], total: [], totDist: [] };
    const { operatorEmissionDetailsList, vesselEmissionDetailsList } = emissionsData;
    const list = assetType === 'operator' ? operatorEmissionDetailsList : vesselEmissionDetailsList;
    let res = {
      ceu: [],
      gtnm: [],
      dwtnm: [],
      totDist: [],
      total: []
    };
    let tempCategories = [];
    for (let item of list) {
      res.ceu.push(item.co2ByCeu || 0);
      res.gtnm.push(item.co2ByGrossTonnageDistance || 0);
      res.dwtnm.push(item.co2ByDeadweightDistance || 0);
      res.totDist.push(item.totalDistanceNm || 0);
      res.total.push(item.totalCo2 || 0);
      tempCategories.push(assetType === 'operator' ? item.operatorShortName : item.vesselName);
    }
    return { data: res, categories: tempCategories };
  }, [emissionsData]);

  const options = useMemo(() => {
    if (!categories?.length || !theme || !user) return null;
    return getChartOptions(displayUnit, categories, theme, user, assetType);
  }, [user, assetType, theme, categories, displayUnit]);

  useEffect(() => {
    if (!emissionsData) return;
    const { averageOperatorEmissionDetails } = emissionsData;
    const propData = assetType === 'operator' ? averageOperatorEmissionDetails : emissionsData;
    const { avgCo2ByCeu, avgCo2ByGrossTonnageDistance, avgCo2ByDeadweightDistance, avgCo2 } =
      propData || {};
    if (displayUnit === 1) {
      setSeries([
        { name: getUnitName(displayUnit, assetType), type: 'column', data: data.ceu },
        {
          name: `Average `,
          type: 'line',
          data: getAverageLine(avgCo2ByCeu, data.ceu?.length)
        },
        { name: 'Distance [NM]', type: 'line', data: data.totDist }
      ]);
    }
    if (displayUnit === 2) {
      setSeries([
        { name: getUnitName(displayUnit, assetType), type: 'column', data: data.gtnm },
        {
          name: `Average`,
          type: 'line',
          data: getAverageLine(avgCo2ByGrossTonnageDistance, data.gtnm?.length)
        }
      ]);
    }
    if (displayUnit === 3) {
      setSeries([
        { name: getUnitName(displayUnit, assetType), type: 'column', data: data.dwtnm },
        {
          name: `Average`,
          type: 'line',
          data: getAverageLine(avgCo2ByDeadweightDistance, data.dwtnm?.length)
        }
      ]);
    }
    if (displayUnit === 4) {
      setSeries([
        { name: getUnitName(displayUnit, assetType), type: 'column', data: data.total },
        {
          name: `Average`,
          type: 'line',
          data: getAverageLine(avgCo2, data.total?.length)
        }
      ]);
    }
  }, [displayUnit, data]);
  return (
    <ApexChart
      loading={!options || loading}
      height={480}
      options={options || {}}
      type={'line'}
      data={series}
    />
  );
}

EmissionComparisonChart.propTypes = {
  emissionsData: PropTypes.object,
  loading: PropTypes.bool,
  assetType: PropTypes.string
};

EmissionComparisonChart.defaultProps = {
  emissionsData: null,
  loading: false,
  assetType: 'operator'
};

export default EmissionComparisonChart;
