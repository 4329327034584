import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import operatorPerformance from '@assets/images/faq/operator-performance.png';
import operatorPerformanceFilter from '@assets/images/faq/operator-performance-filter.png';

function OperatorPerformance() {
  return (
    <Grid container spacing={2} id={'operator-performance'}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={'h6'}>
          3.5 Operator Performance &#129517;
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img srcSet={operatorPerformance} src={operatorPerformance} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Operator Performance is very similar to the Port Service Level module, but here we compare
          one selected Operator against everyone competing in the same port pair. The module
          calculates service frequency and transit time against the segment average.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The module also breaks down the individual voyages completed within the selected period
          and shows the arrival port of load, transit time and spread between voyages.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          A score is also calculated for each operator based on whether you are above (+1 point),
          equal to (+0,5 points) or worse than (0 points) the segment average. Maximum score is 4
          points beating the segment on all current criteria.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Consistency{' '}
        </Typography>
        <Typography component={'span'}>
          is calculated by finding the relative standard deviation (measuring deviation from the
          mean) and transforming the value through a function which provides a score between 0-5.
          This allows for a relative comparison of the consistency of the operators on a port call
          or service.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography bold>Filters</Typography>
      </Grid>
      <Grid item xs={12}>
        <img srcSet={operatorPerformanceFilter} src={operatorPerformanceFilter} width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Port of Load/Port of Discharge:{' '}
        </Typography>
        <Typography component={'span'}>
          Search for the port you would like to view. You can search for Name, UNLOCODE and Country
          Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Period:{' '}
        </Typography>
        <Typography component={'span'}>
          Select a from and to Month-Year you would like to see data for
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Operator:{' '}
        </Typography>
        <Typography component={'span'}>
          Choose the operator you would like to evaluate the performance of. Search is active for
          operator short and long name.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Max Transit Time:{' '}
        </Typography>
        <Typography component={'span'}>
          Default shows all records where a vessel has started in Port of Load and ended in Port of
          Discharge. Sometimes the port pair is not on the same rotation and be deemed as
          non-competitive voyages. Therefore the user can filter out the voyages by setting the max
          transit time for voyages/services that will be included in the analysis and scoring.
          Insert a number above 0 to filter.
        </Typography>
      </Grid>
    </Grid>
  );
}

OperatorPerformance.propTypes = {};

OperatorPerformance.defaultProps = {};

export default OperatorPerformance;
