import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, getTableCellRender } from '@helpers';
import { ProfileLink } from '@components/Links';
import { UnderlyingPortActivityModal } from '@components/Sections/ShipAnalytics/PortActivitySection';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function PortCallsByOperatorTable({ selectedLocation, portCallData, dateList, loading }) {
  const [data, setData] = useState(null);
  //total,avg,min,max,consScore
  const [lowScores, setLowScores] = useState([0, 0, 0, 0, 0]);
  const [highScores, setHighScores] = useState([0, 0, 0, 0, 0]);
  const [columns, setColumns] = useState([]);
  const [selectedCellParams, setSelectedCellParams] = useState(null);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const handleCellClick = useCallback((operatorLongName, operatorId, selectedDate) => {
    let date = dateList[selectedDate];
    setSelectedCellParams({
      date: date,
      operatorId: operatorId,
      operatorLongName: operatorLongName
    });
  }, []);

  useEffect(() => {
    if (portCallData && dateList) {
      let tempData = [];
      //total,avg,min,max,consScore
      const tempLows = [0, 0, 0, 0, 0];
      const tempHighs = [0, 0, 0, 0, 0];
      const { operatorResults } = portCallData;
      operatorResults.forEach(
        (
          { total, min, max, operatorLongName, operatorMonthlyResults, operatorId, operatorStats },
          i
        ) => {
          let { consistencyScore, average } = operatorStats || {};
          average = average?.toFixed(2);
          average = average ? parseFloat(average) : 0;
          if (i === 0) {
            tempLows[0] = total;
            tempLows[1] = average;
            tempLows[2] = min;
            tempLows[3] = max;
            tempLows[4] = consistencyScore;
          }
          if (tempHighs[0] < total) {
            tempHighs[0] = total;
          }
          if (tempLows[0] > total) {
            tempLows[0] = total;
          }
          if (tempHighs[1] < average) {
            tempHighs[1] = average;
          }
          if (tempLows[1] > average) {
            tempLows[1] = average;
          }
          if (tempHighs[2] < min) {
            tempHighs[2] = min;
          }
          if (tempLows[2] > min) {
            tempLows[2] = min;
          }

          if (tempHighs[3] < max) {
            tempHighs[3] = max;
          }
          if (tempLows[3] > max) {
            tempLows[3] = max;
          }

          if (tempHighs[4] < consistencyScore) {
            tempHighs[4] = consistencyScore;
          }
          if (tempLows[4] > consistencyScore) {
            tempLows[4] = consistencyScore;
          }
          let obj = {
            consistencyScore: consistencyScore,
            operatorMonthlyResults: operatorMonthlyResults,
            average: formatNumberReadable(average),
            total: formatNumberReadable(total),
            min: min,
            max: max,
            operatorName: operatorLongName,
            operatorId: operatorId
          };
          dateList.forEach((monthDate, i) => {
            let monthObj = {};
            monthObj[monthDate.split(' ').join('_')] = operatorMonthlyResults[i]?.portCalls;
            obj = { ...obj, ...monthObj };
          });
          tempData.push(obj);
        }
      );
      setData(tempData);
      setLowScores(tempLows);
      setHighScores(tempHighs);
    }
  }, [portCallData]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const {
      palette: {
        tables: {
          boxShadow: { insetRight }
        }
      }
    } = theme;
    let columnDef = [
      {
        name: 'operatorName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: insetRight
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      }
    ];

    const tableEnd = [
      {
        name: 'total',
        label: 'Total',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          setCellProps: () => {
            return {
              style: {
                boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[0], lowScores[0], dataIndex);
          }
        }
      },
      {
        name: 'average',
        label: 'Avg',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[1], lowScores[1], dataIndex);
          }
        }
      },
      {
        name: 'min',
        label: 'Min',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[2], lowScores[2], dataIndex);
          }
        }
      },
      {
        name: 'max',
        label: 'Max',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[3], lowScores[3], dataIndex);
          }
        }
      },
      {
        name: 'consistencyScore',
        label: 'Consistency Score',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          customHeadLabelRender: () => {
            return (
              <TextWithTooltipIcon
                tooltipText={tooltipConsistencyContents}
                label={'Consistency Score'}
                labelVariant={'body2'}
                variant={'body2'}
              />
            );
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[4], lowScores[4], dataIndex);
          }
        }
      }
    ];
    dateList?.map((value, i) => {
      columnDef.push({
        name: value.split(' ').join('_'),
        label: value,
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          },
          setCellHeaderProps: () => {
            let shadow = {};
            if (i === 0) {
              shadow = 'inset 3px 0px 2px -1px rgb(0 0 0 / 20%)';
            } else if (i === dateList.length - 1) {
              shadow = 'inset -3px 0px 2px -1px rgb(0 0 0 / 20%)';
            }
            return {
              style: {
                boxShadow: `${shadow}`
              }
            };
          },

          customBodyRender: (dataIndex, rowIndex) => {
            if (!data || !portCallData) {
              return '--';
            }
            const { columnIndex } = rowIndex;

            const { operatorMonthlyTotals } = portCallData;
            const monthlyDetails = operatorMonthlyTotals[columnIndex - 2];
            let oprId = rowIndex.rowData[1];
            let oprName = rowIndex.rowData[0];
            let period = rowIndex.columnIndex - 2;

            return (
              <div
                style={{
                  cursor: dataIndex > 0 ? 'pointer' : 'default'
                }}
                onClick={() => {
                  if (dataIndex > 0) {
                    handleCellClick(oprName, oprId, period);
                  }
                }}>
                {getTableCellRender(monthlyDetails?.max, monthlyDetails?.min, dataIndex)}
              </div>
            );
          }
        }
      });
    });
    setColumns([...columnDef, ...tableEnd]);
  }, [dateList, data]);

  const options = {
    customTableBodyFooterRender: () => {
      if (!data || !dateList) {
        return;
      }
      const { operatorMonthlyTotals } = portCallData;
      let total = 0;
      return (
        <tbody id={'custom-table-footer'}>
          <TableRow
            key={`total-row-footer`}
            sx={{ backgroundColor: ({ palette }) => palette.background.default }}>
            <TableCell sx={{ boxShadow: ({ palette }) => palette.tables.boxShadow.insetRight }}>
              <Typography variant={'caption'}>Total</Typography>
            </TableCell>
            {dateList.map((date, i) => {
              total += operatorMonthlyTotals[i]?.portCallTotal || 0;
              return (
                <TableCell sx={{ pl: 3 }} key={`total-${date}`}>
                  <b>{operatorMonthlyTotals[i]?.portCallTotal || '0'}</b>
                </TableCell>
              );
            })}
            <TableCell sx={{ pl: 3 }} style={{ boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)' }}>
              <b>{formatNumberReadable(total)}</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,

    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'port-calls-by-operator.csv'
    },
    print: false,
    viewColumns: false,
    pagination: false,
    elevation: 0,
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'total',
      direction: 'desc'
    }
  };

  return (
    <>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' },
          MUIDataTableToolbar: {
            styleOverrides: {
              root: {
                minHeight: 0
              }
            }
          }
        }}
        loading={loading}
        mode={theme.mode}
        title={''}
        data={data?.length ? data : []}
        columns={columns}
        options={options}
      />

      <UnderlyingPortActivityModal
        selectedLocation={selectedLocation}
        selectedCellParams={selectedCellParams}
        handleClose={() => setSelectedCellParams(null)}
      />
    </>
  );
}

PortCallsByOperatorTable.propTypes = {
  portCallData: PropTypes.object,
  dateList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  selectedLocation: PropTypes.object
};

PortCallsByOperatorTable.defaultProps = {
  portCallData: {},
  dateList: [],
  loading: false,
  selectedLocation: {}
};

export default PortCallsByOperatorTable;
