import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  SvgTextEsgian,
  TextField,
  Typography,
  VisibilityIcon,
  VisibilityOffIcon,
  Link
} from '@esgian/esgianui';
import { setToken } from '@store/helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserAccess, handleLoginUser } from '@store/features';

const getErrorMessage = (status) => {
  switch (status) {
    case 401:
      return 'Login Failed - Unrecognized e-mail or password.';
    case 500:
      return 'An error occurred. Please contact Esgian if the problem persists';
    default:
      return;
  }
};

function LoginPage({ loginRedirect }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ login: null, email: null, password: null });
  const [submit, setSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = useSelector(getUser);
  const userHasAccess = useSelector(getUserAccess);
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  const isValidEmail = () => {
    if (email && email !== '') {
      if (/\S+@\S+\.\S+/.test(email)) {
        setError((prevState) => ({ ...prevState, ...{ email: null } }));
        return true;
      }
      setError((prevState) => ({ ...prevState, ...{ email: 'Invalid email format.' } }));
      return false;
    }
    setError((prevState) => ({ ...prevState, ...{ email: 'Required' } }));
    return false;
  };

  const isValidPassword = () => {
    if (!password) {
      setError((prevState) => ({ ...prevState, ...{ password: 'Required' } }));
      return false;
    }
    setError((prevState) => ({ ...prevState, ...{ password: null } }));
    return true;
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    if (submit) {
      isValidEmail();
    }
  };

  const handlePwdChange = (pwd) => {
    setPassword(pwd);
    if (submit) {
      isValidPassword();
    }
  };

  const handleLogin = async (signal) => {
    if (!submit || !isValidEmail() || !isValidPassword()) {
      setSubmit(false);
      return;
    }
    setLoading(true);
    dispatch(handleLoginUser({ signal: signal, email: email, password: password })).then(
      async (res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setToken(res.payload.user.token, rememberMe);
          setSubmit(false);
          setLoading(false);
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Login successful',
            showConfirmButton: false,
            timer: 1500
          });
        } else if (res.meta.requestStatus === 'rejected') {
          setSubmit(false);
          setLoading(false);
          setError({ ...error, ...{ login: getErrorMessage(res.payload.status) } });
        }
      }
    );
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setSubmit(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    handleLogin(signal);
    return () => {
      controller.abort();
    };
  }, [submit]);

  useEffect(() => {
    if (userHasAccess) {
      if (loginRedirect === '/login') {
        routeChange('/');
      } else {
        routeChange(loginRedirect);
      }
    }
  }, [user]);

  return (
    <Grid
      container
      spacing={1}
      justifyContent={'center'}
      sx={{ minWidth: '100vw', minHeight: 'calc(100vh - 64px - 80px - 2em)' }}>
      <Grid item md={6} sm={10} xs={12} alignSelf={'center'}>
        <Paper
          variant={'outlined'}
          sx={{
            p: 2
          }}>
          <Grid container spacing={2} direction={'column'} alignItems={'center'}>
            <Grid item xs={12}>
              <SvgTextEsgian id1={'logoa'} id2={'logob'} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'} id={'login-header'}>
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body2'} id={'sub-header'}>
                Please log in to continue
              </Typography>
            </Grid>
            <Divider />
            {!!error.login && (
              <Grid item container justifyContent={'center'}>
                <Grid item xs={12}>
                  <Alert severity="error">{error.login}</Alert>
                </Grid>
              </Grid>
            )}
            {loading && (
              <Grid item>
                <CircularProgress size={'7em'} />
              </Grid>
            )}
            {user && !userHasAccess && (
              <Alert severity={'error'}>This user does not have access to ship analytics.</Alert>
            )}
            <>
              {!loading && (
                <Grid item container justifyContent={'center'}>
                  <Grid item xs={8}>
                    <TextField
                      id={'email-input'}
                      helperText={error.email}
                      fullWidth
                      value={email}
                      onChange={(event) => handleEmailChange(event.target.value)}
                      label="Email"
                      type={'email'}
                      error={!!error.email}
                    />
                  </Grid>
                </Grid>
              )}
              {!loading && (
                <Grid item container justifyContent={'center'}>
                  <Grid item xs={8}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel error={!!error.password}>Password</InputLabel>
                      <OutlinedInput
                        id={'password-input'}
                        error={!!error.password}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={({ target }) => handlePwdChange(target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {error.password && <FormHelperText error>{error.password}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} container justifyContent="space-between">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                      }
                      label="Remember me"
                    />
                    <Link href="https://login.esgian.com/reset-password" underline="none">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid item container justifyContent={'center'}>
                <Grid item xs={8}>
                  {!loading && (
                    <Button
                      id={'login-button'}
                      type="submit"
                      fullWidth
                      key={'submit'}
                      onClick={() => setSubmit(true)}
                      variant={'contained'}>
                      Log in
                    </Button>
                  )}
                </Grid>
              </Grid>
            </>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

LoginPage.propTypes = {
  loginRedirect: PropTypes.string
};

LoginPage.defaultProps = {
  loginRedirect: '/'
};

export default LoginPage;
