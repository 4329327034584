import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DateRangeMobi, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import VesselAvgSpeedChart from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselActivitySection/VesselAvgSpeedChart';
import VesselActivityProfileChart from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselActivitySection/VesselActivityProfileChart';
import { VesselPortCallsTable } from '@components/Tables';
import { TextWithTooltipIcon } from '@components';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';
import { getVesselAvgMonthlySpeed, getVesselMonthlyPercentActivityStatus } from '@api';
import { useParams } from 'react-router-dom';

function VesselActivitySection({
  setPortCallData,
  portCallData,
  loading: propLoading,
  vesselName,
  vesselImo
}) {
  const { segmentMinDate } = useSegment();
  const params = useParams();

  const [activityDates, setActivityDates] = useState({
    start: moment().subtract(1, 'year').startOf('month'),
    end: moment()
  });
  const [speedDates, setSpeedDates] = useState({
    start: moment().subtract(1, 'year').startOf('month'),
    end: moment()
  });
  const [loadingActivityData, setLoadingActivityData] = useState(true);
  const [loadingSpeedData, setLoadingSpeedData] = useState(true);
  const [avgSpeedData, setAvgSpeedData] = useState(null);
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getVesselActivity = async () => {
      setLoadingActivityData(true);
      try {
        await getVesselMonthlyPercentActivityStatus(
          signal,
          params.imo,
          activityDates.start,
          activityDates.end
        )
          .then((result) => {
            setActivityData(result);
            setLoadingActivityData(false);
          })
          .catch(() => {
            setLoadingActivityData(false);
            setActivityData([]);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (activityDates.start.isValid() && activityDates.end.isValid()) {
      getVesselActivity();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [activityDates]);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getSpeedData = async () => {
      setLoadingSpeedData(true);
      try {
        getVesselAvgMonthlySpeed(params.imo, speedDates.start, speedDates.end, 2.0, signal)
          .then((result) => {
            setLoadingSpeedData(false);
            setAvgSpeedData(result);
          })
          .catch(() => {
            setAvgSpeedData([]);
            setLoadingSpeedData(false);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (speedDates.start.isValid() && speedDates.end.isValid()) {
      getSpeedData();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [speedDates]);

  const handleDateSelect = useCallback((val, chart) => {
    if (val[0].isValid()) {
      if (chart === 'activity') {
        setActivityDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
      } else {
        setSpeedDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
      }
    }
    if (val[1].isValid()) {
      if (val[1].isSameOrAfter(moment(), 'month', '[]')) {
        if (chart === 'activity') {
          setActivityDates((prevState) => ({ ...prevState, end: moment() }));
        } else {
          setSpeedDates((prevState) => ({ ...prevState, end: moment() }));
        }
      } else {
        if (chart === 'activity') {
          setActivityDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
        } else {
          setSpeedDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
        }
      }
    }
  }, []);
  return (
    <>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'h6'}>Average speed</Typography>
              <Box sx={{ width: '10em' }}>
                <DateRangeMobi
                  sx={{
                    inputProps: {
                      InputProps: {
                        sx: { fontSize: '0.875rem' }
                      },
                      variant: 'standard',
                      defaultValue: undefined,
                      value:
                        speedDates.start || speedDates.end
                          ? `${
                              speedDates.start ? moment(speedDates.start).format('MMM YYYY') : ''
                            } - ${speedDates.end ? moment(speedDates.end).format('MMM YYYY') : ''}`
                          : null
                    }
                  }}
                  onClose={({ value }) => {
                    handleDateSelect(value, 'speed');
                  }}
                  dateFormat={'MMM YYYY'}
                  minStartDate={segmentMinDate}
                  disableFuture
                  startDate={speedDates.start}
                  endDate={speedDates.end}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Box>
            </Stack>
            <Divider />
            <VesselAvgSpeedChart
              vesselAvgSpeedData={avgSpeedData}
              loading={loadingSpeedData}
              fileName={vesselName?.split(' ')?.join('-').toLowerCase() + '-average-speed'}
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack>
              <TextWithTooltipIcon
                label={<Typography variant={'h6'}>Activity profile</Typography>}
                tooltipText={
                  <Stack>
                    <Typography variant={'caption'}>Transit: Vessel Speed &gt; 3 kn</Typography>
                    <Typography variant={'caption'}>
                      Standby: 0 kn &lt; Vessel Speed &le; 3 kn
                    </Typography>
                    <Typography variant={'caption'}>At Port: Vessel at port</Typography>
                  </Stack>
                }
              />
              <Box sx={{ width: '10em' }}>
                <DateRangeMobi
                  sx={{
                    inputProps: {
                      InputProps: {
                        sx: { fontSize: '0.875rem' }
                      },
                      variant: 'standard',
                      defaultValue: undefined,
                      value:
                        activityDates.start || activityDates.end
                          ? `${
                              activityDates.start
                                ? moment(activityDates.start).format('MMM YYYY')
                                : ''
                            } - ${
                              activityDates.end ? moment(activityDates.end).format('MMM YYYY') : ''
                            }`
                          : null
                    }
                  }}
                  onClose={({ value }) => {
                    handleDateSelect(value, 'activity');
                  }}
                  dateFormat={'MMM YYYY'}
                  minStartDate={segmentMinDate}
                  disableFuture
                  startDate={activityDates.start}
                  endDate={activityDates.end}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Box>
            </Stack>
            <Divider />
            <VesselActivityProfileChart
              vesselActivityData={activityData}
              loading={loadingActivityData}
              fileName={vesselName?.split(' ')?.join('-').toLowerCase() + '-activity-profile'}
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <VesselPortCallsTable
          setPortCallData={setPortCallData}
          assetLevel={'vessel'}
          name={vesselName?.split(' ')?.join('-')}
          vesselImo={vesselImo}
          hideColumns={['vesselName', 'imo', 'operatorName', 'countryName']}
          portCalls={portCallData}
          loading={propLoading}
          title={'Last Port Calls'}
          labels={['Vessel', 'Port', 'UNLOCODE', 'Arrival', 'Departure', 'Port Time (hours)']}
          headers={['vesselName', 'portName', 'portCode', 'startDate', 'endDate', 'timeInPort']}
        />
      </Grid>
    </>
  );
}

VesselActivitySection.propTypes = {
  setPortCallData: PropTypes.func.isRequired,
  portCallData: PropTypes.arrayOf(PropTypes.object),
  vesselActivityData: PropTypes.object,
  loading: PropTypes.bool,
  vesselName: PropTypes.string,
  vesselImo: PropTypes.number
};

VesselActivitySection.defaultProps = {
  portCallData: [],
  vesselActivityData: null,
  vesselName: '',
  loading: false,
  vesselImo: null
};

export default VesselActivitySection;
