export const objectToQuery = (obj) => {
  let query = '';
  Object.keys(obj).map((key, i) => {
    if (i !== 0) {
      query += '&';
    }
    query += `${key}=${obj[key]}`;
  });
  return query;
};
