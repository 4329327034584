import React from 'react';
import PropTypes from 'prop-types';
import { CountryFlag, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay';
import { PortMap } from '@components/Maps';
import { VesselPortCallsTable, UpcomingVesselPortCallsTable } from '@components/Tables';
import { ProfileLink } from '@components/Links';
import { useTimezone } from '@hooks/useTimezone';
function PortOverviewSection({
  portData,
  setLastPortCalls,
  lastPortCalls,
  upcomingPortCalls,
  loading,
  maxDraughtLastPortCalls
}) {
  const { getTimeZoneDisplay } = useTimezone();
  const { portName, country, isoAlpha2, cpointlat, cpointlon, unlocode, id } = portData || {};
  const { vesselName, draught, draughtDate, imo } = maxDraughtLastPortCalls || {};
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item container spacing={2} xs={12}>
              <Grid item alignSelf={'center'}>
                <Typography variant={'h6'}>Overview</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={'column'} spacing={2}>
                <ValueDisplay title={'Name'} value={portName} loading={loading} />
                <ValueDisplay title={'Country'} loading={loading}>
                  <Stack spacing={1} direction={'row'}>
                    <CountryFlag size={20} countryCode={isoAlpha2} />
                    <Typography variant={'body2'}>{`${country?.name}`}</Typography>
                  </Stack>
                </ValueDisplay>
                <ValueDisplay
                  title={'Location(lat/lon)'}
                  value={`${cpointlat}/${cpointlon}`}
                  loading={loading}
                />
                <ValueDisplay title={'UNLOCODE'} value={`${unlocode}`} loading={loading} />
                <ValueDisplay
                  loading={loading}
                  title={'Max reported Draught'}
                  className={'vessel-details'}>
                  <Stack>
                    <ProfileLink profile={'vessel'} name={vesselName} id={imo} />
                    <Typography variant={'body2'}>
                      {`${draught}m on ${getTimeZoneDisplay(draughtDate)}`}
                    </Typography>
                  </Stack>
                </ValueDisplay>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper>
          <PortMap height={'40vh'} loading={loading} portData={portData} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <VesselPortCallsTable
          assetLevel={'port'}
          setPortCallData={setLastPortCalls}
          hideColumns={['portName', 'portCode', 'countryName']}
          title={'Most Recent Vessels Port Calls'}
          portCalls={lastPortCalls}
          loading={loading}
          name={portName}
          geoId={id}
          labels={[
            'Vessel',
            'IMO',
            'Operator',
            'Port',
            'UNLOCODE',
            'Arrival',
            'Departure',
            'Port Time (hours)'
          ]}
          headers={[
            'vesselName',
            'imo',
            'operatorShortName',
            'portName',
            'portCode',
            'startDate',
            'endDate',
            'timeInPort'
          ]}
          allowDownloadAll={true}
        />
      </Grid>
      <Grid item xs={12}>
        <UpcomingVesselPortCallsTable
          assetLevel={'port'}
          title={'Upcoming Vessels Port Calls'}
          upcomingPortCalls={upcomingPortCalls}
          loading={loading}
          name={portName}
        />
      </Grid>
    </Grid>
  );
}

PortOverviewSection.propTypes = {
  portData: PropTypes.object,
  setLastPortCalls: PropTypes.func.isRequired,
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  maxDraughtLastPortCalls: PropTypes.object
};

PortOverviewSection.defaultProps = {
  portData: null,
  lastPortCalls: [],
  loading: false,
  upcomingPortCalls: [],
  maxDraughtLastPortCalls: {}
};

export default PortOverviewSection;
