import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, ApexChart, Button, ButtonGroup, Grid, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
const getAnnotations = (withAnnotations, segmentAvg, operatorAvg, numberOfOperatorsInSegment) => {
  if (!withAnnotations) {
    return {};
  }
  let annotations = [
    {
      y: operatorAvg,
      strokeDashArray: 5,
      borderColor: '#6bc2c3',
      label: {
        position: 'left',
        textAnchor: 'start',
        borderColor: '#6bc2c3',
        style: {
          color: '#fff',
          background: '#6bc2c3'
        },
        text: 'Operator Avg.'
      }
    }
  ];
  if (numberOfOperatorsInSegment > 1) {
    annotations.push({
      y: segmentAvg,
      borderColor: '#5db1f0',
      strokeDashArray: 5,
      label: {
        borderColor: '#5db1f0',
        textAnchor: 'end',
        style: {
          color: '#fff',
          background: '#5db1f0'
        },
        text: 'Segment Avg.'
      }
    });
  }
  return {
    position: 'front',
    yaxis: annotations
  };
};

const getDataLabelColor = (dataPointIndex, segData, operatorData) => {
  if (
    segData[dataPointIndex]?.segmentTransitAverage >=
    operatorData[dataPointIndex]?.operatorTransitAverage
  ) {
    return '#30d072';
  }
  return '#f25c54';
};
const getMainChartDefOptions = (
  user,
  dateRangeList,
  theme,
  withAnnotations = false,
  operatorAvg,
  segmentAvg,
  segData,
  operatorData,
  numberOfOperatorsInSegment,
  type,
  fileName
) => {
  return {
    chart: {
      background: theme.palette.charts.background,
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: theme.palette.charts.tenColors,
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true
      },
      shared: true,
      intersect: false
    },
    annotations: getAnnotations(
      withAnnotations,
      operatorAvg,
      segmentAvg,
      numberOfOperatorsInSegment
    ),
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: [
          function ({ dataPointIndex }) {
            return getDataLabelColor(dataPointIndex, segData, operatorData);
          }
        ]
      },
      formatter: function (val, index) {
        const { dataPointIndex, seriesIndex } = index;
        if (numberOfOperatorsInSegment === 1) {
          return;
        }
        if (seriesIndex === 1) {
          return;
        }
        let operatorVal = operatorData[dataPointIndex]?.operatorTransitAverage;
        let segmentVal = segData[dataPointIndex]?.segmentTransitAverage;
        const diff = operatorVal - segmentVal;
        let percent = ((diff / segmentVal) * 100).toFixed(1);
        percent = !isNaN(percent) ? percent : 0;
        return `${diff.toFixed(2)} (${percent}%)`;
      }
    },
    fill:
      type === 'bar'
        ? { opacity: 1 }
        : {
            opacity: [1, 0.4],
            type: ['solid', 'gradient'],
            gradient: {
              shade: 'light',
              type: 'vertical',
              opacityFrom: 0.6,
              opacityTo: 0.2,
              stops: [0, 100]
            }
          },
    markers: {
      size: 0
    },
    theme: {
      mode: theme.mode
    },
    stroke: {
      width: 2,
      colors: type === 'bar' ? 'transparent' : undefined,
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '75%',
        barHeight: '80%',
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: dateRangeList
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    yaxis: {
      min: 0,
      max: (max) => max * 1.2,
      labels: {
        formatter: (value) => {
          return value?.toFixed(0);
        }
      }
    }
  };
};
function TransitTimePerformanceChart({ loading, performanceData, dateRangeList, fileName }) {
  const [type, setType] = useState('bar');
  const [noData, setNoData] = useState(false);
  const [optionsBar, setOptionsBar] = useState({});
  const [optionsLine, setOptionsLine] = useState({});
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      return;
    }
    const { segmentPerformance, operatorPerformance } = performanceData;
    let { segmentTransitResult, numberOfOperatorsInSegment } = segmentPerformance;
    let { transitResult } = operatorPerformance;
    transitResult = transitResult[0];
    let { operatorLongName, operatorMonthlyTransitResults, transitStats } = transitResult || {};
    if (!transitResult) {
      setNoData(true);
      return;
    }
    let { segmentOperatorMonthlyPerformance: segmentTransit, transitStats: segmentStats } =
      segmentTransitResult;
    let operatorSeries = operatorMonthlyTransitResults?.map(
      ({ operatorTransitAverage }) => operatorTransitAverage || 0
    );
    let operatorData = { name: operatorLongName, type: type, data: operatorSeries };

    let segmentSeries = segmentTransit?.map(
      ({ segmentTransitAverage }) => segmentTransitAverage || 0
    );
    let segmentData = {
      name: 'Segment',
      type: type === 'bar' ? 'bar' : 'area',
      data: segmentSeries
    };
    // TODO This should use total not avg
    setOptionsBar(
      getMainChartDefOptions(
        user,
        dateRangeList,
        theme,
        true,
        transitStats?.average,
        segmentStats?.average,
        segmentTransit,
        operatorMonthlyTransitResults,
        numberOfOperatorsInSegment,
        type,
        fileName
      )
    );
    setOptionsLine(
      getMainChartDefOptions(
        user,
        dateRangeList,
        theme,
        true,
        transitStats?.average,
        segmentStats?.average,
        segmentTransit,
        operatorMonthlyTransitResults,
        numberOfOperatorsInSegment,
        type,
        fileName
      )
    );

    if (numberOfOperatorsInSegment > 1) {
      setSeries([operatorData, segmentData]);
    } else {
      setSeries([operatorData]);
    }
  }, [performanceData, theme, dateRangeList, type]);

  return (
    <Grid container spacing={2}>
      {!loading && (
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
            <Typography>VIEW BY:</Typography>
            <ButtonGroup disableElevation>
              <Button
                onClick={() => setType('bar')}
                variant={type === 'bar' ? 'contained' : 'outlined'}>
                Bar Chart
              </Button>
              <Button
                onClick={() => setType('line')}
                variant={type === 'line' ? 'contained' : 'outlined'}>
                Line Chart
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        {type === 'bar' && !noData && (
          <ApexChart
            options={optionsBar || {}}
            type={'bar'}
            loading={loading}
            data={series}
            height={480}
            fileName={fileName}
          />
        )}
        {type === 'line' && !noData && (
          <ApexChart
            options={optionsLine || {}}
            type={'line'}
            loading={loading}
            data={series}
            height={480}
            fileName={fileName}
          />
        )}
        {noData && (
          <Alert severity={'info'}>We dont have any data for the selected operator </Alert>
        )}
      </Grid>
    </Grid>
  );
}

TransitTimePerformanceChart.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool,
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired
};

TransitTimePerformanceChart.defaultProps = {
  performanceData: null,
  dateRangeList: [],
  loading: false
};

export default TransitTimePerformanceChart;
