import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Modal, TextField, Typography } from '@esgian/esgianui';

function UpdateDataModal({ content, onClose, onSubmit }) {
  const [value, setValue] = useState(content.value);
  return (
    <Modal handleClose={onClose} title="Update the data" open={!!content}>
      <Box alignItems="center" flexDirection="column" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="space-between" p="40px">
          <Typography>{content.label}</Typography>
          <Box width="80px">
            <TextField
              fullWidth
              type="number"
              value={value === undefined ? '--' : value}
              onKeyDown={(e) => {
                const currentValue = e.target.value;
                if (
                  (currentValue.length > 0 && e.key === '-') ||
                  e.key === '+' ||
                  e.key === 'e' ||
                  e.key === '-'
                ) {
                  e.preventDefault();
                }
              }}
              onChange={({ target }) => {
                setValue(target.value);
              }}
              variant="standard"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Box>
        </Box>
        <Box>
          <Grid container py={1} justifyContent="center" spacing={2}>
            <Grid item>
              <Button variant="text" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button disabled={value === content.value} key={2} onClick={() => onSubmit(value)}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

UpdateDataModal.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default UpdateDataModal;
