import { createSlice } from '@reduxjs/toolkit';
import {
  DATE_FORMAT,
  SEGMENT,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_LIFTING_CAPACITY
} from '@constants';
import moment from 'moment';

export const REGION_ACTIVITY_SLICE_KEYS = {
  date: 'date',
  selectedOperators: 'array',
  vesselSizeMin: 'number',
  vesselSizeMax: 'number',
  minLiftingCapacity: 'number',
  maxLiftingCapacity: 'number'
};

const initialState = {
  date: moment().format(DATE_FORMAT),
  selectedOperators: 'all',
  vesselSizeMin: 0,
  vesselSizeMax: VESSEL_MAX_CAPACITY,
  minLiftingCapacity: 0,
  maxLiftingCapacity: VESSEL_MAX_LIFTING_CAPACITY
};

const RegionActivitySlice = createSlice({
  name: 'RegionActivity',
  initialState: initialState,
  reducers: {
    updateRegionActivityFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    resetRegionActivityFilters: (state, action) => {
      const segment = action.payload;
      if (segment.id === SEGMENT.MPP.id) {
        state.vesselSizeMax = VESSEL_MAX_CAPACITY_DWT;
      } else {
        state.vesselSizeMax = VESSEL_MAX_CAPACITY;
      }
      state.date = initialState.date;
      state.selectedOperators = initialState.selectedOperators;
      state.vesselSizeMin = initialState.vesselSizeMin;
      state.minLiftingCapacity = initialState.minLiftingCapacity;
      state.maxLiftingCapacity = initialState.maxLiftingCapacity;
    }
  }
});

export const getRegionActivityFilters = (state) => {
  return state.filters.regionProfile.regionActivity;
};

export const { resetRegionActivityFilters, updateRegionActivityFilters } =
  RegionActivitySlice.actions;

export default RegionActivitySlice.reducer;
