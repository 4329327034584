import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@esgian/esgianui';

export const mapFullscreenButtonParentStyle = {
  position: 'absolute',
  zIndex: 20,
  right: '10px',
  bottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  gap: 2
};

function MapFullscreenButton({ onClick, isFullScreen }) {
  return (
    <Button
      onClick={onClick}
      id={isFullScreen ? 'exit-fullscreen-button' : 'fullscreen-button'}
      startIcon={
        <img
          src={
            isFullScreen
              ? '/assets/images/full-screen-exit-icon.png'
              : '/assets/images/full-screen-icon.png'
          }
          alt={isFullScreen ? 'Fullscreen Exit' : 'Full screen'}
        />
      }
      sx={{ background: 'white', color: 'black' }}>
      <span>{isFullScreen ? 'Exit full screen' : 'Full screen'}</span>
    </Button>
  );
}

MapFullscreenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired
};

export default MapFullscreenButton;
