import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import consistencyScore from '@assets/images/faq/consistency-score.png';

function GeneralQuestions() {
  return (
    <Grid item xs={12} id={'general'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>5. FAQ</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography bold variant={'h6'}>
            Who needs Shipping Analytics?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            The tool provides unique insight into the relative service delivery across all major
            KPIs with frequency, transit time and reliability across some 460+ global ports &
            120000+ port calls, and counting. This is highly valuable intel for both sides of the
            supply and demand function. Operators can compare and benchmark their own performance
            against their peers whereas the cargo owner, whether being a freight forwarder or OEM
            can assess their suppliers’ performance against the alternatives. Albeit one can segment
            the main user groups into four: 1) Commercial ship operators, 2) Tonnage providers/ship
            owners, 3) Freight forwarders and 4) OEMs, the system will provide valuable data for all
            stakeholders looking to gain a deeper understanding of the dynamics of the shipping
            industry.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography bold variant={'h6'}>
            How is the data collected?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Esgian tracks the global RoRo fleet using AIS data – input that is also a prerequisite
            for our Greenpact Ships emission module. By matching each vessel to a commercial
            operator and geofencing the global ports, we can track each vessels commercial sailing
            pattern and provide analysis with a 98% accuracy rate.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography bold variant={'h6'}>
            How do you calculate the Consistency Score?
          </Typography>
        </Grid>
        <Grid item sm={8} md={9}>
          <Typography>
            The consistency score is a mathematical formula that looks at the relative standard
            deviation of a dataset. To make more sense of the output, Esgian shows consistency
            distributed on a relative scale from 0 to 5 with 5 being the best. A score of 5 means
            that the service is 100 % consistent in terms of the number of port calls, port pair
            service or transit time.
          </Typography>
        </Grid>
        <Grid item sm={4} md={3}>
          <img srcSet={consistencyScore} src={consistencyScore} width={'200px'} />
        </Grid>
        <Grid item xs={12}>
          <Typography bold variant={'h6'}>
            How do you keep the vessel owner/operator mapping updated?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Through our strategic partnership with Hesnes Shipping AS, a market leader in the car
            carrier ship brokerage space, we are able to assign each vessel to a commercial operator
            on a regular basis.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography bold variant={'h6'}>
            Will Shipping Analytics allow the user to compare scheduling reliability across the
            global PCTC fleet?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Yes, it will and that is also one of the core value propositions of the software.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

GeneralQuestions.propTypes = {};

GeneralQuestions.defaultProps = {};

export default GeneralQuestions;
