// fleetCiiRatingSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
  DATE_FORMAT,
  OPERATOR_MAX_FLEET_SIZE,
  OPERATOR_SELECT_TYPES,
  SEGMENT,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MIN_BUILD_YEAR
} from '@constants';
import moment from 'moment';
import { toast } from 'react-toastify';

const countFilter = (val, defaultValue) => {
  return parseInt(val) !== defaultValue && val !== undefined && val !== null;
};

const getAdvancedFilterCount = (filters, isRoRo) => {
  let count = 0;
  const { maxCapacity, minCapacity, minFleetSize, maxFleetSize, minBuildYear, maxBuildYear } =
    filters || {};
  let defaultMaxCapacity = isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT;
  count += countFilter(maxCapacity, defaultMaxCapacity) || countFilter(minCapacity, 0) ? 1 : 0;
  count +=
    countFilter(maxFleetSize, OPERATOR_MAX_FLEET_SIZE) || countFilter(minFleetSize, 0) ? 1 : 0;
  count +=
    countFilter(maxBuildYear, moment().year()) || countFilter(minBuildYear, VESSEL_MIN_BUILD_YEAR)
      ? 1
      : 0;
  return count;
};

export const FLEET_CII_SLICE_KEYS = {
  displayUnit: 'number',
  selectType: 'string',
  selectedAssets: 'array',
  startDate: 'date',
  endDate: 'date',
  minCapacity: 'number',
  maxCapacity: 'number',
  minFleetSize: 'number',
  maxFleetSize: 'number',
  minBuildYear: 'number',
  maxBuildYear: 'number'
};

const initialState = {
  selectType: OPERATOR_SELECT_TYPES.AUTO,
  selectedAssets: [],
  startDate: moment().subtract(1, 'years').startOf('year').format(DATE_FORMAT),
  endDate: moment().subtract(1, 'years').endOf('year').format(DATE_FORMAT),
  minCapacity: 0,
  maxCapacity: VESSEL_MAX_CAPACITY,
  minFleetSize: 0,
  maxFleetSize: OPERATOR_MAX_FLEET_SIZE,
  minBuildYear: VESSEL_MIN_BUILD_YEAR,
  maxBuildYear: moment().year()
};

const createFleetCiiFilterSlice = (key) => {
  return createSlice({
    name: key,
    initialState: initialState,
    reducers: {
      updateFilters: (state, action) => {
        const { payload } = action;
        Object.keys(payload).forEach((key) => {
          state[key] = payload[key];
        });
      },
      resetAdvancedFilters: (state, action) => {
        const segment = action.payload;
        if (segment.id === SEGMENT.MPP.id) {
          state.maxCapacity = VESSEL_MAX_CAPACITY_DWT;
        } else {
          state.maxCapacity = VESSEL_MAX_CAPACITY;
        }
        state.minCapacity = initialState.minCapacity;
        state.minFleetSize = initialState.minFleetSize;
        state.maxFleetSize = initialState.maxFleetSize;
        state.minBuildYear = initialState.minBuildYear;
        state.maxBuildYear = initialState.maxBuildYear;
        toast.success('Filters updated', {
          position: 'top-right'
        });
      },
      resetFilters: (state, action) => {
        const { segment = {}, asset } = action.payload;
        let newStart = moment().subtract(1, 'years');
        newStart = asset === 'operator' ? newStart.year() : newStart.format(DATE_FORMAT);
        if (segment.id === SEGMENT.MPP.id) {
          state.startDate = newStart;
          state.maxCapacity = VESSEL_MAX_CAPACITY_DWT;
        } else {
          state.startDate = moment().subtract(1, 'years').startOf('year').format(DATE_FORMAT);
          state.maxCapacity = VESSEL_MAX_CAPACITY;
        }
        state.selectType = initialState.selectType;
        state.selectedAssets = initialState.selectedAssets;
        state.endDate = initialState.endDate;
        state.minCapacity = initialState.minCapacity;
        state.minFleetSize = initialState.minFleetSize;
        state.maxFleetSize = initialState.maxFleetSize;
        state.minBuildYear = initialState.minBuildYear;
        state.maxBuildYear = initialState.maxBuildYear;
      }
    }
  });
};
export const getOprFleetCiiFilters = (state) => {
  return state.filters.emissionsAnalytics.operatorComparison.fleetCiiRating;
};
export const getVslFleetCiiFilters = (state) => {
  return state.filters.emissionsAnalytics.vesselComparison.fleetCiiRating;
};

export const getOprFleetCiiAdvFilterCount = (state) => {
  const filters = state.filters.emissionsAnalytics.operatorComparison.fleetCiiRating;
  const isRoRo = state.general.segment.id === SEGMENT.RoRo.id;
  return getAdvancedFilterCount(filters, isRoRo);
};

export const getVslFleetCiiAdvFilterCount = (state) => {
  const filters = state.filters.emissionsAnalytics.vesselComparison.fleetCiiRating;
  const isRoRo = state.general.segment.id === SEGMENT.RoRo.id;
  return getAdvancedFilterCount(filters, isRoRo);
};

export default createFleetCiiFilterSlice;
