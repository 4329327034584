import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, getTableCellRenderCII } from '@helpers';
import { useSegment } from '@hooks/useSegment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, theme, user, ratings, periodSelect, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, threeColors, twoColorsContrast, fiveColorsIndication }
    }
  } = theme;

  return {
    chart: {
      type: 'bar',
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background,
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: [twoColorsContrast[1], threeColors[0], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        distributed: false, // this line is mandatory
        borderRadius: 4,
        columnWidth: periodSelect === 3 ? '40%' : '80%',
        dataLabels: {
          position: 'center'
        }
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      formatter: function (val, { dataPointIndex }) {
        return ratings[dataPointIndex];
      },
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        colors: [
          function ({ dataPointIndex }) {
            if (ratings[dataPointIndex] === 'A') {
              return fiveColorsIndication[0];
            } else if (ratings[dataPointIndex] === 'B') {
              return fiveColorsIndication[1];
            } else if (ratings[dataPointIndex] === 'C') {
              return fiveColorsIndication[2];
            } else if (ratings[dataPointIndex] === 'D') {
              return fiveColorsIndication[3];
            } else if (ratings[dataPointIndex] === 'E') {
              return fiveColorsIndication[4];
            } else {
              return fiveColorsIndication[0];
            }
          }
        ]
      },
      colors: function ({ dataPointIndex }) {
        return [getTableCellRenderCII(ratings[dataPointIndex])];
      },
      textAnchor: 'middle'
    },
    legend: {
      fontSize: '14px',
      markers: {
        width: 15,
        height: 15,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 2,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      position: 'top',
      horizontalAlign: 'left'
    },
    xaxis: {
      categories: categories,
      tickPlacement: 'between'
    },
    yaxis: [
      {
        seriesName: 'CII',
        min: 0,
        max: (max) => max * 1.1,
        forceNiceScale: true,
        title: {
          text: 'CII'
        },
        decimalsInFloat: 1
      }
    ],
    stroke: {
      width: [0, 2, 2, 2, 2, 2, 2],
      curve: 'straight',
      dashArray: [0, 0, 5]
    },

    markers: {
      size: [0, 4, 0, 0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 5
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatNumberReadable(value?.toFixed(1));
        }
      },
      enabledOnSeries: [0, 1, 2]
    }
  };
};

function VesselCiiRatingChart({ ciiRatingData, periodSelect, loading, fileName }) {
  const [newCiiRatingData, setNewCiiRatingData] = useState(null);
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const { isMPP } = useSegment();

  const categories = useMemo(() => {
    if (!ciiRatingData) return;
    const { vesselPeriodEmissionDetailsList: periodData } = ciiRatingData;

    const tempCategories = periodData?.map(({ periodStr }) => periodStr) || [];
    if (isMPP && periodSelect === 3) {
      return tempCategories?.slice(1);
    }
    return tempCategories;
  }, [ciiRatingData, periodSelect]);

  useEffect(() => {
    if (!ciiRatingData) return;

    const {
      vesselPeriodEmissionDetailsList: periodData,
      polyFitCII,
      poseidonPrinciple
    } = ciiRatingData;

    let tempData = {
      cii: [],
      ciiRating: [],
      superiorBoundary: [],
      lowerBoundary: [],
      upperBoundary: [],
      inferiorBoundary: [],
      polyFitCII: polyFitCII,
      poseidonPrinciple: poseidonPrinciple
    };

    for (let item of periodData) {
      tempData.cii.push(item.cii || 0);
      tempData.ciiRating.push(item.ciiRating || 0);
      tempData.superiorBoundary.push(item.superiorBoundary || 0);
      tempData.lowerBoundary.push(item.lowerBoundary || 0);
      tempData.upperBoundary.push(item.upperBoundary || 0);
      tempData.inferiorBoundary.push(item.inferiorBoundary || 0);
    }
    setNewCiiRatingData(tempData);
  }, [ciiRatingData]);

  const series = useMemo(() => {
    if (!newCiiRatingData) return;

    let dynamicSeries = [];
    let tempSeries = [
      {
        name: 'CII',
        type: 'column',
        data: newCiiRatingData.cii || [],
        index: 0 // set the static index to 0
      },
      {
        name: 'Poseidon Principle',
        type: 'line',
        data: newCiiRatingData.poseidonPrinciple || [],
        index: 1 // set the static index to 1
      }
    ];

    if (periodSelect !== 3) {
      dynamicSeries.push({
        name: 'Trendline',
        type: 'line',
        data: newCiiRatingData.polyFitCII || [],
        index: 3
      });
    }
    tempSeries = [...tempSeries, ...dynamicSeries];
    if (isMPP && periodSelect === 3) {
      tempSeries = tempSeries.map((item) => {
        return { ...item, ...{ data: item.data.slice(1) } };
      });
    }

    return tempSeries;
  }, [periodSelect, newCiiRatingData]);

  const options = useMemo(() => {
    if (!categories?.length || !newCiiRatingData) return;
    let grades = newCiiRatingData.ciiRating;
    if (periodSelect === 3 && isMPP) {
      grades = grades.slice(1);
    }
    return getOptions(categories, theme, user, grades, periodSelect, fileName);
  }, [categories, theme, user, series, periodSelect]);

  return (
    <ApexChart
      loading={loading || !series?.length}
      height={480}
      options={options || {}}
      type={'line'}
      data={series}
    />
  );
}
VesselCiiRatingChart.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.object,
  periodSelect: PropTypes.number,
  fileName: PropTypes.string.isRequired
};
VesselCiiRatingChart.defaultProps = {
  ciiRatingData: {},
  periodSelect: 1,
  loading: false
};
export default VesselCiiRatingChart;
