import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, getTableCellRenderCII } from '@helpers';
import { Table } from '@esgian/esgianui';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function FleetCiiRatingTable({ loading, ciiRatingData }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const columns = useMemo(() => {
    return [
      {
        name: 'operatorLongName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      },
      {
        name: 'fleetSize',
        label: 'Fleet size',
        options: {
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getMinMaxFilterListOptions('Fleet size'),
          filterOptions: getMinMaxFilterOptions('Fleet size')
        }
      },
      {
        name: 'countARating',
        label: 'A',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('A'),
          filterOptions: getMinMaxFilterOptions('A')
        }
      },
      {
        name: 'countBRating',
        label: 'B',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('B'),
          filterOptions: getMinMaxFilterOptions('B')
        }
      },
      {
        name: 'countCRating',
        label: 'C',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('C'),
          filterOptions: getMinMaxFilterOptions('C')
        }
      },
      {
        name: 'countDRating',
        label: 'D',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('D'),
          filterOptions: getMinMaxFilterOptions('D')
        }
      },
      {
        name: 'countERating',
        label: 'E',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('E'),
          filterOptions: getMinMaxFilterOptions('E')
        }
      },
      {
        name: 'averageCIIRating',
        label: 'Average rating',
        options: {
          filterType: 'multiselect',
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getTableCellRenderCII(value, theme);
          }
        }
      }
    ];
  }, [theme]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: true,
      search: true,
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: 'operator-fleet-cii-rating'
      },
      print: checkUserHasAccess(user, true),
      viewColumns: false,
      pagination: true,
      elevation: 0,
      toolbar: true,
      rowsPerPage: 25,
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'fleetSize',
        direction: 'desc'
      }
    };
  }, [user]);

  return (
    <Table
      loading={loading}
      mode={theme.mode}
      title={'Operator fleet CII rating'}
      data={ciiRatingData?.length ? ciiRatingData : []}
      columns={columns}
      options={options}
    />
  );
}

FleetCiiRatingTable.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.arrayOf(PropTypes.object)
};

FleetCiiRatingTable.defaultProps = {
  ciiRatingData: [],
  loading: false
};

export default FleetCiiRatingTable;
