import React from 'react';
import { Box } from '@esgian/esgianui';
import PropTypes from 'prop-types';

function ActivityMapSection({ renderDashboardActivityMap }) {
  return <Box height="82vh">{renderDashboardActivityMap?.()}</Box>;
}

ActivityMapSection.propTypes = {
  renderDashboardActivityMap: PropTypes.func
};

export default ActivityMapSection;
