import {
  Paper,
  Box,
  Typography,
  Button,
  VisibilityOffIcon,
  IconButton,
  InfoOutlineIcon
} from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getGeneralSlice } from '@store/features';
import { useSelector } from 'react-redux';
import { formatToDecimalPlaces } from '@helpers';

const StatusIndicatorDot = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

StatusIndicatorDot.propTypes = {
  color: PropTypes.string
};

const SimulatedVoyageOverviewFloatingWindow = ({
  origin,
  destination,
  transitDays,
  numPortCalls,
  distanceNmi,
  cargoT
}) => {
  const { themeMode } = useSelector(getGeneralSlice);
  const [isExpanded, setIsExpanded] = useState(true);

  const paperBackground = themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)';

  if (!isExpanded) {
    return (
      <IconButton
        sx={{
          pointerEvents: 'auto',
          height: 32,
          width: 32,
          p: 2,
          backgroundColor: paperBackground,
          '&:hover': {
            backgroundColor: paperBackground
          }
        }}
        onClick={() => setIsExpanded(true)}>
        <InfoOutlineIcon sx={{ color: 'black', '&:hover': { color: 'black' }, fontSize: 20 }} />
      </IconButton>
    );
  }

  return (
    <Paper
      sx={{
        width: '15rem',
        background: paperBackground,
        p: '0.5rem 0rem',
        pointerEvents: 'auto'
      }}>
      <Box display="flex" flexDirection="column" pl={2} pr={2} gap={1}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            <StatusIndicatorDot color="#0E9EFF" /> Origin:
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {origin}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            <StatusIndicatorDot color="#FF9800" /> Destination:
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {destination}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            Transit Time:
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {formatToDecimalPlaces(transitDays, 0)} days
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            Intermediate Port calls:
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {numPortCalls}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            Distance (NM):
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {formatToDecimalPlaces(distanceNmi, 0)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="primary.contrastText">
            Cargo (T):
          </Typography>
          <Typography variant="body2" color="primary.contrastText">
            {formatToDecimalPlaces(cargoT, 0)}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setIsExpanded(false);
          }}
          size="small"
          startIcon={<VisibilityOffIcon sx={{ color: 'black' }} />}
          sx={{
            color: 'black',
            borderColor: 'black',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderColor: 'black'
            },
            mt: 1,
            width: '5rem'
          }}>
          Hide
        </Button>
      </Box>
    </Paper>
  );
};

SimulatedVoyageOverviewFloatingWindow.propTypes = {
  origin: PropTypes.string,
  destination: PropTypes.string,
  transitDays: PropTypes.number,
  numPortCalls: PropTypes.number,
  distanceNmi: PropTypes.number,
  cargoT: PropTypes.number
};

export default SimulatedVoyageOverviewFloatingWindow;
