import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConsistencyChart } from '@components/Charts';

function sortByScore(a, b) {
  if (a?.frequencyStats?.consistencyScore < b?.frequencyStats?.consistencyScore) {
    return 1;
  }
  if (a?.frequencyStats?.consistencyScore > b?.frequencyStats?.consistencyScore) {
    return -1;
  }
  return 0;
}

function ServiceLevelConsistencyChart({ loading, portServiceData, fileName }) {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (portServiceData) {
      const { frequencyResults } = portServiceData;
      let data = [...frequencyResults].sort(sortByScore);
      setCategories(data.map(({ operatorLongName }) => operatorLongName));
      setSeries([{ data: data.map(({ frequencyStats }) => frequencyStats.consistencyScore) }]);
    }
  }, [portServiceData]);

  return (
    <ConsistencyChart
      categories={categories}
      fileName={fileName}
      series={series}
      loading={loading}
    />
  );
}

ServiceLevelConsistencyChart.propTypes = {
  portServiceData: PropTypes.object,
  loading: PropTypes.bool,
  performanceData: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

ServiceLevelConsistencyChart.defaultProps = {
  portServiceData: null,
  loading: false,
  performanceData: null
};

export default ServiceLevelConsistencyChart;
