import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { CircularProgress } from '@esgian/esgianui';
import {
  attachRouteLayer,
  createPulsingMarker,
  createShipIcon,
  putMarkerOnMap
} from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const PULSING_MARKER_START_PREFIX = 'start';
const PULSING_MARKER_END_PREFIX = 'end';

const SimulatedVoyageRouteMap = ({ voyageTransitDetails, currentShipMarkerIndex = 0 }) => {
  const themeMode = useSelector(getThemeMode);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const shipMarkerRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const transitDetailsCollection = voyageTransitDetails?.transitDetails ?? [];

  useEffect(() => {
    if (mapRef.current) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      zoom: 1
    });

    mapRef.current = map;

    map.on('load', () => {
      setIsMapLoaded(true);

      const routes = [
        { route: transitDetailsCollection, color: themeMode ? '#80DFEB' : '#1557FF' }
      ];
      attachRouteLayer(map, routes, 'simulated-voyage-route', true);

      const pulsingMarkerStart = createPulsingMarker(mapRef.current, 'rgba(14,81,255,1)');
      const pulsingMarkerEnd = createPulsingMarker(mapRef.current, 'rgba(255,152,0)');
      if (transitDetailsCollection.length > 0) {
        putMarkerOnMap(
          map,
          pulsingMarkerStart,
          transitDetailsCollection[0].lon,
          transitDetailsCollection[0].lat,
          PULSING_MARKER_START_PREFIX
        );

        putMarkerOnMap(
          map,
          pulsingMarkerEnd,
          transitDetailsCollection[transitDetailsCollection.length - 1].lon,
          transitDetailsCollection[transitDetailsCollection.length - 1].lat,
          PULSING_MARKER_END_PREFIX
        );
      }
    });

    return () => map.remove();
  }, [themeMode, voyageTransitDetails]);

  useEffect(() => {
    if (!isMapLoaded) return;

    if (
      currentShipMarkerIndex === 0 ||
      currentShipMarkerIndex === transitDetailsCollection.length - 1
    ) {
      shipMarkerRef.current && shipMarkerRef.current.remove();
      return;
    }

    if (shipMarkerRef.current) {
      shipMarkerRef.current.remove();
    }

    const currentPosition = transitDetailsCollection[currentShipMarkerIndex];

    if (!currentPosition) {
      return;
    }
    const newShipMarker = new mapboxgl.Marker({
      element: document.createElement('img')
    })
      .setLngLat([currentPosition.lon, currentPosition.lat])
      .setLngLat([currentPosition.lon, currentPosition.lat])
      .addTo(mapRef.current);

    newShipMarker.getElement().src = createShipIcon();
    shipMarkerRef.current = newShipMarker;
  }, [currentShipMarkerIndex]);

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />
      {!isMapLoaded && (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)'
          }}
          size={40}
        />
      )}
    </div>
  );
};

SimulatedVoyageRouteMap.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  currentShipMarkerIndex: PropTypes.number.isRequired
};

export default SimulatedVoyageRouteMap;
