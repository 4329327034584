import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesBrushChart } from '@components/Charts';

function PortCallFrequencyChart({
  loading,
  selectedOperators,
  portActivityData,
  dateList,
  fileName
}) {
  const [seriesMain, setSeriesMain] = useState([]);
  const [seriesBrush, setSeriesBrush] = useState([]);

  useEffect(() => {
    if (portActivityData) {
      // Do logic here
      const { operatorMonthlyTotals, operatorResults } = portActivityData;
      let tempTotal = [];
      let tempMainSeries = [];

      operatorResults.map(({ operatorMonthlyResults, operatorLongName }) => {
        let operatorData = [];
        operatorMonthlyResults.forEach(({ portCalls }) => {
          operatorData.push(portCalls);
        });
        tempMainSeries.push({
          name: operatorLongName,
          data: operatorData
        });
      });

      for (let i = 0; i < dateList.length; i++) {
        let val = operatorMonthlyTotals[i]?.portCallTotal || 0;
        tempTotal.push(val);
      }
      setSeriesMain(tempMainSeries);
      setSeriesBrush([{ name: 'Total', data: tempTotal }]);
    }
  }, [portActivityData, selectedOperators]);
  return (
    <TimeSeriesBrushChart
      loading={loading}
      seriesBrush={seriesBrush}
      seriesMain={seriesMain}
      dateList={dateList}
      groupName={'portCallFrequencyCharts'}
      idBrush={'brushPortCallFrequency'}
      idMain={'mainPortCallFrequency'}
      fileName={fileName}
    />
  );
}

PortCallFrequencyChart.propTypes = {
  loading: PropTypes.bool,
  portActivityData: PropTypes.object,
  selectedOperators: PropTypes.arrayOf(PropTypes.object),
  dateList: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired
};

PortCallFrequencyChart.defaultProps = {
  portActivityData: null,
  loading: false,
  selectedOperators: [],
  dateList: []
};

export default PortCallFrequencyChart;
