import { combineReducers } from '@reduxjs/toolkit';
import CommercialAnalyticsSlice from '@store/features/filters/CommercialAnalyticsPage';
import EmissionsAnalyticsReducer from '@store/features/filters/EmissionAnalyticsPage/EmissionsAnalyticsReducer';
import RegionProfileReducer from '@store/features/filters/RegionProfilePage/RegionProfileReducer';
import VoyageAnalyticsReducer from '@store/features/filters/VoyageAnalyticsPage/VoyageAnalyticsReducer';

const FiltersReducer = combineReducers({
  commercialAnalytics: CommercialAnalyticsSlice,
  emissionsAnalytics: EmissionsAnalyticsReducer,
  regionProfile: RegionProfileReducer,
  voyageAnalytics: VoyageAnalyticsReducer
});
export default FiltersReducer;
