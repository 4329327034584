import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import { getPortDetails, getPortUpcomingPortCalls } from '@api/Port';
import PortProfileSection from '@components/Sections/ShipAnalytics/PortProfileSection';
import {
  getPortStats,
  getOperatorYTDStats,
  getPortCongestion,
  getLatestPortCalls,
  getMaxPortDraught
} from '@api';
import { DATE_FORMAT, DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';

function PortProfilePage() {
  const [portData, setPortData] = useState(null);
  const [portStats, setPortStats] = useState(null);
  const [lastPortCalls, setLastPortCalls] = useState(null);
  const [upcomingPortCalls, setUpcomingPortCalls] = useState(null);
  const params = useParams();
  const [loadingLoadingParamData, setLoadingParamData] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const navigate = useNavigate();
  const [portCongestionData, setPortCongestionData] = useState(null);
  const [operatorStats, setOperatorStats] = useState(null);
  const [selectedYear] = useState(moment().year());
  const { segment, isRoRo } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const [maxPortDraught, setMaxPortDraught] = useState(null);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { segmentMinDate } = useSegment();

  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getPortData = async (portId) => {
      setLoadingDetails(true);
      try {
        await getPortDetails(signal, portId)
          .then((result) => {
            setPortData(result);
          })
          .catch(() => setPortData(null));
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoadingDetails(false);
    };
    getPortData(params.portId);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getOperatorTypeData = async (portId) => {
      setLoadingParamData(true);
      try {
        await Promise.all([
          getPortStats(
            {
              PortId: portId,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              setPortStats(result);
            })
            .catch(() => {
              setPortStats(null);
            }),
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.PORT,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              geoIds: [portId],
              numberOfPortCalls: 250,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setLastPortCalls(result);
          }),
          getMaxPortDraught(
            {
              geographicalType: GEOGRAPHICAL_TYPES.PORT,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              geoIds: [portId],
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setMaxPortDraught(result);
          }),
          getPortUpcomingPortCalls(
            {
              PortId: portId,
              NumberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setUpcomingPortCalls(result);
          }),
          getPortCongestion(
            {
              StartDate: moment(segmentMinDate).format(DATE_FORMAT),
              EndDate: moment().format(DATE_FORMAT),
              GeoType: GEOGRAPHICAL_TYPES.PORT,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              GeoId: portId,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setPortCongestionData(result);
          }),
          getOperatorYTDStats(
            {
              FromType: GEOGRAPHICAL_TYPES.PORT,
              FromId: portId,
              Year: selectedYear,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              setOperatorStats(result);
            })
            .catch(() => {
              setOperatorStats(null);
            })
        ]).finally(() => {
          setLoadingParamData(false);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getOperatorTypeData(params.portId);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [operatorType, commercialCategoryType, segment]);

  const { portName } = portData || {};
  const { maxDraughtLastPortCalls } = maxPortDraught || {};
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={loadingDetails || !portData}
          title={`${portName}`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Commercial Analytics
              </Link>
              <Typography color="primary">Port Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <PortProfileSection
          lastPortCalls={lastPortCalls}
          portStats={portStats}
          setLastPortCalls={setLastPortCalls}
          loading={loadingLoadingParamData}
          portData={portData}
          portCongestionData={portCongestionData}
          operatorStats={operatorStats}
          selectedYear={selectedYear}
          upcomingPortCalls={upcomingPortCalls}
          maxDraughtLastPortCalls={maxDraughtLastPortCalls}
        />
      </Grid>
    </Grid>
  );
}

PortProfilePage.propTypes = { portCongestionData: PropTypes.array };

PortProfilePage.defaultProps = { portCongestionData: [] };

export default PortProfilePage;
