import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowBackIcon,
  Divider,
  DoubleArrowRightIcon,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  SearchIcon,
  Skeleton,
  Stack,
  Typography
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks/useTheme';

function sortPorts(a, b) {
  if (a.portName < b.portName) {
    return -1;
  }
  if (a.portName > b.portName) {
    return 1;
  }
  return 0;
}
function PortListDisplay({ selectedPort, setSelectedPort, mapHeight, loading, map, ports }) {
  const [portsList, setPortsList] = useState([]);
  const [query, setQuery] = useState('');
  const {
    theme: {
      palette: {
        neutral: { primary }
      }
    },
    themeMode,
    customScrollbar
  } = useTheme();

  const handlePortSelect = (port) => {
    map.flyTo({ center: [port.cpointLon, port.cpointLat], zoom: 10, duration: 2000 });
    setSelectedPort(port);
  };
  const deselectedPort = () => {
    map.flyTo({ center: [0, 0], zoom: 1, duration: 1000 });
    setSelectedPort(null);
  };

  useEffect(() => {
    if (query !== '') {
      setPortsList(
        ports
          .filter(({ portName, vessels }) => {
            return (
              portName.toLowerCase().includes(query.toLowerCase()) ||
              vessels?.find(({ vesselName }) =>
                vesselName.toLowerCase().includes(query.toLowerCase())
              )
            );
          })
          .sort(sortPorts)
      );
    } else {
      setPortsList(ports.sort(sortPorts));
    }
  }, [ports, query]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '0px'
        }}>
        {!!(!selectedPort && (loading || ports.length)) && (
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                padding: '16px 16px 16px 16px',
                zIndex: 10,
                borderBottom: (theme) =>
                  `1px solid ${themeMode ? theme.palette.border.dark : theme.palette.border.light}`
              }}>
              <OutlinedInput
                fullWidth
                value={query}
                onChange={({ target }) => setQuery(target.value)}
                inputProps={{ style: { padding: '8px' } }}
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                variant={'outlined'}
                placeholder={'Search port/vessel...'}
              />
            </Grid>

            {(!!portsList?.length || loading) && (
              <Grid item xs={12} sx={{ pt: '0px !important' }}>
                <List
                  sx={{
                    p: 0,
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: `calc(${mapHeight} - 73px)`,
                    '& ul': { padding: 0 },
                    ...customScrollbar
                  }}>
                  {loading &&
                    Array.apply(null, { length: 12 }).map((e, i) => (
                      <React.Fragment key={`skeleton-${i}`}>
                        <ListItem
                          sx={{
                            backgroundColor: `${i % 2 === 0 ? primary : 'transparent'}`
                          }}>
                          <ListItemText>
                            <Skeleton width={'100%'} />
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  {!loading &&
                    portsList?.map((port, i) => (
                      <React.Fragment key={`${port.portId}-${i}`}>
                        <ListItem
                          sx={{
                            transition: 'all 0.1s ease-in',
                            backgroundColor: `${i % 2 === 0 ? primary : 'transparent'}`
                          }}
                          onClick={() => handlePortSelect(port)}
                          button
                          secondaryAction={<DoubleArrowRightIcon />}>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={
                              <ProfileLink
                                profile={'port'}
                                name={port?.portName}
                                id={port?.portId}
                              />
                            }
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                </List>
              </Grid>
            )}
            {!portsList?.length && !loading && (
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography>No matching search results</Typography>
              </Grid>
            )}
          </Grid>
        )}

        {selectedPort && (
          <Grid container>
            <Grid item xs={12} sx={{ zIndex: 10, boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 3px 0px' }}>
              <Stack sx={{ p: 1 }} spacing={1} alignItems={'center'} direction={'row'}>
                <IconButton color={'text.primary'} onClick={deselectedPort}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant={'h6'}>{selectedPort.portName}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <List
                sx={{
                  p: 0,
                  overflow: 'auto',
                  maxHeight: `calc(${mapHeight} - 57px)`,
                  ...customScrollbar
                }}>
                {selectedPort.vessels?.map(({ imo, vesselName }, i) => (
                  <React.Fragment key={`${imo}-${i}`}>
                    <ListItem
                      sx={{
                        backgroundColor: `${i % 2 === 0 ? primary : 'transparent'}`
                      }}>
                      <ListItemText
                        primary={<ProfileLink profile={'vessel'} id={imo} name={vesselName} />}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

PortListDisplay.propTypes = {
  mapHeight: PropTypes.string,
  themeMode: PropTypes.bool,
  loading: PropTypes.bool,
  map: PropTypes.object,
  ports: PropTypes.arrayOf(PropTypes.object),
  selectedPort: PropTypes.object,
  setSelectedPort: PropTypes.func.isRequired
};

PortListDisplay.defaultProps = {
  selectedPort: null,
  mapHeight: '70vh',
  themeMode: false,
  loading: false,
  map: null
};

export default PortListDisplay;
