import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {
  Badge,
  Box,
  Button,
  DateRangeMobi,
  FilterIcon,
  Grid,
  Stack,
  VesselsTreeSelect
} from '@esgian/esgianui';
import VesselComparisonAdvancedFilters from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselComparisonSectionsFilter/VesselComparisonAdvancedFilters';
import {
  initUrlParams,
  setUrlParams
} from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/helpers';
import { useSearchParams } from 'react-router-dom';
import { useDynamicLookupQueries, useSegment, useStaticLookupQueries } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getVesselCompSection } from '@store/features/filters/SectionSlice/SectionSlice';
import {
  updateVslCo2EmissionsFilters,
  updateVslFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';
import { getDateFormat } from '@store/features';
import { getVslCo2EmissionsAdvFilterCount } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { getVslFleetCiiAdvFilterCount } from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { getStartEndMonth, getMonthDateRangePickerSx } from '@helpers/DatePickerHelpers';

function VesselComparisonSectionsFilter({ setInit, filters, init }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMPP, segmentMinDate } = useSegment();
  const dispatch = useDispatch();
  const {
    lookupOwnerHierarchyQuery: { data: lookupOwnerHierarchy }
  } = useStaticLookupQueries();

  const {
    lookupOperatorVesselHierarchyQuery: { data: lookupOperatorVesselHierarchy },
    lookupVesselsQuery: { data: lookupVessels }
  } = useDynamicLookupQueries();

  const dateFormat = useSelector(getDateFormat);
  const section = useSelector(getVesselCompSection);
  const filterCount = useSelector(
    section === 1 ? getVslCo2EmissionsAdvFilterCount : getVslFleetCiiAdvFilterCount
  );

  const { startDate, endDate, selectedAssets, selectType } = filters;

  const updateFilterValue = useCallback(
    (payload) => {
      if (section === 1) {
        dispatch(updateVslCo2EmissionsFilters(payload));
      } else {
        dispatch(updateVslFleetCiiFilters(payload));
      }
    },
    [section]
  );

  useEffect(() => {
    if (lookupOwnerHierarchy?.length) {
      const newFilters = initUrlParams(filters, section, lookupOwnerHierarchy, searchParams);
      updateFilterValue(newFilters);
      setInit(true);
    }
  }, [lookupOwnerHierarchy, section]);

  useEffect(() => {
    if (init) {
      setUrlParams(filters, section, searchParams, setSearchParams);
    }
  }, [filters, init]);

  const vesselHierarchyOptions = useMemo(() => {
    if (
      lookupOwnerHierarchy.length &&
      lookupOperatorVesselHierarchy.length &&
      lookupVessels.length
    ) {
      return {
        ownerHierarchy: lookupOwnerHierarchy,
        operatorHierarchy: lookupOperatorVesselHierarchy,
        vessels: lookupVessels
      };
    }
    return null;
  }, [lookupOwnerHierarchy, lookupOperatorVesselHierarchy, lookupVessels]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <Stack direction={'row'} spacing={2}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
            <VesselsTreeSelect
              autoEnabledText={'Displaying the top 15 vessels given the selected period'}
              handleClose={(value) => {
                let selected = value.selected;
                if (
                  JSON.stringify(selected) === JSON.stringify(selectedAssets) &&
                  value.type === selectType
                )
                  return;
                if (value.type === 'auto') {
                  selected = [];
                }
                updateFilterValue({ selectType: value.type, selectedAssets: selected });
              }}
              hideOwner={isMPP}
              autoButtonTitle={'Top 15'}
              selectType={selectType}
              handleSelectTypeChange={() => {}}
              lookupVesselHierarchy={vesselHierarchyOptions}
              selected={selectedAssets}
              loading={!vesselHierarchyOptions}
              label={'Vessels*'}
            />
            <Badge sx={{ alignSelf: 'flex-end' }} badgeContent={filterCount} color="error">
              <Button
                sx={{ height: 'min-content', mb: '0.6em' }}
                startIcon={<FilterIcon />}
                variant={'text'}
                onClick={() => setAdvancedOpen(true)}>
                Advanced Filter
              </Button>
            </Badge>
          </Stack>
        </Grid>
        <VesselComparisonAdvancedFilters
          filters={filters}
          updateFilterValue={updateFilterValue}
          advancedOpen={advancedOpen}
          setAdvancedOpen={setAdvancedOpen}
        />
      </Grid>
    </Box>
  );
}

VesselComparisonSectionsFilter.propTypes = {
  init: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  setInit: PropTypes.func.isRequired
};

VesselComparisonSectionsFilter.defaultProps = {};

export default VesselComparisonSectionsFilter;
