import { combineReducers } from '@reduxjs/toolkit';
import createFleetCiiFilterSlice from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import createSectionSlice from '@store/features/filters/SectionSlice/SectionSlice';
import createCo2EmissionsSlice from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';

const FleetCiiSlice = createFleetCiiFilterSlice('operatorFleetCii');
const Co2EmissionsSlice = createCo2EmissionsSlice('operatorCo2Emissions');
const OperatorSectionSlice = createSectionSlice('operatorSectionSlice');

const OperatorComparisonReducer = combineReducers({
  section: OperatorSectionSlice.reducer,
  fleetCiiRating: FleetCiiSlice.reducer,
  co2Emissions: Co2EmissionsSlice.reducer
});

export const { setSection: setOperatorCompSection } = OperatorSectionSlice.actions;
export const {
  updateFilters: updateOprFleetCiiFilters,
  resetAdvancedFilters: resetOprFleetCiiAdvFilters,
  resetFilters: resetOprFleetCiiFilters
} = FleetCiiSlice.actions;
export const {
  setDisplayUnit: setOprEmissionsDisplayUnit,
  updateFilters: updateOprCo2EmissionsFilters,
  resetAdvancedFilters: resetOprCo2EmissionsAdvFilters,
  resetFilters: resetOprCo2EmissionsFilters
} = Co2EmissionsSlice.actions;

export default OperatorComparisonReducer;
