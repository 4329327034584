import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import activitySection from '@assets/images/faq/activity-section.png';
import regionActivityMap from '@assets/images/faq/region-activity-map.png';
import activitySlider from '@assets/images/faq/activity-slider.png';
import activityFilters from '@assets/images/faq/activity-filters.png';

function ActivityMap() {
  return (
    <Grid container spacing={2} id={'activity-map'}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={'h6'}>
          3.2 Activity Map&#127758;
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img alt={'activity-section'} srcSet={activitySection} src={activitySection} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The activity map shows the current daily positioning of the global fleet mapped to 23
          commercial regions, canals and oceans. The map allows a 30-day drag and drop view of the
          activity and the table shows the breakdown of the number of vessels per region per
          operator. Hover over a region to see the boundary and click on a region on the map to view
          region details and activity development over time:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img alt={'region-activity-map'} srcSet={regionActivityMap} src={regionActivityMap} />
        <Typography variant={'body2'} color={'text.secondary'}>
          Click on a region to navigate to region details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom bold>
          Filters
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={8} sx={{ pt: 4 }}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <Typography component={'span'} bold>
                  Operator:{' '}
                </Typography>
                <Typography component={'span'}>
                  Select all or a set of the available operators
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} bold>
                  Vessels Size:{' '}
                </Typography>
                <Typography component={'span'}>
                  Set a min/max filter based on the vessel car capacity(CEU)
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} bold>
                  Date:{' '}
                </Typography>
                <Typography component={'span'}>
                  The solution will load 30 days prior to and including the selected date. The
                  default date is today
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <img alt={'activity-filters'} srcSet={activityFilters} src={activityFilters} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img alt={'activity-slider'} srcSet={activitySlider} src={activitySlider} width={'100%'} />
        <Typography variant={'body2'} color={'text.secondary'}>
          Use slider to adjust date within the filtered period
        </Typography>
      </Grid>
    </Grid>
  );
}

ActivityMap.propTypes = {};

ActivityMap.defaultProps = {};

export default ActivityMap;
