import React from 'react';
import { Grid, Link, List, ListItem, Typography } from '@esgian/esgianui';

function Content() {
  return (
    <Grid item xs={12} id={'content'}>
      <Typography variant={'h5'}>Content</Typography>
      <List
        sx={{
          listStyleType: 'numb',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item'
          }
        }}>
        <ListItem sx={{ pl: 1 }}>
          <Typography>
            <Link underline="hover" href={'#content'}>
              Content
            </Link>
          </Typography>
        </ListItem>
        <ListItem sx={{ pl: 1 }}>
          <Link underline="hover" href={'#signup-support'}>
            Sign up and user support
          </Link>
        </ListItem>
        <ListItem sx={{ pl: 1 }}>
          <Link sx={{ pb: 0 }} underline="hover" href={'#modules'}>
            Modules
          </Link>
        </ListItem>
        <List
          sx={{
            pt: 0,
            listStyleType: 'circle',
            pl: 4
          }}>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#general-navigation'}>
              3.1 General Navigation&#9654;&#65039;
            </Link>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#activity-map'}>
              3.2 Activity Map &#127758;
            </Link>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#port-activity'}>
              3.3 Port Activity &#9875;
            </Link>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#port-service-level'}>
              3.4 Port Service Level &#128200;
            </Link>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#operator-performance'}>
              3.5 Operator Performance &#129517;
            </Link>
          </ListItem>
        </List>
        <ListItem sx={{ pb: 0, pl: 1 }}>
          <Link sx={{ pb: 0 }} underline="hover" href={'#profiles'}>
            Vessel, Operator, Owner Overview
          </Link>
        </ListItem>
        <List sx={{ pt: 0, listStyleType: 'circle', pl: 4 }}>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#vessel-profile'}>
              4.1 Vessel Profile
            </Link>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Link sx={{ pb: 0 }} underline="hover" href={'#owner-operator-profile'}>
              4.2 Owner/Operator Profile{' '}
            </Link>
          </ListItem>
        </List>
        <ListItem sx={{ pl: 1 }}>
          <Link sx={{ pb: 0 }} underline="hover" href={'#general'}>
            FAQ
          </Link>
        </ListItem>
      </List>
    </Grid>
  );
}

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
