import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkUserHasAccess } from '@helpers';
import { loginUser, verifyTokenValid } from '@api';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

const USER_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

const initialState = {
  user: null,
  hasAccess: false,
  browserId: null,
  isLoggedIn: false,
  error: null,
  status: USER_STATUS.IDLE
};

export const fetchUserFromToken = createAsyncThunk(
  'auth/verifyToken',
  async (signal, { rejectWithValue }) => {
    try {
      let response = await Promise.all([getCurrentBrowserFingerPrint(), verifyTokenValid(signal)]);
      return { user: response[1], browserId: response[0] };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleLoginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ signal, email, password }, { rejectWithValue }) => {
    try {
      let response = await Promise.all([
        getCurrentBrowserFingerPrint(),
        loginUser(signal, email, password)
      ]);
      return { user: response[1], browserId: response[0] };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.status = USER_STATUS.SUCCEEDED;
      state.user = action.payload.user;
      state.browserId = action.payload.browserId;
      state.hasAccess = checkUserHasAccess(action.payload.user);
      state.isLoggedIn = true;
    },
    setUnauthorisedUser: (state, action) => {
      state.status = USER_STATUS.FAILED;
      state.user = null;
      state.hasAccess = false;
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    resetUser: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserFromToken.pending, (state) => {
        state.status = USER_STATUS.LOADING;
      })
      .addCase(fetchUserFromToken.fulfilled, (state, action) => {
        AuthSlice.caseReducers.setUser(state, action);
      })
      .addCase(fetchUserFromToken.rejected, (state, action) => {
        AuthSlice.caseReducers.setUnauthorisedUser(state, action);
      })
      .addCase(handleLoginUser.pending, (state) => {
        state.status = USER_STATUS.LOADING;
      })
      .addCase(handleLoginUser.fulfilled, (state, action) => {
        AuthSlice.caseReducers.setUser(state, action);
      })
      .addCase(handleLoginUser.rejected, (state, action) => {
        AuthSlice.caseReducers.setUnauthorisedUser(state, action);
      });
  }
});

export const getUser = (state) => state.auth.user;
export const getUserStatus = (state) => state.auth.status;
export const isUserLoading = (state) =>
  state.auth.status === USER_STATUS.LOADING || state.auth.status === USER_STATUS.IDLE;
export const getUserAccess = (state) => state.auth.hasAccess;

export const { setUser, setUnauthorisedUser, resetUser } = AuthSlice.actions;

export default AuthSlice.reducer;
