import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { fuelTableHeight } from './MainGeneratorFuelTable';

export const getFuelData = (fuelType, data) => ({
  fuelType: {
    value: fuelType
  },
  from: {
    value: new Date(data.from).toLocaleDateString()
  },
  to: {
    value: new Date(data.to).toLocaleDateString()
  },
  co2eEmissionFactor: {
    value: data.co2eEmissionFactor,
    isEditable: false
  },
  co2EmissionFactor: {
    value: data.co2EmissionFactor,
    isEditable: false
  }
});

export const fuelColumns = [
  {
    label: 'Fuel type',
    name: 'fuelType'
  },
  {
    label: 'From',
    name: 'from'
  },
  {
    label: 'To',
    name: 'to'
  },
  {
    label: 'CO₂e Emission factor',
    name: 'co2eEmissionFactor',
    tooltip: 'Well to wake emission factor. Defines tonnes of CO₂e produced per tonne fuel.'
  },
  {
    label: 'CO₂ Conversion factor',
    name: 'co2EmissionFactor',
    tooltip: 'Tank to wake conversion factor. Defines tonnes of CO₂ produced per tonne fuel.'
  }
];

const MainEngineFuelTable = ({ mainEngineFuel, fuelTypes, isLoading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const mappedData = mainEngineFuel.map((fuel) => {
      return getFuelData(fuelTypes[fuel.fuelTypeId], fuel);
    });

    setData(mappedData);
  }, [mainEngineFuel]);
  return (
    <TableWithSummary
      title="Main engine fuel"
      columns={fuelColumns}
      data={data}
      onUpdate={(rowIndex, columnName, newValue) => {
        const newData = [...data];
        newData[rowIndex][columnName].value = +newValue;
        setData(newData);
      }}
      isLoading={isLoading}
      minTableHeight={fuelTableHeight}
      maxTableHeight={fuelTableHeight}
      id="main-engine-fuel-table"
    />
  );
};

MainEngineFuelTable.propTypes = {
  mainEngineFuel: PropTypes.arrayOf,
  fuelTypes: PropTypes.object,
  isLoading: PropTypes.bool
};

export const dateRangePropTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  co2eEmissionFactor: PropTypes.number.isRequired,
  co2EmissionFactor: PropTypes.number.isRequired
};

export default MainEngineFuelTable;
