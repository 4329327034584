import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLatestPortCalls, getOwnershipInformation, getVesselsPositions } from '@api';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import OwnerProfileSection from '@components/Sections/ShipAnalytics/OwnerProfileSection';
import { DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import { useSegment } from '@hooks';

function OwnerProfilePage() {
  const [ownerData, setOwnerData] = useState(null);
  const [lastPortCalls, setLastPortCalls] = useState(null);
  const [vessels, setVessels] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { segment } = useSegment();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getOperatorData = async (ownerId) => {
      try {
        setLoading(true);
        await Promise.all([
          getOwnershipInformation({ OwnerId: ownerId }, signal).then((result) => {
            setOwnerData(result);
            const { ownerVessels } = result;
            getVesselsPositions(
              ownerVessels?.map(({ imo }) => imo),
              signal
            ).then((result) => {
              setVessels(result);
            });
          }),
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.ALL,
              segmentTypeId: segment.id,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              owners: [ownerId]
            },
            signal
          ).then((result) => {
            setLastPortCalls(result);
          })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getOperatorData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, []);

  const { ownerLongName, ownerShortName } = ownerData || {};
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={loading || !ownerData}
          title={`${ownerLongName} (${ownerShortName})`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary">Owner Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerProfileSection
          setLastPortCalls={setLastPortCalls}
          lastPortCalls={lastPortCalls}
          vessels={vessels}
          ownerData={ownerData}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

OwnerProfilePage.propTypes = {};

OwnerProfilePage.defaultProps = {};

export default OwnerProfilePage;
