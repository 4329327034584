import React from 'react';
import {
  Box,
  IconButton,
  InsightsOutlinedIcon,
  Popover,
  SettingsOutlinedIcon,
  ToolTip,
  TuneIcon
} from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { getTransparentBackground } from '@helpers';
import PropTypes from 'prop-types';
import { bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { useTheme } from '@hooks';
import { CircuitIcon } from '../../../Icons';

const DashboardActivityMapActions = ({
  isDisabledInsight,
  onInsightClick,
  onFilterClick,
  isFilterApplied,
  isInsightApplied,
  renderPopupContent,
  mapActionsPopupState,
  onMapSettingsClick,
  isMapSettingsApplied,
  isInboundVesselsFilterApplied,
  onInboundVesselsFilterClick
}) => {
  const themeMode = useSelector(getThemeMode);
  const transparentColor = getTransparentBackground(themeMode);
  const { customScrollbar } = useTheme();

  const getActions = (iconStyles = {}) => [
    {
      icon: <InsightsOutlinedIcon sx={iconStyles} />,
      onClick: onInsightClick,
      isActive: isInsightApplied,
      tooltip: 'Insight'
    },
    {
      icon: <TuneIcon size={iconStyles} />,
      onClick: onFilterClick,
      isActive: isFilterApplied,
      tooltip: 'Advanced Filter'
    },
    {
      icon: <SettingsOutlinedIcon sx={iconStyles} />,
      onClick: onMapSettingsClick,
      isActive: isMapSettingsApplied,
      tooltip: 'Map Settings'
    },
    {
      icon: <CircuitIcon sx={iconStyles} />,
      onClick: onInboundVesselsFilterClick,
      isActive: isInboundVesselsFilterApplied,
      tooltip: 'Inbound Vessels Filter'
    }
  ];

  const actions = getActions({
    fontSize: '24px',
    color: themeMode ? 'white' : 'black'
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          backgroundColor: transparentColor,
          borderRadius: 1,
          border: `1px solid ${transparentColor}`,
          p: 1
        }}>
        {actions.map((action, index) => (
          <Box key={index} position="relative">
            <ToolTip title={action.tooltip}>
              <IconButton
                disabled={index === 0 && isDisabledInsight}
                sx={{
                  backgroundColor: transparentColor,
                  borderRadius: '2px',
                  border: `1px solid ${transparentColor}`,
                  height: '32px',
                  width: '32px'
                }}
                {...bindTrigger(mapActionsPopupState)}
                onClick={(e) => {
                  action.onClick();
                  if (index !== 0 && index !== 3) {
                    bindTrigger(mapActionsPopupState).onClick(e);
                  }
                }}>
                {action.icon}
              </IconButton>
              {action.isActive && (
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#1976d2',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }}
                />
              )}
            </ToolTip>
          </Box>
        ))}
      </Box>
      {renderPopupContent() && (
        <Popover
          {...bindPopover(mapActionsPopupState)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
          PaperProps={{
            sx: { minWidth: '30vw', ...customScrollbar }
          }}>
          {renderPopupContent()}
        </Popover>
      )}
    </>
  );
};

DashboardActivityMapActions.propTypes = {
  onInsightClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  onMySaveClick: PropTypes.func,
  onMapSettingsClick: PropTypes.func,
  isDisabledInsight: PropTypes.bool,
  isFilterApplied: PropTypes.bool,
  isInsightApplied: PropTypes.bool,
  isMapSettingsApplied: PropTypes.bool,
  isSavedApplied: PropTypes.bool,
  renderPopupContent: PropTypes.func,
  mapActionsPopupState: PropTypes.object,
  isInboundVesselsFilterApplied: PropTypes.bool,
  onInboundVesselsFilterClick: PropTypes.func
};

export default DashboardActivityMapActions;
