import { useQueries } from '@tanstack/react-query';
import { getVesselDetailsByFilters } from '@api';
import segment1OperatorTypeId1 from '../queryInitialData/vesselsByFilters/segment1OperatorTypeId1.json';
import segment1OperatorTypeId2 from '../queryInitialData/vesselsByFilters/segment1OperatorTypeId2.json';
import segment2CommercialCategoryId1 from '../queryInitialData/vesselsByFilters/segment2CommercialCategoryId1.json';
import segment2CommercialCategoryId2 from '../queryInitialData/vesselsByFilters/segment2CommercialCategoryId2.json';
import segment2CommercialCategoryId3 from '../queryInitialData/vesselsByFilters/segment2CommercialCategoryId3.json';
import segment2CommercialCategoryId4 from '../queryInitialData/vesselsByFilters/segment2CommercialCategoryId4.json';

const getInitialData = (segmentId, categoryId) => {
  if (segmentId === 1 && categoryId === 1) return segment1OperatorTypeId1;
  if (segmentId === 1 && categoryId === 2) return segment1OperatorTypeId2;
  if (segmentId === 2 && categoryId === 1) return segment2CommercialCategoryId1;
  if (segmentId === 2 && categoryId === 2) return segment2CommercialCategoryId2;
  if (segmentId === 2 && categoryId === 3) return segment2CommercialCategoryId3;
  if (segmentId === 2 && categoryId === 4) return segment2CommercialCategoryId4;
};

export const useVesselsDetailsByFilters = () => {
  const segments = {
    RORO: {
      id: 1,
      categoryIds: [1, 2]
    },
    MPP: {
      id: 2,
      categoryIds: [1, 2, 3, 4]
    }
  };

  const createQueryConfig = ({ segmentId, categoryId }) => ({
    queryKey: ['vesselsDetails', segmentId, categoryId],
    queryFn: async ({ signal }) => {
      const response = await getVesselDetailsByFilters(
        {
          segmentTypeIds: [segmentId],
          [segmentId === segments.RORO.id ? 'operatorTypeIds' : 'commercialCategoryIds']: [
            categoryId
          ],
          activeVessels: true
        },
        signal
      );
      return response.vesselInformation;
    },
    initialData: getInitialData(segmentId, categoryId)
  });

  const queryConfigs = Object.values(segments).flatMap(({ id: segmentId, categoryIds }) =>
    categoryIds.map((categoryId) => createQueryConfig({ segmentId, categoryId }))
  );

  const queryResults = useQueries({
    queries: queryConfigs
  });

  const vesselsDetailsBySegmentAndCategory = Object.fromEntries(
    Object.entries(segments).map(([_, { id: segmentId, categoryIds }]) => [
      segmentId,
      Object.fromEntries(
        categoryIds.map((categoryId, index) => {
          const queryIndex =
            segmentId === segments.RORO.id ? index : segments.RORO.categoryIds.length + index;
          return [categoryId, queryResults[queryIndex].data];
        })
      )
    ])
  );

  const isLoading = queryResults.some((query) => query.isPending);

  const getVesselsBySegmentAndCategory = (segmentId, categoryId) =>
    vesselsDetailsBySegmentAndCategory[segmentId]?.[categoryId];

  return {
    vesselsDetailsBySegmentAndCategory,
    getVesselsBySegmentAndCategory,
    isLoading
  };
};
