import { Stack, Typography } from '@esgian/esgianui';
import React from 'react';

export const tooltipServicesContents = (
  <Stack>
    <Typography variant={'caption'}>
      This allows the user to set a minimum number of services/voyages performed within the set
      period.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      For example if you are looking at a 12 month period and want to see only operators having at
      least 1 sailing per month on average, you need to insert 12.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      If you are looking for 2 sailings per month on average, you need to insert 24 (12 months x 2
      sailings/month on average)
    </Typography>
  </Stack>
);
