import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Paper, Stack } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import {
  getDateBetweenFilterListOptions,
  getDateBetweenFilterOptions
} from '@components/Tables/helpers';
import { checkUserHasAccess, tableTitlePaddingLeft, getDirection } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { DATE_TIME_FORMAT } from '@constants';
import { useTimezone } from '@hooks/useTimezone';

const getTimeDisplay = (startDate, endDate, hoursOnly) => {
  const start = moment(startDate, DATE_TIME_FORMAT);
  const end = moment(endDate, DATE_TIME_FORMAT);

  // If either date is invalid, return an error message
  if (!start.isValid() || !end.isValid()) {
    return 'Invalid date';
  }

  let duration = moment.duration(end.diff(start));
  hoursOnly = duration.asHours();
  return `${hoursOnly.toFixed(2)} `;
};

function CanalVesselPortCallsTable({
  title = 'Recent Departures',
  loading = false,
  canalDeparturesData = [],
  fileName = '',
  hideColumns = false
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const { getTimeZoneDisplay, timeZone } = useTimezone();

  const tableData = useMemo(() => {
    if (!canalDeparturesData?.length) return [];
    return canalDeparturesData.map((rowData) => {
      let row = { ...rowData };
      let destination = null;
      let destinationId = null;
      let destinationDateETA = null;
      let destinationDateATA = null;
      let originATD = null;

      //destination port
      if (Object.hasOwn(row, 'actualNextPort') && row.actualNextPort !== null) {
        destination = row.actualNextPort?.portName;
        destinationId = row.actualNextPort?.portId;
      } else if (Object.hasOwn(row, 'reportedNextPort') && row.reportedNextPort !== null) {
        destination = row.reportedNextPort?.portName;
        destinationId = row.reportedNextPort?.portId;
      } else {
        destination =
          row.destinationPort !== null ? row.destinationPort?.portName : row.destination;
      }
      row.destination = { destinationName: destination, destinationId: destinationId };

      //destination ETA
      if (Object.hasOwn(row, 'reportedNextPort') && row.reportedNextPort !== null) {
        destinationDateETA = row.reportedNextPort?.startDate;
      }
      if (moment(destinationDateETA).isValid()) {
        row.destinationDateETA = moment(destinationDateETA).format('YYYY-MM-DD HH:mm:ss');
      } else {
        row.destinationDateETA = moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss');
      }

      //destination ATA
      if (Object.hasOwn(row, 'actualNextPort') && row.actualNextPort !== null) {
        destinationDateATA = row.actualNextPort?.startDate;
      }
      if (moment(destinationDateATA).isValid()) {
        row.destinationDateATA = moment(destinationDateATA).format('YYYY-MM-DD HH:mm:ss');
      } else {
        row.destinationDateATA = moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss');
      }

      //origin ATD
      if (
        Object.hasOwn(row, 'originPort') &&
        row.originPort !== null &&
        Object.hasOwn(row.originPort, 'startDate')
      ) {
        originATD = row.originPort.startDate;
      }
      if (moment(originATD).isValid()) {
        row.originATD = moment(originATD).format('YYYY-MM-DD HH:mm:ss');
      } else {
        row.originATD = moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss');
      }

      return row;
    });
  }, [canalDeparturesData]);

  const allColumns = [
    {
      name: 'vesselName',
      label: 'Vessel',
      options: {
        filterType: 'multiselect',
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'vessel'} name={dataIndex} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        sort: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'operatorShortName',
      label: 'Operator',
      options: {
        sort: true,
        filterType: 'multiselect',
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'operator'} name={dataIndex} id={parseInt(rowData[3])} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'speed',
      label: 'Speed',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'heading',
      label: 'Heading',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'canalArrival',
      label: 'Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (moment(value).isValid()) {
            return getTimeZoneDisplay(value);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },

    {
      name: 'canalDeparture',
      label: 'Departure',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (moment(value).isValid()) {
            return getTimeZoneDisplay(value);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'transitTime',
      label: 'Time in transit (hours)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;
            if (order === 'asc') {
              return val2 <= val1 ? 1 : -1;
            }
            return val2 >= val1 ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          const calcTime = getTimeDisplay(
            moment(rowData[6]).format(DATE_TIME_FORMAT),
            moment(rowData[7]).format(DATE_TIME_FORMAT)
          );
          return dataIndex !== null && calcTime > 0 ? calcTime : '--';
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'headingDirection',
      label: 'Direction',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = getDirection(obj1.data);
            let val2 = getDirection(obj2.data);
            if (order === 'asc') {
              return val2 <= val1 ? 1 : -1;
            } else {
              return val2 >= val1 ? 1 : -1;
            }
          };
        },
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? getDirection(dataIndex) : '--';
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'originPort',
      label: 'Origin',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data ? obj1.data.portName : '--';
            let val2 = obj2.data ? obj2.data.portName : '--';
            if (order === 'asc') {
              return val2 <= val1 ? 1 : -1;
            } else {
              return val2 >= val1 ? 1 : -1;
            }
          };
        },
        customBodyRender: (dataIndex) => {
          return dataIndex && Object.hasOwn(dataIndex, 'portName') ? (
            <ProfileLink profile={'port'} name={dataIndex.portName} id={dataIndex.portId} />
          ) : (
            '--'
          );
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'originATD',
      label: 'Origin ATD',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);

            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (value !== moment('1970-01-01').format(DATE_TIME_FORMAT)) {
            return getTimeZoneDisplay(value);
          } else {
            return '-';
          }
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'canalArrival',
      label: 'Canal Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (moment(value).isValid()) {
            return getTimeZoneDisplay(value);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'destination',
      label: 'Destination',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data.destinationName?.toLowerCase() ?? ''; // Normalize to lowercase for case-insensitive comparison
            const val2 = obj2.data.destinationName?.toLowerCase() ?? '';

            if (order === 'asc') {
              return val1 < val2 ? -1 : val1 > val2 ? 1 : 0; // Ascending order
            }
            return val1 > val2 ? -1 : val1 < val2 ? 1 : 0; // Descending order
          };
        },
        customBodyRender: (dataIndex) => {
          if (dataIndex.destinationId) {
            return (
              <ProfileLink
                profile={'port'}
                id={dataIndex.destinationId}
                name={dataIndex.destinationName}
              />
            );
          } else if (dataIndex.destinationName) {
            return dataIndex.destinationName;
          }
          return '--';
        }
      }
    },
    {
      name: 'destinationDateETA',
      label: 'Destination ETA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            {
              return val2.isAfter(val1) ? 1 : -1;
            }
          };
        },
        customBodyRender: (value) => {
          if (value !== moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss')) {
            return getTimeZoneDisplay(value);
          } else {
            return '-';
          }
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'destinationDateATA',
      label: 'Destination ATA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (value !== moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss')) {
            return getTimeZoneDisplay(value);
          } else {
            return '-';
          }
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },

    {
      name: 'portCode',
      label: 'Destination LOCODE',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        filterType: 'multiselect',
        customBodyRender: (dataIndex, { rowIndex }) => {
          let destinationCode = null;
          destinationCode =
            canalDeparturesData[rowIndex].destinationPort !== null
              ? canalDeparturesData[rowIndex].destinationPort?.portCode
              : canalDeparturesData[rowIndex]?.destination;
          return destinationCode ? destinationCode : '--';
        }
      }
    },

    {
      name: 'portId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'eta',
      label: 'ETA',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (moment(value).isValid()) {
            return getTimeZoneDisplay(value);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'timeUntilArrival',
      label: 'Time until arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          const calcTime = getTimeDisplay(
            moment().format(DATE_TIME_FORMAT),
            moment(rowData[18]).format(DATE_TIME_FORMAT)
          );
          const days = Math.trunc(calcTime / 24);
          const hours = Math.round(calcTime) - days * 24;
          if (dataIndex !== null && calcTime > 0) {
            if (days > 0) {
              return days + 'd ' + hours + 'h';
            } else {
              return hours + 'h';
            }
          } else {
            return '0h';
          }
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'Estimated Time of Arrival',
      label: 'Estimated Time of Arrival',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (moment(value).isValid()) {
            return getTimeZoneDisplay(value);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    },
    {
      name: 'positionReceived',
      label: 'Position received',
      options: {
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val2.isBefore(val1) ? 1 : -1;
            }
            return val2.isAfter(val1) ? 1 : -1;
          };
        },
        customBodyRender: (value) => {
          if (value) {
            return getTimeZoneDisplay(value);
          } else {
            return '-';
          }
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Position received'),
        filterOptions: getDateBetweenFilterOptions('Position received', 'YYYY-MM-DDTHH:mm:ss')
      }
    }
  ];
  const filteredColumns = allColumns.map((column) => {
    let updatedColumn = { ...column };
    if (hideColumns.includes(updatedColumn.label)) {
      updatedColumn.options = {
        viewColumns: false,
        display: false,
        filter: false,
        download: false
      };
    }
    return updatedColumn;
  });

  let sortInitName = 'canalDeparture';
  let sortInitOrder = 'desc';
  tableData.forEach((row) => {
    if (Object.hasOwn(row, 'canalArrival') && Object.hasOwn(row, 'positionReceived')) {
      sortInitName = 'canalArrival';
      sortInitOrder = 'asc';
    } else if (Object.hasOwn(row, 'eta')) {
      sortInitName = 'eta';
      sortInitOrder = 'asc';
    }
  });

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: title !== 'In Transit',
    download: checkUserHasAccess(user, true) && title !== 'In Transit',
    setTableProps: () => {
      return {
        id: 'vessel-port-calls-table'
      };
    },
    downloadOptions: {
      filename: fileName
    },
    print: false,
    viewColumns: false,
    pagination: title !== 'In Transit',
    toolbar: title !== 'In Transit',
    elevation: 0,
    tableBodyMaxHeight: title !== 'In Transit' ? '80vh' : '30vh',
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: sortInitName,
      direction: sortInitOrder
    },
    onDownload: (buildHead, buildBody, propColumns, propData) => {
      const tableData = [...propData].map((tableRow) => {
        const res = [];
        Object.keys(tableRow.data).forEach((key) => {
          const val = tableRow.data[key];
          if (val && typeof val === 'object') {
            if (Object.hasOwn(val, 'destinationName')) {
              res[Number(key)] = val.destinationName;
            }
            if (Object.hasOwn(val, 'portName')) {
              res[Number(key)] = val.portName;
            }
          } else {
            res[Number(key)] = val;
          }
        });
        return { data: res, index: undefined };
      });
      return '\uFEFF' + buildHead(propColumns) + buildBody(tableData);
    }
  };

  return (
    <Paper id={'canal-vessel-portcalls-table'}>
      {tableData && (
        <Table
          className={'canal-overview-table'}
          customStyle={tableTitlePaddingLeft}
          loading={loading}
          mode={theme.mode}
          title={
            title !== 'In Transit' ? (
              <Stack sx={{ pb: 2, pt: 2 }}>
                <Typography id={'vessel-port-calls-title'} variant={'h6'}>
                  {title}
                </Typography>
              </Stack>
            ) : (
              ''
            )
          }
          data={tableData?.length ? tableData : []}
          columns={filteredColumns}
          options={options}
        />
      )}
    </Paper>
  );
}

CanalVesselPortCallsTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  assetLevel: PropTypes.oneOf(['canal']),
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  canalDeparturesData: PropTypes.arrayOf(PropTypes.object),
  hideColumns: PropTypes.arrayOf(PropTypes.string)
};

export default CanalVesselPortCallsTable;
