import { combineReducers } from '@reduxjs/toolkit';
import operatorComparisonReducer from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import vesselComparisonReducer from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';

const EmissionsAnalyticsReducer = combineReducers({
  operatorComparison: operatorComparisonReducer,
  vesselComparison: vesselComparisonReducer
});

export default EmissionsAnalyticsReducer;
