import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function VoyagePerformanceAnalysis({ voyagePerformanceData }) {
  return (
    <>
      {voyagePerformanceData.map(({ title, min, max, avg, score }, i) => {
        return (
          <Grid key={`table-analysis-${i}`} item xs={12}>
            <Paper variant={'outlined'}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    background: ({ palette }) => palette.background.default,
                    p: 2,
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                  }}>
                  <Grid item xs={12}>
                    <Typography variant={'body2'} color={'text.primary'}>
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sx={{
                    p: '16px 8px 8px 16px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                  }}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Average (days)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>{avg?.toFixed(1)}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sx={{
                    p: '16px 8px 8px 16px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                  }}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Max (days)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>{max?.toFixed(1) || '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sx={{
                    p: '16px 8px 8px 16px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                  }}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>Min (days)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>{min?.toFixed(1) || '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sx={{
                    p: '16px 8px 8px 16px'
                  }}>
                  <Grid item xs={6}>
                    <TextWithTooltipIcon
                      label={<Typography variant={'body2'}>Consistency Score</Typography>}
                      tooltipText={tooltipConsistencyContents}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>{score}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </>
  );
}

VoyagePerformanceAnalysis.propTypes = {
  voyagePerformanceData: PropTypes.arrayOf(PropTypes.object)
};

VoyagePerformanceAnalysis.defaultProps = {
  voyagePerformanceData: []
};

export default VoyagePerformanceAnalysis;
