import React from 'react';
import PropTypes from 'prop-types';
import PortListDisplay from '@components/Maps/TradeRouteMap/PortListDisplay';
import MapFiltersDisplay from '@components/Maps/TradeRouteMap/MapFiltersDisplay';
import { useTheme } from '@hooks/useTheme';

function MenuDisplayWrapper({
  selectedMenu,
  mapHeight,
  map,
  ports,
  loading,
  newPortsOnly,
  setNewPortsOnly,
  showPortMarkers,
  setShowPortMarkers,
  selectedPort,
  setSelectedPort,
  selectedQuarter
}) {
  const {
    theme: {
      palette: {
        background: { paper },
        border: { light, dark }
      }
    },
    themeMode
  } = useTheme();

  return (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.25) 2px 4px 4px 0px',
        clipPath: 'inset(0px -15px 0px 0px)',
        borderRight: `1px solid ${themeMode ? dark : light}`,
        borderTop: `1px solid ${themeMode ? dark : light}`,
        width: '15vw',
        transition: 'all 0.1s ease-in',
        height: `${mapHeight}`,
        display: 'flex',
        maxHeight: `${mapHeight}`,
        backgroundColor: paper
      }}>
      {selectedMenu === 1 && (
        <PortListDisplay
          selectedPort={selectedPort}
          setSelectedPort={setSelectedPort}
          loading={loading}
          map={map}
          ports={ports}
          themeMode={themeMode}
          mapHeight={mapHeight}
        />
      )}
      {selectedMenu === 2 && (
        <MapFiltersDisplay
          showPortMarkers={showPortMarkers}
          setShowPortMarkers={setShowPortMarkers}
          newPortsOnly={newPortsOnly}
          setNewPortsOnly={setNewPortsOnly}
          selectedQuarter={selectedQuarter}
        />
      )}
    </div>
  );
}

MenuDisplayWrapper.propTypes = {
  selectedMenu: PropTypes.number,
  mapHeight: PropTypes.string,
  loading: PropTypes.bool,
  map: PropTypes.object,
  ports: PropTypes.arrayOf(PropTypes.object),
  newPortsOnly: PropTypes.bool,
  setNewPortsOnly: PropTypes.func.isRequired,
  showPortMarkers: PropTypes.bool,
  setShowPortMarkers: PropTypes.func.isRequired,
  selectedPort: PropTypes.object,
  setSelectedPort: PropTypes.func.isRequired,
  selectedQuarter: PropTypes.string.isRequired
};

MenuDisplayWrapper.defaultProps = {
  selectedMenu: 1,
  mapHeight: '70vh',
  map: null,
  ports: [],
  loading: false,
  newPortsOnly: false,
  showPortMarkers: false,
  selectedPort: null
};

export default MenuDisplayWrapper;
