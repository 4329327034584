import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, Typography } from '@esgian/esgianui';
import InsightHeaderWithTimeFilter from './InsightHeaderWithTimeFilter';
import { useGlobalPortWaitingTimeChart } from '../../../../Charts/GlobalAveragePortWaitingTimeChart/GlobalAveragePortWaitingTimeChart';
import { formatToDecimalPlaces } from '@helpers';
import { ProfileLink } from '@components/Links';

function GlobalPortWaitingTimeInsight({ globalPortCongestion }) {
  const { series, options, monthlyComparison } = useGlobalPortWaitingTimeChart(
    globalPortCongestion,
    true
  );

  const mainInsight = [
    {
      label: 'Global avg port waiting time (Days)',
      value: formatToDecimalPlaces(monthlyComparison?.avgWaitingTimeDays, 2),
      increase: formatToDecimalPlaces(monthlyComparison?.avgWaitingTimePercent, 2)
    },
    {
      label: 'Total waiting time (Days)',
      value: formatToDecimalPlaces(monthlyComparison?.totalWaitingTimeDays, 2),
      increase: formatToDecimalPlaces(monthlyComparison?.totalWaitingTimePercent, 2)
    },
    {
      label: 'Number of vessels',
      value: formatToDecimalPlaces(monthlyComparison?.numOfVessels, 2),
      increase: formatToDecimalPlaces(monthlyComparison?.numOfVesselsPercent, 2)
    },
    {
      label: 'Supply/Demand impact (Ship equivalent)',
      value: formatToDecimalPlaces(monthlyComparison?.supplyDemandImpact, 2)
    }
  ];

  const highestRateIncrease = [
    {
      label: 'Port - Avg port waiting time (Days)',
      value: monthlyComparison?.highestIncreasePortName || '-',
      id: monthlyComparison?.highestIncreasePortId
    }
  ];

  const highestRateDecrease = [
    {
      label: 'Port - Avg port waiting time (Days)',
      value: monthlyComparison?.highestDecreasePortName || '-',
      id: monthlyComparison?.highestDecreasePortId
    }
  ];

  return (
    <InsightHeaderWithTimeFilter
      title="Global avg port waiting time"
      filterOptions={null}
      renderChildren={() => (
        <Box display="flex" flexDirection="column" gap={2}>
          <ApexChart
            loading={!series.length || !options}
            data={series}
            height={350}
            options={options || {}}
            type={'bar'}
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <Box>
              <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary' }}>
                Main Insights
              </Typography>
              <Typography variant="caption">Latest completed month vs previous month</Typography>
            </Box>
            <ul
              style={{
                padding: 0,
                margin: 0,
                paddingLeft: 32,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {mainInsight.map((item) => (
                <li key={item.label}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                    <Box display="flex" gap={0.5} alignItems="center">
                      <Typography sx={{ fontSize: '14px' }}>{item.value}</Typography>
                      {item.increase && (
                        <Typography
                          sx={{
                            color: item.increase > 0 ? 'success.main' : 'error.main',
                            fontSize: '0.7rem'
                          }}>
                          {item.increase > 0 ? `+${item.increase}%` : `${item.increase}%`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>

            <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary', mt: 2 }}>
              Highest Rate of Increase
            </Typography>
            <ul
              style={{
                padding: 0,
                margin: 0,
                paddingLeft: 32,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {highestRateIncrease.map((item) => (
                <li key={item.label}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                    <ProfileLink
                      uriParams={'section=2'}
                      profile={'port'}
                      id={item?.id}
                      name={item.value}
                      linkVariant="body1"
                      textAlign="right"
                      fontSize="14px"
                    />
                  </Box>
                </li>
              ))}
            </ul>

            <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary', mt: 2 }}>
              Highest Rate of Decrease
            </Typography>

            <ul
              style={{
                padding: 0,
                margin: 0,
                paddingLeft: 32,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {highestRateDecrease.map((item) => (
                <li key={item.label}>
                  <Box display="flex" justifyContent="space-between" gap={1}>
                    <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                    <ProfileLink
                      uriParams={'section=2'}
                      profile={'port'}
                      id={item?.id}
                      name={item.value}
                      linkVariant="body1"
                      textAlign="right"
                      fontSize="14px"
                    />
                  </Box>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    />
  );
}

GlobalPortWaitingTimeInsight.propTypes = {
  globalPortCongestion: PropTypes.object
};

export default GlobalPortWaitingTimeInsight;
