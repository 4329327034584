import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ColorBox, Divider, Grid, Paper, Typography } from '@esgian/esgianui';
import { VesselTransitTimeAndSpread } from '@components/Sections/ShipAnalytics/OperatorPerformanceSection';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function TransitTimePerformanceOverview({ performanceData, dateRangeList }) {
  const [operatorVsSegmentData, setOperatorVsSegmentData] = useState([]);
  const [operatorName, setOperatorName] = useState(null);

  const createOperatorVsSegmentData = (
    months,
    operatorTransitResult,
    segmentTransitResult,
    numInSegment
  ) => {
    let singleOperator = numInSegment === 1;
    let tempData = [];
    let {
      segmentOperatorMonthlyPerformance: segmentMonthlyTransitResults,
      transitMax: segMax,
      transitMin: segMin,
      transitStats: segStats
    } = segmentTransitResult;
    const { consistencyScore: segScore, average: segAverage } = segStats;
    const {
      operatorMonthlyTransitResults,
      transitMin: oprMin,
      transitMax: oprMax,
      transitStats: oprStats
    } = operatorTransitResult;
    const { consistencyScore: oprScore, average: oprAverage } = oprStats;

    months.forEach((month, index) => {
      let oprAvg = operatorMonthlyTransitResults[index]?.operatorTransitAverage
        ? operatorMonthlyTransitResults[index]?.operatorTransitAverage
        : 0;
      let segAvg = segmentMonthlyTransitResults[index]?.segmentTransitAverage
        ? segmentMonthlyTransitResults[index]?.segmentTransitAverage
        : 0;

      let performance = ((segAvg - oprAvg) / oprAvg) * 100;

      if (!segAvg && !oprAvg) {
        performance = null;
      }

      tempData.push({
        month: month,
        seg: !segAvg ? null : segAvg.toFixed(1),
        opr: !oprAvg ? null : oprAvg.toFixed(1),
        performance: !singleOperator ? performance?.toFixed(0) || null : null
      });
    });
    tempData.push({
      month: 'Average',
      seg: !singleOperator ? segAverage.toFixed(1) : null,
      opr: oprAverage.toFixed(1),
      highlight: true,
      performance: !singleOperator
        ? (((segAverage - oprAverage) / oprAverage) * 100).toFixed(0)
        : null
    });
    tempData.push({
      month: 'Quickest',
      seg: !singleOperator ? segMin.toFixed(1) : null,
      opr: oprMin?.toFixed(1),
      performance: !singleOperator ? (((segMin - oprMin) / segMin) * 100).toFixed(0) : null
    });
    tempData.push({
      month: 'Slowest',
      seg: !singleOperator ? segMax.toFixed(1) : null,
      opr: oprMax?.toFixed(1),
      performance: !singleOperator ? (((segMax - oprMax) / oprMax) * 100).toFixed(0) : null
    });
    tempData.push({
      month: 'Consistency',
      hint: tooltipConsistencyContents,
      highlight: true,
      seg: !singleOperator ? segScore : null,
      opr: oprScore ? oprScore : null,
      performance: !singleOperator ? (((oprScore - segScore) / segScore) * 100).toFixed(0) : null
    });
    setOperatorVsSegmentData(tempData);
  };

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      return;
    }
    const { operatorPerformance, segmentPerformance } = performanceData;
    let { transitResult } = operatorPerformance;
    let { segmentTransitResult, numberOfOperatorsInSegment } = segmentPerformance;
    transitResult = transitResult[0];
    const { operatorLongName } = transitResult;
    createOperatorVsSegmentData(
      dateRangeList,
      transitResult,
      segmentTransitResult,
      numberOfOperatorsInSegment
    );
    setOperatorName(operatorLongName);
  }, [performanceData]);

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <VesselTransitTimeAndSpread
            dateRangeList={dateRangeList}
            performanceData={performanceData}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper variant={'outlined'}>
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    background: ({ palette }) => palette.background.paper,
                    p: 2,
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px'
                  }}>
                  <Grid item xs={3}>
                    <Typography variant={'body2'} bold color={'text.secondary'}>
                      Period
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={'body2'} bold color={'text.secondary'}>
                      {`${operatorName} Average`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={'body2'} bold color={'text.secondary'}>
                      Segment Average
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={'body2'} bold color={'text.secondary'}>
                      {`${operatorName} Performance`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {!!operatorVsSegmentData.length &&
                operatorVsSegmentData?.map(
                  ({ month, hint, seg, opr, performance, highlight }, i) => {
                    return (
                      <Grid
                        key={`${month}-vs-${i}`}
                        item
                        xs={12}
                        sx={{
                          pl: 2,
                          pt: 1,
                          pb: 1,
                          pr: 2,
                          backgroundColor: ({ palette }) =>
                            highlight ? palette.background.default : '',
                          borderBottom:
                            i !== operatorVsSegmentData.length - 1
                              ? '1px solid rgba(0, 0, 0, 0.12)'
                              : ''
                        }}>
                        <Grid container alignItems={'center'}>
                          <Grid item xs={3}>
                            {!hint ? (
                              <Typography bold={highlight} variant={'body2'}>
                                {month}
                              </Typography>
                            ) : (
                              <TextWithTooltipIcon
                                labelBold
                                tooltipText={hint}
                                label={month}
                                labelVariant={'body2'}
                                variant={'body2'}
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography bold={highlight} variant={'body2'}>
                              {opr ? opr : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography bold={highlight} variant={'body2'}>
                              {seg ? seg : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {performance && isFinite(performance) ? (
                              <ColorBox
                                color={
                                  performance === 0 ? 'info' : performance > 0 ? 'success' : 'error'
                                }>
                                <Typography variant={'body2'} bold>
                                  {performance}%
                                </Typography>
                              </ColorBox>
                            ) : (
                              <Typography bold sx={{ p: 1, m: '4px' }} variant={'body2'}>
                                {'N/A'}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

TransitTimePerformanceOverview.propTypes = {
  performanceData: PropTypes.object,
  dateRangeList: PropTypes.arrayOf(PropTypes.string)
};

TransitTimePerformanceOverview.defaultProps = {
  performanceData: null,
  dateRangeList: []
};

export default TransitTimePerformanceOverview;
