import React from 'react';
import { FormGroup, FormLabel, TextField, DatePicker, Grid, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { DATE_FORMAT } from '@constants';

// -------  Options for input validation  ------------
export const getFilterInputValidationList = () => {
  return {
    render: (value) => {
      if (value[0] && value[0] > -1) {
        return `${value[0]}`;
      }
      return [];
    }
  };
};

export const getFilterInputValidationOption = (fieldName) => {
  return {
    names: [],
    logic(value, filters) {
      if (filters[0]) {
        return !`${value}`.includes(filters[0]);
      }
      return false;
    },
    display: (filterList, onChange, index, column) => (
      <div>
        <FormLabel></FormLabel>
        <FormGroup>
          <TextField
            variant={'standard'}
            label={fieldName}
            type={'number'}
            InputProps={{ inputProps: { min: 0 } }}
            value={filterList[index][0] || ''}
            onChange={(event) => {
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '100%' }}
          />
          {filterList[index] < 0 && (
            <Typography variant={'caption'} color={'error.main'}>
              The value must be greater than or equal 0
            </Typography>
          )}
        </FormGroup>
      </div>
    )
  };
};

// -------- Options for min max numeric value filter ----------

export const getMinMaxFilterListOptions = (fieldName) => {
  return {
    render: (v) => {
      if (v[0] && v[1] && v[0] > v[1]) {
        return [`Max ${fieldName}: ${v[1]}`, `Min ${fieldName}: ${v[0]}`];
      } else if (v[0] && v[1]) {
        return [`Min ${fieldName}: ${v[0]}`, `Max ${fieldName}: ${v[1]}`];
      } else if (v[0]) {
        return `Min ${fieldName}: ${v[0]}`;
      } else if (v[1]) {
        return `Max ${fieldName}: ${v[1]}`;
      }
      return [];
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    }
  };
};

const minMaxFilterLogic = (size, filters) => {
  if (filters[0] && filters[1]) {
    return size < filters[0] || size > filters[1];
  } else if (filters[0]) {
    return size < filters[0];
  } else if (filters[1]) {
    return size > filters[1];
  }
  return false;
};
export const getMinMaxFilterOptions = (formLabel, costumeFilterLogic) => {
  return {
    names: [],
    logic(size, filters) {
      if (costumeFilterLogic) {
        return costumeFilterLogic(size, filters);
      }
      return minMaxFilterLogic(size, filters);
    },
    display: (filterList, onChange, index, column) => {
      return (
        <div>
          <FormLabel>{formLabel}</FormLabel>
          <FormGroup row>
            <TextField
              variant={'standard'}
              type={'number'}
              InputProps={{ inputProps: { min: 0 } }}
              label="Min"
              value={filterList[index][0] || ''}
              onChange={(event) => {
                if (event.target.value >= 0) {
                  filterList[index][0] = event.target.value;
                } else {
                  filterList[index][0] = 0;
                }
                onChange(filterList[index], index, column);
              }}
              style={{ width: '45%', marginRight: '5%' }}
            />
            <TextField
              variant={'standard'}
              type={'number'}
              label="Max"
              InputProps={{ inputProps: { min: 0 } }}
              value={filterList[index][1] || ''}
              onChange={(event) => {
                if (event.target.value >= 0) {
                  filterList[index][1] = event.target.value;
                } else {
                  filterList[index][1] = 0;
                }
                onChange(filterList[index], index, column);
              }}
              style={{ width: '45%' }}
            />
          </FormGroup>
          {!isNaN(parseFloat(filterList[index][0])) &&
            !isNaN(parseFloat(filterList[index][1])) &&
            parseFloat(filterList[index][0]) > parseFloat(filterList[index][1]) &&
            filterList[index][1] !== 0 && (
              <Typography variant={'caption'} color={'error.main'}>
                Max must be greater than min
              </Typography>
            )}
        </div>
      );
    }
  };
};

// -------- Options for date between filter ----------
export const getDateBetweenFilterListOptions = (startName = 'Start', inputFormat = DATE_FORMAT) => {
  return {
    //TODO maybe change this to 1 chip
    render: (v) => {
      if (v[0] && v[1]) {
        return [
          `${startName} Between: ${moment(v[0], inputFormat).format(inputFormat)} - ${moment(
            v[1],
            inputFormat
          ).format(inputFormat)}`
        ];
      } else if (v[0] == null && v[1]) {
        return `${startName} To : ${moment(v[1], inputFormat).format(inputFormat)}`;
      } else if (v[1] == null && v[0]) {
        return `${startName} From : ${moment(v[0], inputFormat).format(inputFormat)}`;
      }
      return [];
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0 || filterPos === -1) {
        filterList[index] = [];
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      }

      return filterList;
    }
  };
};

export const getDateBetweenFilterOptions = (formLabel, inputFormat = DATE_FORMAT) => {
  return {
    names: [],
    logic(date, filters) {
      if (filters[0] && filters[1]) {
        return !moment(date, inputFormat).isBetween(filters[0], filters[1], 'day', '[]');
      } else if (filters[0]) {
        return moment(date, inputFormat).isBefore(filters[0]);
      } else if (filters[1]) {
        return moment(date, inputFormat).isBefore(filters[1]);
      }
      return false;
    },
    display: (filterList, onChange, index, column) => (
      <div>
        <FormLabel>{formLabel}</FormLabel>
        <FormGroup row>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                label={'From'}
                disableFuture
                inputFormat={DATE_FORMAT}
                minDate={moment('01/11/2020', 'DD/MM/yyyy')}
                maxDate={moment(filterList[index][1] || moment())}
                onChange={(event) => {
                  filterList[index][0] = event;
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => <TextField variant={'standard'} fullWidth {...params} />}
                value={filterList[index][0]}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={'To'}
                disableFuture
                inputFormat={DATE_FORMAT}
                minDate={moment(filterList[index][0] || '01/11/2020', 'DD/MM/yyyy')}
                onChange={(event) => {
                  filterList[index][1] = event;
                  onChange(filterList[index], index, column);
                }}
                renderInput={(params) => <TextField variant={'standard'} fullWidth {...params} />}
                value={filterList[index][1]}
              />
            </Grid>
          </Grid>
        </FormGroup>
        {filterList[index][0] &&
          filterList[index][1] &&
          filterList[index][0] > filterList[index][1] && (
            <Typography variant={'caption'} color={'error.main'}>
              Please select a valid date
            </Typography>
          )}
      </div>
    )
  };
};
