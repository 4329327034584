import { Box, Typography } from '@esgian/esgianui';
import React from 'react';
import { useSegment } from '@hooks';

const MeasureSelectTooltipContent = () => {
  const { isRoRo } = useSegment();
  const capacityUnit = isRoRo ? 'CEU Capacity' : 'Deadweight';
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption">
        The port waiting time figures present the aggregated results of underlying vessel anchor
        events. A vessel anchor event is defined as the time a vessel is either stationary at anchor
        or drifting at the port anchorage area before entering port and laying berth.
      </Typography>
      <Typography my={1} variant="caption">
        The port waiting time is given in four different measures: “Total waiting time”, “Avg.
        waiting time”, “No. of anchor events” and ”{capacityUnit}”. All measures are allocated to
        respective week/month meaning anchor events crossing into next week/month will have their
        time split.
      </Typography>
      <Box display="flex" alignItems="flex-start">
        <Typography fontWeight="bold" flexShrink={0} width="170px" variant="caption">
          Total waiting time:
        </Typography>
        <Typography variant="caption">
          The sum of all anchor times in respective week/month.
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Typography fontWeight="bold" flexShrink={0} width="170px" variant="caption">
          Number of anchor events:
        </Typography>
        <Typography variant="caption">
          The count of distinct anchor events in respective week/month.
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Typography fontWeight="bold" flexShrink={0} width="170px" variant="caption">
          Average waiting time:
        </Typography>
        <Typography variant="caption">
          The sum of all anchor times divided by number of anchor events in respective week/month.
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Typography fontWeight="bold" flexShrink={0} width="170px" variant="caption">
          {isRoRo ? 'CEU capacity:' : 'Deadweight tonnage:'}
        </Typography>
        <Typography variant="caption">
          Corresponding sum of vessel {isRoRo ? 'CEU' : 'DWT'} capacity of vessels at anchor the
          respective week/month.
        </Typography>
      </Box>
    </Box>
  );
};

export default MeasureSelectTooltipContent;
