import React from 'react';
import PropTypes from 'prop-types';
import { AnchorIcon, Divider, Grid, List, ListItemButton, TuneIcon } from '@esgian/esgianui';
import { useTheme } from '@hooks/useTheme';

function MapControllerBar({ setSelectedMenu, selectedMenu }) {
  const {
    theme: {
      palette: {
        background: { paper },
        border: { light, dark },
        neutral: { neutral01 }
      }
    },
    themeMode
  } = useTheme();

  return (
    <div
      style={{
        borderRight: `1px solid ${themeMode ? dark : light}`,
        borderTop: `1px solid ${themeMode ? dark : light}`,
        display: 'flex',
        // position: 'absolute',
        zIndex: 1,
        top: '0',
        transition: 'all 0.1s ease-in',
        left: '0',
        height: '100%',
        width: '3vw',
        backgroundColor: paper
      }}>
      <Grid container>
        <Grid item xs={12}>
          <List sx={{ p: 0 }}>
            <ListItemButton
              onClick={() => setSelectedMenu(1)}
              sx={{
                borderRight: '2px transparent',
                borderLeft: (theme) =>
                  `2px solid ${selectedMenu === 1 ? theme.palette.primary.main : 'transparent'}`,
                p: 2,
                background: selectedMenu === 1 ? neutral01 : 'transparent',
                justifyContent: 'center'
              }}>
              <AnchorIcon fontSize={'small'} color={selectedMenu === 1 ? 'primary' : 'secondary'} />
            </ListItemButton>
            <Divider />
            <ListItemButton
              onClick={() => setSelectedMenu(2)}
              sx={{
                borderRight: '2px transparent',
                background: selectedMenu === 2 ? neutral01 : 'transparent',
                borderLeft: (theme) =>
                  `2px solid ${selectedMenu === 2 ? theme.palette.primary.main : 'transparent'}`,
                p: 2,
                justifyContent: 'center'
              }}>
              <TuneIcon fontSize={'small'} color={selectedMenu === 2 ? 'primary' : 'secondary'} />
            </ListItemButton>
            <Divider />
          </List>
        </Grid>
      </Grid>
    </div>
  );
}

MapControllerBar.propTypes = {
  setSelectedMenu: PropTypes.func.isRequired,
  selectedMenu: PropTypes.number
};

MapControllerBar.defaultProps = {
  selectedMenu: 1
};

export default MapControllerBar;
