import React, { useMemo, useState } from 'react';

import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Divider,
  Box
} from '@esgian/esgianui';
import MonthlyPortWaitingTable from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalWaitingTimeSection/MonthlyPortWaitingTable';
import WeeklyPortWaitingChart from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalWaitingTimeSection/WeeklyPortWaitingChart';
import MonthlyPortWaitingChart from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalWaitingTimeSection/MonthlyPortWaitingChart';
import moment from 'moment';
import { useSegment } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import MeasureSelectTooltipContent from './MeasureSelectTooltipContent';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { GEOGRAPHICAL_TYPES, DATE_FORMAT, SEGMENT } from '@constants';
import { getCanalWaiting } from '@api';
import { useQuery } from '@tanstack/react-query';

function CanalWaitingTimeSection() {
  const [measureSelect, setMeasureSelect] = useState(1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();
  const endDate = moment();
  let startDate = moment(segment.id === SEGMENT.RoRo.id ? '2021-01-01' : '2022-01-01');

  function getMeasureInfo(measureSelect, assetLevel) {
    switch (measureSelect) {
      case 1:
        return { id: 1, type: 'average ' + assetLevel + ' waiting time', unit: 'Hours' };
      case 2:
        return { id: 2, type: 'total ' + assetLevel + ' waiting time', unit: 'Days' };
      case 3:
        return segment.id === 1
          ? { id: 3, type: 'CEU Capacity', unit: 'CEU' }
          : { id: 3, type: 'Deadweight', unit: 'DWT' };
      case 4:
        return { id: 4, type: 'number of anchor events', unit: 'Anchor events' };
      default:
        return { type: 'waiting-time', unit: '' };
    }
  }

  const CWTQuery = useQuery({
    queryKey: ['canal', 'waiting-time', segment.id, operatorType, commercialCategoryType],
    enabled: !!startDate && !!endDate,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      const body = {
        start: `${moment(startDate).format(DATE_FORMAT)}Z`,
        end: `${moment(endDate).add(1, 'day').format(DATE_FORMAT)}Z`,
        operatorTypeId: queryKey[3],
        segmentTypeId: queryKey[2],
        commercialCategoryId: queryKey[4],
        geoId: params.canalId,
        geoType: GEOGRAPHICAL_TYPES.CANAL,
        congestionType: 2
      };
      return getCanalWaiting(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });
  const years = useMemo(() => {
    let res = [];
    for (let i = startDate.year(); i <= endDate.year(); i++) {
      res.push(i);
    }
    return res;
  }, []);

  return (
    <Paper variant={'outlined'} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item lg={5.5} className={'section-filter'}>
            <Grid item xs={8}>
              <Box display="flex">
                <FormControl variant="outlined" sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="measure-select-label">
                    <TextWithTooltipIcon
                      label="Measure"
                      tooltipText={<MeasureSelectTooltipContent />}
                      labelVariant={'inherit'}
                      labelBold={false}
                      color={'inherit'}
                      tooltipMaxWidth="600px"
                    />
                  </InputLabel>
                  <Select
                    value={measureSelect}
                    labelId="port-waiting-tab-measure-select-label"
                    variant="outlined"
                    label={
                      <TextWithTooltipIcon
                        label={'Measure'}
                        color={'inherit'}
                        labelVariant={'inherit'}
                        labelBold={false}
                        tooltipMaxWidth="600px"
                        tooltipText={<MeasureSelectTooltipContent />}
                      />
                    }
                    onChange={(event) => setMeasureSelect(parseInt(event.target.value))}
                    id="port-waiting-tab-measure-select"
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value={1}>Avg. waiting time</MenuItem>
                    <MenuItem value={2}>Total waiting time</MenuItem>
                    {segment.id === 1 ? (
                      <MenuItem value={3}>CEU Capacity</MenuItem>
                    ) : (
                      <MenuItem value={3}>Deadweight</MenuItem>
                    )}
                    <MenuItem value={4}>No. of anchor events</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <Divider />
          </Grid>
          <WeeklyPortWaitingChart
            portCongestionData={CWTQuery.data}
            loading={CWTQuery.isFetching}
            selectedMeasure={getMeasureInfo(measureSelect, 'canal')}
            fileName={`${'canalName'?.split(' ').join('-')}-weekly-${
              getMeasureInfo(measureSelect, 'canal').type
            }`}
          />
          <MonthlyPortWaitingChart
            portCongestionData={CWTQuery.data}
            loading={CWTQuery.isFetching}
            selectedMeasure={getMeasureInfo(measureSelect, 'canal')}
            fileName={`${'canalName'?.split(' ').join('-')}-monthly-${
              getMeasureInfo(measureSelect, 'canal').type
            }`}
          />
          <MonthlyPortWaitingTable
            portCongestionData={CWTQuery.data}
            loading={CWTQuery.isFetching}
            yearOptions={years}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            selectedMeasure={getMeasureInfo(measureSelect, 'canal')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CanalWaitingTimeSection;
