import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@esgian/esgianui';
import moment from 'moment';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal/UnderlyingCanalStatsModal';
import { getDirection } from '@helpers';
import { useTheme } from '@hooks/useTheme';

const getDefaultOperatorSetWeek = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (
    startDateClone &&
    endDateClone &&
    startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
  ) {
    let weekNumber = startDateClone.isoWeek();
    let yearNumber = startDateClone.isoWeekYear();
    if (startDateClone.isoWeekYear() < startDateClone.year()) {
      startDateClone.add(1, 'isoWeek');
      continue;
    }
    let key = `${yearNumber}_${weekNumber}`;
    res[key] = {
      year: yearNumber,
      week: weekNumber,
      value: 0,
      modalData: {}
    };

    startDateClone.add(1, 'isoWeek');
  }
  return res;
};

const getDefaultOperatorSetMonth = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (startDateClone && endDateClone && startDateClone.isSameOrBefore(endDateClone)) {
    let monthNumber = startDateClone.month();
    let yearNumber = startDateClone.year();
    let key = `${yearNumber}_${monthNumber}`;
    res[key] = {
      year: yearNumber,
      month: monthNumber,
      value: 0,
      modalData: {}
    };

    startDateClone.add(1, 'month');
  }
  return res;
};

function CanalTransitsTableOpDir({
  startDate = null,
  endDate = null,
  loading = false,
  canalTransits = null,
  timeFrame = 'weekly',
  fileName = ''
}) {
  const user = useSelector(getUser);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const { customScrollbar, theme } = useTheme();

  const weeklyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(
      ({
        canalArrival,
        operatorId,
        operatorShortName,
        headingDirection,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit
      }) => {
        if (headingDirection !== 0) {
          if (!dataSet[operatorId]) {
            dataSet[operatorId] = {
              operatorName: operatorShortName
            };
          }
          if (!dataSet[operatorId][headingDirection]) {
            dataSet[operatorId][headingDirection] = {
              directionName: getDirection(headingDirection),
              data: getDefaultOperatorSetWeek(startDate, endDate)
            };
          }
          let arrival = moment(canalArrival);
          let weekNumber = arrival.clone().isoWeek();
          let yearNumber = arrival.clone().isoWeekYear();
          if (
            !Object.hasOwn(
              dataSet[operatorId][headingDirection].data,
              `${yearNumber}_${weekNumber}`
            )
          ) {
            if (
              Object.hasOwn(dataSet[operatorId][headingDirection].data, `${yearNumber + 1}_${1}`)
            ) {
              yearNumber++;
              weekNumber = 1;
            } else {
              yearNumber--;
              weekNumber = 52;
            }
          }
          let key = `${yearNumber}_${weekNumber}`;
          dataSet[operatorId][headingDirection].data[key].value += 1;

          if (
            !dataSet[operatorId][headingDirection].data[key].modalData[`${imo}_${canalArrival}`]
          ) {
            dataSet[operatorId][headingDirection].data[key].modalData[`${imo}_${canalArrival}`] = {
              vesselName: vesselName,
              imo: imo,
              operatorLongName: operatorLongName,
              canalArrival: canalArrival,
              canalDeparture: canalDeparture,
              transit: moment.duration(transit).asHours().toFixed(2),
              headingDirection: getDirection(headingDirection),
              year: yearNumber,
              period: weekNumber,
              operatorId: operatorId
            };
          }
        }
      }
    );

    return dataSet;
  }, [canalTransits]);

  const monthlyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(
      ({
        canalArrival,
        operatorId,
        operatorShortName,
        headingDirection,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit
      }) => {
        if (headingDirection !== 0) {
          if (!dataSet[operatorId]) {
            dataSet[operatorId] = {
              operatorName: operatorShortName
            };
          }
          if (!dataSet[operatorId][headingDirection]) {
            dataSet[operatorId][headingDirection] = {
              directionName: getDirection(headingDirection),
              data: getDefaultOperatorSetMonth(startDate, endDate)
            };
          }
          let arrival = moment(canalArrival);
          let monthNumber = arrival.clone().month();
          let yearNumber = arrival.clone().year();
          let key = `${yearNumber}_${monthNumber}`;
          dataSet[operatorId][headingDirection].data[key].value += 1;

          if (
            !dataSet[operatorId][headingDirection].data[key].modalData[`${imo}_${canalArrival}`]
          ) {
            dataSet[operatorId][headingDirection].data[key].modalData[`${imo}_${canalArrival}`] = {
              vesselName: vesselName,
              imo: imo,
              operatorLongName: operatorLongName,
              canalArrival: canalArrival,
              canalDeparture: canalDeparture,
              transit: moment.duration(transit).asHours().toFixed(2),
              headingDirection: getDirection(headingDirection),
              year: yearNumber,
              period: moment().month(monthNumber).format('MMM'),
              operatorId: operatorId
            };
          }
        }
      }
    );

    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  let timeColumns = {};
  let opNames = {};

  if (timeFrame === 'weekly') {
    Object.keys(weeklyTableData).forEach((operatorId) => {
      const opData = weeklyTableData[operatorId];

      opNames[operatorId] = {
        operatorName: weeklyTableData[operatorId].operatorName
      };
      Object.keys(opData).forEach((directionId) => {
        if (directionId !== 'operatorName') {
          const dirData = opData[directionId].data;
          opNames[operatorId][directionId] = {
            directionName: weeklyTableData[operatorId][directionId].directionName,
            data: {},
            total: {
              value: 0,
              modalData: {}
            }
          };
          opNames[operatorId][directionId].data = dirData;

          Object.keys(dirData).forEach((weeklyData) => {
            const weekData = dirData[weeklyData];
            opNames[operatorId][directionId].total.value += dirData[weeklyData].value;
            opNames[operatorId][directionId].total.modalData = {
              ...opNames[operatorId][directionId].total.modalData,
              ...weekData.modalData
            };

            if (!yearGroups.find(({ mentionedYear }) => mentionedYear === weekData.year)) {
              yearGroups.push({
                mentionedYear: weekData.year,
                yearCount: 0
              });
              Object.keys(dirData).forEach((opRow) => {
                if (
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === weekData.year
                  )
                ) {
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === weekData.year
                  ).yearCount++;
                }
              });
            }
            if (!timeColumns[`${weekData.year}_${weekData.week}`]) {
              timeColumns[`${weekData.year}_${weekData.week}`] = weekData.week;
            }
          });
        }
      });
    });
  } else {
    Object.keys(monthlyTableData).forEach((operatorId) => {
      const opData = monthlyTableData[operatorId];

      opNames[operatorId] = {
        operatorName: monthlyTableData[operatorId].operatorName
      };
      Object.keys(opData).forEach((directionId) => {
        if (directionId !== 'operatorName') {
          const dirData = opData[directionId].data;
          opNames[operatorId][directionId] = {
            directionName: monthlyTableData[operatorId][directionId].directionName,
            data: {},
            total: {
              value: 0,
              modalData: {}
            }
          };
          opNames[operatorId][directionId].data = dirData;

          Object.keys(dirData).forEach((monthlyData) => {
            const monthData = dirData[monthlyData];
            opNames[operatorId][directionId].total.value += dirData[monthlyData].value;
            opNames[operatorId][directionId].total.modalData = {
              ...opNames[operatorId][directionId].total.modalData,
              ...monthData.modalData
            };

            if (!yearGroups.find(({ mentionedYear }) => mentionedYear === monthData.year)) {
              yearGroups.push({
                mentionedYear: monthData.year,
                yearCount: 0
              });
              Object.keys(dirData).forEach((opRow) => {
                if (
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === monthData.year
                  )
                ) {
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === monthData.year
                  ).yearCount++;
                }
              });
            }
            if (!timeColumns[`${monthData.year}_${monthData.month}`]) {
              timeColumns[`${monthData.year}_${monthData.month}`] =
                moment().month(monthData.month).format('MMM') + ' ' + monthData.year;
            }
          });
        }
      });
    });
  }

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      fixedHeader: true,
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: fileName
      },
      setTableProps: () => ({
        id: timeFrame === 'weekly' ? 'weekly-canal-transits-table' : 'monthly-canal-transits-table'
      }),
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [weeklyTableData, monthlyTableData]);

  let convertedColumns = [];
  Object.keys(timeColumns).map((index) => {
    convertedColumns.push(timeColumns[index]);
  });

  let tableData = [];
  Object.keys(opNames).forEach((operatorId) => {
    const { operatorName } = opNames[operatorId];
    const opData = opNames[operatorId];
    Object.keys(opData).map((directionId) => {
      if (directionId !== 'operatorName') {
        const { data, directionName, total } = opNames[operatorId][directionId];

        const operatorData = [];
        Object.keys(data).map((key) => {
          operatorData.push(data[key]);
        });

        tableData.push({
          name: operatorName + ' ' + directionName,
          data: operatorData,
          total: total
        });
      }
    });
  });

  return (
    <>
      <TableContainer sx={{ maxHeight: 440, ...customScrollbar }} mode={theme.mode}>
        {opNames && (
          <BasicTable stickyHeader aria-label="sticky table" options={options}>
            {timeFrame === 'weekly' && (
              <TableHead>
                <TableRow>
                  {[{ isEmpty: true }, ...yearGroups].map(
                    ({ isEmpty, mentionedYear, yearCount }, i) => (
                      <TableCell
                        key={isEmpty ? i : mentionedYear}
                        align="center"
                        colSpan={isEmpty ? 1 : yearCount}>
                        <b>{mentionedYear ?? ''}</b>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
            )}
            <TableHead>
              <TableRow>
                {convertedColumns.length > 0 &&
                  [
                    timeFrame === 'weekly' ? 'Operator/Week' : 'Operator',
                    ...convertedColumns,
                    'Total'
                  ].map((val) => {
                    return (
                      <TableCell
                        key={convertedColumns.indexOf(val) + val}
                        align="center"
                        colSpan={1}>
                        <b>{val}</b>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                ...Object.keys(tableData).map((operatorId) => {
                  const { name, data, total } = tableData[operatorId];

                  return (
                    <TableRow key={operatorId}>
                      <TableCell colSpan={1} align="center">
                        <b>{name}</b>
                      </TableCell>
                      {Object.keys(data).map((key) => (
                        <TableCell
                          colSpan={1}
                          sx={{
                            cursor: 'pointer'
                          }}
                          align="center"
                          key={`${key}_${operatorId}`}
                          onClick={() => {
                            setModalOpen(true);
                            setModalData(data[key].modalData);
                          }}>
                          {data[key].value}
                        </TableCell>
                      ))}
                      <TableCell
                        colSpan={1}
                        sx={{
                          cursor: 'pointer'
                        }}
                        align="center"
                        onClick={() => {
                          setModalOpen(true);
                          setModalData(total.modalData);
                        }}>
                        {total.value}
                      </TableCell>
                    </TableRow>
                  );
                })
              ]}
            </TableBody>
          </BasicTable>
        )}
      </TableContainer>
      {modalOpen && Object.keys(modalData).length > 0 && (
        <UnderlyingCanalStatsModal
          modalOpen={modalOpen}
          modalData={modalData}
          setModalOpen={setModalOpen}
          setModalData={setModalData}
        />
      )}
    </>
  );
}

CanalTransitsTableOpDir.propTypes = {
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  timeFrame: PropTypes.string,
  fileName: PropTypes.string
};

export default CanalTransitsTableOpDir;
