import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal, Paper, Table, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { useTimezone } from '@hooks/useTimezone';
import moment from 'moment';

function UnderlyingPortStatsModal({ modalData, modalOpen, setModalOpen, setModalData }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const modalDataArray = [];
  const { getTimeZoneDisplay } = useTimezone();
  const year = moment(modalData[0]?.startDate).year();

  Object.keys(modalData).forEach((imo) => {
    modalDataArray.push(modalData[imo]);
  });
  let modalTitle = '';
  modalTitle += `${modalData[0].operatorLongName} - Port Calls ${year}`;

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: modalTitle.replaceAll(' ', '-')
      },
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [modalDataArray]);

  const tableColumns = [
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'operatorLongName',
      label: 'Operator',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'operator'} id={rowData[3]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'startDate',
      label: 'Arrival',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'endDate',
      label: 'Departure',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'timeInPort',
      label: 'Port Time (hours)',
      options: {
        sort: true,
        display: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          if (value != null) {
            return (value * 24).toFixed(2);
          }
          return '--';
        }
      }
    }
  ];

  return (
    <Modal
      handleClose={() => {
        setModalOpen(false);
        setModalData(null);
      }}
      title={modalTitle}
      open={modalOpen}
      size={'lg'}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" sx={{ p: 1 }}>
            {<b>Port Calls</b>}: {Object.keys(modalData)?.length}
          </Typography>
        </Grid>
      </Grid>

      {modalDataArray && (
        <Paper variant={'outlined'}>
          <Table
            customStyle={{
              MUIDataTableFilterList: { display: 'none' },
              MUIDataTableToolbar: {
                styleOverrides: {
                  root: {
                    minHeight: 0
                  }
                }
              }
            }}
            mode={theme.mode}
            title={''}
            data={modalDataArray}
            columns={tableColumns}
            options={options}
          />
        </Paper>
      )}
    </Modal>
  );
}

UnderlyingPortStatsModal.propTypes = {
  modalData: PropTypes.object,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired
};

UnderlyingPortStatsModal.defaultProps = {
  modalData: null,
  selectedLocation: null
};

export default UnderlyingPortStatsModal;
