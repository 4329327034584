import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal } from '@esgian/esgianui';

const ActionModal = ({
  title,
  open,
  content,
  onAgree,
  onCancel,
  agreeButtonLabel,
  cancelButtonLabel,
  isDisabledAgree
}) => {
  return (
    <Modal title={title} open={open} handleClose={onCancel}>
      <Box>{content}</Box>
      <Box display="flex" gap={2} justifyContent="center" alignItems="center">
        <Button variant="text" onClick={onCancel}>
          {cancelButtonLabel ?? 'Close'}
        </Button>
        <Button disabled={isDisabledAgree} onClick={onAgree} style={{ minWidth: '100px' }}>
          {agreeButtonLabel ?? 'Save'}
        </Button>
      </Box>
    </Modal>
  );
};

ActionModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  content: PropTypes.node,
  onAgree: PropTypes.func,
  onCancel: PropTypes.func,
  agreeButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  isDisabledAgree: PropTypes.bool
};

export default ActionModal;
