import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetVoyageType } from '../../../../pages/VoyageAnalytics/hooks/useGetVoyageType';
import { VoyageType } from '../VoyageUtils';
import { useSegment, useTheme } from '@hooks';
const VoyageGroupListPopup = ({
  savedGroups,
  setModalOpen,
  isSavingVoyageToGroup,
  isLoadingVoyageGroups,
  setIsOpenManageModal
}) => {
  const hasAnyGroup = savedGroups.length > 0;
  const location = useLocation();
  const navigate = useNavigate();
  const voyageType = useGetVoyageType();
  const { segmentName } = useSegment();
  const { customScrollbar } = useTheme();

  const isSimulatedVoyageGroup = location?.pathname.includes('simulated-voyage');

  return (
    <Box sx={{ width: '450px' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
        <Typography variant={'caption'}>
          {voyageType === VoyageType.ONGOING ? 'ONGOING' : 'COMPLETED'} VOYAGE GROUPS
        </Typography>
        <Stack spacing={1} direction={'row'}>
          <Button size={'small'} variant="text" onClick={() => setModalOpen(true)}>
            CREATE
          </Button>
          <Button
            size={'small'}
            disabled={savedGroups.length === 0}
            variant="text"
            onClick={() => setIsOpenManageModal(true)}>
            Manage
          </Button>
        </Stack>
      </Stack>

      {!hasAnyGroup && !isLoadingVoyageGroups && (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
          variant="body1"
          color="gray"
          px={1}>
          Hi, You have no group yet. Create one to start
        </Typography>
      )}
      {isLoadingVoyageGroups && (
        <Typography variant="body1" p={1}>
          Loading...
        </Typography>
      )}
      {!isLoadingVoyageGroups && hasAnyGroup && (
        <List sx={{ maxHeight: '200px', overflow: 'auto', ...customScrollbar }}>
          {savedGroups.map((group) => {
            return (
              <ListItem key={group.id} disablePadding>
                <ListItemButton
                  sx={{
                    py: 1,
                    px: 2,
                    display: 'block'
                  }}
                  id="voyage-group-list-item"
                  onClick={() => {
                    if (group.voyages.length === 0) {
                      return;
                    }
                    const navLink = isSimulatedVoyageGroup
                      ? `/${segmentName.toLowerCase()}/simulated-voyage-group/${group.id}`
                      : `/${segmentName.toLowerCase()}/voyage-group/${group.id}`;
                    navigate(navLink);
                  }}
                  disabled={isSavingVoyageToGroup || group.voyages.length === 0}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                      <ListItemText primary={group.name} />
                    </Box>
                    <Box>
                      <Typography variant="body2" color="gray">
                        ({group.voyages.length} voyages)
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

VoyageGroupListPopup.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  savedGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSavingVoyageToGroup: PropTypes.bool,
  isLoadingVoyageGroups: PropTypes.bool,
  setIsOpenManageModal: PropTypes.func.isRequired
};

export default VoyageGroupListPopup;
