import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import {
  getCountryDetail,
  getActivePorts,
  getCountryPortStats,
  getCountryUpcomingPortCalls,
  getCountryTopPorts
} from '@api/Countries';
import { DATE_FORMAT, DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import CountryProfileSection from '@components/Sections/ShipAnalytics/CountryProfileSection';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { getLatestPortCalls, getPortCongestion } from '@api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import { useQuery } from '@tanstack/react-query';

function CountryProfilePage() {
  const [countryDetailData, setCountryDetailData] = useState(null);
  const [countryPortsData, setCountryPortsData] = useState(null);
  const [countryTopPortsData, setCountryTopPortsData] = useState(null);
  const [countryPortCallsData, setCountryPortCallsData] = useState(null);
  const [countryUpcomingPortCallsData, setCountryUpcomingPortCallsData] = useState(null);
  const [countryPortStatsData, setCountryPortStats] = useState(null);
  const [portCongestionData, setPortCongestionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const operatorType = useSelector(getOperatorType);
  const { segment, isRoRo, segmentMinDate } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const routeChange = (path) => {
    navigate(path);
  };

  const countryTopPortsQuery = useQuery({
    queryKey: [
      'countryOverview',
      params.countryId,
      selectedYear,
      operatorType,
      segment.id,
      commercialCategoryType
    ],
    enabled: true,
    placeholderData: null,
    queryFn: async ({ queryKey, signal }) => {
      const body = {
        CountryId: queryKey[1],
        NumberOfPorts: 20,
        NumberOfOperators: 20,
        Year: queryKey[2],
        OperatorTypeId: queryKey[3],
        SegmentTypeId: queryKey[4],
        commercialCategoryId: queryKey[5]
      };

      return getCountryTopPorts(body, signal)
        .then((result) => setCountryTopPortsData(result.topPortsYTD))
        .catch(() => []);
    }
  });
  const { data } = countryTopPortsQuery;
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    window.scrollTo(0, 0);
    const getParamData = async (countryId) => {
      setLoading(true);
      try {
        await Promise.all([
          getCountryDetail(signal, countryId).then((result) => {
            setCountryDetailData(result);
          }),
          getPortCongestion(
            {
              StartDate: moment(segmentMinDate).format(DATE_FORMAT),
              EndDate: moment().format(DATE_FORMAT),
              GeoType: GEOGRAPHICAL_TYPES.COUNTRY,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              GeoId: countryId
            },
            signal
          ).then((result) => {
            setPortCongestionData(result);
          }),
          getCountryTopPorts(
            {
              CountryId: countryId,
              NumberOfPorts: 20,
              NumberOfOperators: 20,
              Year: selectedYear,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setCountryTopPortsData(result.topPortsYTD);
          }),
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.COUNTRY,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              commercialCategoryId: commercialCategoryType,
              geoIds: [countryId]
            },
            signal
          ).then((result) => {
            setCountryPortCallsData(result);
          }),
          getCountryUpcomingPortCalls(
            {
              CountryId: countryId,
              NumberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              setCountryUpcomingPortCallsData(result);
            })
            .catch(() => {
              setCountryUpcomingPortCallsData([]);
            }),
          getActivePorts(
            {
              geoIds: [countryId],
              geoType: GEOGRAPHICAL_TYPES.COUNTRY,
              SegmentTypeId: segment.id,
              operatorType: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setCountryPortsData(result);
          }),
          getCountryPortStats(
            {
              countryId: countryId,
              SegmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setCountryPortStats(result);
          })
        ]).finally(() => setLoading(false));
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getParamData(params.countryId);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, operatorType, selectedYear, commercialCategoryType]);

  const handleSelectYear = useCallback(({ target }) => {
    setSelectedYear(target.value);
  }, []);

  const { name, id } = countryDetailData || {};
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={loading || !countryDetailData}
          title={`${name}`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange('/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary">Country Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <CountryProfileSection
          setCountryPortCallsData={setCountryPortCallsData}
          countryDetailData={countryDetailData}
          countryPortsData={countryPortsData}
          countryPortCallsData={countryPortCallsData}
          countryPortStatsData={countryPortStatsData}
          countryUpcomingPortCallsData={countryUpcomingPortCallsData}
          portCongestionData={portCongestionData}
          countryTopPortsData={countryTopPortsData}
          handleSelectYear={handleSelectYear}
          selectedYear={selectedYear}
          geoId={id}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

CountryProfilePage.propTypes = {};

CountryProfilePage.defaultProps = {};

export default CountryProfilePage;
