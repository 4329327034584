import { useQuery } from '@tanstack/react-query';
import { getTopPorts } from '@api';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import moment from 'moment';

export const useTopPorts = (props = {}) => {
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const { year = moment().year(), numberOfPorts = 10, numberOfOperators = 0 } = props;

  return useQuery({
    queryKey: [
      'topPorts',
      year,
      segment.id,
      operatorType,
      commercialCategoryType,
      numberOfPorts,
      numberOfOperators
    ],
    queryFn: ({ signal }) =>
      getTopPorts(
        {
          NumberOfPorts: numberOfPorts,
          NumberOfOperators: numberOfOperators,
          Year: year,
          OperatorTypeId: operatorType,
          SegmentTypeId: segment.id,
          CommercialCategoryId: commercialCategoryType
        },
        signal
      ),
    select: (data) => data.topPortsYTD,
    enabled: Boolean(
      year &&
        typeof segment.id === 'number' &&
        typeof operatorType === 'number' &&
        typeof commercialCategoryType === 'number'
    )
  });
};
