import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Grid } from '@esgian/esgianui';
import { checkUserHasAccess, getDateList } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import AveragePortTimeDashboardDetails from '@components/Charts/GlobalAveragePortTimeChart/AveragePortTimeDashboardDetails';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

export const useGlobalAveragePortTimeData = (
  globalPortStats,
  isDashboard,
  isEndDateCurrentMonth,
  startDate,
  endDate
) => {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const getChartOptions = (theme, dateList, user, maxValue) => {
    const {
      palette: {
        mode,
        charts: { background, threeColors, twoColorsContrast }
      }
    } = theme;
    return {
      chart: {
        height: 480,
        type: 'mixed',
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        background: background,
        stacked: false,
        toolbar: {
          tools: {
            download: checkUserHasAccess(user, true)
          },
          export: {
            csv: {
              filename: 'global-average-port-time'
            },
            svg: {
              filename: 'global-average-port-time'
            },
            png: {
              filename: 'global-average-port-time'
            }
          }
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: '50%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [0, 3, 2],
        curve: 'straight',
        dashArray: [0, 0, 6]
      },
      colors: [twoColorsContrast[1], threeColors[1], twoColorsContrast[0]],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex }) {
            if (seriesIndex === 1) {
              return value;
            }
            return value?.toFixed(2);
          }
        },
        x: {
          show: true
        }
      },
      markers: {
        size: [0, 3],
        strokeColors: '#ffffff',
        strokeWidth: 1,
        hover: {
          size: 5
        }
      },
      fill: {
        type: 'solid'
      },
      theme: {
        mode: mode
      },
      yaxis: [
        {
          min: 0,
          max: () => maxValue * 1.15,
          tickAmount: 5,
          decimalsInFloat: 2,
          title: {
            text: 'DAYS'
          }
        },
        {
          opposite: true,
          min: 300,
          max: (max) => max * 1.15,
          decimalsInFloat: 0,
          tickAmount: 5,
          labels: {
            style: {
              colors: [threeColors[1]]
            }
          },
          title: {
            text: 'NO OF PORT CALLS',
            style: {
              color: threeColors[1]
            }
          }
        },
        {
          min: 0,
          max: maxValue * 1.15,
          tickAmount: 5,
          decimalsInFloat: 2,
          show: false
        }
      ],
      xaxis: {
        categories: dateList,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -30,
        markers: { width: 8, height: 8, radius: [0, 12, 12] }
      }
    };
  };

  const chartData = React.useMemo(() => {
    if (!globalPortStats) return { series: [], maxValue: 0, details: {} };

    const { polyFit = [], monthlyPortCalls = [], monthlyTotalComparisonDto = [] } = globalPortStats;
    const monthlyPortCallsLength = monthlyPortCalls.length;
    const avgData = [];
    const totData = [];
    let max = 0;
    let data = [];
    if (monthlyPortCallsLength)
      data = isDashboard
        ? monthlyPortCalls?.slice(-13, -1)
        : !isEndDateCurrentMonth
        ? monthlyPortCalls
        : monthlyPortCalls?.slice(-monthlyPortCallsLength, -1);
    data.forEach(({ portTimeAverage, total }) => {
      max = max < portTimeAverage ? portTimeAverage : max;
      avgData.push(portTimeAverage || 0);
      totData.push(total || 0);
    });
    const tempSeries = [
      {
        name: 'Average Port Time [Days]',
        type: 'column',
        data: avgData.map((value) => (value !== undefined ? +value.toFixed(2) : null))
      },
      {
        name: 'Number of Port Calls',
        type: 'line',
        data: totData
      },
      {
        name: 'Average Port Time Trendline [Days]',
        data: (isDashboard
          ? polyFit.slice(-13, -1) || []
          : !isEndDateCurrentMonth
          ? polyFit || []
          : polyFit?.slice(-monthlyPortCallsLength, -1) || []
        ).map((value) => (value !== undefined ? +value.toFixed(2) : null)),
        type: 'line'
      }
    ];

    return {
      series: tempSeries,
      maxValue: max,
      details: { ...monthlyTotalComparisonDto }
    };
  }, [globalPortStats, isDashboard, isEndDateCurrentMonth]);

  const options = React.useMemo(() => {
    return getChartOptions(theme, getDateList(startDate, endDate), user, chartData.maxValue);
  }, [theme, chartData.maxValue, startDate, endDate]);

  return { ...chartData, options };
};

function GlobalAveragePortTimeChart({
  isDashboard,
  globalPortStats,
  loading,
  startDate,
  endDate,
  isEndDateCurrentMonth = false
}) {
  const { series, options, details } = useGlobalAveragePortTimeData(
    globalPortStats,
    isDashboard,
    isEndDateCurrentMonth,
    startDate,
    endDate
  );

  return (
    <Grid item xs={12}>
      <ApexChart
        loading={loading || !options}
        data={series}
        height={480}
        options={options || {}}
        type={'bar'}
      />
      {isDashboard && <AveragePortTimeDashboardDetails details={details} />}
    </Grid>
  );
}

GlobalAveragePortTimeChart.propTypes = {
  loading: PropTypes.bool,
  globalPortStats: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  isDashboard: PropTypes.bool,
  isEndDateCurrentMonth: PropTypes.bool
};

GlobalAveragePortTimeChart.defaultProps = {
  loading: false,
  globalPortStats: null,
  startDate: null,
  endDate: null,
  isDashboard: false
};

export default GlobalAveragePortTimeChart;
