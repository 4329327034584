import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '@components/Tables/EmissionAndETSTables/TableWithSummary';
import { formatNumberReadable } from '@helpers';
import { co2EmissionTableColumns } from '../../../Tables/EmissionAndETSTables/Co2EmissionTable';

const getDataShape = (type, wtt, ttw, wtw, tooltip) => ({
  type: { value: type, tooltip },
  wtt: { value: wtt },
  ttw: { value: ttw },
  wtw: { value: wtw }
});

const SimulatedVoyageCo2Emission = ({ ciiRating }) => {
  const data = [
    getDataShape(
      'CO₂',
      ciiRating?.totalCo2Wtt ?? '--',
      ciiRating?.totalCo2Ttw ? formatNumberReadable(ciiRating.totalCo2Ttw.toFixed(0)) : '--',
      ciiRating?.totalCo2Wtw ?? '--'
    ),
    getDataShape(
      'CII',
      ciiRating?.ciiValueWtt ?? '--',
      ciiRating?.ciiValueTtw ? formatNumberReadable(ciiRating.ciiValueTtw.toFixed(2)) : '--',
      ciiRating?.ciiValueWtw ?? '--'
    ),
    getDataShape(
      't CO₂/t.km',
      ciiRating?.tCo2Wtt ? ciiRating.tCo2Wtt.toFixed(3) : '--',
      ciiRating?.tCo2Ttw ? ciiRating.tCo2Ttw.toFixed(3) : '--',
      ciiRating?.tCo2Wtw ? ciiRating.tCo2Wtw.toFixed(3) : '--',
      't CO₂/t.km'
    )
  ];

  return <TableWithSummary title={'CO2 Emission'} columns={co2EmissionTableColumns} data={data} />;
};

SimulatedVoyageCo2Emission.propTypes = {
  ciiRating: PropTypes.object,
  tite: PropTypes.string
};

export default SimulatedVoyageCo2Emission;
