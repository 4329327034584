import { createSlice } from '@reduxjs/toolkit';

const createSectionSlice = (key) => {
  return createSlice({
    name: key,
    initialState: 1,
    reducers: {
      setSection: (state, action) => {
        return action.payload;
      }
    }
  });
};

export const getOperatorCompSection = (state) => {
  return state.filters.emissionsAnalytics.operatorComparison.section;
};

export const getVesselCompSection = (state) => {
  return state.filters.emissionsAnalytics.vesselComparison.section;
};

export default createSectionSlice;
