import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  DashboardInfoBox,
  Paper,
  ShipsIcon,
  Stack,
  TrendingUpIcon,
  AccountBoxIcon,
  AnchorIcon,
  Typography,
  Button,
  ChevronRightIcon
} from '@esgian/esgianui';
import {
  GlobalAveragePortTimeChart,
  GlobalAveragePortWaitingTimeChart,
  GlobalEmissionIntensityChart,
  GlobalFleetActivityDevelopmentChart
} from '@components/Charts';
import moment from 'moment';
import { TextWithTooltipIcon } from '@components';
import { useNavigate } from 'react-router-dom';
import { useOperatorType, useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getDateFormat } from '@store/features';
import { GLOBAL_AVERAGE_PORT_TIME_TEXT } from '@constants/tooltips';

function DashboardSection({
  kpiData,
  endDate,
  globalPortStats,
  globalPortCongestion,
  globalFleetActivity,
  globalEmissionIntensity,
  loading,
  isDashboard,
  activePorts
}) {
  let navigate = useNavigate();
  const { uriExt } = useSegment();
  const { isOprTypeBoth, isShortSea, isDeepSea, operatorType } = useOperatorType();
  const { ssVessels, dsVessels, totVessels, totOpr, ssOpr, dsOpr } = kpiData || {};
  const dateFormat = useSelector(getDateFormat);
  const numberOfDataPoints = useMemo(() => {
    if (!kpiData) return null;
    const startTimestamp = moment('2021-01-01 00:00', 'YYYY-MM-DD HH:mm');
    const numberOfHours = moment().diff(startTimestamp, 'hours');
    let selectedVessels = totVessels;
    if (isShortSea) {
      selectedVessels = ssVessels;
    }
    if (isDeepSea) {
      selectedVessels = dsVessels;
    }
    return numberOfHours * 30 * selectedVessels;
  }, [kpiData, operatorType]);

  const routeChange = (path) => {
    navigate(path);
    window.scrollTo({ top: 0 });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <DashboardInfoBox
          loading={numberOfDataPoints === null || loading}
          value={numberOfDataPoints}
          icon={<TrendingUpIcon color={'inherit'} />}
          header={
            <TextWithTooltipIcon
              labelBold
              labelVariant={'subtitle1'}
              label={'Data point'}
              tooltipText={
                'Number of data points recorded corresponding to the selected operator type in Esgian Shipping RORO suite  '
              }
            />
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DashboardInfoBox
          loading={kpiData === null || loading}
          value={isOprTypeBoth ? totVessels : isShortSea ? ssVessels : dsVessels}
          icon={
            <ShipsIcon sx={{ fill: ({ palette }) => palette.text.primary }} color={'inherit'} />
          }
          header={
            <TextWithTooltipIcon
              labelBold
              labelVariant={'subtitle1'}
              label={'Vessels'}
              tooltipText={
                'Number of vessels corresponding to the selected operator type in Esgian Shipping RORO suite'
              }
            />
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DashboardInfoBox
          loading={kpiData === null || loading}
          value={activePorts?.length || '-'}
          icon={<AnchorIcon color={'inherit'} />}
          header={
            <TextWithTooltipIcon
              labelBold
              labelVariant={'subtitle1'}
              label={'Ports'}
              tooltipText={'Number of ports covered in Esgian Shipping RORO suite'}
            />
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DashboardInfoBox
          loading={kpiData === null || loading}
          value={isOprTypeBoth ? totOpr : isShortSea ? ssOpr : dsOpr}
          icon={<AccountBoxIcon sx={{ color: ({ palette }) => palette.text.primary }} />}
          header={
            <TextWithTooltipIcon
              labelBold
              labelVariant={'subtitle1'}
              label={'Operators'}
              tooltipText={
                'Number of operators corresponding to the selected operator type in Esgian Shipping RORO suite'
              }
            />
          }
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'row'}>
              <TextWithTooltipIcon
                label={'Global Average Port Time'}
                labelVariant={'h6'}
                tooltipText={GLOBAL_AVERAGE_PORT_TIME_TEXT}
              />
            </Stack>
            <GlobalAveragePortTimeChart
              startDate={moment().subtract(1, 'year').format(dateFormat)}
              endDate={moment().subtract(1, 'months').format(dateFormat)}
              isDashboard
              globalPortStats={globalPortStats}
              loading={loading}
              fileName={'global-port-efficiency'}
              headerText={'Global Port Efficiency'}
            />
            <Stack sx={{ textAlign: 'center' }}>
              <Button
                variant={'outlined'}
                endIcon={<ChevronRightIcon />}
                sx={{ padding: '8px 16px', mx: 'auto' }}
                onClick={() => routeChange(`${uriExt}/commercial-analytics/ports`)}>
                Port Page
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item lg={6} sm={12}>
        <Paper sx={{ height: '100%', p: 2 }}>
          <Stack spacing={2}>
            <Typography variant={'h6'}>Global Average Port Waiting Time</Typography>
            <GlobalAveragePortWaitingTimeChart
              globalPortCongestion={globalPortCongestion}
              loading={loading}
              filename={'global-average-port-waiting-time'}
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item lg={6} sm={12}>
        <Paper sx={{ height: '100%', p: 2 }}>
          <Stack spacing={2}>
            <Stack sx={{ mb: 2 }}>
              <Typography variant={'h6'}>Global Fleet Activity Development</Typography>
            </Stack>
            <GlobalFleetActivityDevelopmentChart
              globalFleetActivity={globalFleetActivity}
              loading={loading}
              fileName={'global-fleet-activity-development'}
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={1} alignItems={'end'}>
              <Typography variant={'h6'}>Global Emission Intensity</Typography>
              <TextWithTooltipIcon
                iconColor={'primary'}
                label={
                  <Typography variant={'caption'} color={'primary'}>
                    (CO<sub>2</sub> / GT.NM)
                  </Typography>
                }
                tooltipText={
                  <Stack>
                    <Typography variant={'caption'}>Total CO₂ (gram) per total</Typography>
                    <Typography variant={'caption'}>Gross tonnage of the fleet per</Typography>
                    <Typography variant={'caption'}>
                      Total distance travelled by the fleet
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
            <GlobalEmissionIntensityChart
              endDate={endDate}
              globalEmissionIntensity={globalEmissionIntensity}
              loading={loading}
              fileName={'global-emission-intensity'}
              headerText={'Global Port Efficiency'}
              isDashboard={isDashboard}
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}

DashboardSection.propTypes = {
  regionsData: PropTypes.arrayOf(PropTypes.object),
  topPortsData: PropTypes.arrayOf(PropTypes.object),
  activePorts: PropTypes.arrayOf(PropTypes.object),
  globalPortStats: PropTypes.object,
  globalPortCongestion: PropTypes.object,
  kpiData: PropTypes.object,
  globalFleetActivity: PropTypes.object,
  globalEmissionIntensity: PropTypes.object,
  endDate: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  handleSelectYear: PropTypes.func,
  headerText: PropTypes.string,
  isDashboard: PropTypes.bool
};

DashboardSection.defaultProps = {
  loading: false,
  regionsData: [],
  activePorts: [],
  globalPortStats: null,
  globalPortCongestion: null,
  kpiData: null,
  globalFleetActivity: null,
  globalEmissionIntensity: null,
  topPortsData: [],
  handleSelectYear: () => null,
  headerText: '',
  isDashboard: false
};

export default DashboardSection;
