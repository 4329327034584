import { BaseApiRequest } from '@api/BaseApi';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { objectToQuery } from '@api/helpers';

export const fetchLookupOperators = (body, signal) => {
  return BaseApiRequest(
    signal,
    `/CompanyInformationByFilters?api/version=1.0`,
    'ships',
    'POST',
    body
  );
};

export const fetchLookupPorts = (data, signal) => {
  const query = objectToQuery({ ...data, GeographicalTypeId: GEOGRAPHICAL_TYPES.PORT });

  return BaseApiRequest(signal, `/Lookup/GeoData?${query}`);
};

export const fetchLookupCanals = (data, signal) => {
  const regionTypes = data && data.regionTypes ? data.regionTypes : [];
  const body = { regionTypes };
  return BaseApiRequest(signal, `/RegionData`, 'geo', 'POST', body);
};
export const fetchLookupRegions = (data, signal) => {
  const regionTypes = data && data.regionTypes ? data.regionTypes : [];
  const body = { regionTypes };
  return BaseApiRequest(signal, `/RegionData`, 'geo', 'POST', body);
};
export const fetchLookupLocations = (signal) => {
  return BaseApiRequest(signal, `/Locations`, 'geo');
};
