import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import SimulatedVoyageFilterForm from './SimulatedVoyageFilterForm';
import SimulatedVoyageResultSummary from './SimulatedVoyageResultSummary';
import SimulatedVoyageMapFilter from './SimulatedVoyageMapFilter';
import SimulatedVoyageOverviewFloatingWindow from './SimulatedVoyageOverviewFloatingWindow.js';
import SimulatedVoyageRouteMap from './SimulatedVoyageRouteMap.js';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

function SimulatedVoyageSection({ simulationResultData, onSimulationStart, onSaveToGroup }) {
  const themeMode = useSelector(getThemeMode);
  const [selectedShipMarkerIndex, setSelectedShipMarkerIndex] = useState(0);
  return (
    <Box flex={1} display="flex" gap={2}>
      <Box flex={1} display="flex">
        <SimulatedVoyageFilterForm onSubmit={onSimulationStart} />
      </Box>
      <Box flex={3} display="flex">
        <SimulatedVoyageResultSummary
          simulationResultData={simulationResultData}
          renderCustomMapSection={(reducedTransitDetails) => (
            <Box flexBasis="450px" position="relative">
              <Box
                width="100%"
                position="absolute"
                p={2}
                display="flex"
                flexDirection="column"
                gap={2}
                zIndex={100}
                sx={{ pointerEvents: 'none' }}>
                <SimulatedVoyageMapFilter
                  onTimelineChange={setSelectedShipMarkerIndex}
                  onSaveToGroup={onSaveToGroup}
                  voyageTransitDetails={reducedTransitDetails}
                  selectedTimelineDay={selectedShipMarkerIndex}
                />
                <SimulatedVoyageOverviewFloatingWindow {...simulationResultData.voyageOverview} />
              </Box>
              <SimulatedVoyageRouteMap
                key={themeMode}
                voyageTransitDetails={reducedTransitDetails}
                currentShipMarkerIndex={selectedShipMarkerIndex}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}

SimulatedVoyageSection.propTypes = {
  simulationResultData: PropTypes.object,
  onSimulationStart: PropTypes.func,
  onSaveToGroup: PropTypes.func
};

export default SimulatedVoyageSection;
