import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';

function CIIRatingsDisclaimer({ setShowStatement }) {
  const { isRoRo } = useSegment();
  if (isRoRo) {
    return (
      <Alert severity={'info'}>
        <Typography variant={'body2'}>
          This analysis is based on modelled hourly emission from vessels using Esgian’s emission
          estimation methodology
          {isRoRo && (
            <>
              {' '}
              which was accredited by Smart Freight Center (Click{' '}
              <Typography
                variant={'body2'}
                color={'primary'}
                component={'span'}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  setShowStatement(true);
                }}>
                here
              </Typography>{' '}
              for Accreditation statement)
            </>
          )}
          . CII ratings are very sensitive to minor inaccuracies in the modelled CO<sub>2</sub>{' '}
          estimation which can cause the vessels to be rated high or low by one or two rating. The
          CII ratings in the analysis act as good estimates for CII rating of a vessel.
        </Typography>
      </Alert>
    );
  }
  return (
    <Alert severity={'info'}>
      This analysis is based on modelled hourly emissions from vessels using Esgian’s emission
      estimation methodology. CII ratings are very sensitive to minor inaccuracies in the modelled
      CO<sub>2</sub> estimation which can cause the vessels to be rated high or low by one or two
      rating. The CII ratings in the analysis act as good estimates for CII rating of a vessel.
    </Alert>
  );
}

CIIRatingsDisclaimer.propTypes = { setShowStatement: PropTypes.func.isRequired };

CIIRatingsDisclaimer.defaultProps = {};

export default CIIRatingsDisclaimer;
