import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { getOwnerStats } from '@api';
import OwnerOverviewSection from '@components/Sections/ShipAnalytics/OwnerOverviewSection';
import { useSelector } from 'react-redux';
import { getSegment } from '@store/features';

function OwnersOverviewPage() {
  const [loading, setLoading] = useState(false);
  const [ownerData, setOwnerData] = useState(null);
  const segment = useSelector(getSegment);

  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getOwnerData = async () => {
      try {
        setLoading(true);
        const responses = await getOwnerStats({ SegmentTypeId: segment.id }, signal);
        setOwnerData(responses);
        setLoading(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getOwnerData();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment]);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-owner-overview'}
          loading={loading}
          title={'Owners Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                id={'home-breadcrumb'}
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="owner-overview">
                Owners Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerOverviewSection ownerData={ownerData} loading={loading} />
      </Grid>
    </Grid>
  );
}

OwnersOverviewPage.propTypes = {};

OwnersOverviewPage.defaultProps = {};

export default OwnersOverviewPage;
