import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ExpandLessIcon, ExpandMoreIcon, Typography } from '@esgian/esgianui';

const ExpandableWrapper = ({ title, contents }) => {
  const [expandedContentIndex, setExpandedContentIndex] = useState();

  return (
    <Box display="flex" gap={2} flexDirection="column" justifyContent="space-between">
      {title && <Typography variant="h6">{title}</Typography>}
      <Box display="flex" flexWrap="nowrap" gap={2} alignItems="stretch">
        {contents.map((content, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            flexGrow={1}
            flexBasis={0}
            minWidth={0}>
            <Box width="100%" flexGrow={1} display="flex" flexDirection="column">
              {content.main}
            </Box>
            {content.expanded.length > 0 && (
              <Button
                sx={{ width: '180px' }}
                variant="outlined"
                onClick={() => {
                  setExpandedContentIndex(expandedContentIndex === index ? undefined : index);
                }}
                endIcon={
                  expandedContentIndex === index ? (
                    <ExpandLessIcon color="primary" />
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )
                }>
                {expandedContentIndex === index ? 'Hide' : 'Show'} Details
              </Button>
            )}
          </Box>
        ))}
      </Box>
      {expandedContentIndex !== undefined && (
        <Box display="flex" flexDirection="column" gap={2}>
          {contents[expandedContentIndex].expanded.map((content, index) => (
            <Box key={index}>{content}</Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

ExpandableWrapper.propTypes = {
  title: PropTypes.node,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.node,
      expanded: PropTypes.arrayOf(PropTypes.node)
    })
  ).isRequired
};

export default ExpandableWrapper;
