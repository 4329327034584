import moment from 'moment/moment';
import { DATE_FORMAT, OPERATOR_SELECT_TYPES } from '@constants';
// SET FUNCTIONS
export const setSliceToUri = (filters, sliceKeys, ext, searchParams) => {
  Object.keys(filters).forEach((key) => {
    const paramKey = `${ext}${key[0].toUpperCase()}${key.slice(1)}`;
    if (['number', 'string'].includes(sliceKeys[key])) {
      searchParams.set(paramKey, filters[key]);
    }
    if (sliceKeys[key] === 'date') {
      if (moment(filters[key], DATE_FORMAT).isValid()) {
        searchParams.set(paramKey, moment(filters[key], DATE_FORMAT)?.format(DATE_FORMAT));
      }
    }
  });
  return searchParams;
};

export const setSelectedOperatorsToUrl = (selectedOperators, selectType, key, searchParams) => {
  if (selectedOperators?.length && selectType === OPERATOR_SELECT_TYPES.MANUAL) {
    searchParams.set(key, selectedOperators?.map(({ id }) => id).join(','));
  }
  if (selectType !== OPERATOR_SELECT_TYPES.MANUAL) {
    searchParams.delete(key);
  }
  return searchParams;
};

export const setSelectedLocationToUrl = (location, key, searchParams) => {
  if (location) {
    searchParams.set(key, location?.id || null);
    searchParams.set(`${key}Type`, location?.type || null);
  }
  return searchParams;
};

export const setSelectedPortToUrl = (port, key, searchParams) => {
  if (port) {
    searchParams.set(key, port?.id || null);
  }
  return searchParams;
};

// GET FUNCTIONS

export const getStandardValuesFromUri = (newFilters, sliceKeys, extLength, searchParams) => {
  searchParams.forEach((value, key) => {
    if (key === 'section') {
      return;
    }
    let filterKey = `${key[extLength].toLowerCase()}${key.slice(extLength + 1)}`;
    if (sliceKeys[filterKey] === 'number') {
      newFilters[filterKey] = parseInt(value);
    }
    if (sliceKeys[filterKey] === 'string') {
      newFilters[filterKey] = value;
    }
    if (sliceKeys[filterKey] === 'date') {
      newFilters[filterKey] = moment(value, DATE_FORMAT).isValid()
        ? moment(value, DATE_FORMAT).format(DATE_FORMAT)
        : null;
    }
  });
  return newFilters;
};
/**
 * Not all slices has auto select, in those cases the select type is null
 * */
export const getSelectedOperatorsFromUri = (key, selectType, lookupOperators, searchParams) => {
  const selectedOperators = searchParams.get(key);
  let tempOperators = [];
  if ((selectType === OPERATOR_SELECT_TYPES.MANUAL || selectType === null) && selectedOperators) {
    if (selectedOperators === 'all') {
      tempOperators = lookupOperators;
    } else {
      selectedOperators.split(',').map((val) => {
        let opr = lookupOperators.find(({ id }) => id === parseInt(val));
        if (opr) {
          tempOperators.push(opr);
        }
      });
    }
  }

  return tempOperators.length ? tempOperators : null;
};

export const getSelectedLocationFromUri = (key, lookupLocations, searchParams) => {
  let result = null;
  const location = parseInt(searchParams.get(key));
  const locationType = searchParams.get(`${key}Type`);
  if (location && locationType) {
    result =
      lookupLocations.find(({ id, type }) => id === location && type === locationType) || null;
  }

  return result;
};
export const getSelectedPortFromUri = (key, lookupPorts, searchParams) => {
  let result = null;
  const portId = parseInt(searchParams.get(key));
  if (portId) {
    result = lookupPorts.find(({ id }) => id === portId) || null;
  }

  return result;
};
