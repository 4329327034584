import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Paper, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import moment from 'moment';

const getOptions = (categories, theme, user, fileName, yearGroups) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, monoColor }
    }
  } = theme;

  return {
    chart: {
      animations: {
        enabled: false
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true
      },
      type: 'bar',
      group: 'groupName',
      height: 480,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        offsetX: 0,
        offsetY: -30,
        tools: {
          download: checkUserHasAccess(user, true),
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: monoColor,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      tickPlacement: 'between',
      group: {
        groups: yearGroups.map(({ mentionedYear, yearCount }) => {
          return {
            title: mentionedYear,
            cols: yearCount
          };
        })
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    yaxis: {
      max: (max) => max * 1.2,
      title: {
        text: 'No. of Transits'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '70%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 0]
    },
    markers: {
      size: 0,
      strokewidth: 2,
      hover: {
        size: 5
      }
    }
  };
};

function CanalTransitsChart({
  loading = false,
  fileName = '',
  canalTransits = null,
  timeFrame = 'weekly',
  startDate = null,
  endDate = null
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const weeklyChartData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let startDateClone = startDate ? startDate.clone() : null;
    let endDateClone = endDate ? endDate.clone() : null;
    let dataSet = [];
    while (
      startDateClone &&
      endDateClone &&
      startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
    ) {
      let weekNumber = startDateClone.isoWeek();
      let yearNumber = startDateClone.isoWeekYear();
      if (startDateClone.isoWeekYear() < startDateClone.year()) {
        startDateClone.add(1, 'isoWeek');
        continue;
      }
      let key = `${yearNumber}_${weekNumber}`;
      dataSet.push({
        year: yearNumber,
        week: weekNumber,
        value: 0,
        id: key
      });
      startDateClone.add(1, 'isoWeek');
    }
    canalTransits?.forEach(({ canalArrival }) => {
      let arrival = moment(canalArrival);
      let weekNumber = arrival.clone().isoWeek();
      let yearNumber = arrival.clone().isoWeekYear();
      if (!dataSet.find(({ year }) => year === yearNumber)) {
        if (dataSet.find(({ year }) => year === yearNumber + 1)) {
          yearNumber++;
          weekNumber = 1;
        } else {
          yearNumber--;
          weekNumber = 52;
        }
      }
      let key = `${yearNumber}_${weekNumber}`;

      let currentVal = dataSet.find(({ id }) => id === key);
      currentVal.value++;
    });
    return dataSet;
  }, [canalTransits]);

  const monthlyChartData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let startDateClone = startDate ? startDate.clone() : null;
    let endDateClone = endDate ? endDate.clone() : null;
    let dataSet = [];
    while (startDateClone && endDateClone && startDateClone.isSameOrBefore(endDateClone)) {
      let monthNumber = startDateClone.month();
      let yearNumber = startDateClone.year();
      let key = `${yearNumber}_${monthNumber}`;
      dataSet.push({
        year: yearNumber,
        month: monthNumber,
        value: 0,
        id: key
      });
      startDateClone.add(1, 'month');
    }
    canalTransits?.forEach(({ canalArrival }) => {
      let arrival = moment.utc(canalArrival);
      let monthNumber = moment.utc(arrival.clone()).month();
      let yearNumber = arrival.clone().year();
      let key = `${yearNumber}_${monthNumber}`;
      let currentVal = dataSet.find(({ id }) => id === key);
      currentVal.value++;
    });

    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  if (timeFrame === 'weekly') {
    weeklyChartData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  } else {
    monthlyChartData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  }

  const { series } = useMemo(() => {
    let max = 0;

    let tempSeries = [];
    tempSeries = [
      {
        name: 'No. of transits',
        type: 'column',
        data:
          timeFrame === 'weekly'
            ? weeklyChartData.map(({ value }) => value)
            : monthlyChartData.map(({ value }) => value)
      }
    ];

    return { series: tempSeries, maxValue: max };
  }, [weeklyChartData, monthlyChartData]);

  const { categories } = useMemo(() => {
    if (!canalTransits?.length) {
      return { categories: [] };
    }

    let tempCategories = [];
    tempCategories =
      timeFrame === 'weekly'
        ? weeklyChartData.map(({ week }) => week)
        : monthlyChartData.map(
            ({ month, year }) => moment().month(month).format('MMM') + ' ' + year
          );

    return { categories: tempCategories };
  }, [canalTransits, timeFrame]);

  const options = useMemo(() => {
    if (!categories?.length) return {};
    return getOptions(categories, theme, user, fileName, yearGroups);
  }, [theme, categories, user, fileName, timeFrame]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant={'h6'}>
          {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)} canal transits
        </Typography>
        <ApexChart
          loading={loading || !options}
          height={480}
          options={options || {}}
          type={'bar'}
          data={series}
        />
      </Stack>
    </Paper>
  );
}

CanalTransitsChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  timeFrame: PropTypes.string,
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default CanalTransitsChart;
