import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ColorBox, Divider, GaugeChart, Grid, Paper, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks';

function PerformanceScore({ loading, performanceData }) {
  const [data, setData] = useState([]);
  const [score, setScore] = useState(null);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData) return;
    let tempData = [];
    let tempScore = 0;
    const { operatorPerformance, segmentPerformance } = performanceData;
    let { operatorFrequencyResult, transitResult } = operatorPerformance;
    let {
      segmentFrequencyResult,
      segmentTransitResult,
      numberOfOperatorsInSegment: numOprInSegment
    } = segmentPerformance;
    operatorFrequencyResult = operatorFrequencyResult[0];
    transitResult = transitResult[0];
    const { frequencyTotal, frequencyStats: oprStats } = operatorFrequencyResult;
    const { frequencyTotal: segTot, frequencyStats: segStats } = segmentFrequencyResult;

    let point = frequencyTotal > segTot ? 1 : 0;
    if (frequencyTotal === segTot) {
      point = 0.5;
    }
    tempData.push({
      title: 'Service Frequency',
      vs: (((frequencyTotal - segTot) / segTot) * 100).toFixed(0),
      point: point
    });
    tempScore += point;
    const { consistencyScore: oprCs } = oprStats;
    const { consistencyScore: segCs } = segStats;
    point = oprCs > segCs ? 1 : 0;
    if (oprCs === segCs) {
      point = 0.5;
    }
    tempData.push({
      title: 'Service Consistency',
      vs: (((oprCs - segCs) / segCs) * 100).toFixed(0),
      point: point
    });
    tempScore += point;

    const { transitStats: segTransitStats } = segmentTransitResult;
    const { transitStats: oprTransitStats } = transitResult;
    const { consistencyScore: segConsistencyScore, average: segAverage } = segTransitStats;
    const { consistencyScore: oprConsistencyScore, average: oprAverage } = oprTransitStats;
    point = oprAverage < segAverage ? 1 : 0;
    if (oprAverage === segAverage) {
      point = 0.5;
    }
    tempData.push({
      title: 'Average Transit Time',
      vs: (((segAverage - oprAverage) / oprAverage) * 100).toFixed(0),
      point: point
    });
    tempScore += point;

    point = oprConsistencyScore > segConsistencyScore ? 1 : 0;
    if (oprConsistencyScore === segConsistencyScore) {
      point = 0.5;
    }
    tempData.push({
      title: 'Average Transit Consistency',
      vs: (((oprConsistencyScore - segConsistencyScore) / segConsistencyScore) * 100).toFixed(0),
      point: point
    });
    tempScore += point;

    if (numOprInSegment === 1) {
      tempData = tempData.map(({ title }) => {
        return { title: title, vs: 0, point: 1 };
      });
      tempScore = 4;
    }

    setData(tempData);
    setScore(tempScore);
  }, [performanceData]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={8}>
        <Paper variant={'outlined'}>
          <Grid
            container
            sx={{
              background: 'transparent',
              p: 2,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px'
            }}>
            <Grid item xs={5}>
              <Typography variant={'body2'} bold color={'text.secondary'}>
                Service Performance Indicators (total period)
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'body2'} bold color={'text.secondary'}>
                Vs Segment
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'body2'} bold color={'text.secondary'}>
                Service Delivery out of 4
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            alignItems={'center'}
            container
            sx={{
              padding: '0px 16px 16px 16px'
            }}>
            {/*{TODO Create loading functionality}*/}
            {!!data.length &&
              data.map(({ title, vs, point }) => (
                <React.Fragment key={title.replace(/\s+/g, '-').toLowerCase()}>
                  <Grid item xs={5}>
                    <Typography bold variant={'body2'}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ p: 1 }}>
                    <ColorBox color={vs === 0 ? 'info' : vs > 0 ? 'success' : 'error'}>
                      <Typography variant={'body2'} bold>
                        {vs}%
                      </Typography>
                    </ColorBox>
                  </Grid>
                  <Grid item xs={3}>
                    {point}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper variant={'outlined'} sx={{ height: '100%' }}>
          <Grid container sx={{ p: 2 }} spacing={2}>
            <Grid item xs={12}>
              <Typography bold>Performance Score</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <GaugeChart
                // colors={['#e76158', '#f79d65', '#fdd50c', '#6fe393', '#30d072']}
                colors={['#e76158', '#f79d65', '#fdd50c', '#30d072']}
                themeMode={theme.mode}
                arcWidth={0.1}
                formatTextValue={() => (loading ? '' : `${score}/4`)}
                nrOfLevels={4}
                id={'performanceScore'}
                percent={score / 4}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

PerformanceScore.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool
};

PerformanceScore.defaultProps = {
  performanceData: null,
  loading: false
};

export default PerformanceScore;
