import React, { useEffect, useState } from 'react';
import { Divider, Grid, Paper, Tab, Tabs, Typography, Stack, NotFound } from '@esgian/esgianui';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import moment from 'moment';
import { getVesselsEmission } from '@api/Emissions';
import { EmissionComparisonChart, EmissionComparisonTable } from '@components/Sections';
import VesselComparisonSectionsFilter from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselComparisonSectionsFilter';
import { OPERATOR_TYPE, OPERATOR_SELECT_TYPES } from '@constants';
import AccreditationStatementModal from '@components/Modals/AccreditationStatementModal';
import { EmissionsDisclaimer } from '@components';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { getVslCo2EmissionsFilters } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { setVslEmissionsDisplayUnit } from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';

function VesselCo2EmissionsSection() {
  const [emissionsData, setEmissionsData] = useState(null);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const { isRoRo, segment } = useSegment();
  const dispatch = useDispatch();
  const co2EmissionsFilters = useSelector(getVslCo2EmissionsFilters);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getVesselCiiData = async () => {
      const {
        startDate,
        endDate,
        selectType,
        maxCapacity,
        minCapacity,
        minBuildYear,
        maxBuildYear,
        selectedAssets
      } = co2EmissionsFilters;
      try {
        if (!init || !moment(startDate).isValid() || !moment(endDate).isValid()) {
          return;
        }
        let vesselIds = null;

        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && !selectedAssets.length) {
          setEmissionsData(null);
          return;
        }
        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && selectedAssets.length) {
          vesselIds = selectedAssets
            .filter(({ id, type }) => {
              if (type === 'vessel') {
                return id;
              }
            })
            .map(({ id }) => id);
        }

        setLoading(true);
        await getVesselsEmission(
          startDate,
          endDate,
          vesselIds,
          minCapacity,
          maxCapacity,
          minBuildYear,
          maxBuildYear,
          segment.id,
          OPERATOR_TYPE.ALL,
          signal
        )
          .catch(() => {
            setEmissionsData(null);
            setLoading(false);
          })
          .then((result) => {
            setEmissionsData(result);
            setLoading(false);
          });
      } catch (error) {
        if (!signal.aborted) {
          setLoading(false);
          setEmissionsData(null);
        }
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getVesselCiiData();
    return () => {
      controller.abort();
    };
  }, [co2EmissionsFilters, init, segment]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Stack spacing={2}>
            <Typography sx={{ pt: 2, pl: 2 }} variant={'h6'}>
              Vessel CO<sub>2</sub> emission comparison
            </Typography>
            <VesselComparisonSectionsFilter
              filters={co2EmissionsFilters}
              init={init}
              setInit={setInit}
            />
            <Stack sx={{ p: 2 }} spacing={2}>
              <Stack>
                <Tabs
                  onChange={(_, val) => {
                    dispatch(setVslEmissionsDisplayUnit(val));
                  }}
                  value={co2EmissionsFilters.displayUnit}>
                  {isRoRo && (
                    <Tab
                      value={1}
                      id="co2-ceu-tab"
                      label={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle2'}>
                              CO<sub>2</sub>/CEU
                            </Typography>
                          }
                          tooltipText={
                            'Tonnes of CO₂ emission per CEU( Car equivalent unit) of a vessel.'
                          }
                        />
                      }
                    />
                  )}
                  <Tab
                    value={3}
                    id="dwt-nm-tab"
                    label={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle2'}>
                            CO<sub>2</sub>/(DWT&#8226;NM)
                          </Typography>
                        }
                        tooltipText={
                          'Grams of CO₂ emission per DWT (Deadweight) and distance travelled (NM- nautical miles) of a vessel.'
                        }
                      />
                    }
                  />
                  <Tab
                    value={4}
                    id="total-co2-tab"
                    label={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle2'}>
                            Total CO<sub>2</sub>
                          </Typography>
                        }
                        tooltipText={'Tonnes of CO₂ emission of a vessel.'}
                      />
                    }
                  />
                </Tabs>
                <Divider />
              </Stack>
              <Stack spacing={2}>
                <EmissionsDisclaimer setShowStatement={setShowStatement} />
                {!loading && !emissionsData ? (
                  <NotFound
                    show={true}
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                ) : (
                  <EmissionComparisonChart
                    loading={loading}
                    emissionsData={emissionsData}
                    assetType={'vessel'}
                  />
                )}
              </Stack>
            </Stack>
            {(loading || !!emissionsData) && (
              <EmissionComparisonTable
                emissionsData={emissionsData}
                assetType={'vessel'}
                loading={loading}
              />
            )}
          </Stack>
        </Paper>
      </Grid>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

VesselCo2EmissionsSection.propTypes = {};

VesselCo2EmissionsSection.defaultProps = {};

export default VesselCo2EmissionsSection;
