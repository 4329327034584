import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AnchorIcon,
  Autocomplete,
  CircularProgress,
  Collapse,
  CountryFlag,
  ExpandLessIcon,
  ExpandMoreIcon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  RegionIcon,
  TextField,
  Typography
} from '@esgian/esgianui';
import { useTheme } from '@hooks';
import { TextWithTooltipIcon } from '@components';

const getIcon = (type, isoAlpha2 = null) => {
  if (type === 'Port') return <AnchorIcon />;
  if (type === 'Region') return <RegionIcon />;
  if (type === 'Country') return <CountryFlag size={20} countryCode={isoAlpha2} />;
};

const getGroupTooltip = (group) => {
  if (group === 'Port') return null;
  if (group === 'Region') return 'all ports in region';
  if (group === 'Country') return 'all ports in country';
};

const getGroupRender = (group) => {
  let tooltip = getGroupTooltip(group);
  let text = group;
  if (tooltip) {
    text = (
      <TextWithTooltipIcon
        sx={{ gap: 0.5 }}
        labelVariant={'body2'}
        label={group}
        tooltipText={tooltip}
      />
    );
  }
  return (
    <ListItemText primaryTypographyProps={{ component: 'span', variant: 'body2' }} primary={text} />
  );
};

function LocationSelect({
  handleChange,
  selectedLocation,
  placeholder,
  locationList,
  keyPreFix,
  label,
  width,
  disablePortal = true,
  disabled = false,
  disableRegion = false,
  loading = false
}) {
  const [open, setOpen] = useState({ port: false, region: false, country: false });
  const { customScrollbar } = useTheme();
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleOpenClick = (group) => {
    let temp = { ...open };
    temp[group] = !temp[group];
    setOpen(temp);
  };

  useEffect(() => {
    const openState = {
      port: false,
      region: false,
      country: false
    };
    if (filteredOptions.length < 20) {
      filteredOptions.forEach((option) => {
        openState[option?.type?.toLowerCase()] = true;
      });
    }
    setOpen(openState);
  }, [filteredOptions]);

  const filteredLocationList = locationList.filter((location) => {
    if (disableRegion && location.type === 'Region') {
      return false;
    }
    return true;
  });

  return (
    <Autocomplete
      sx={{ width: width ?? 220 }}
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      componentsProps={{
        paper: {
          sx: {
            width: width ?? '250px'
          }
        }
      }}
      renderGroup={(params) => (
        <React.Fragment key={`${keyPreFix}-${params.group}-group`}>
          <ListItemButton
            sx={{
              background: (theme) => theme.palette.background.paper,
              boxShadow: open[params.group] ? '0px 3px 3px 0px rgb(0 0 0 / 14%)' : ''
            }}
            divider
            onClick={() => handleOpenClick(params.group.toLowerCase())}>
            {getGroupRender(params.group)}
            {open[params.group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={open[params.group.toLowerCase()]} timeout={0} unmountOnExit>
            <div>{params.children}</div>
          </Collapse>
        </React.Fragment>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.type === value.type}
      fullWidth
      onChange={(_, operator) => handleChange(operator)}
      disableClearable
      disablePortal={disablePortal}
      groupBy={(option) => option?.type}
      disabled={disabled || !filteredLocationList.length || loading}
      getOptionLabel={(option) =>
        `${option?.name}${option?.type === 'Port' ? `(${option?.locode})` : ''}`
      }
      id="location-select"
      value={selectedLocation}
      options={filteredLocationList || []}
      onInputChange={(_, inputValue) => {
        const filtered = filteredLocationList.filter((location) =>
          location.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filtered);
      }}
      renderOption={(props, option) => (
        <ListItem {...props} key={`${keyPreFix}-${option.id}-${option.type}`} divider dense>
          <ListItemIcon sx={{ minWidth: '35px' }}>
            {getIcon(option?.type, option?.isoAlpha2)}
          </ListItemIcon>
          <ListItemText
            primary={`${option?.name}${option?.type === 'Port' ? `(${option?.locode})` : ''}`}
            secondary={option?.type}
          />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: '200px' }}
          placeholder={placeholder}
          fullWidth
          {...params}
          label={
            loading ? (
              <CircularProgress size={25} />
            ) : (
              <Typography variant={'body1'}>{label}</Typography>
            )
          }
        />
      )}
    />
  );
}

LocationSelect.propTypes = {
  handleChange: PropTypes.func,
  selectedLocation: PropTypes.object,
  placeholder: PropTypes.string,
  locationList: PropTypes.arrayOf(PropTypes.object),
  keyPreFix: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disablePortal: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRegion: PropTypes.bool,
  loading: PropTypes.bool
};

LocationSelect.defaultProps = {
  handleChange: null,
  selectedLocation: null,
  placeholder: null,
  keyPreFix: '',
  label: '',
  locationList: [],
  disabled: false,
  disableRegion: false,
  loading: false
};

export default LocationSelect;
