import { DATE_FORMAT, SEGMENT } from '@constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSegment } from '@store/features';
import moment from 'moment';

export const useSegment = () => {
  const segment = useSelector(getSegment);

  const isRoRo = useMemo(() => {
    return segment.id === SEGMENT.RoRo.id;
  }, [segment]);

  const isMPP = useMemo(() => {
    return segment.id === SEGMENT.MPP.id;
  }, [segment]);

  const uriExt = useMemo(() => {
    return segment.id === SEGMENT.RoRo.id ? '/roro' : '/mpp';
  }, [segment]);

  const breadcrumbHome = useMemo(() => {
    return segment.id === SEGMENT.RoRo.id ? '/roro/dashboard' : '/mpp/commercial-analytics';
  }, [segment]);

  const segmentName = useMemo(() => {
    return segment.id === SEGMENT.RoRo.id ? 'RoRo' : 'MPP';
  }, [segment]);

  const segmentMinDate = useMemo(() => {
    return moment(segment.id === SEGMENT.RoRo.id ? '2021-01-01' : '2022-01-01', DATE_FORMAT);
  }, [segment]);

  return { segmentMinDate, isRoRo, isMPP, uriExt, segment, segmentName, breadcrumbHome };
};
