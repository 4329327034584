import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  DATE_FORMAT,
  SEGMENT,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_LIFTING_CAPACITY
} from '@constants';

export const ACTIVITY_MAP_SLICE_KEYS = {
  selectedDate: 'date',
  vesselSizeMin: 'number',
  vesselSizeMax: 'number',
  minLiftingCapacity: 'number',
  maxLiftingCapacity: 'number',
  selectedSlideDate: 'number',
  selectedOperators: 'array'
};

const initialState = {
  selectedDate: moment().format(DATE_FORMAT),
  vesselSizeMin: 0,
  vesselSizeMax: VESSEL_MAX_CAPACITY,
  minLiftingCapacity: 0,
  maxLiftingCapacity: VESSEL_MAX_LIFTING_CAPACITY,
  selectedSlideDate: 30,
  selectedOperators: 'all'
};

const ActivityMapSlice = createSlice({
  name: 'ActivityMap',
  initialState: initialState,
  reducers: {
    setSelectedSlideDate: (state, action) => {
      state.selectedSlideDate = action.payload;
    },
    updateActivityMapFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    resetActivityMapFilters: (state, action) => {
      const segment = action.payload;
      if (segment.id === SEGMENT.MPP.id) {
        state.vesselSizeMax = VESSEL_MAX_CAPACITY_DWT;
      } else {
        state.vesselSizeMax = VESSEL_MAX_CAPACITY;
      }
      state.selectedDate = initialState.selectedDate;
      state.vesselSizeMin = initialState.vesselSizeMin;
      state.minLiftingCapacity = initialState.minLiftingCapacity;
      state.maxLiftingCapacity = initialState.maxLiftingCapacity;
      state.selectedSlideDate = initialState.selectedSlideDate;
      state.selectedOperators = initialState.selectedOperators;
    }
  }
});

export const getActivityMapFilters = (state) => {
  return state.filters.commercialAnalytics.activityMap;
};

export const { setSelectedSlideDate, updateActivityMapFilters, resetActivityMapFilters } =
  ActivityMapSlice.actions;

export default ActivityMapSlice.reducer;
