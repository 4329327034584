import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, NotFound, Divider, DateRangeMobi } from '@esgian/esgianui';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import RegionActivityTable from '@components/Sections/ShipAnalytics/RegionSection/RegionActivityTable/RegionActivityTable';
import RegionActivityChart from '@components/Sections/ShipAnalytics/RegionSection/RegionActivityChart/RegionActivityChart';
import { useDynamicLookupQueries, useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getCommercialCategoryType, getDateFormat, getOperatorType } from '@store/features';
import {
  getRegionHistActivityFilters,
  REGION_HISTORICAL_ACTIVITY_SLICE_KEYS,
  updateRegionHistActivityFilters
} from '@store/features/filters/RegionProfilePage/RegionHistoricalActivitySlice';
import OperatorAdvancedMultiSelect from '@components/Inputs/OperatorAdvancedSelect';
import { fetchRegionDetails } from '@api';
import { getDateList } from '@helpers';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSelectedOperatorsToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';
import { getStartEndMonth, getMonthDateRangePickerSx } from '@helpers/DatePickerHelpers';

const initUrlParams = (filters, lookupOperators, searchParams) => {
  let newFilters = { ...filters };

  newFilters = getStandardValuesFromUri(
    newFilters,
    REGION_HISTORICAL_ACTIVITY_SLICE_KEYS,
    3,
    searchParams
  );
  const oprs = getSelectedOperatorsFromUri(
    'rhaSelectedOperators',
    newFilters.selectType,
    lookupOperators,
    searchParams
  );
  if (oprs) {
    newFilters.selectedOperators = oprs;
  }
  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  const { selectedOperators, selectType } = filters;
  searchParams = setSliceToUri(filters, REGION_HISTORICAL_ACTIVITY_SLICE_KEYS, 'rha', searchParams);
  searchParams = setSelectedOperatorsToUrl(
    selectedOperators,
    selectType,
    `rhaSelectedOperators`,
    searchParams
  );
  setSearchParams(searchParams);
};

function HistoricalActivity({ regionId, regionName }) {
  const [init, setInit] = useState(false);
  const [regionData, setRegionData] = useState(null);
  const [dateList, setDateList] = useState([]);
  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { segment, segmentMinDate } = useSegment();
  const dispatch = useDispatch();
  const historicalActivityFilters = useSelector(getRegionHistActivityFilters);
  const operatorType = useSelector(getOperatorType);
  const dateFormat = useSelector(getDateFormat);
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const { selectedOperators, selectType, startDate, endDate } = historicalActivityFilters;

  const updateFilterValue = (payload) => {
    dispatch(updateRegionHistActivityFilters(payload));
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getRegionDetails = async () => {
      const { startDate, endDate, selectType, selectedOperators } = historicalActivityFilters;
      try {
        setLoading(true);
        await fetchRegionDetails(
          {
            regionId: regionId,
            startDate: startDate,
            endDate: endDate,
            selectedOperators: selectedOperators,
            segmentTypeId: segment.id,
            operatorType: operatorType,
            commercialCategoryId: commercialCategoryType,
            topXOperators: selectType === 'auto' ? 10 : null
          },
          signal
        )
          .then((results) => {
            setNoData(false);
            setRegionData(results);
            setDateList(getDateList(startDate, endDate));
          })
          .finally(() => setLoading(false));
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (init) {
      getRegionDetails();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [historicalActivityFilters, operatorType, commercialCategoryType, init]);

  useEffect(() => {
    if (lookupOperators?.length) {
      let newFilters = initUrlParams(historicalActivityFilters, lookupOperators, searchParams);
      dispatch(updateRegionHistActivityFilters(newFilters));
      setInit(true);
    }
  }, [lookupOperators]);

  useEffect(() => {
    if (init) {
      setUrlParams(historicalActivityFilters, searchParams, setSearchParams);
    }
  }, [historicalActivityFilters, init]);

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item className={'section-filter'}>
                <DateRangeMobi
                  sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
                  onClose={({ value }) => {
                    const { start, end } = getStartEndMonth(value, dateFormat);
                    updateFilterValue({
                      startDate: start,
                      endDate: end
                    });
                  }}
                  dateFormat={dateFormat}
                  minStartDate={segmentMinDate}
                  disableFuture
                  startDate={moment(startDate, dateFormat)}
                  endDate={moment(endDate, dateFormat)}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Grid>
              <Grid item lg={3} md={4} className={'section-filter'}>
                <OperatorAdvancedMultiSelect
                  selected={selectedOperators}
                  autoEnabledText={'Displaying the top 10 operators given the selected period'}
                  handleClose={(values) => {
                    updateFilterValue({
                      selectType: values.operatorType,
                      selectedOperators: values.selectedOperators
                    });
                  }}
                  loading={!lookupOperators}
                  operators={lookupOperators}
                  selectType={selectType}
                  label={'Operators*'}
                  showAdvanceButton={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {!loading && noData && (
              <NotFound
                show={true}
                header={'No Matching Result'}
                text={'Please change your search parameters'}
              />
            )}
            {!noData && (
              <RegionActivityChart
                loading={loading}
                fileName={`${regionName?.split(' ').join('-')}-${moment(
                  startDate,
                  dateFormat
                ).format('MMYY')}-${moment(endDate, dateFormat).format('MMYY')}`}
                dateList={dateList}
                regionData={regionData}
              />
            )}
          </Grid>
          {!noData && (
            <Grid item xs={12}>
              <RegionActivityTable dateList={dateList} loading={loading} regionData={regionData} />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}

HistoricalActivity.propTypes = {
  regionId: PropTypes.number.isRequired,
  regionName: PropTypes.string
};

HistoricalActivity.defaultProps = {
  regionName: ''
};

export default HistoricalActivity;
