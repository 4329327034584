import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography } from '@esgian/esgianui';

function ValueDisplay({ title, value, loading, children, className }) {
  return (
    <>
      {!loading ? (
        <Grid container item>
          <Grid container className={className}>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{title}:</Typography>
            </Grid>
            <Grid item xs={6}>
              {!!children && children}
              {!children && <Typography variant={'body2'}>{value}</Typography>}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={8}>
          <Skeleton />
        </Grid>
      )}
    </>
  );
}

ValueDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

ValueDisplay.defaultProps = {
  children: null,
  value: '',
  loading: false,
  className: null
};

export default ValueDisplay;
