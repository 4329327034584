import { createSlice } from '@reduxjs/toolkit';
import {
  OPERATOR_TYPE,
  VESSEL_MAX_TRANSIT_TIME,
  VESSEL_MAX_LIFTING_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_CAPACITY_CARS,
  COMMERCIAL_CATEGORY_TYPE,
  VESSEL_MIN_BUILD_YEAR,
  VESSEL_MAX_GROSS_TONNAGE
} from '@constants';

export const COMPLETED_VOYAGES_SLICE_KEYS = {
  toType: 'number',
  fromType: 'number',
  toId: 'number',
  fromId: 'number',
  imos: 'array',
  operatorVesselSelect: 'array',
  operatorIds: 'array',
  startDate: 'date',
  endDate: 'date',
  operatorTypeId: 'number',
  segmentTypeId: 'number',
  commercialCategoryId: 'number',
  minTransitTime: 'number',
  maxTransitTime: 'number',
  minBuildYear: 'number',
  maxBuildYear: 'number',
  minCarCapacity: 'number',
  maxCarCapacity: 'number',
  minLiftingCapacity: 'number',
  maxLiftingCapacity: 'number',
  minDeadweight: 'number',
  maxDeadweight: 'number',
  minGrossTonnage: 'number',
  maxGrossTonnage: 'number'
};

const initialState = {
  toType: null,
  fromType: null,
  toId: null,
  fromId: null,
  imos: null,
  operatorIds: null,
  startDate: null,
  endDate: null,
  operatorTypeId: OPERATOR_TYPE.ALL,
  segmentTypeId: 1,
  commercialCategoryId: COMMERCIAL_CATEGORY_TYPE.ALL,
  minTransitTime: 0,
  maxTransitTime: VESSEL_MAX_TRANSIT_TIME,
  minBuildYear: VESSEL_MIN_BUILD_YEAR,
  maxBuildYear: 2024,
  minCarCapacity: 0,
  maxCarCapacity: VESSEL_MAX_CAPACITY_CARS,
  minLiftingCapacity: 0,
  maxLiftingCapacity: VESSEL_MAX_LIFTING_CAPACITY,
  minDeadweight: 0,
  maxDeadweight: VESSEL_MAX_CAPACITY_DWT,
  minGrossTonnage: 0,
  maxGrossTonnage: VESSEL_MAX_GROSS_TONNAGE
};
const CompletedVoyagesSlice = createSlice({
  name: 'CompletedVoyages',
  initialState: initialState,
  reducers: {
    resetCompletedVoyagesFilters: () => initialState,
    updateCompletedVoyagesFilters: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
  }
});

export const getCompletedVoyagesFilters = (state) => {
  return state.filters.voyageAnalytics.completedVoyages;
};

export const { resetCompletedVoyagesFilters, updateCompletedVoyagesFilters } =
  CompletedVoyagesSlice.actions;

export default CompletedVoyagesSlice.reducer;
