import { createSlice } from '@reduxjs/toolkit';
import { OPERATOR_TYPE, OPERATOR_SELECT_TYPES } from '@constants';

export const PORT_ACTIVITY_SLICE_KEYS = {
  subSection: 'number',
  startDate: 'date',
  endDate: 'date',
  selectedLocation: 'custom',
  selectedOperators: 'array',
  selectType: 'string',
  operatorType: 'number'
};

const initialState = {
  subSection: 1,
  startDate: null,
  endDate: null,
  selectedLocation: null,
  selectedOperators: [],
  selectType: OPERATOR_SELECT_TYPES.AUTO,
  operatorType: OPERATOR_TYPE.ALL
};

const PortActivitySlice = createSlice({
  name: 'PortActivity',
  initialState: initialState,
  reducers: {
    setPortActivitySection: (state, action) => {
      state.subSection = action.payload;
    },
    setPortActivityOperatorType: (state, action) => {
      state.operatorType = action.payload;
    },
    resetPortActivityAdvFilters: (state) => {
      state.operatorType = OPERATOR_TYPE.ALL;
      state.selectedOperators = [];
      state.selectType = OPERATOR_SELECT_TYPES.AUTO;
    },
    resetPortActivityFilters: () => initialState,
    updatePortActivityFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }
  }
});

export const getPortActivityFilters = (state) => {
  return state.filters.commercialAnalytics.portActivity;
};
export const getPortActivityFilterCount = (state) => {
  let count = 0;
  let slice = state.filters.commercialAnalytics.portActivity;
  count += slice.selectType !== OPERATOR_SELECT_TYPES.AUTO ? 1 : 0;
  count += slice.operatorType !== OPERATOR_TYPE.ALL ? 1 : 0;
  return count;
};

export const {
  setPortActivitySection,
  resetPortActivityFilters,
  resetPortActivityAdvFilters,
  setPortActivityOperatorType,
  updatePortActivityFilters
} = PortActivitySlice.actions;

export default PortActivitySlice.reducer;
