import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function PerformanceIndicatorMenu({ subSection, setSubSection }) {
  const [open, setOpen] = useState(true);
  const { theme } = useTheme();

  const getSelectedBackground = useCallback(
    (section) => {
      if (subSection === section) {
        return theme.palette.background.default;
      }
      return '';
    },
    [theme, subSection]
  );

  return (
    <List>
      <ListItem
        button
        sx={{
          borderRight: ({ palette }) =>
            subSection === 1 ? `3px solid ${palette.primary.main}` : '',
          background: getSelectedBackground(1)
        }}
        onClick={() => setSubSection(1)}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            color: subSection === 1 ? 'primary' : 'secondary'
          }}
          primary="SERVICE LEVEL"
        />
      </ListItem>
      <ListItem button onClick={() => setOpen(!open)} sx={{ justifyContent: 'space-between' }}>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          primary="TRANSIT TIME AND SPREAD"
        />
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pt: 0 }}>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 2 ? `3px solid ${palette.primary.main}` : '',
              background: getSelectedBackground(2)
            }}
            onClick={() => setSubSection(2)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 2 ? 'primary' : 'secondary'
              }}
              primary="TRANSIT TIME"
            />
          </ListItem>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 3 ? `3px solid ${palette.primary.main}` : '',
              background: getSelectedBackground(3)
            }}
            onClick={() => setSubSection(3)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 3 ? 'primary' : 'secondary'
              }}
              primary="SPREAD ANALYSIS"
            />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}

PerformanceIndicatorMenu.propTypes = {
  setSubSection: PropTypes.func.isRequired,
  subSection: PropTypes.number.isRequired
};

PerformanceIndicatorMenu.defaultProps = {};

export default PerformanceIndicatorMenu;
