import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Button,
  ArrowDropDownIcon,
  Typography,
  Box,
  Tabs,
  Tab,
  DateRangeMobi,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Grid
} from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys, VoyageSummaryPeriodTypes } from '../VoyageSummaryConstants';
import moment from 'moment';

function VoyageSummaryPeriodSelection({
  dateRange,
  startDate: sDate,
  endDate: eDate,
  voyagePortCalls
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get(VoyageSummaryFilterKeys.PERIOD_TYPE) ?? VoyageSummaryPeriodTypes[0].value
  );
  const [startDate, setStartDate] = useState(dateRange[0]);
  const [endDate, setEndDate] = useState(dateRange[1]);
  const [fromPort, setFromPort] = useState(searchParams.get(VoyageSummaryFilterKeys.FROM_PORT));
  const [toPort, setToPort] = useState(searchParams.get(VoyageSummaryFilterKeys.TO_PORT));

  const handlePeriodTypeChange = (_, val) => {
    setCurrentTab(val);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyClick = () => {
    setSearchParams((updatedSearchParams) => {
      updatedSearchParams.set(VoyageSummaryFilterKeys.PERIOD_TYPE, currentTab);
      updatedSearchParams.set(VoyageSummaryFilterKeys.START_DATE, startDate);
      updatedSearchParams.set(VoyageSummaryFilterKeys.END_DATE, endDate);
      updatedSearchParams.set(VoyageSummaryFilterKeys.FROM_PORT, fromPort);
      updatedSearchParams.set(VoyageSummaryFilterKeys.TO_PORT, toPort);
      return updatedSearchParams;
    });
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-controls="map-options-menu"
        aria-haspopup="true"
        onClick={null} // set to click event to enable period selection
        variant="text"
        sx={{
          borderRadius: 1,
          color: 'text.primary'
        }}
        //endIcon={<ArrowDropDownIcon />}
      >
        {moment(startDate).format('YYYY-MM-DD')} -{' '}
        {moment().isSame(endDate, 'day') ? 'Today' : moment(endDate).format('YYYY-MM-DD')}
      </Button>
      <Menu
        id="map-options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Typography
            color="secondary"
            sx={{
              p: 2
            }}>
            Period Selection
          </Typography>
          <Tabs
            value={currentTab}
            onChange={handlePeriodTypeChange}
            aria-label="period type options">
            {VoyageSummaryPeriodTypes.map((periodType) => (
              <Tab
                key={periodType.value}
                label={periodType.label}
                value={periodType.value}
                sx={{
                  w: '100%',
                  textTransform: 'none',
                  flex: 1
                }}
              />
            ))}
          </Tabs>
        </Box>
        {currentTab === VoyageSummaryPeriodTypes[0].value && (
          <Box
            sx={{
              p: 2,
              width: '360px'
            }}>
            {startDate && endDate && (
              <DateRangeMobi
                minStartDate={moment(sDate)}
                maxDate={moment(eDate)}
                startDate={moment(startDate)}
                endDate={moment(endDate)}
                dateFormat="YYYY-MM-DD"
                display="inline"
                doubleInput
                onClose={({ value }) => {
                  if (!value || value.length <= 1) return;
                  if (value[0] && !!value[0].isValid && value[0].isValid()) {
                    setStartDate(value[0].valueOf());
                  }
                  if (value[1] && !!value[1].isValid && value[1].isValid()) {
                    setEndDate(value[1].valueOf());
                  }
                }}
              />
            )}
          </Box>
        )}
        {currentTab === VoyageSummaryPeriodTypes[1].value && (
          <Stack width="360px" spacing={1} p={2}>
            <FormControl variant="outlined">
              <InputLabel id="port-from-selection-label">From</InputLabel>
              <Select
                label="From"
                labelId="port-from-selection-label"
                value={fromPort}
                onChange={({ target }) => {
                  let portCall = voyagePortCalls.portCalls[parseInt(target.value)];
                  setFromPort(parseInt(target.value));
                  setStartDate(moment(portCall.departureDate).valueOf());
                }}
                variant="outlined">
                {voyagePortCalls.portCalls.map(({ portName, departureDate }, index) => (
                  <MenuItem key={index} value={index}>
                    <Typography>
                      {portName}{' '}
                      <span style={{ fontSize: '14px' }}>
                        ATD: {moment(departureDate).format('YYYY-MM-DD HH:mm:ss')}
                      </span>
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="port-to-selection-label">To</InputLabel>
              <Select
                label="To"
                labelId="port-to-selection-label"
                value={toPort}
                onChange={({ target }) => {
                  let portCall = voyagePortCalls.portCalls[parseInt(target.value)];
                  setToPort(parseInt(target.value));
                  setEndDate(moment(portCall.departureDate).valueOf());
                }}
                variant="outlined">
                {voyagePortCalls.portCalls.map(({ portName, departureDate }, index) => (
                  <MenuItem key={index} value={index}>
                    <Typography>
                      {portName}
                      <span style={{ fontSize: '14px' }}>
                        ATD: {moment(departureDate).format('YYYY-MM-DD HH:mm:ss')}
                      </span>
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
        <Grid container py={1} justifyContent="center" spacing={2}>
          <Grid item>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleApplyClick}>Apply</Button>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}

VoyageSummaryPeriodSelection.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.number),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  voyagePortCalls: PropTypes.object.isRequired
};

export default VoyageSummaryPeriodSelection;
