import { Box, CloseIcon, IconButton, Typography } from '@esgian/esgianui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VoyageSummaryOverview from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryOverview/VoyageSummaryOverview';
import VoyagePortWaitingTime from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryPortWaitingTime/VoyagePortWaitingTime';
import { formatToDecimalPlaces, formatNumberReadable } from '@helpers';

const VoyageDetails = ({
  onClose,
  summaryOverview,
  voyageTransitDetails,
  voyagePortCalls,
  latestPortCall
}) => {
  const [vesselsData, setVesselsData] = useState(null);

  return (
    <Box width="350px">
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h6">Voyage details</Typography>
        <IconButton
          onClick={() => {
            onClose();
          }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <VoyageSummaryOverview
          voyageTransitDetails={voyageTransitDetails}
          summaryOverview={summaryOverview}
          isOngoingVoyage
          onLoadVesselData={setVesselsData}
          latestPortCall={latestPortCall}
        />
      </Box>
      <Box p={2}>
        <VoyagePortWaitingTime
          voyagePortCalls={voyagePortCalls?.portCalls}
          averageData={{
            averagePortTime: formatNumberReadable(
              formatToDecimalPlaces(voyagePortCalls?.averagePortTimeDays, 2)
            ),
            averagePortWaitingTime: formatNumberReadable(
              formatToDecimalPlaces(voyagePortCalls?.averagePortWaitingTimeDays, 2)
            )
          }}
          isOngoingVoyage
        />
      </Box>
    </Box>
  );
};

VoyageDetails.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  summaryOverview: PropTypes.object,
  latestPortCall: PropTypes.object,
  voyageTransitDetails: PropTypes.object,
  voyagePortCalls: PropTypes.object
};

export default VoyageDetails;
