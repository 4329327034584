import { createSlice } from '@reduxjs/toolkit';
import { OPERATOR_SELECT_TYPES, VESSEL_MAX_TRANSIT_TIME } from '@constants';

export const SERVICE_LEVEL_SLICE_KEYS = {
  subSection: 'number',
  startDate: 'date',
  endDate: 'date',
  selectedLocationLoad: 'custom',
  selectedLocationDischarge: 'custom',
  selectedOperators: 'array',
  selectType: 'string',
  maxTransitTime: 'number',
  minServices: 'number'
};

const initialState = {
  subSection: 1,
  startDate: null,
  endDate: null,
  selectedLocationLoad: null,
  selectedLocationDischarge: null,
  selectedOperators: [],
  selectType: OPERATOR_SELECT_TYPES.AUTO,
  maxTransitTime: VESSEL_MAX_TRANSIT_TIME,
  minServices: 0
};

const ServiceLevelSlice = createSlice({
  name: 'ServiceLevel',
  initialState: initialState,
  reducers: {
    setServiceLevelSection: (state, action) => {
      state.subSection = action.payload;
    },

    resetServiceLevelAdvFilters: (state) => {
      state.selectedOperators = initialState.selectedOperators;
      state.selectType = initialState.selectType;
      state.maxTransitTime = initialState.maxTransitTime;
      state.minServices = initialState.minServices;
    },
    resetServiceLevelFilters: () => initialState,
    updateServiceLevelFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }
  }
});

export const getServiceLevelFilters = (state) => {
  return state.filters.commercialAnalytics.serviceLevel;
};
export const getServiceLevelFilterCount = (state) => {
  let count = 0;
  let slice = state.filters.commercialAnalytics.serviceLevel;
  const { selectType, maxTransitTime, minServices } = slice || {};

  count += selectType !== initialState.selectType ? 1 : 0;
  count += parseInt(maxTransitTime) !== initialState.maxTransitTime ? 1 : 0;
  count += parseInt(minServices) !== initialState.minServices ? 1 : 0;
  return count;
};

export const {
  setServiceLevelSection,
  resetServiceLevelAdvFilters,
  updateServiceLevelFilters,
  resetServiceLevelFilters
} = ServiceLevelSlice.actions;

export default ServiceLevelSlice.reducer;
