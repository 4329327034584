import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@esgian/esgianui';
import { useSegment } from '@hooks';

function SubSectionMenu({ setSubSection, subSection }) {
  const [open, setOpen] = useState({ fnc: true, ceu: true, tt: true });
  const { isRoRo } = useSegment();

  const getSelectedBackground = useCallback(
    (section, palette) => {
      if (subSection === section) {
        return palette.background.default;
      }
      return '';
    },
    [subSection]
  );

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        backgroundColor: ({ palette }) => palette.background.paper
      }}
      component="nav"
      aria-labelledby="nested-list-subheader">
      <ListItem
        button
        onClick={() => setOpen({ ...open, fnc: !open.fnc })}
        sx={{ justifyContent: 'space-between' }}>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
          primary="Service Level"
        />
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          {open.fnc ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open.fnc} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pt: 0 }}>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 1 ? `3px solid ${palette.primary.main}` : '',
              background: ({ palette }) => getSelectedBackground(1, palette)
            }}
            onClick={() => setSubSection(1)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 1 ? 'primary' : 'secondary'
              }}
              primary="Service Frequency"
            />
          </ListItem>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 2 ? `3px solid ${palette.primary.main}` : '',
              background: ({ palette }) => getSelectedBackground(2, palette)
            }}
            onClick={() => setSubSection(2)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 2 ? 'primary' : 'secondary'
              }}
              primary="Service Consistency"
            />
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        button
        onClick={() => setOpen({ ...open, ...{ tt: !open.tt } })}
        sx={{ justifyContent: 'space-between' }}>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
          primary="Transit Time"
        />
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          {open.tt ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open.tt} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pt: 0 }}>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 3 ? `3px solid ${palette.primary.main}` : '',
              background: ({ palette }) => getSelectedBackground(3, palette)
            }}
            onClick={() => setSubSection(3)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 3 ? 'primary' : 'secondary'
              }}
              primary="Transit Time Consistency"
            />
          </ListItem>
        </List>
      </Collapse>
      {isRoRo && (
        <>
          <ListItem
            button
            onClick={() => setOpen({ ...open, ...{ ceu: !open.ceu } })}
            sx={{ justifyContent: 'space-between' }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
              primary="CEU"
            />
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              {open.ceu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
          </ListItem>
          <Collapse in={open.ceu} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pt: 0 }}>
              <ListItem
                button
                sx={{
                  pl: 4,
                  borderRight: ({ palette }) =>
                    subSection === 4 ? `3px solid ${palette.primary.main}` : '',
                  background: ({ palette }) => getSelectedBackground(4, palette)
                }}
                onClick={() => setSubSection(4)}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: subSection === 4 ? 'primary' : 'secondary'
                  }}
                  primary="CEU Capacity"
                />
              </ListItem>
              <ListItem
                button
                sx={{
                  pl: 4,
                  borderRight: ({ palette }) =>
                    subSection === 5 ? `3px solid ${palette.primary.main}` : '',
                  background: ({ palette }) => getSelectedBackground(5, palette)
                }}
                onClick={() => setSubSection(5)}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: subSection === 5 ? 'primary' : 'secondary'
                  }}
                  primary="CEU Consistency"
                />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}

SubSectionMenu.propTypes = {
  setSubSection: PropTypes.func.isRequired,
  subSection: PropTypes.number.isRequired
};

SubSectionMenu.defaultProps = {};

export default SubSectionMenu;
