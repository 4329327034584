import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Stack, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks';
import { DATE_TIME_FORMAT_DAY_OF } from '@constants';
import { useTimezone } from '@hooks/useTimezone';

function VesselPositionDetails({ vesselLatestPositionData }) {
  const { course, draught, mmsi, timestamp, speed, vesselName } = vesselLatestPositionData || {};
  const { customScrollbar } = useTheme();
  const { getTimeZoneDisplay } = useTimezone();

  return (
    <Grid
      container
      sx={{
        ...customScrollbar,
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper'
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '4px',
          alignItems: 'center',
          background: ({ palette }) =>
            palette.mode === 'light' ? palette.primary.main : palette.primary.dark,
          borderRadius: '3px 3px 0px 0px',
          width: '100%',
          boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 25%)'
        }}>
        <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
          <Typography color={'#fff'} bold variant={'h6'} sx={{ p: 2 }}>
            {vesselName ? vesselName : 'Vessel'}
          </Typography>
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {[
          {
            label: 'Position Received :',
            value: timestamp ? getTimeZoneDisplay(timestamp, DATE_TIME_FORMAT_DAY_OF) : '-'
          },
          { label: 'MMSI :', value: mmsi },
          { label: 'Speed :', value: `${speed} Knots` },
          { label: 'Course :', value: `${course}°` },
          { label: 'Draught :', value: `${draught} m` }
        ].map((item, index) => (
          <Grid item container xs={12} key={index}>
            <Grid item>
              <Typography variant="body2" bold>
                {item.label}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ ml: index === 0 ? 0 : 1 }}>
                {item.value}
              </Typography>
            </Grid>
            {index < 4 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

VesselPositionDetails.propTypes = {
  vesselLatestPositionData: PropTypes.object
};

VesselPositionDetails.defaultProps = {
  vesselLatestPositionData: {}
};

export default VesselPositionDetails;
