import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { formatNumberReadable, formatToDecimalPlaces } from '@helpers';
import { TextWithTooltipIcon } from '@components';

const MyCargoExpandedCo2eEmissionTable = ({ myCargoToc, myCargoHoc, isLoading }) => {
  const tocColumns = getTocColumns();
  const tocData = getTocData(myCargoToc);
  const hocColumns = getHocColumns();
  const hocData = getHocData(myCargoHoc);

  return (
    <>
      <TableWithSummary
        title="My Cargo TOC (Transport Operation Category) Emission"
        columns={tocColumns}
        data={tocData}
        isLoading={isLoading}
        id="my-cargo-toc-emission-table"
      />
      <TableWithSummary
        title={getHocTitle()}
        columns={hocColumns}
        data={hocData}
        isLoading={isLoading}
        id="my-cargo-hoc-emission-table"
      />
    </>
  );
};

const getTocColumns = () => [
  { name: 'voyageLeg', label: 'Voyage Leg' },
  { name: 'origin', label: 'Origin' },
  { name: 'destination', label: 'Destination' },
  {
    name: 'distanceKm',
    label: 'Distance (km)',
    tooltip:
      'For RORO vessels, "Distance" corresponds to shortest distance between port pairs estimated by Esgian based on positional data of historical voyages of all vessels in the Esgian database. For MPP vessels, "Distance" corresponds to actual distance travelled between port pairs based on the positional data of the vessel carrying the cargo.'
  },
  {
    name: 'myTocActivity',
    label: 'My TOC activity (T-km)',
    tooltip: 'TOC activity is the product of cargo weight (t) and distance (km).'
  },
  {
    name: 'energyProduction',
    label: 'Energy Production',
    tooltip:
      'CO₂e (kg) Emission from energy production (also referred to as Well to Tank (WTT) Emission) corresponding to the user input cargo weight'
  },
  {
    name: 'operational',
    label: 'Operational',
    tooltip:
      'CO₂e (kg)Emission from vessel operation (also referred to as Tank to Wake ( TTW) Emission) corresponding to the user input cargo weight'
  },
  {
    name: 'overall',
    label: 'Overall',
    tooltip:
      'Sum of CO₂e (kg) Emission from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) corresponding to the user input cargo weight'
  }
];

const getTocData = (myCargoToc) => {
  const voyageLegData = myCargoToc.voyageLegMyCargoEmissionIntensities?.map(mapVoyageLegData) ?? [];
  const totalRow = getTotalRow(myCargoToc);
  const ballastRow = getBallastRow(myCargoToc);
  const totalPlusBallastRow = getTotalPlusBallastRow(myCargoToc);

  const finalData = [...voyageLegData, totalRow, ballastRow, totalPlusBallastRow];
  return finalData.map(sanitizeInfinityValues);
};

const mapVoyageLegData = (d) => ({
  voyageLeg: { value: `#${d.voyageLeg}` },
  origin: { value: d.fromPortName },
  destination: { value: d.toPortName },
  distanceKm: { value: formatNumberReadable(formatToDecimalPlaces(d.distanceKm, 0)) },
  myTocActivity: { value: formatNumberReadable(formatToDecimalPlaces(d.myTocActivity, 0)) },
  energyProduction: {
    value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionEneryProductionKg, 0))
  },
  operational: {
    value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionOperationalKg, 0))
  },
  overall: { value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionOverallKg, 0)) }
});

const getTotalRow = (myCargoToc) => ({
  voyageLeg: { value: 'Total' },
  origin: { value: '-' },
  destination: { value: '-' },
  distanceKm: { value: '-' },
  myTocActivity: {
    value: formatNumberReadable(formatToDecimalPlaces(myCargoToc.totalMyTocActivity, 0))
  },
  energyProduction: {
    value: formatNumberReadable(
      formatToDecimalPlaces(myCargoToc.totalGhgEmissionEneryProductionKg, 0)
    )
  },
  operational: {
    value: formatNumberReadable(formatToDecimalPlaces(myCargoToc.totalGhgEmissionOperationalKg, 0))
  },
  overall: {
    value: formatNumberReadable(formatToDecimalPlaces(myCargoToc.totalGhgEmissionOverallKg, 0))
  }
});

const getBallastRow = (myCargoToc) => ({
  voyageLeg: { value: 'Ballast' },
  origin: { value: '-' },
  destination: { value: '-' },
  distanceKm: { value: '-' },
  myTocActivity: { value: '-' },
  energyProduction: { value: '-' },
  operational: { value: '-' },
  overall: { value: formatNumberReadable(formatToDecimalPlaces(myCargoToc.ballast, 0)) }
});

const getTotalPlusBallastRow = (myCargoToc) => ({
  voyageLeg: { value: 'Total + Ballast' },
  origin: { value: '-' },
  destination: { value: '-' },
  distanceKm: { value: '-' },
  myTocActivity: { value: '-' },
  energyProduction: { value: '-' },
  operational: { value: '-' },
  overall: {
    value: formatNumberReadable(
      formatToDecimalPlaces(myCargoToc.totalGhgEmissionOverallPlusBallastKg, 0)
    )
  }
});

const sanitizeInfinityValues = (row) => ({
  ...row,
  overall: {
    ...row.overall,
    value: row.overall.value === 'Infinity' || row.overall.value === '∞' ? '-' : row.overall.value
  }
});

const getHocColumns = () => [
  { name: 'portName', label: 'Port Name' },
  {
    name: 'operationalHocEmissionIntensityKgCo2eT',
    label: 'Operational HOC Emission intensity (kg CO₂e/T)',
    tooltip: `Operational HOC Emission intensity defines the GHG emission per ton cargo handled at corresponding port. Esgian assumes the origin and destination selected by the user as the port of load and port of discharge respectively. The emission intensities are based on the default value defined in the GLECC framework. For RORO vessel it assumed that the port doesn't use any equipment to handle the cargo.`
  },
  {
    name: 'hocActivityT',
    label: 'HOC activity (T)',
    tooltip: `HOC (Hub operations category) Activity corresponds to the weight of the cargo (the user input cargo weight (My cargo)) in tonnes.`
  },
  {
    name: 'ghcEmissionKgCo2e',
    label: 'GHG Emission (kg CO₂e)',
    tooltip: 'GHG Emission from Hub operations which includes loading and discharging.'
  }
];

const getHocData = (myCargoHoc) =>
  myCargoHoc.voyageLegMyCargoHocEmissions?.map((d) => ({
    portName: { value: d.portName },
    operationalHocEmissionIntensityKgCo2eT: {
      value: formatToDecimalPlaces(d.operationalHocEmissionIntensity, 2)
    },
    hocActivityT: { value: formatNumberReadable(formatToDecimalPlaces(d.hocActivity, 1)) },
    ghcEmissionKgCo2e: { value: formatNumberReadable(formatToDecimalPlaces(d.hocGhgEmission, 0)) }
  })) ?? [];

const getHocTitle = () => (
  <TextWithTooltipIcon
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.1em'
    }}
    labelVariant="subtitle1"
    label={'My Cargo HOC (Hub Operation Category) Emission'}
    tooltipText={
      'HOC ( Hub Operation Category: A HOC is a group of hub operations that share similar characteristics). HOC GHG Emission corresponds to GHG emission from equipment at port during loading and discharging operations at ports.'
    }
  />
);

MyCargoExpandedCo2eEmissionTable.propTypes = {
  myCargoToc: PropTypes.object,
  myCargoHoc: PropTypes.object,
  isLoading: PropTypes.bool
};

export default MyCargoExpandedCo2eEmissionTable;
