import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';

function SignUpAndUserSupport() {
  return (
    <Grid item xs={12} id={'signup-support'}>
      <Typography variant={'h5'}>2. Sign up and user support</Typography>
      <Typography sx={{ pt: 2 }}>
        Please navigate to{' '}
        <a href={'https://login.esgian.com/sign-up'} target={'_blank'} rel="noreferrer">
          https://login.esgian.com/sign-up
        </a>{' '}
        and create a user profile with your work domain email.
      </Typography>
      <Typography sx={{ pt: 2 }}>
        Licencing and access to modules will be provided based on the domain of your email/company.
      </Typography>
      <Typography sx={{ pt: 2 }}>
        A representative from Esgian will assign you the appropriate modules once the user is
        created.
      </Typography>
      <Typography sx={{ pt: 2 }}>
        If you need any support you can send an email to{' '}
        <a href="mailto: hello@esgian.com">hello@esgian.com</a> and we will help out.
      </Typography>
      <Typography sx={{ pt: 2 }}>URL to access modules: </Typography>
      <Typography sx={{ pt: 2 }}>
        Shipping Analytics:{' '}
        <a
          href={'https://ship-analytics.esgian.com/roro/commercial-analytics'}
          target={'_blank'}
          rel="noreferrer">
          https://ship-analytics.esgian.com/roro/commercial-analytics
        </a>
      </Typography>
      <Typography sx={{ pt: 2 }}>
        GreenPact Ships:{' '}
        <a
          href={'https://ship-analytics.esgian.com/emissions-analytics/'}
          target={'_blank'}
          rel="noreferrer">
          https://ship-analytics.esgian.com/emissions-analytics/
        </a>
      </Typography>
    </Grid>
  );
}

SignUpAndUserSupport.propTypes = {};

SignUpAndUserSupport.defaultProps = {};

export default SignUpAndUserSupport;
