import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ServiceLevelChart,
  ServiceLevelOverview,
  SpreadAnalysisChart,
  TransitTimeAndSpreadOverview,
  TransitTimeChart
} from '@components/Sections/ShipAnalytics/OperatorPerformanceSection';
import { Grid, Paper } from '@esgian/esgianui';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getOperatorPerformanceFilters } from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
import { getDateList } from '@helpers';

function PerformanceIndicator({ loading, performanceData, renderOverview, vesselsEmissions }) {
  const {
    subSection,
    startDate,
    endDate,
    selectedPortLoad,
    selectedOperator,
    selectedPortDischarge
  } = useSelector(getOperatorPerformanceFilters);

  const getFileName = useCallback(
    (key) => {
      const operatorShortName = selectedOperator?.operatorShortName;
      const CodePOL = selectedPortLoad?.locode;
      const CodePOD = selectedPortDischarge?.locode;
      return `${operatorShortName}-${key}-${CodePOL}-${CodePOD}-${moment(startDate).format(
        'MMYY'
      )}-${moment(endDate).format('MMYY')}`;
    },
    [performanceData]
  );

  const dateRangeList = useMemo(() => {
    return getDateList(startDate, endDate);
  }, [startDate, endDate]);

  if (!renderOverview) {
    return (
      <>
        <Grid item xs={12}>
          {subSection === 1 && (
            <ServiceLevelChart
              loading={loading}
              performanceData={performanceData}
              dateRangeList={dateRangeList}
              fileName={getFileName('ServiceLevel')}
            />
          )}
          {subSection === 2 && (
            <TransitTimeChart
              loading={loading}
              performanceData={performanceData}
              fileName={getFileName('Transit')}
            />
          )}
          {subSection === 3 && (
            <SpreadAnalysisChart
              loading={loading}
              performanceData={performanceData}
              fileName={getFileName('Spread')}
            />
          )}
        </Grid>
      </>
    );
  }
  return (
    <Grid item xs={12}>
      <Paper variant={'outlined'} sx={{ p: 2, maxWidth: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {subSection === 1 && (
              <ServiceLevelOverview
                dateRangeList={dateRangeList}
                performanceData={performanceData}
              />
            )}
            {(subSection === 2 || subSection === 3) && (
              <TransitTimeAndSpreadOverview
                loading={loading}
                vesselsEmissions={vesselsEmissions}
                dateRangeList={dateRangeList}
                performanceData={performanceData}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

PerformanceIndicator.propTypes = {
  vesselsEmissions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  performanceData: PropTypes.object,
  renderOverview: PropTypes.bool
};

PerformanceIndicator.defaultProps = {
  vesselsEmissions: null,
  performanceData: {},
  renderOverview: false,
  loading: false
};

export default PerformanceIndicator;
