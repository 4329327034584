import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Link, Box, IconButton, DeleteOutlineIcon } from '@esgian/esgianui';
import { useTheme, useSegment } from '@hooks';
import { formatToDecimalPlaces, tableTitlePaddingLeft } from '@helpers';
import { Link as RouterLink } from 'react-router-dom';

const SimulatedVoyageList = ({ voyagesDetails, onDeleteVoyage }) => {
  const { theme } = useTheme();
  const { uriExt } = useSegment();

  const navigationLink = `${uriExt}/simulated-voyage-summary/voyageId`;

  const columns = useMemo(
    () => [
      { name: 'vesselName', label: 'Vessel name' },
      { name: 'cargoT', label: 'CEU capacity' },
      { name: 'operatorName', label: 'Operator name' },
      { name: 'fuelType', label: 'Fuel type' },
      { name: 'avgSpeed', label: 'Avg speed (kts)' },
      { name: 'transitDays', label: 'Transit time (Days)' },
      { name: 'etsCost', label: 'ETS cost (€)' },
      { name: 'co2eIntensity', label: 'CO2e intensity (t)' },
      { name: 'co2eTn', label: 'CO2 Emission (t)' },
      { name: 'notes', label: 'Notes' },
      {
        name: 'details',
        label: 'Details',
        options: {
          customBodyRender: (value, tableMeta) => (
            <Box display="flex" gap={1}>
              <Link component={RouterLink} to={navigationLink} underline="none">
                View
              </Link>
              <IconButton onClick={() => onDeleteVoyage?.(tableMeta.rowIndex)}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        }
      }
    ],
    [onDeleteVoyage, navigationLink]
  );

  const options = useMemo(
    () => ({
      selectableRows: 'none',
      responsive: 'standard',
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      search: false,
      elevation: 0,
      pagination: true,
      toolbar: false,
      tableBodyMaxHeight: '80vh',
      textLabels: {
        body: {
          noMatch: 'Sorry, no simulated voyages found'
        }
      },
      setTableProps: () => {
        return {
          id: 'simulated-voyage-table'
        };
      }
    }),
    []
  );

  const data = useMemo(
    () =>
      voyagesDetails.map((voyage) => ({
        vesselName: voyage.voyageTransitDetails?.vesselName,
        cargoT: voyage.voyageOverview?.cargoT,
        operatorName: voyage.voyageOverview?.operatorName ?? 'Silent Ship',
        fuelType: voyage.voyageOverview?.fuelType ?? 'Fuel A',
        avgSpeed: formatToDecimalPlaces(voyage.voyageTransitDetails?.avgSpeed, 2),
        transitDays: formatToDecimalPlaces(voyage.emissionSummary?.transitDays, 2),
        etsCost: formatToDecimalPlaces(voyage.emissionSummary?.etsCost, 2),
        co2eIntensity: formatToDecimalPlaces(voyage.emissionSummary?.co2eIntensity, 6),
        co2eTn: formatToDecimalPlaces(voyage.emissionSummary?.co2eTn, 2),
        notes: voyage.voyageOverview?.notes ?? '-',
        details: null
      })),
    [voyagesDetails]
  );

  return (
    <Table
      customStyle={tableTitlePaddingLeft}
      title="Simulated voyage List"
      data={data}
      columns={columns}
      options={options}
      mode={theme.mode}
    />
  );
};

SimulatedVoyageList.propTypes = {
  voyagesDetails: PropTypes.array.isRequired,
  onDeleteVoyage: PropTypes.func
};

export default SimulatedVoyageList;
