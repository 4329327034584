import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Table, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, getCovertTimeToHours } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { PORT_CALLS_TEXT, PORT_TIME_TEXT, PORT_WAITING_TIME_TEXT } from '@constants/tooltips';

function PortsInRegionTable({
  regionPortsData = [],
  loading,
  hideFilters,
  hideColumns,
  regionName
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const columns = useMemo(() => {
    return [
      {
        name: 'portName',
        label: 'Port Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          setCellProps: () => {
            return {
              style: {
                width: '10vw',
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (value, data) => {
            const { rowData } = data;
            return value !== null ? (
              <ProfileLink profile={'port'} name={value} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },

      {
        name: 'id',
        options: { display: false, viewColumns: false, filter: false, download: false }
      },
      {
        name: 'unlocode',
        label: 'Port Code',
        options: {
          display: !hideColumns.includes('portCode'),
          sort: true,
          sortThirdClickReset: true,
          filter: !hideFilters.includes('portFilterCode'),
          filterType: 'multiselect'
        }
      },
      {
        name: 'id',
        options: { display: false, viewColumns: false, filter: false, download: false }
      },
      {
        name: 'country',
        label: 'Country Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          setCellProps: () => {
            return {
              style: {
                width: '15vw',
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (value) => {
            return value !== null ? (
              <ProfileLink
                profile={'country'}
                id={value.id}
                name={value.countryName}
                countryCode={value.isoAlpha3}
              />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'totalPortCallsLastMonth',
        label: 'Port Calls',
        options: {
          hint: PORT_CALLS_TEXT,
          sort: true,
          sortThirdClickReset: true
        }
      },
      {
        name: 'avgPortTimeLastMonth',
        label: 'Port Time (hours)',
        options: {
          hint: PORT_TIME_TEXT,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getCovertTimeToHours({ timeString: value });
          }
        }
      },
      {
        name: 'avgPortWaitingTimeLastMonth',
        label: 'Port Waiting Time (hours)',
        options: {
          hint: PORT_WAITING_TIME_TEXT,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getCovertTimeToHours({ timeString: value });
          }
        }
      }
    ];
  }, []);

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    enableNestedDataAccess: '.',
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `ports-in-${regionName}.csv`.toLowerCase().split(' ').join('-')
    },
    setTableProps: () => {
      return {
        id: 'ports-in-regions-table'
      };
    },
    print: false,
    viewColumns: false,
    pagination: false,
    tableBodyMaxHeight: '30vh',
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    sortOrder: {
      name: 'totalPortCallsLastMonth',
      direction: 'desc'
    }
  };
  return (
    <Paper sx={{ overflow: 'auto', minHeight: '100%' }}>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' }
        }}
        title={
          <Stack>
            <Typography id={'regions-title'} variant={'h6'}>
              Ports
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              {regionName}
            </Typography>
          </Stack>
        }
        skeletonRows={6}
        loading={loading}
        mode={theme.mode}
        data={regionPortsData || []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

PortsInRegionTable.propTypes = {
  regionPortsData: PropTypes.arrayOf(PropTypes.object),
  hideFilters: PropTypes.arrayOf(PropTypes.string),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  regionName: PropTypes.string
};

PortsInRegionTable.defaultProps = {
  regionPortsData: [],
  hideFilters: [],
  hideColumns: [],
  loading: false,
  regionName: ''
};

export default PortsInRegionTable;
