import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography, Skeleton } from '@esgian/esgianui';
import { PortIcon, LocationIcon, VesselIcon } from '@components';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@constants';
import { getProgressPercentageBetweenTwoDates, formatToDecimalPlaces } from '@helpers';
import VesselStatus from './VesselStatus';
import { useCiiRatingData, useMonthlyActivityStatus, useAverageMonthlySpeed } from '@hooks';

const progressVesselIconSize = 12;
const progressBarHeight = 2;

const getAverageSpeedStats = (avgMonthlySpeed, numMonths) => {
  if (!avgMonthlySpeed?.imoAverageMonthlySpeed?.length) {
    return {
      performanceChangePercent: '-',
      recentAverageSpeed: '-'
    };
  }

  const avgMonthlySpeedData = avgMonthlySpeed.imoAverageMonthlySpeed;
  const startIndexPrevious = Math.max(0, avgMonthlySpeedData.length - numMonths * 2);
  const endIndexPrevious = Math.max(0, avgMonthlySpeedData.length - numMonths);

  const previousPeriodData = avgMonthlySpeedData.slice(startIndexPrevious, endIndexPrevious);
  const currentPeriodData = avgMonthlySpeedData.slice(-numMonths);

  if (!previousPeriodData.length || !currentPeriodData.length) {
    return {
      performanceChangePercent: '-',
      recentAverageSpeed: '-'
    };
  }

  const calculateAverageSpeed = (data) => {
    const validSpeeds = data.filter(({ monthlyAvgSpeed }) => monthlyAvgSpeed != null);
    if (!validSpeeds.length) return 0;
    return (
      validSpeeds.reduce((sum, { monthlyAvgSpeed }) => sum + monthlyAvgSpeed, 0) /
      validSpeeds.length
    );
  };

  const previousPeriodAvgSpeed = calculateAverageSpeed(previousPeriodData);
  const currentPeriodAvgSpeed = calculateAverageSpeed(currentPeriodData);

  if (!previousPeriodAvgSpeed || !currentPeriodAvgSpeed) {
    return {
      performanceChangePercent: '-',
      recentAverageSpeed: '-'
    };
  }

  const performanceChangePercent = formatToDecimalPlaces(
    ((currentPeriodAvgSpeed - previousPeriodAvgSpeed) / previousPeriodAvgSpeed) * 100,
    0
  );

  return {
    performanceChangePercent: +performanceChangePercent,
    recentAverageSpeed: +formatToDecimalPlaces(currentPeriodAvgSpeed, 0)
  };
};

const getActivityStatusStats = (monthlyActivityStatus, numMonths) => {
  const defaultResponse = {
    avgTransit: '-',
    avgStandby: '-',
    avgAtPort: '-',
    avgTransitPercentage: '-',
    avgStandbyPercentage: '-',
    avgAtPortPercentage: '-',
    transitChangePercent: '-',
    standbyChangePercent: '-',
    atPortChangePercent: '-'
  };

  if (!monthlyActivityStatus?.imoMonthlyPercentActivityStatus?.length) {
    return defaultResponse;
  }

  const monthlyActivityStatusData = monthlyActivityStatus.imoMonthlyPercentActivityStatus;
  const startIndexPrevious = Math.max(0, monthlyActivityStatusData.length - numMonths * 2);
  const endIndexPrevious = Math.max(0, monthlyActivityStatusData.length - numMonths);

  const previousPeriodData = monthlyActivityStatusData.slice(startIndexPrevious, endIndexPrevious);
  const currentPeriodData = monthlyActivityStatusData.slice(-numMonths);

  if (!previousPeriodData.length || !currentPeriodData.length) {
    return defaultResponse;
  }

  const calculateAverages = (data) => {
    if (!data.length) return null;

    const sums = data.reduce(
      (acc, month) => ({
        transit: acc.transit + (month.countMonthlyTransit || 0),
        standby: acc.standby + (month.countMonthlyStandBy || 0),
        atPort: acc.atPort + (month.countMonthlyAtPort || 0),
        transitPerc: acc.transitPerc + (month.percentMonthlyTransit || 0),
        standbyPerc: acc.standbyPerc + (month.percentMonthlyStandBy || 0),
        atPortPerc: acc.atPortPerc + (month.percentMonthlyAtPort || 0)
      }),
      {
        transit: 0,
        standby: 0,
        atPort: 0,
        transitPerc: 0,
        standbyPerc: 0,
        atPortPerc: 0
      }
    );

    return {
      avgTransit: +formatToDecimalPlaces(sums.transit / data.length, 0),
      avgStandby: +formatToDecimalPlaces(sums.standby / data.length, 0),
      avgAtPort: +formatToDecimalPlaces(sums.atPort / data.length, 0),
      avgTransitPercentage: +formatToDecimalPlaces(sums.transitPerc / data.length, 0),
      avgStandbyPercentage: +formatToDecimalPlaces(sums.standbyPerc / data.length, 0),
      avgAtPortPercentage: +formatToDecimalPlaces(sums.atPortPerc / data.length, 0)
    };
  };

  const previousPeriodAverages = calculateAverages(previousPeriodData);
  const currentPeriodAverages = calculateAverages(currentPeriodData);

  if (!previousPeriodAverages || !currentPeriodAverages) {
    return defaultResponse;
  }

  const calculateChangePercent = (current, previous) => {
    if (!previous || !current || previous === 0) return '-';
    return +formatToDecimalPlaces(((current - previous) / previous) * 100, 0);
  };

  return {
    ...currentPeriodAverages,
    transitChangePercent: calculateChangePercent(
      currentPeriodAverages.avgTransit,
      previousPeriodAverages.avgTransit
    ),
    standbyChangePercent: calculateChangePercent(
      currentPeriodAverages.avgStandby,
      previousPeriodAverages.avgStandby
    ),
    atPortChangePercent: calculateChangePercent(
      currentPeriodAverages.avgAtPort,
      previousPeriodAverages.avgAtPort
    )
  };
};

const OverviewTab = ({
  selectedVesselLatestPosition,
  selectedVesselInformation,
  selectedVesselLatestPortCall,
  selectedVesselNextPortCall,
  isLoadingVesselsDetails
}) => {
  const { ciiRatingData, isLoadingCiiRatingData } = useCiiRatingData(
    selectedVesselInformation?.imo
  );
  const { avgMonthlySpeed, isLoadingAvgMonthlySpeed } = useAverageMonthlySpeed(
    selectedVesselInformation?.imo
  );
  const { monthlyActivityStatus, isLoadingMonthlyActivityStatus } = useMonthlyActivityStatus(
    selectedVesselInformation?.imo
  );

  const [is6MSelected, setIs6MSelected] = useState(true);
  const BASIC_INFO = [
    { label: 'IMO', value: selectedVesselInformation?.imo || '-' },
    { label: 'Build year', value: selectedVesselInformation?.buildYear || '-' },
    { label: 'Operator', value: selectedVesselInformation?.operatorInformation?.companyName || '-' }
  ];

  const averageSpeedStats = getAverageSpeedStats(avgMonthlySpeed, is6MSelected ? 6 : 12);
  const activityStatusStats = getActivityStatusStats(monthlyActivityStatus, is6MSelected ? 6 : 12);

  const latestPositionInfo = [
    {
      label: 'Position Received',
      value: selectedVesselLatestPosition?.timestamp
        ? moment(selectedVesselLatestPosition.timestamp).format(DATE_TIME_FORMAT)
        : '-'
    },
    { label: 'MMSI', value: selectedVesselLatestPosition?.mmsi || '-' },
    {
      label: 'Speed',
      value: selectedVesselLatestPosition?.speed ? `${selectedVesselLatestPosition.speed} kn` : '-'
    },
    { label: 'Course', value: selectedVesselLatestPosition?.course || '-' },
    { label: 'Draught', value: selectedVesselLatestPosition?.draught || '-' }
  ];

  const vesselPerformanceData = [
    {
      label: 'Avg speed',
      percentage: averageSpeedStats.performanceChangePercent,
      value: averageSpeedStats.recentAverageSpeed
    },
    {
      label: 'Avg transit',
      percentage: activityStatusStats.avgTransitPercentage,
      value: activityStatusStats.avgTransit
    },
    {
      label: 'Avg standby',
      percentage: activityStatusStats.avgStandbyPercentage,
      value: activityStatusStats.avgStandby
    },
    {
      label: 'Avg at port',
      percentage: activityStatusStats.avgAtPortPercentage,
      value: activityStatusStats.avgAtPort
    }
  ];

  const progressPercentage =
    getProgressPercentageBetweenTwoDates(
      selectedVesselLatestPortCall?.endDate,
      selectedVesselNextPortCall?.eta
    ) || 0;

  const currentMonthEmissionData = ciiRatingData?.vesselPeriodEmissionDetailsList?.at(-1);
  const previousMonthEmissionData = ciiRatingData?.vesselPeriodEmissionDetailsList?.at(-2);

  const currentMonthTotalTco2 = currentMonthEmissionData?.totalCo2;
  const previousMonthTotalTco2 = previousMonthEmissionData?.totalCo2;
  const totalTco2Changed =
    previousMonthTotalTco2 && currentMonthTotalTco2
      ? ((currentMonthTotalTco2 - previousMonthTotalTco2) / previousMonthTotalTco2) * 100
      : 0;
  const currentMonthCiiRating = currentMonthEmissionData?.ciiRating || '-';

  const renderedBasicCard = isLoadingVesselsDetails ? (
    <Paper sx={{ p: 1 }}>
      <Skeleton variant="rectangular" height={120} />
    </Paper>
  ) : (
    <Paper sx={{ p: 1 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Basic</Typography>
      <ul style={{ margin: 0 }}>
        {BASIC_INFO.map(({ label, value }) => (
          <li key={label}>
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Typography variant="body2">{label}</Typography>
              <Typography variant="body2" sx={{ textAlign: 'right' }}>
                {value}
              </Typography>
            </Box>
          </li>
        ))}
      </ul>
    </Paper>
  );

  const renderedLatestPositionInfoCard = isLoadingVesselsDetails ? (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="rectangular" height={180} />
      </Box>
    </Paper>
  ) : (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Latest position info</Typography>

      <Box display="flex" flexDirection="column" gap={0.7}>
        <Box display="flex" justifyContent="space-between">
          <Typography>{selectedVesselLatestPortCall?.portName || '-'}</Typography>
          <Typography sx={{ textAlign: 'right' }}>
            {selectedVesselNextPortCall?.portName || '-'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontSize: '0.8rem' }}>
            {selectedVesselLatestPortCall?.portCode || '-'}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }}>
            {selectedVesselNextPortCall?.portUnlocode || '-'}
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <PortIcon />
          <Box
            sx={{
              height: progressBarHeight,
              bgcolor: 'grey.100',
              flex: 1,
              position: 'relative'
            }}>
            <Box
              sx={{
                bgcolor: '#2196F3',
                height: progressBarHeight,
                width: `${progressPercentage}%`,
                textAlign: 'right'
              }}
            />
            <Box
              sx={{
                transform: 'rotate(90deg)',
                position: 'absolute',
                top: `-${progressVesselIconSize - progressBarHeight / 2}px`,
                left: `calc(${progressPercentage}% - ${progressVesselIconSize / 2}px)`
              }}>
              <VesselIcon size={progressVesselIconSize} />
            </Box>
          </Box>
          <LocationIcon color="#EACC45" />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontSize: '0.8rem' }}>
            ATD:{' '}
            {selectedVesselLatestPortCall?.endDate
              ? moment(selectedVesselLatestPortCall.endDate).format(DATE_TIME_FORMAT)
              : '-'}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', textAlign: 'right' }}>
            ETA:{' '}
            {selectedVesselNextPortCall?.eta
              ? moment(selectedVesselNextPortCall.eta).format(DATE_TIME_FORMAT)
              : '-'}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '0.8rem' }}>Latest position info:</Typography>
        <ul style={{ margin: 0, paddingLeft: '16px' }}>
          {latestPositionInfo.map(({ label, value }) => (
            <li key={label}>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontSize: '0.8rem' }}>{label}:</Typography>
                <Typography sx={{ fontSize: '0.8rem', textAlign: 'right' }}>{value}</Typography>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </Paper>
  );

  const renderedVesselPerformanceCard =
    isLoadingAvgMonthlySpeed || isLoadingMonthlyActivityStatus ? (
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" justifyContent="space-between">
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={100} height={24} />
          </Box>
          <Skeleton variant="rectangular" height={160} />
        </Box>
      </Paper>
    ) : (
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Typography sx={{ fontWeight: 'bold' }}>Vessel performance</Typography>
            <Box display="flex" gap={1} alignItems="center" justifyContent="flex-end">
              <Typography>Latest:</Typography>
              <Box display="flex" gap={0.5}>
                <Button
                  sx={{
                    m: 0,
                    p: 0.2,
                    minWidth: 0,
                    borderRadius: 0,
                    textDecoration: is6MSelected ? 'underline' : 'none'
                  }}
                  variant="text"
                  onClick={() => setIs6MSelected(true)}>
                  6M
                </Button>
                <Button
                  sx={{
                    m: 0,
                    p: 0.2,
                    minWidth: 0,
                    borderRadius: 0,
                    textDecoration: !is6MSelected ? 'underline' : 'none'
                  }}
                  variant="text"
                  onClick={() => setIs6MSelected(false)}>
                  12M
                </Button>
              </Box>
            </Box>
          </Box>
          <ul style={{ margin: 0, paddingLeft: '16px' }}>
            {vesselPerformanceData.map(({ label, percentage, value }) => (
              <li key={label}>
                <Box display="flex" justifyContent="space-between" mb={0.5}>
                  <Typography>{label}</Typography>
                  <Box textAlign="right">
                    <Typography sx={{ color: percentage > 0 ? 'success.main' : 'error.main' }}>
                      {percentage !== '-' && percentage > 0 ? '+' : ''}
                      {percentage}%
                    </Typography>
                    <Typography sx={{ fontSize: '0.8rem' }}>{value}</Typography>
                  </Box>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      </Paper>
    );

  const renderedEmissionCard = isLoadingCiiRatingData ? (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="rectangular" height={80} />
      </Box>
    </Paper>
  ) : (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography sx={{ fontWeight: 'bold' }}>Emission</Typography>
        <Box display="flex" justifyContent="space-between" mb={0.5}>
          <Typography>Total tCO2 this month</Typography>
          <Box textAlign="right">
            <Typography sx={{ color: totalTco2Changed > 0 ? 'success.main' : 'error.main' }}>
              {totalTco2Changed
                ? (totalTco2Changed > 0 ? '+' : '') +
                  formatToDecimalPlaces(totalTco2Changed, 0) +
                  '%'
                : '-'}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {currentMonthTotalTco2 ? formatToDecimalPlaces(currentMonthTotalTco2, 1) : '-'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={0.5}>
          <Typography>CII rating</Typography>
          <Typography sx={{ color: 'success.main' }}>{currentMonthCiiRating}</Typography>
        </Box>
      </Box>
    </Paper>
  );

  const renderedVesselStatusCard = isLoadingMonthlyActivityStatus ? (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="rectangular" height={80} />
      </Box>
    </Paper>
  ) : (
    <VesselStatus monthlyActivityStatus={monthlyActivityStatus} />
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {renderedBasicCard}
      {renderedLatestPositionInfoCard}
      {renderedVesselStatusCard}
      {renderedVesselPerformanceCard}
      {renderedEmissionCard}
    </Box>
  );
};

OverviewTab.propTypes = {
  selectedVesselLatestPosition: PropTypes.object,
  selectedVesselInformation: PropTypes.object,
  selectedVesselLatestPortCall: PropTypes.object,
  selectedVesselNextPortCall: PropTypes.object,
  isLoadingVesselsDetails: PropTypes.bool
};

export default OverviewTab;
