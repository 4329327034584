import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getPortDetails = (signal, portId) => {
  return BaseApiRequest(signal, `/Port/PortDetails/${portId}`, 'geo');
};

export const getPortUpcomingPortCalls = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/IncomingVessels/Port?${query}`);
};

export const getGlobalPortCongestion = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/GlobalPortCongestion?${query}`);
};

export const getLastPortCallsDownload = (data, signal) => {
  return BaseApiRequest(signal, `/File/LastPortCalls?api-version=1.0`, 'ships', 'download', data);
};
