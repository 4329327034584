import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getVesselMonthlyPercentActivityStatus } from '@api';
import { DATE_FORMAT } from '@constants';

export const useMonthlyActivityStatus = (imo) => {
  const { data: monthlyActivityStatus, isPending: isLoadingMonthlyActivityStatus } = useQuery({
    queryKey: ['vesselMonthlyActivityStatus', imo],
    queryFn: ({ signal }) =>
      getVesselMonthlyPercentActivityStatus(
        signal,
        imo,
        moment().startOf('day').subtract(1, 'day').subtract(24, 'months').format(DATE_FORMAT),
        moment().startOf('day').subtract(1, 'day').format(DATE_FORMAT)
      ),
    enabled: !!imo
  });

  return {
    monthlyActivityStatus,
    isLoadingMonthlyActivityStatus
  };
};
