import { useQuery } from '@tanstack/react-query';
import { getPortCongestion } from '@api';
import { useSelector } from 'react-redux';
import { getOperatorType, getSegment, getDateFormat } from '@store/features';
import moment from 'moment';
import { GEOGRAPHICAL_TYPES } from '@constants';

export const useGlobalPortCongestion = () => {
  const segment = useSelector(getSegment);
  const operatorType = useSelector(getOperatorType);
  const dateFormat = useSelector(getDateFormat);
  const startDate = moment().subtract(12, 'month').startOf('month').format(dateFormat);
  const endDate = moment().subtract(1, 'month').endOf('month').format(dateFormat);

  return useQuery({
    queryKey: ['globalPortCongestion', segment.id, operatorType],
    queryFn: ({ signal }) =>
      getPortCongestion(
        {
          operatorTypeId: operatorType,
          segmentTypeId: segment.id,
          startDate,
          endDate,
          GeoType: GEOGRAPHICAL_TYPES.ALL
        },
        signal
      ),
    enabled: Boolean(typeof segment.id === 'number' && typeof operatorType === 'number')
  });
};
