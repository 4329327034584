import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@esgian/esgianui';
import { checkUserHasAccess, getTableCellRenderCII } from '@helpers';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const quarterToMonth = (quarter) => {
  switch (quarter) {
    case 'Q1':
      return '01';
    case 'Q2':
      return '03';
    case 'Q3':
      return '06';
    case 'Q4':
      return '09';
  }
};

const getPeriod = (periodSelect) => {
  let periodName = 'Month';
  if (periodSelect === 2) {
    periodName = 'Quarter';
  }
  if (periodSelect === 3) {
    periodName = 'Year';
  }
  return periodName;
};

function VesselCiiRatingTable({ ciiRatingData, loading, periodSelect, fileName }) {
  const { isMPP } = useSegment();
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const tableData = useMemo(() => {
    if (!ciiRatingData) return;
    const periodData = ciiRatingData.vesselPeriodEmissionDetailsList;

    let tempData = {
      cii: [],
      ciiRating: [],
      poseidonPrinciple: []
    };
    if (periodData) {
      for (let item of periodData) {
        tempData.cii.push(item.cii || 0);
        tempData.ciiRating.push(item.ciiRating || 0);
        tempData.poseidonPrinciple.push(item.poseidonPrinciple || 0);
      }
      if (periodSelect === 3 && isMPP) {
        return periodData.slice(1);
      }
      return periodData;
    }
  }, [ciiRatingData, periodSelect]);

  const columns = useMemo(() => {
    return [
      {
        name: 'periodStr',
        label: getPeriod(periodSelect),
        options: {
          sortCompare: (order) => {
            return (obj1, obj2) => {
              if (periodSelect === 1) {
                let val1 = moment(obj1.data, 'MMM yyyy').unix();
                let val2 = moment(obj2.data, 'MMM yyyy').unix();
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              } else if (periodSelect === 2) {
                let quarterAndYear1 = obj1.data?.split(' ');
                let quarterAndYear2 = obj2.data?.split(' ');

                let val1 = moment(
                  `${quarterToMonth(quarterAndYear1[0])} ${quarterAndYear1[1]}`,
                  'MM yyyy'
                ).unix();
                let val2 = moment(
                  `${quarterToMonth(quarterAndYear2[0])} ${quarterAndYear2[1]}`,
                  'MM yyyy'
                ).unix();
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              } else if (periodSelect === 3) {
                let val1 = moment(obj1.data, 'yyyy').unix();
                let val2 = moment(obj2.data, 'yyyy').unix();
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              }
            };
          },
          sortThirdClickReset: true,
          filter: true,
          sort: true
        }
      },
      {
        name: 'cii',
        label: 'CII',
        options: {
          hint: 'Carbon intensity index(g CO₂/GT.NM)',
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return parseFloat(value?.toFixed(2)) || '-';
          }
        }
      },
      {
        name: 'ciiRating',
        label: 'CII Rating',
        options: {
          filter: true,
          sort: true,
          setCellProps: () => {
            return {};
          },
          customBodyRender: (value) => {
            return getTableCellRenderCII(value, theme);
          }
        }
      },
      {
        name: 'poseidonPrinciple',
        label: 'Poseidon Principle',
        options: {
          hint: 'CII (gCO2/GT.NM) limits as per Poseidon principle trajectory',
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return parseFloat(value?.toFixed(2));
          }
        }
      }
    ];
  }, [theme, periodSelect]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: true,
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: fileName
      },
      tableBodyMaxHeight: '70vh',
      tableBodyHeight: '70vh',
      print: checkUserHasAccess(user, true),
      viewColumns: false,
      pagination: false,
      elevation: 0,
      toolbar: true,
      rowsPerPage: 12,
      sortOrder: {
        name: 'periodStr',
        direction: 'desc'
      }
    };
  }, [user]);
  return (
    <Table
      loading={loading}
      mode={theme.mode}
      data={tableData?.length ? tableData : []}
      columns={columns}
      options={options}
    />
  );
}

VesselCiiRatingTable.propTypes = {
  ciiRatingData: PropTypes.object,
  loading: PropTypes.bool,
  periodSelect: PropTypes.number,
  fileName: PropTypes.string.isRequired,
  vesselName: PropTypes.string
};

VesselCiiRatingTable.defaultProps = {
  ciiRatingData: null,
  loading: false,
  periodSelect: 1,
  vesselName: ''
};

export default VesselCiiRatingTable;
