import { ArrowBackIcon, Box, ColorBox, Typography } from '@esgian/esgianui';
import React from 'react';
import { useSegment } from '@hooks';
import { useNavigate, useParams } from 'react-router-dom';
import savedVoyagesData from '../SimulatedVoyagePage/simulation-result-data.json';
import SimulatedVoyageGroupSection from '../../../components/Sections/VoyageAnalytics/SimulatedVoyageGroupSection';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

const SimulatedVoyageGroupPage = () => {
  const { uriExt } = useSegment();
  const navigate = useNavigate();

  const themeMode = useSelector(getThemeMode);

  const savedVoyages = savedVoyagesData;

  return (
    <Box display="flex" flexDirection="column" gap={2} height="98vh" width="100%">
      <Box display="flex" alignItems="center" gap={1}>
        <ColorBox
          onClick={() =>
            navigate(`${uriExt}/simulated-voyages`, {
              relative: true
            })
          }>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
        <Box sx={{ marginLeft: 1 }}>
          <Typography variant="h6">Group 1</Typography>
          <Typography variant="body2">Simulated Voyages: {savedVoyages.length}</Typography>
        </Box>
      </Box>
      <Box flex={1}>
        <SimulatedVoyageGroupSection key={themeMode} savedVoyagesDetails={savedVoyages} />
      </Box>
    </Box>
  );
};

export default SimulatedVoyageGroupPage;
