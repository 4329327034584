import React, { useEffect, useMemo, useState } from 'react';
import { Box, Slider, Typography } from '@esgian/esgianui';
import moment from 'moment';
import PropTypes from 'prop-types';
import VoyageSummaryPeriodSelection from './VoyageSummaryPeriodSelection';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';
import { VoyageSummaryFilterKeys } from '../VoyageSummaryConstants';
import { useDebounce } from '@hooks/useDebounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

const VoyageSummaryDateSlider = ({ startDate, endDate, voyagePortCalls, isOngoingVoyage }) => {
  const [searchParams] = useSearchParams();
  const { themeMode } = useSelector(getGeneralSlice);

  const [dateRange, setDateRange] = useState([
    moment(startDate).valueOf(),
    moment(endDate).valueOf()
  ]);
  const [selectedDateTime, setSelectedDateTime] = useState(moment(endDate).valueOf());
  const navigate = useNavigate();
  const debouncedSelectedDateTime = useDebounce(selectedDateTime);

  const selectedPeriodFilter = useMemo(() => {
    let startDate = searchParams.get(VoyageSummaryFilterKeys.START_DATE);
    let endDate = searchParams.get(VoyageSummaryFilterKeys.END_DATE);
    startDate = startDate ? +startDate : null;
    endDate = endDate ? +endDate : null;
    return { startDate: startDate, endDate: endDate };
  }, [searchParams]);

  useEffect(() => {
    const start = selectedPeriodFilter.startDate ?? moment(startDate).valueOf();
    const end = selectedPeriodFilter.endDate ?? moment(endDate).valueOf();
    if (start !== dateRange[0] || end !== dateRange[1]) {
      setSelectedDateTime(end);
    }
    setDateRange([start, end]);
  }, [startDate, endDate, selectedPeriodFilter]);

  useEffect(() => {
    if (debouncedSelectedDateTime) {
      searchParams.set(VoyageSummaryFilterKeys.SELECTED_DATE, debouncedSelectedDateTime);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [debouncedSelectedDateTime]);

  const handleDateRangeChange = (event, newValue) => {
    setSelectedDateTime(newValue);
  };

  return (
    <Box flex={1} mx={2} id={'voyage-date-slider'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1
        }}>
        <Typography>Timeline:</Typography>
        <Box>
          <VoyageSummaryPeriodSelection
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            voyagePortCalls={voyagePortCalls}
          />
        </Box>
      </Box>
      <Slider
        min={dateRange[0]}
        max={dateRange[1]}
        value={selectedDateTime}
        onChange={handleDateRangeChange}
        valueLabelDisplay="auto"
        step={5 * 60 * 1000}
        valueLabelFormat={(value) => moment(value).format('YYYY-MM-DD HH:mm')}
        sx={{
          '& .MuiSlider-valueLabel': {
            background: 'transparent',
            color: themeMode ? 'black' : 'white',
            '& .MuiSlider-valueLabelLabel': {
              backgroundColor: themeMode ? '#FFFFFFCC' : '#000000CC',
              p: 0.5,
              borderRadius: 0.5
            }
          }
        }}
      />
    </Box>
  );
};

VoyageSummaryDateSlider.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  isOngoingVoyage: PropTypes.bool
};

export default VoyageSummaryDateSlider;
