import { OPERATOR_TYPE } from '@constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOperatorType } from '@store/features';

export const useOperatorType = () => {
  const operatorType = useSelector(getOperatorType);

  const isOprTypeBoth = useMemo(() => operatorType === OPERATOR_TYPE.ALL, [operatorType]);
  const isShortSea = useMemo(() => operatorType === OPERATOR_TYPE.SHORT_SEA, [operatorType]);
  const isDeepSea = useMemo(() => operatorType === OPERATOR_TYPE.DEEP_SEA, [operatorType]);

  return { isOprTypeBoth, isShortSea, isDeepSea, operatorType: operatorType };
};
