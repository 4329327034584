import React, { useEffect } from 'react';
import { Grid, PageHeader, Typography, CircularProgress, Box, Link } from '@esgian/esgianui';
import OngoingVoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/OngoingVoyageAnalyticsFilters';
import OnGoingVoyageSection from '@components/Sections/VoyageAnalytics/OngoingVoyageSection';
import VoyageGroupsPopup from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import { useVoyageGroups } from '../hooks/useVoyageGroups';
import { useOngoingVoyageFilter } from '../hooks/useOngoingVoyageFilter';
import { useSelector } from 'react-redux';
import { getOngoingVoyagesFilters } from '@store/features/filters/VoyageAnalyticsPage/OngoingVoyage/OngoingVoyagesSlice';

function OngoingVoyagePage() {
  const { ongoingVoyagesGroups, prefetchTransitDetails } = useVoyageGroups();
  const { isLoading, searchResults, trigger, isFetched } = useOngoingVoyageFilter();
  const reduxFilters = useSelector(getOngoingVoyagesFilters);

  const checkValidFilters = (filters) => {
    return !!(filters && (filters.imos || filters.operatorIds));
  };

  useEffect(() => {
    if (!ongoingVoyagesGroups || !ongoingVoyagesGroups.length) return;
    prefetchTransitDetails(
      ongoingVoyagesGroups.filter((g) => g.isFavorite && g.voyages.length > 0).map((g) => g.id)
    );
  }, [ongoingVoyagesGroups]);

  useEffect(() => {
    if (!isFetched && checkValidFilters(reduxFilters)) {
      trigger(reduxFilters);
    }
  }, [reduxFilters, isFetched]);

  return (
    <Box sx={{ minHeight: '90vh', width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <PageHeader id={'lbl-voyage-analytics'} title={'Voyage analytics'} />
        </Grid>
        <Grid item container xs={6} spacing={1} justifyContent="flex-end" alignItems="center">
          <VoyageGroupsPopup mode="search" />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'body2'}>Ongoing voyage</Typography>
            <Link
              href="https://www.youtube.com/watch?v=dLpmlfOvp3s"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ marginLeft: 1, fontSize: '14px' }}>
              (Introduction Video)
            </Link>
          </Box>
        </Grid>
        <OngoingVoyageAnalyticsFilters
          checkValidFilters={checkValidFilters}
          handleApplyFilters={(filterParams) => {
            trigger(filterParams);
          }}
        />
        {isLoading && (
          <Grid
            item
            xs={12}
            style={{
              minHeight: '400px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}>
            <CircularProgress
              sx={{
                position: 'absolute',
                top: 'calc(50% - 50px)',
                left: 'calc(50% - 50px)'
              }}
              size={100}
            />
          </Grid>
        )}

        {!isLoading && (
          <Grid
            item
            xs={12}
            style={{
              minHeight: '400px'
            }}>
            <OnGoingVoyageSection loading={isLoading} completedVoyagesData={searchResults} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default OngoingVoyagePage;
