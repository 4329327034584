import React from 'react';
import PropTypes from 'prop-types';
import { Button, Chip, Grid, Alert, Divider } from '@esgian/esgianui';

function MissingOperatorsDisplay({
  missingOperators,
  selectedOperators,
  setSelectedOperators,
  setMissingOperators,
  noData,
  alertText
}) {
  const removeSelectedOperator = (operatorId) => {
    let selectedResult;
    let missingResult = [];
    if (operatorId === 'All') {
      let ids = missingOperators.map(({ id }) => id);
      selectedResult = selectedOperators.filter(({ id }) => !ids.includes(id));
    } else {
      selectedResult = selectedOperators.filter(({ id }) => id !== operatorId);
      missingResult = missingOperators.filter(({ id }) => id !== operatorId);
    }
    setSelectedOperators(selectedResult);
    setMissingOperators(missingResult);
  };

  return (
    <>
      {!!missingOperators.length && !noData && (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Alert severity={'info'}>{alertText}</Alert>
          </Grid>
          <Grid container item spacing={1}>
            {missingOperators.map(({ id, operatorShortName }) => (
              <Grid item key={`${id}-missing}`}>
                <Chip label={operatorShortName} onDelete={() => removeSelectedOperator(id)} />
              </Grid>
            ))}
            <Grid item>
              <Button variant={'outlined'} onClick={() => removeSelectedOperator('All')}>
                Clear Invalid Operators
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

MissingOperatorsDisplay.propTypes = {
  missingOperators: PropTypes.arrayOf(PropTypes.object),
  selectedOperators: PropTypes.arrayOf(PropTypes.object),
  setSelectedOperators: PropTypes.func.isRequired,
  noData: PropTypes.bool.isRequired,
  alertText: PropTypes.string.isRequired,
  setMissingOperators: PropTypes.func.isRequired
};

MissingOperatorsDisplay.defaultProps = {
  missingOperators: [],
  selectedOperators: []
};

export default MissingOperatorsDisplay;
