import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DashboardActivityMapDrawerHeader from './DashboardActivityMapDrawerHeader';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  FilterSlider,
  DateRangeMobi,
  IconButton,
  Paper
} from '@esgian/esgianui';
import { OperatorMultiSelect, OwnerMultiSelect, LocationSelect } from '@components/Inputs';
import moment from 'moment';
import { useDynamicLookupQueries, useStaticLookupQueries, useSegment, useTheme } from '@hooks';
import {
  COMMERCIAL_CATEGORY_TYPE,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_LIFTING_CAPACITY,
  VESSEL_MIN_BUILD_YEAR,
  VESSEL_MAX_GROSS_TONNAGE,
  OPERATOR_TYPE
} from '@constants';
import { LoopPlaceholder } from '../../../Common';
import { formatNumberReadable } from '@helpers';
import { useSelector } from 'react-redux';
import { getDateFormat, getThemeMode } from '@store/features';
import { CloseIcon } from '@esgian/esgianui';
import { getTransparentBackground } from '@helpers';

const initialValues = {
  selectedLocation1: null,
  selectedLocation2: null,
  selectedLocation3: null,
  selectedLocation4: null,
  selectedOperators: [],
  selectedOwners: [],
  minCarCapacity: 0,
  maxCarCapacity: VESSEL_MAX_CAPACITY,
  minLiftingCapacity: 0,
  maxLiftingCapacity: VESSEL_MAX_LIFTING_CAPACITY,
  minBuildYear: VESSEL_MIN_BUILD_YEAR,
  maxBuildYear: moment().year(),
  minDeadweight: 0,
  maxDeadweight: VESSEL_MAX_CAPACITY_DWT,
  minGrossTonnage: 0,
  maxGrossTonnage: VESSEL_MAX_GROSS_TONNAGE,
  operatorTypeId: OPERATOR_TYPE.ALL,
  commercialCategoryId: COMMERCIAL_CATEGORY_TYPE.ALL
};

export const isInitialValues = (filters) => {
  return Object.keys(initialValues).every((key) => filters?.[key] === initialValues[key]);
};

const DashboardActivityMapFilter = ({
  onClose,
  onApply,
  defaultFilters,
  disableLocationFilter,
  disableOperatorFilter,
  disableOwnerFilter,
  isLoading,
  isInboundVesselsFiltering
}) => {
  const { customScrollbar } = useTheme();
  const { lookupOperatorsQuery, lookupOwnerOperatorVesselHierarchyQuery } =
    useDynamicLookupQueries();

  const dateFormat = useSelector(getDateFormat);
  const themeMode = useSelector(getThemeMode);

  const { lookupLocationsQuery } = useStaticLookupQueries();
  const { isRoRo } = useSegment();

  // Safely access data with default empty arrays
  const lookupOperators = lookupOperatorsQuery?.data || [];
  const lookupOwners = lookupOwnerOperatorVesselHierarchyQuery?.data || [];
  const lookupLocations = lookupLocationsQuery?.data || [];

  const [locationCount, setLocationCount] = useState(1);

  // State management for all filter values with default values from props
  const [filters, setFilters] = useState({
    selectedLocation1: defaultFilters?.selectedLocation1 || null,
    selectedLocation2: defaultFilters?.selectedLocation2 || null,
    selectedLocation3: defaultFilters?.selectedLocation3 || null,
    selectedLocation4: defaultFilters?.selectedLocation4 || null,
    selectedOperators: defaultFilters?.selectedOperators || [],
    selectedOwners: defaultFilters?.selectedOwners || [],
    selectedCategories: defaultFilters?.selectedCategories || [],
    minCarCapacity: defaultFilters?.minCarCapacity || 0,
    maxCarCapacity: defaultFilters?.maxCarCapacity || VESSEL_MAX_CAPACITY,
    minLiftingCapacity: defaultFilters?.minLiftingCapacity || 0,
    maxLiftingCapacity: defaultFilters?.maxLiftingCapacity || VESSEL_MAX_LIFTING_CAPACITY,
    minBuildYear: defaultFilters?.minBuildYear || VESSEL_MIN_BUILD_YEAR,
    maxBuildYear: defaultFilters?.maxBuildYear || moment().year(),
    minDeadweight: defaultFilters?.minDeadweight || 0,
    maxDeadweight: defaultFilters?.maxDeadweight || VESSEL_MAX_CAPACITY_DWT,
    minGrossTonnage: defaultFilters?.minGrossTonnage || 0,
    maxGrossTonnage: defaultFilters?.maxGrossTonnage || VESSEL_MAX_GROSS_TONNAGE,
    operatorTypeId: defaultFilters?.operatorTypeId || OPERATOR_TYPE.ALL,
    commercialCategoryId: defaultFilters?.commercialCategoryId || COMMERCIAL_CATEGORY_TYPE.ALL,
    startDate: defaultFilters?.startDate || null,
    endDate: defaultFilters?.endDate || null
  });

  // Handler to update filter values
  const updateFilterValue = useCallback((payload) => {
    setFilters((prev) => ({ ...prev, ...payload }));
  }, []);

  const handleReset = () => {
    setFilters(initialValues);
    setLocationCount(1);
    onApply(initialValues);
  };

  const handleApply = () => {
    onApply(filters);
  };

  const handleAddLocation = () => {
    if (locationCount < 4) {
      setLocationCount((prev) => prev + 1);
    }
  };

  const handleRemoveLocation = (index) => {
    // Update filters to set the removed location to null
    updateFilterValue({ [`selectedLocation${index + 1}`]: null });

    // Shift remaining locations up
    for (let i = index + 1; i < locationCount; i++) {
      updateFilterValue({
        [`selectedLocation${i}`]: filters[`selectedLocation${i + 1}`]
      });
    }

    // Clear the last location
    updateFilterValue({ [`selectedLocation${locationCount}`]: null });

    // Decrease location count
    setLocationCount((prev) => prev - 1);
  };

  if (isLoading) {
    return <LoopPlaceholder />;
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: getTransparentBackground(themeMode)
      }}>
      <Box px={4} py={2}>
        <DashboardActivityMapDrawerHeader
          title={isInboundVesselsFiltering ? 'Inbound Vessels Filter' : 'Advanced Filter'}
          onClose={onClose}
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        pt={2}
        px={4}
        overflow="auto"
        sx={customScrollbar}
        flex={1}
        mb={2}>
        {[...Array(locationCount)].map((_, index) => (
          <Box key={`location${index + 1}`} display="flex" alignItems="center" gap={1}>
            <LocationSelect
              label={
                locationCount > 1
                  ? `${isInboundVesselsFiltering ? 'Destination' : 'Location'} ${index + 1}`
                  : isInboundVesselsFiltering
                  ? 'Destination'
                  : 'Location'
              }
              placeholder="Search by port or country"
              handleChange={(val) => updateFilterValue({ [`selectedLocation${index + 1}`]: val })}
              selectedLocation={filters[`selectedLocation${index + 1}`]}
              locationList={lookupLocations}
              width="100%"
              disabled={disableLocationFilter}
              disableRegion
              loading={lookupLocationsQuery.isFetching}
            />
            {locationCount > 1 && (
              <IconButton onClick={() => handleRemoveLocation(index)} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        ))}

        {isInboundVesselsFiltering && locationCount < 4 && (
          <Button onClick={handleAddLocation} variant="text" sx={{ alignSelf: 'flex-start' }}>
            + Add Destination
          </Button>
        )}

        {isInboundVesselsFiltering && (
          <DateRangeMobi
            sx={{
              inputProps: {
                autoComplete: 'off',
                label: 'ETA window',
                defaultValue: undefined,
                value:
                  filters?.startDate || filters?.endDate
                    ? `${
                        moment(filters?.startDate).isValid()
                          ? moment(filters?.startDate).format(dateFormat)
                          : ''
                      } - ${
                        moment(filters?.endDate).isValid()
                          ? moment(filters?.endDate).format(dateFormat)
                          : ''
                      }`
                    : ''
              }
            }}
            onClose={({ value: [start, end] }) => {
              updateFilterValue({
                startDate: moment(start).format(dateFormat),
                endDate: moment(end).format(dateFormat)
              });
            }}
            dateFormat={dateFormat}
            startDate={filters?.startDate ? moment(filters.startDate).format(dateFormat) : null}
            endDate={filters?.endDate ? moment(filters.endDate).format(dateFormat) : null}
            placeholder={'ETA window'}
            minStartDate={moment()}
          />
        )}

        <OperatorMultiSelect
          helperText=""
          error={false}
          placeholder="Select Operators..."
          selectedOperators={filters.selectedOperators}
          operatorsList={lookupOperators}
          handleChange={(values) => updateFilterValue({ selectedOperators: values })}
          width="100%"
          loading={lookupOperatorsQuery.isFetching}
          disabled={disableOperatorFilter}
          label="Operator"
        />

        {isRoRo && (
          <OwnerMultiSelect
            helperText=""
            error={false}
            placeholder="Select Owners..."
            selectedOwners={filters.selectedOwners}
            ownersList={lookupOwners}
            handleChange={(values) => updateFilterValue({ selectedOwners: values })}
            width="100%"
            loading={lookupOwnerOperatorVesselHierarchyQuery.isFetching}
            disabled={disableOwnerFilter}
            label="Owner"
          />
        )}

        <Box>
          <Typography variant="subtitle1" gutterBottom color="text.primary">
            Display {isRoRo ? 'Operators' : 'Commercial category'}
          </Typography>
          {isRoRo ? (
            <RadioGroup
              value={filters.operatorTypeId}
              onChange={({ target }) => {
                updateFilterValue({ operatorTypeId: Number(target.value) });
              }}>
              <FormControlLabel
                value={OPERATOR_TYPE.ALL}
                label={<Typography variant="subtitle2">All</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={OPERATOR_TYPE.SHORT_SEA}
                label={<Typography variant="subtitle2">Short sea</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={OPERATOR_TYPE.DEEP_SEA}
                label={<Typography variant="subtitle2">Deep sea</Typography>}
                control={<Radio />}
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              value={filters.commercialCategoryId}
              onChange={({ target }) => {
                updateFilterValue({ commercialCategoryId: Number(target.value) });
              }}>
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.ALL}
                label={<Typography variant="subtitle2">All</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_CORE}
                label={<Typography variant="subtitle2">Deep sea core</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_EXT}
                label={<Typography variant="subtitle2">Deep sea extended</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.SHORT_SEA_EUR}
                label={<Typography variant="subtitle2">Short sea Europe</Typography>}
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.OTHER}
                label={<Typography variant="subtitle2">Others</Typography>}
                control={<Radio />}
              />
            </RadioGroup>
          )}
        </Box>

        <Box>
          <FilterSlider
            disableMarkRotation
            step={isRoRo ? 100 : 1000}
            marks={[
              { value: 0, label: 0 },
              {
                value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY,
                label: isRoRo
                  ? formatNumberReadable(VESSEL_MAX_CAPACITY)
                  : formatNumberReadable(VESSEL_MAX_LIFTING_CAPACITY)
              }
            ]}
            value={
              isRoRo
                ? [filters.minCarCapacity, filters.maxCarCapacity]
                : [filters.minLiftingCapacity, filters.maxLiftingCapacity]
            }
            max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY}
            min={0}
            title={isRoRo ? 'Car capacity (CEU)' : 'Lifting capacity (MT)'}
            onChange={(val) =>
              updateFilterValue(
                isRoRo
                  ? {
                      minCarCapacity: val[0],
                      maxCarCapacity: val[1]
                    }
                  : {
                      minLiftingCapacity: val[0],
                      maxLiftingCapacity: val[1]
                    }
              )
            }
          />
        </Box>

        <Box>
          <FilterSlider
            disableMarkRotation
            marks={[
              { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
              { value: moment().year(), label: moment().year() }
            ]}
            value={[filters.minBuildYear, filters.maxBuildYear]}
            max={moment().year()}
            min={VESSEL_MIN_BUILD_YEAR}
            title="Build year"
            onChange={(val) => updateFilterValue({ minBuildYear: val[0], maxBuildYear: val[1] })}
          />
        </Box>

        <Box>
          <FilterSlider
            disableMarkRotation
            step={1000}
            marks={[
              { value: 0, label: 0 },
              {
                value: VESSEL_MAX_CAPACITY_DWT,
                label: formatNumberReadable(VESSEL_MAX_CAPACITY_DWT)
              }
            ]}
            value={[filters.minDeadweight, filters.maxDeadweight]}
            max={VESSEL_MAX_CAPACITY_DWT}
            min={0}
            title="Deadweight (DWT)"
            onChange={(val) => updateFilterValue({ minDeadweight: val[0], maxDeadweight: val[1] })}
          />
        </Box>

        <Box>
          <FilterSlider
            disableMarkRotation
            step={10}
            marks={[
              { value: 0, label: 0 },
              {
                value: VESSEL_MAX_GROSS_TONNAGE,
                label: formatNumberReadable(VESSEL_MAX_GROSS_TONNAGE)
              }
            ]}
            value={[filters.minGrossTonnage, filters.maxGrossTonnage]}
            max={VESSEL_MAX_GROSS_TONNAGE}
            min={0}
            title="Gross Tonnage (GT)"
            onChange={(val) =>
              updateFilterValue({ minGrossTonnage: val[0], maxGrossTonnage: val[1] })
            }
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2} mb={2}>
        <Divider />
        <Box display="flex" justifyContent="space-between" px={2}>
          <Button variant="text" onClick={handleReset}>
            Reset all
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

DashboardActivityMapFilter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  defaultFilters: PropTypes.shape({
    selectedLocation1: PropTypes.object,
    selectedLocation2: PropTypes.object,
    selectedLocation3: PropTypes.object,
    selectedLocation4: PropTypes.object,
    selectedOperators: PropTypes.array,
    selectedOwners: PropTypes.array,
    selectedCategories: PropTypes.array,
    minCarCapacity: PropTypes.number,
    maxCarCapacity: PropTypes.number,
    minLiftingCapacity: PropTypes.number,
    maxLiftingCapacity: PropTypes.number,
    minBuildYear: PropTypes.number,
    maxBuildYear: PropTypes.number,
    minDeadweight: PropTypes.number,
    maxDeadweight: PropTypes.number,
    minGrossTonnage: PropTypes.number,
    maxGrossTonnage: PropTypes.number,
    operatorTypeId: PropTypes.number,
    commercialCategoryId: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  disableLocationFilter: PropTypes.bool,
  disableOperatorFilter: PropTypes.bool,
  disableOwnerFilter: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInboundVesselsFiltering: PropTypes.bool
};

export default DashboardActivityMapFilter;
