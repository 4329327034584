import { createSlice } from '@reduxjs/toolkit';
import { DATE_FORMAT, OPERATOR_SELECT_TYPES } from '@constants';
import moment from 'moment';

export const REGION_HISTORICAL_ACTIVITY_SLICE_KEYS = {
  startDate: 'date',
  endDate: 'date',
  selectedOperators: 'array',
  selectType: 'string'
};

const initialState = {
  startDate: moment().subtract(6, 'months').startOf('month').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  selectedOperators: [],
  selectType: OPERATOR_SELECT_TYPES.AUTO
};

const RegionHistoricalActivitySlice = createSlice({
  name: 'HistoricalActivity',
  initialState: initialState,
  reducers: {
    resetRegionHistActivityFilters: () => initialState,
    updateRegionHistActivityFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }
  }
});

export const getRegionHistActivityFilters = (state) => {
  return state.filters.regionProfile.historicalActivity;
};

export const { updateRegionHistActivityFilters, resetRegionHistActivityFilters } =
  RegionHistoricalActivitySlice.actions;

export default RegionHistoricalActivitySlice.reducer;
