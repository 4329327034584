import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import moment from 'moment';
import { useSegment } from '@hooks';
import CanalProfileSection from '@components/Sections/ShipAnalytics/CanalProfileSection';
import { useQuery } from '@tanstack/react-query';
import { REGION_TYPES } from '@constants';
import { getCanalOverview } from '@api';
import { useParams } from 'react-router-dom';

function CanalProfilePage() {
  const navigate = useNavigate();
  const [selectedYear] = useState(moment().year());
  const params = useParams();
  const { isRoRo } = useSegment();

  const routeChange = (path) => {
    navigate(path);
  };

  const canalOverviewQuery = useQuery({
    queryKey: ['canalOverview', params.canalId],
    enabled: true,
    placeholderData: null,
    queryFn: ({ signal }) => {
      let body = {
        regionTypes: [REGION_TYPES.CANALS]
      };
      return getCanalOverview(body, signal)
        .then((results) => {
          const canal = results?.find(({ regionId }) => regionId === parseInt(params.canalId));
          if (canal) {
            return canal;
          }
          return {};
        })
        .catch(() => []);
    }
  });

  const { data } = canalOverviewQuery;

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={canalOverviewQuery.isFetching}
          title={data ? data.name : ''}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Commercial Analytics
              </Link>
              <Typography color="primary">Canal Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CanalProfileSection
          overviewData={data}
          selectedYear={selectedYear}
          drawPolygon={data ? data.polygon : ''}
          canalLat={data ? data.lat : 0}
          canalLong={data ? data.long : 0}
          canalCode={data ? data.unlocode : ''}
          canalName={data ? data.name : ''}
        />
      </Grid>
    </Grid>
  );
}

export default CanalProfilePage;
