import React from 'react';
import { Box, Paper, Typography } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { getTransparentBackground } from '@helpers';
import PropTypes from 'prop-types';

const DashboardActivityMapInfoWindow = ({ infoItems }) => {
  const themeMode = useSelector(getThemeMode);

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: getTransparentBackground(themeMode)
      }}>
      {infoItems.map((item) => (
        <Box key={item.label} display="flex" alignItems="center" gap={1}>
          {item.icon}
          <Typography variant="body2">{item.label}</Typography>
        </Box>
      ))}
    </Paper>
  );
};

DashboardActivityMapInfoWindow.propTypes = {
  infoItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DashboardActivityMapInfoWindow;
