import React from 'react';
import PropTypes from 'prop-types';

const PortIcon = ({ color = '#a0d9ef', size = 16 }) => {
  const width = size;
  const height = size;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="4" fill={color} />
    </svg>
  );
};

PortIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default PortIcon;
