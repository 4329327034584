import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';

function NumberOfSailingsTable({ monthlyData }) {
  return (
    <Grid item xs={6}>
      <Paper variant={'outlined'}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              background: ({ palette }) => palette.background.default,
              p: 2,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
            <Typography variant={'body2'} bold color={'text.secondary'}>
              Total Number of sailings
            </Typography>
          </Grid>
          {monthlyData.map(({ month, portCalls, highlight }, i) => (
            <Grid
              key={`${month}-sailings`}
              item
              container
              sx={{
                pl: 2,
                pt: 1,
                pb: 1,
                pr: 2,
                backgroundColor: highlight ? ({ palette }) => palette.background.default : '',
                borderBottom: i !== monthlyData.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : ''
              }}>
              <Grid item xs={6}>
                <Typography variant={'body2'}>{month}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'body2'}>{portCalls}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
}

NumberOfSailingsTable.propTypes = {
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  monthlyData: PropTypes.arrayOf(PropTypes.object)
};

NumberOfSailingsTable.defaultProps = { dateRangeList: [], monthlyData: [] };

export default NumberOfSailingsTable;
