import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography, Stack } from '@esgian/esgianui';

const LoopPlaceholder = ({ message = 'Loading...', size = 100, height = '95vh' }) => (
  <Stack
    sx={{
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: height,
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px'
      }}>
      <CircularProgress size={size} />
      {message && <Typography variant="body1">{message}</Typography>}
    </Box>
  </Stack>
);

LoopPlaceholder.propTypes = {
  message: PropTypes.string,
  size: PropTypes.number,
  height: PropTypes.string
};

export default LoopPlaceholder;
