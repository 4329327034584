import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Modal, Grid, Paper, Box } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { getUnderlyingPortCalls } from '@api';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getSegment, getOperatorType, getUser } from '@store/features';
import { useTheme } from '@hooks';
import { DATE_TIME_FORMAT } from '@constants';
import { DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT } from '@constants/tooltips';
import { useTimezone } from '@hooks/useTimezone';

const getTimeDisplay = (startDate, endDate, daysOnly) => {
  let days = moment(endDate, DATE_TIME_FORMAT).diff(moment(startDate, DATE_TIME_FORMAT), 'days');
  let hours = moment(endDate, DATE_TIME_FORMAT).diff(
    moment(startDate, DATE_TIME_FORMAT).add(days, 'd'),
    'hours'
  );
  let minutes = moment(endDate, DATE_TIME_FORMAT).diff(
    moment(startDate, DATE_TIME_FORMAT).add(days, 'd').add(hours, 'hours'),
    'minutes'
  );
  return daysOnly ? days : `${days}d ${hours}h ${minutes}m`;
};

function UnderlyingPortActivityModal({ selectedLocation, selectedCellParams, handleClose }) {
  const [tableData, setTableData] = useState([]);
  const user = useSelector(getUser);
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const segment = useSelector(getSegment);
  const { theme } = useTheme();
  const { getTimeZoneDisplay } = useTimezone();

  const tableColumns = [
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'portId',
      label: 'Port ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'portName',
      label: 'Port Name',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'port'} id={rowData[2]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'startDate',
      label: 'Arrival',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'percentArrivalDraught',
      label: 'Arrival Percentage Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'imoDraught',
      label: 'IMO Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'arrivalDraught',
      label: 'Draught on arrival',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentArrivalDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[6]);
            return `${dataIndex}/${
              rowIndex.rowData[6] !== null ? rowIndex.rowData[6]?.toFixed(1) : 'N/A'
            } (${(percentArrivalDraught * 100).toFixed(1)}%)`;
          } else {
            return 'N/A';
          }
        }
      }
    },
    {
      name: 'endDate',
      label: 'Departure',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'percentDepartureDraught',
      label: 'Departure Percentage Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'departureDraught',
      label: 'Draught on departure',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentDepartureDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[6]);
            return `${dataIndex}/${
              rowIndex.rowData[6] !== null ? rowIndex.rowData[6]?.toFixed(1) : 'N/A'
            } (${(percentDepartureDraught * 100).toFixed(1)}%)`;
          } else {
            return 'N/A';
          }
        }
      }
    },
    {
      name: 'timeDto',
      label: 'Time',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <Typography variant="caption">
              {getTimeDisplay(
                moment(rowData[4]).format(DATE_TIME_FORMAT),
                moment(rowData[8]).format(DATE_TIME_FORMAT)
              )}
            </Typography>
          ) : (
            '--'
          );
        }
      }
    }
  ];

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const loadData = async () => {
      try {
        const response = await getUnderlyingPortCalls(
          {
            FromType: selectedLocation.type,
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType,
            FromId: selectedLocation.id,
            StartDate: moment(selectedCellParams.date, 'MMM YYYY')
              .startOf('month')
              .format(DATE_TIME_FORMAT),
            EndDate: moment(selectedCellParams.date).endOf('month').format(DATE_TIME_FORMAT),
            OperatorId: selectedCellParams.operatorId,
            SegmentTypeId: segment.id
          },
          signal
        );
        setTableData(response);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (selectedCellParams) {
      loadData();
    }
    return () => {
      controller.abort();
    };
  }, [selectedCellParams]);

  const tableOptions = {
    selectableRows: 'none',
    tableBodyMaxHeight: '500px',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    print: false,
    viewColumns: false,
    downloadOptions: {
      filename: `${selectedCellParams?.operatorLongName} - ${selectedCellParams?.date}`
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return (
        '\uFEFF' +
        buildHead(columns) +
        buildBody(
          data.map((item) => {
            item.data[11] = getTimeDisplay(item.data[4], item.data[8]);
            return item;
          })
        )
      );
    },
    elevation: 0,
    toolbar: checkUserHasAccess(user, true),
    pagination: false
  };

  return (
    <Modal
      handleClose={handleClose}
      title={`${selectedCellParams?.operatorLongName} - ${selectedCellParams?.date}`}
      open={!!selectedCellParams}
      size={'lg'}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" sx={{ p: 1 }}>
            <b>Port Calls</b>: {tableData?.length}
          </Typography>
        </Grid>
      </Grid>

      <Paper variant={'outlined'}>
        <Table
          customStyle={{
            MUIDataTableFilterList: { display: 'none' },
            MUIDataTableToolbar: {
              styleOverrides: {
                root: {
                  minHeight: 0
                }
              }
            }
          }}
          mode={theme.mode}
          title={''}
          data={tableData}
          columns={tableColumns}
          options={tableOptions}
        />
      </Paper>
    </Modal>
  );
}

UnderlyingPortActivityModal.propTypes = {
  selectedCellParams: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object
};

UnderlyingPortActivityModal.defaultProps = {
  selectedCellParams: null,
  selectedLocation: null
};

export default UnderlyingPortActivityModal;
