import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import portServiceLevel from '@assets/images/faq/port-service-level.png';
import portServiceFilters from '@assets/images/faq/port-service-filters.png';
import clearInvalidOperators from '@assets/images/faq/clear-invalid-operators.png';

function PortServiceLevel() {
  return (
    <Grid container spacing={2} id={'port-service-level'}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={'h6'}>
          3.4 Port Service Level &#128200;
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img alt={'port-service-level'} srcSet={portServiceLevel} src={portServiceLevel} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Port Service Level counts the services completed by each operator between the selected
          port pair and calculates performance on frequency and transit time. The module will look
          for voyages completed within the set period of the search.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Consistency{' '}
        </Typography>
        <Typography component={'span'}>
          is calculated by finding the relative standard deviation (measuring deviation from the
          mean) and transforming the value through a function which provides a score between 0-5.
          This allows for a relative comparison of the consistency of the operators on a port call
          or service.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography bold>Filters</Typography>
      </Grid>
      <Grid item xs={12}>
        <img srcSet={portServiceFilters} src={portServiceFilters} width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Port of Load/Port of Discharge:{' '}
        </Typography>
        <Typography component={'span'}>
          Search for the port you would like to view. You can search for Name, UNLOCODE and Country
          Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Period:{' '}
        </Typography>
        <Typography component={'span'}>
          Select a from and to Month-Year you would like to see data for
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Operator(s):{' '}
        </Typography>
        <Typography component={'span'}>
          By default, the solution shows the top 5 operators for the selected port and period. Click
          disable Auto (top 5) to allow your own or all operators selection. Search is active for
          operator short and long name. If all is selected it will show a list of operators who do
          not have any activity in the port, which can then be removed from the selection by
          clicking:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img srcSet={clearInvalidOperators} src={clearInvalidOperators} />
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Max Transit Time:{' '}
        </Typography>
        <Typography component={'span'}>
          Default shows all records where a vessel has started in Port of Load and ended in Port of
          Discharge. Sometimes the port pair is not on the same rotation and be deemed as
          non-competitive voyages. Therefore the user can filter out the voyages by setting the max
          transit time for voyages/services that will be included in the analysis and scoring.
          Insert a number above 0 to filter.
        </Typography>
      </Grid>
    </Grid>
  );
}

PortServiceLevel.propTypes = {};

PortServiceLevel.defaultProps = {};

export default PortServiceLevel;
