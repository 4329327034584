import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import portActivity from '@assets/images/faq/port-activity.png';
import portActivityFilters from '@assets/images/faq/port-activity-filters.png';
import clearInvalidOperators from '@assets/images/faq/clear-invalid-operators.png';

function PortActivity() {
  return (
    <Grid container spacing={2} id={'port-activity'}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={'h6'}>
          3.3 Port Activity &#9875;
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img srcSet={portActivity} src={portActivity} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The Port Activity module allows in-depth analysis of vessel activity in a given port over
          a period of time. Port calls per operator are aggregated per month based on port arrival
          date. Operators are compared showing who has the highest presence, average calls per month
          and the consistency of the service.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The graph will show the activity per operator per month + the total number of port calls
          per month.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The table shows the breakdown of the dataset showing port calls per operator per month,
          average, min/max and consistency score of the operator.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Consistency{' '}
        </Typography>
        <Typography component={'span'}>
          is calculated by finding the relative standard deviation (measuring deviation from the
          mean) and transforming the value through a function which provides a score between 0-5.
          This allows for a relative comparison of the consistency of the operators on a port call
          or service.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography bold>Filters</Typography>
      </Grid>
      <Grid item xs={12}>
        <img srcSet={portActivityFilters} src={portActivityFilters} width={'100%'} />
      </Grid>
      <Grid item xs={12} id={'port-select'}>
        <Typography component={'span'} bold>
          Port Name:{' '}
        </Typography>
        <Typography component={'span'}>
          Search for the port you would like to view. You can search for Name, UNLOCODE and Country
          Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Period:{' '}
        </Typography>
        <Typography component={'span'}>
          Select a from and to Month-Year you would like to see data for
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Operator(s):{' '}
        </Typography>
        <Typography component={'span'}>
          By default, the solution shows the top 5 operators for the selected port and period. Click
          disable Auto (top 5) to allow your own or all operators selection. Search is active for
          operator short and long name. If all is selected it will show a list of operators who do
          not have any activity in the port, which can then be removed from the selection by
          clicking:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img srcSet={clearInvalidOperators} src={clearInvalidOperators} />
      </Grid>
    </Grid>
  );
}

PortActivity.propTypes = {};

PortActivity.defaultProps = {};

export default PortActivity;
