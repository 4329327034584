import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function PerformanceVsSegmentMenu({ setSubSection, subSection }) {
  const [open, setOpen] = useState(true);
  const { theme } = useTheme();

  const getSelectedBackground = useCallback(
    (section) => {
      if (subSection === section) {
        return theme.palette.background.default;
      }
      return '';
    },
    [theme, subSection]
  );

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader">
      <ListItem button onClick={() => setOpen(!open)} sx={{ justifyContent: 'space-between' }}>
        <ListItemText
          sx={{ fontSize: '0.875rem' }}
          primaryTypographyProps={{ variant: 'body2' }}
          primary="FREQUENCY AND CONSISTENCY"
        />
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pt: 0 }}>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 1 ? `3px solid ${palette.primary.main}` : '',
              backgroundColor: getSelectedBackground(1)
            }}
            onClick={() => setSubSection(1)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 1 ? 'primary' : 'secondary'
              }}
              primary="SERVICE FREQUENCY"
            />
          </ListItem>
          <ListItem
            button
            sx={{
              pl: 4,
              borderRight: ({ palette }) =>
                subSection === 2 ? `3px solid ${palette.primary.main}` : '',
              backgroundColor: getSelectedBackground(2)
            }}
            onClick={() => setSubSection(2)}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                color: subSection === 2 ? 'primary' : 'secondary'
              }}
              primary="SERVICE LEVEL CONSISTENCY"
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        sx={{
          borderRight: ({ palette }) =>
            subSection === 3 ? `3px solid ${palette.primary.main}` : '',
          backgroundColor: getSelectedBackground(3)
        }}
        onClick={() => setSubSection(3)}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            color: subSection === 3 ? 'primary' : 'secondary'
          }}
          primary="TRANSIT TIME PERFORMANCE"
        />
      </ListItem>
      <ListItem
        button
        sx={{
          borderRight: ({ palette }) =>
            subSection === 4 ? `3px solid ${palette.primary.main}` : '',
          backgroundColor: getSelectedBackground(4)
        }}
        onClick={() => setSubSection(4)}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            color: subSection === 4 ? 'primary' : 'secondary'
          }}
          primary="OPERATOR PERFORMANCE SCORE"
        />
      </ListItem>
    </List>
  );
}

PerformanceVsSegmentMenu.propTypes = {
  setSubSection: PropTypes.func.isRequired,
  subSection: PropTypes.number.isRequired
};

PerformanceVsSegmentMenu.defaultProps = {};

export default PerformanceVsSegmentMenu;
