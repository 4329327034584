import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
// import moment from 'moment';
// import { formatToDecimalPlaces } from '@helpers';

// calculate port stats for 7D and 30D
// const calculatePortStats = (portCalls, days) => {
//   const now = moment();
//   const startDate = moment().subtract(days, 'days');

//   const filteredCalls = portCalls.filter((call) => {
//     const callDate = moment(new Date(call.startDate));
//     return true || callDate.isBetween(startDate, now);
//   });

//   const stats = {
//     portCallsCount: filteredCalls.length,
//     avgPortWaitingTime: '-',
//     avgPortTime: '-'
//   };

//   if (filteredCalls.length > 0) {
//     stats.avgPortTime =
//       filteredCalls.reduce((sum, call) => sum + call.timeInPort, 0) / filteredCalls.length;
//   }

//   return {
//     portCallsCount: filteredCalls.length,
//     avgPortWaitingTime: formatToDecimalPlaces(stats.avgPortWaitingTime, 2),
//     avgPortTime: formatToDecimalPlaces(stats.avgPortTime, 2)
//   };
// };

const InboundVesselPortTab = ({ ports }) => {
  const [is30DSelected, setIs30DSelected] = useState(true);

  const country = ports?.[0]?.country;

  return (
    <>
      <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap={1}>
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>Port in {country?.countryName}</Typography>
            <Typography variant="caption">{ports?.length} ports</Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center" justifyContent="flex-end">
            <Box display="flex" gap={1}>
              <Button
                sx={{
                  m: 0,
                  p: 0.2,
                  minWidth: 0,
                  borderRadius: 0,
                  textDecoration: is30DSelected ? 'underline' : 'none'
                }}
                variant="text"
                onClick={() => setIs30DSelected(true)}>
                7D
              </Button>
              <Button
                sx={{
                  m: 0,
                  p: 0.2,
                  minWidth: 0,
                  borderRadius: 0,
                  textDecoration: !is30DSelected ? 'underline' : 'none'
                }}
                variant="text"
                onClick={() => setIs30DSelected(false)}>
                30D
              </Button>
            </Box>
          </Box>
        </Box>
        <ul
          style={{
            padding: 0,
            margin: 0,
            paddingLeft: 32,
            display: 'flex',
            flexDirection: 'column',
            gap: 16
          }}>
          {ports.map((port) => {
            return (
              <li key={port.id}>
                <Box>
                  <ProfileLink
                    uriParams={'section=2'}
                    profile={'port'}
                    id={port.id}
                    name={port.portName}
                    linkVariant="body1"
                  />
                  <ul style={{ padding: 0, margin: 0, paddingLeft: 32 }}>
                    <li>
                      <Box display="flex" gap={2} justifyContent="space-between">
                        <Typography>Avg port waiting time(days)</Typography>
                        <Typography>-</Typography>
                      </Box>
                    </li>
                    <li>
                      <Box display="flex" gap={2} justifyContent="space-between">
                        <Typography>Avg port time(days)</Typography>
                        <Typography>-</Typography>
                      </Box>
                    </li>
                    <li>
                      <Box display="flex" gap={2} justifyContent="space-between">
                        <Typography>Port calls</Typography>
                        <Typography>-</Typography>
                      </Box>
                    </li>
                  </ul>
                </Box>
              </li>
            );
          })}
        </ul>
      </Paper>
    </>
  );
};

InboundVesselPortTab.propTypes = {
  ports: PropTypes.array
};

export default InboundVesselPortTab;
