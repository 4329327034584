import { combineReducers } from '@reduxjs/toolkit';
import createSectionSlice from '@store/features/filters/SectionSlice/SectionSlice';
import RegionActivitySlice from '@store/features/filters/RegionProfilePage/RegionActivitySlice';
import RegionHistoricalActivitySlice from '@store/features/filters/RegionProfilePage/RegionHistoricalActivitySlice';
const RegionProfileSectionSlice = createSectionSlice('vesselSectionSlice');

const RegionProfileReducer = combineReducers({
  section: RegionProfileSectionSlice.reducer,
  regionActivity: RegionActivitySlice,
  historicalActivity: RegionHistoricalActivitySlice
});

export default RegionProfileReducer;

export const getRegionProfileSection = (state) => {
  return state.filters.regionProfile.section;
};

export const { setSection: setRegionProfileSection } = RegionProfileSectionSlice.actions;
