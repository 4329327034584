import React from 'react';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import VoyageActivityProfileChart from './VoyageActivityProfileChart';
import PropTypes from 'prop-types';
function VoyageActivityProfile({ chartData }) {
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant={'h6'}>Activity Profile</Typography>
        </Stack>
        <Stack>{chartData && <VoyageActivityProfileChart activityData={chartData} />}</Stack>
      </Stack>
    </Paper>
  );
}

VoyageActivityProfile.propTypes = {
  activityData: PropTypes.object,
  chartData: PropTypes.object.isRequired
};

VoyageActivityProfile.defaultProps = {
  activityData: {
    atPortDays: 0,
    standbyDays: 0,
    transitDays: 0
  },
  chartData: {}
};

export default VoyageActivityProfile;
