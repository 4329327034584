import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode, getUser } from '@store/features';
import { useTheme } from '@hooks';

function PortCallShareChart({ selectedYear, loading, seriesData, categories, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const roundedSeriesData = seriesData.map((value) => parseFloat(value.toFixed(2)));

  const options = useMemo(() => {
    const { palette: { mode, charts: { background, tenPieColors, otherColor } } = {} } =
      theme || {};

    const top10Colors = tenPieColors.slice(0, categories.length);

    const seriesColors = [...top10Colors, otherColor];
    return {
      chart: {
        height: 350,
        type: 'donut',
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        background: background,
        toolbar: {
          tools: {
            download: checkUserHasAccess(user, true)
          },
          export: {
            csv: {
              filename: fileName
            },
            svg: {
              filename: fileName
            },
            png: {
              filename: fileName
            }
          }
        }
      },
      colors: seriesColors,
      legend: {
        show: true,
        offsetX: 30,
        markers: {
          fillColors: seriesColors
        },
        labels: {
          colors: mode ? 'dark' : 'light'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      },
      stroke: {
        width: 0
      },
      dataLabels: {
        dropShadow: {
          enabled: false
        }
      },
      tooltip: {
        theme: theme.mode,
        style: {
          fontSize: '12px',
          fontFamily: undefined,
          backgroundColor: '#ffffff' // Change this to your desired tooltip background color
        },
        custom: function ({ series, seriesIndex, w }) {
          const backgroundColor = theme.mode === 'dark' ? '#ffffff' : '#000000';
          const textColor = theme.mode === 'dark' ? '#000000' : '#ffffff';
          return `<div style="background-color: ${backgroundColor}; padding: 10px; border-radius: 5px;">
                    <span style="color: ${textColor};">${w.globals.labels[seriesIndex]}: ${series[seriesIndex]}</span>
                  </div>`;
        }
      },
      title: {
        text: selectedYear,
        offsetY: 195,
        offsetX: -45,
        style: {
          fontWeight: 100,
          fontSize: '1.25rem',
          color: background
        },
        align: 'center'
      },
      labels: categories
    };
  }, [theme, categories, user, fileName, selectedYear]);
  return (
    <ApexChart
      loading={loading || !options}
      data={roundedSeriesData}
      height={350}
      options={options || {}}
      type={'donut'}
    />
  );
}

PortCallShareChart.propTypes = {
  loading: PropTypes.bool,
  seriesData: PropTypes.arrayOf(PropTypes.number),
  categories: PropTypes.arrayOf(PropTypes.string),
  selectedYear: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired
};

PortCallShareChart.defaultProps = {
  loading: false,
  seriesData: [],
  categories: []
};

export default PortCallShareChart;
