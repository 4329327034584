import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Slider,
  Button,
  FavoriteBorderOutlinedIcon,
  Paper
} from '@esgian/esgianui';

const SimulatedVoyageMapFilter = ({
  voyageTransitDetails,
  selectedTimelineDay,
  onTimelineChange,
  onSaveToGroup
}) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        pointerEvents: 'auto',
        opacity: 0.9
      }}>
      <Box flex={1} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography>Timeline:</Typography>
          <Typography>Day {selectedTimelineDay + 1}</Typography>
        </Box>
        <Slider
          value={selectedTimelineDay}
          onChange={(_e, newValue) => onTimelineChange(newValue)}
          min={0}
          max={voyageTransitDetails?.transitDetails.length - 1}
          step={1}
        />
      </Box>
      <Box>
        <Button
          variant="outlined"
          startIcon={<FavoriteBorderOutlinedIcon />}
          onClick={() => onSaveToGroup()}
          sx={{ width: '13rem' }}>
          Save to group
        </Button>
      </Box>
    </Paper>
  );
};

SimulatedVoyageMapFilter.propTypes = {
  voyageTransitDetails: PropTypes.object,
  selectedTimelineDay: PropTypes.number,
  onTimelineChange: PropTypes.func,
  onSaveToGroup: PropTypes.func
};

export default SimulatedVoyageMapFilter;
