import React from 'react';
import PropTypes from 'prop-types';
import { VoyagesTable } from '@components/Tables';

function OngoingVoyageSection({ loading, completedVoyagesData }) {
  const voyageDataWithPortCalls = completedVoyagesData.map((voyage) => ({
    ...voyage,
    ...voyage.latestPortCall,
    nextPortName: voyage.nextPortCall?.portName,
    nextPortCountryName: voyage.nextPortCall?.countryName,
    nextPortRegionName: voyage.nextPortCall?.regionName,
    nextPortEta: voyage.nextPortCall?.eta
  }));

  return (
    <VoyagesTable
      voyageSearchResults={voyageDataWithPortCalls}
      fileName={'ongoing-voyages'}
      loading={loading}
      title="Ongoing Voyages"
      hideColumns={['imo', 'toPortName', 'fromPortName', 'endDate', 'transitTime']}
      initialSortColumn={'countryName'}
    />
  );
}

OngoingVoyageSection.propTypes = {
  loading: PropTypes.bool,
  completedVoyagesData: PropTypes.arrayOf(PropTypes.object)
};

OngoingVoyageSection.defaultProps = {
  loading: false,
  completedVoyagesData: []
};

export default OngoingVoyageSection;
