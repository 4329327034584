import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { ApexChart, Paper } from '@esgian/esgianui';
import { formatToDecimalPlaces } from '@helpers';
import { useTheme } from '@hooks';

const categories = ['In port', 'Standby', 'Transit'];

const getVesselStatusPercentage = (monthlyActivityStatus, is6MSelected) => {
  const monthlyActivityStatusData = monthlyActivityStatus?.imoMonthlyPercentActivityStatus;

  const rangedData = monthlyActivityStatusData.slice(-(is6MSelected ? 6 : 12));

  const totalTransit = rangedData.reduce((acc, curr) => acc + curr.percentMonthlyTransit, 0);
  const totalStandby = rangedData.reduce((acc, curr) => acc + curr.percentMonthlyStandBy, 0);
  const totalAtPort = rangedData.reduce((acc, curr) => acc + curr.percentMonthlyAtPort, 0);
  const total = totalTransit + totalStandby + totalAtPort;

  return {
    transitPercentage: +formatToDecimalPlaces((totalTransit / total) * 100, 0),
    standbyPercentage: +formatToDecimalPlaces((totalStandby / total) * 100, 0),
    atPortPercentage: +formatToDecimalPlaces((totalAtPort / total) * 100, 0)
  };
};

const VesselStatus = ({ monthlyActivityStatus }) => {
  const [is6MSelected, setIs6MSelected] = useState(true);
  const { theme } = useTheme();

  const vesselStatusPercentage = getVesselStatusPercentage(monthlyActivityStatus, is6MSelected);

  const options = useMemo(() => {
    const {
      palette: { mode }
    } = theme || {};
    return {
      chart: {
        type: 'pie',
        background: 'transparent'
      },
      colors: ['#0E9EFF', '#4CAF50', '#80DFEB'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        labels: {
          colors: mode === 'dark' ? '#fff' : '#000'
        }
      },
      labels: categories,
      dataLabels: {
        formatter: (val) => `${formatToDecimalPlaces(val, 0)}%`
      },
      stroke: {
        width: 0
      }
    };
  }, [theme]);

  const data = [
    vesselStatusPercentage.atPortPercentage,
    vesselStatusPercentage.standbyPercentage,
    vesselStatusPercentage.transitPercentage
  ];

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
        <Typography sx={{ fontWeight: 'bold' }}>Vessel status</Typography>
        <Box display="flex" gap={1} alignItems="center" justifyContent="flex-end">
          <Box display="flex" gap={0.5}>
            <Button
              sx={{
                m: 0,
                p: 0.2,
                minWidth: 0,
                borderRadius: 0,
                textDecoration: is6MSelected ? 'underline' : 'none'
              }}
              variant="text"
              onClick={() => setIs6MSelected(true)}>
              6M
            </Button>
            <Button
              sx={{
                m: 0,
                p: 0.2,
                minWidth: 0,
                borderRadius: 0,
                textDecoration: !is6MSelected ? 'underline' : 'none'
              }}
              variant="text"
              onClick={() => setIs6MSelected(false)}>
              12M
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <ApexChart options={options} data={data} type="pie" />
      </Box>
    </Paper>
  );
};

VesselStatus.propTypes = {
  monthlyActivityStatus: PropTypes.object
};

export default VesselStatus;
