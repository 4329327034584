import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import { getLatestPortCalls, getVesselsPositions } from '@api';
import { GEOGRAPHICAL_TYPES, DEFAULT_NUM_PORT_CALLS } from '@constants';
import { useSegment } from '@hooks';
import moment from 'moment';

export const useInboundVessels = ({ locationId, locationType, startDate, endDate }) => {
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const { data: inboundVesselsPortCalls, isLoading: isPendingInboundVesselsPortCalls } = useQuery({
    queryKey: ['inboundVessels', locationId, locationType, startDate, endDate],
    enabled: !!locationId && !!startDate && !!endDate,
    queryFn: async ({ signal }) => {
      const response = await getLatestPortCalls(
        {
          geographicalType: GEOGRAPHICAL_TYPES.COUNTRY,
          segmentTypeId: segment.id,
          operatorTypeId: operatorType,
          numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
          commercialCategoryId: commercialCategoryType,
          geoIds: [+locationId]
        },
        signal
      );

      const filteredResponse = response.filter((portCall) => {
        return (
          moment(portCall.startDate).isSameOrAfter(startDate) &&
          moment(portCall.endDate).isSameOrBefore(endDate)
        );
      });

      return filteredResponse;
    }
  });

  const { data: inboundVesselsLatestPosition, isLoading: isPendingInboundVesselsLatestPosition } =
    useQuery({
      queryKey: ['inboundVesselsLatestPosition', locationId, locationType, startDate, endDate],
      enabled: !!inboundVesselsPortCalls,
      queryFn: ({ signal }) =>
        getVesselsPositions(
          inboundVesselsPortCalls.map(({ imo }) => imo),
          signal
        )
    });

  const isLoadingInboundVesselsRelatedInfo =
    isPendingInboundVesselsPortCalls || isPendingInboundVesselsLatestPosition;

  return {
    inboundVesselsPortCalls,
    inboundVesselsLatestPosition,
    isLoadingInboundVesselsRelatedInfo
  };
};
