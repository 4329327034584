import { createSlice } from '@reduxjs/toolkit';
import { OPERATOR_TYPE, VESSEL_MAX_TRANSIT_TIME } from '@constants';

export const OPERATOR_PERFORMANCE_SLICE_KEYS = {
  tabSection: 'number',
  subSection: 'number',
  selectedPortLoad: 'custom',
  selectedPortDischarge: 'custom',
  startDate: 'date',
  endDate: 'date',
  selectedOperators: 'array',
  maxTransitTime: 'number',
  minServices: 'number',
  operatorType: 'number'
};

const initialState = {
  tabSection: 1,
  subSection: 1,
  selectedPortLoad: null,
  selectedPortDischarge: null,
  startDate: null,
  endDate: null,
  selectedOperator: null,
  operatorType: OPERATOR_TYPE.ALL,
  maxTransitTime: VESSEL_MAX_TRANSIT_TIME,
  minServices: 0
};

const OperatorPerformanceSlice = createSlice({
  name: 'OperatorPerformance',
  initialState: initialState,
  reducers: {
    setOperatorPerformanceSubSection: (state, action) => {
      state.subSection = action.payload;
    },
    setOperatorPerformanceTabSection: (state, action) => {
      state.tabSection = action.payload;
    },
    resetOperatorPerformanceFilters: () => initialState,

    updateOperatorPerformanceFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }
  }
});

export const getOperatorPerformanceFilters = (state) => {
  return state.filters.commercialAnalytics.operatorPerformance;
};

export const {
  resetOperatorPerformanceFilters,
  updateOperatorPerformanceFilters,
  setOperatorPerformanceSubSection,
  setOperatorPerformanceTabSection
} = OperatorPerformanceSlice.actions;

export default OperatorPerformanceSlice.reducer;
