import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, TextField, Typography, Skeleton } from '@esgian/esgianui';
import { PortIcon } from '@components';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@constants';
import { formatToDecimalPlaces } from '@helpers';
import { ProfileLink } from '@components/Links';

const portCallsTimePeriodOptions = [
  { label: '1 month', value: 1 },
  { label: '3 months', value: 3 },
  { label: '6 months', value: 6 }
];

const VoyageTab = ({
  selectedVesselNextPortCall,
  latestPortCalls,
  isLoadingNextPortCall,
  isLoadingLatestPortCalls
}) => {
  const [selectedPortCallsTimePeriod, setSelectedPortCallsTimePeriod] = useState(
    portCallsTimePeriodOptions[0]
  );

  const filteredLatestPortCalls = latestPortCalls?.filter((portCall) => {
    const portCallDate = moment(portCall.startDate);
    const currentDate = moment();
    return portCallDate.isAfter(currentDate.subtract(selectedPortCallsTimePeriod.value, 'months'));
  });

  if (isLoadingLatestPortCalls || isLoadingNextPortCall) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 'bold' }}>Port call history</Typography>
        <Box width="150px">
          <Autocomplete
            options={portCallsTimePeriodOptions}
            size="small"
            renderInput={(params) => <TextField {...params} label="Latest" />}
            value={selectedPortCallsTimePeriod}
            onChange={(_, value) => setSelectedPortCallsTimePeriod(value)}
            disableClearable
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={1} alignItems="center">
            <PortIcon size={10} color="yellow" />
            <ProfileLink
              name={selectedVesselNextPortCall.portName}
              profile={'port'}
              id={selectedVesselNextPortCall.portId}
              linkVariant={'body1'}
            />
            <Typography variant="body2">
              ETA: {moment(selectedVesselNextPortCall).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box
            minHeight="30px"
            px={2}
            borderLeft="3px solid"
            borderColor="primary.light"
            mx="2px"
          />
        </Box>
        {filteredLatestPortCalls.map(
          (
            {
              portName,
              eta,
              timeInPort,
              startDate,
              percentArrivalDraught,
              imoDraught,
              arrivalDraught,
              endDate,
              portId
            },
            index
          ) => {
            const arrival = index === 0 ? moment(startDate).format(DATE_TIME_FORMAT) : null;
            const draughtOnArrivalToDisplay =
              index === 0
                ? `${formatToDecimalPlaces(arrivalDraught, 1)} / ${formatToDecimalPlaces(
                    imoDraught,
                    1
                  )} (${formatToDecimalPlaces(percentArrivalDraught, 1)}%)`
                : null;

            const departure = index === 0 ? moment(endDate).format(DATE_TIME_FORMAT) : null;
            return (
              <Box key={portName} display="flex" flexDirection="column" gap={1}>
                <Box display="flex" gap={1} alignItems="center">
                  <PortIcon size={10} color="#CD91DC" />
                  <ProfileLink name={portName} profile={'port'} id={portId} linkVariant={'body1'} />
                  <Typography variant="body2">
                    {eta ? 'ETA:' : departure ? 'Departure:' : ''} {eta || departure}
                  </Typography>
                </Box>
                <Box px={2} borderLeft="3px solid" borderColor="primary.light" mx="2px">
                  {!eta && (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">
                          Port time: {formatToDecimalPlaces(timeInPort, 1)} days
                        </Typography>
                        {/* <Typography variant="body2" sx={{ textAlign: 'right' }}>
                          CO2 emissions: {'-'} kg
                        </Typography> */}
                      </Box>
                      {/* <Typography variant="body2">Port waiting time: {'-'} days</Typography>

                      <Typography variant="body2">Transit time: {'-'} days</Typography> */}

                      {arrival && <Typography variant="body2">Arrival time: {arrival}</Typography>}
                      {draughtOnArrivalToDisplay && (
                        <Typography variant="body2">
                          Draught on arrival: {draughtOnArrivalToDisplay}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};

VoyageTab.propTypes = {
  selectedVesselNextPortCall: PropTypes.object,
  latestPortCalls: PropTypes.array,
  isLoadingLatestPortCalls: PropTypes.bool,
  isLoadingNextPortCall: PropTypes.bool
};

export default VoyageTab;
