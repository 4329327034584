import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { useSearchParams } from 'react-router-dom';
import { createVesselMarkers, createPortMarkers } from '@helpers';
import LoopPlaceholder from '../../../Common/LoopPlaceholder';
import { PortIcon } from '../../../Icons';
import { DashboardActivityMap, DashboardActivityMapInfoWindow } from '../../../Maps';
import {
  useDynamicLookupQueries,
  useVesselsLatestPosition,
  useTopPorts,
  useGlobalPortStats,
  useGlobalMonthlyPercentActivityStatus,
  useGlobalEmissionIntensity,
  useGlobalPortCongestion,
  useSegment,
  useStaticLookupQueries,
  useVesselsDetailsByFilters,
  useTheme
} from '@hooks';
import { useTimezone } from '@hooks/useTimezone';
import DashboardActivityMapVesselSearchBox from './DashboardActivityMapVesselComponents/DashboardActivityMapVesselSearchBox';
import DashboardActivityMapVesselDetailsWindow from './DashboardActivityMapVesselComponents/DashboardActivityMapVesselDetailsWindow';
import moment from 'moment';
import DashboardActivityMapInboundVesselInsight from './DashboardActivityMapInboundVesselComponents/DashboardActivityMapInboundVesselInsight';
import DashboardActivityMapGlobalInsights from './DashboardActivityMapGlobalComponents/DashboardActivityMapGlobalInsights';
import DashboardActivityMapFilter, {
  isInitialValues
} from '../../../Maps/DashboardActivityMap/DashboardActivityMapDrawer/DashboardActivityMapFilter';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { ACTIVITY_MAP_ACTIONS } from '../../../Maps/DashboardActivityMap/DashboardActivityMapDrawer';
import DashboardActivityMapInsight from '../../../Maps/DashboardActivityMap/DashboardActivityMapDrawer/DashboardActivityMapInsight';

export const WINDOW_STATE = {
  OPEN: 'open',
  CLOSED: 'closed'
};

export const WINDOW_TYPE = {
  VESSEL: 'vessel',
  INBOUND_VESSEL: 'inboundVessel'
};

export const VESSEL_SEARCH_ITEM_TYPE = {
  OPERATOR: 'operator',
  OWNER: 'owner',
  VESSEL: 'vessel',
  COUNTRY: 'country',
  PORT: 'port'
};

export const ACTIVITY_MAP_SEARCH_PARAM_KEYS = {
  DETAILS_WINDOW_STATE: 'detailsWindowState',
  DETAILS_WINDOW_TYPE: 'detailsWindowType',
  ITEM_ID: 'itemId',
  INBOUND_VESSEL: {
    LOCATION_TYPE: 'inboundVesselLocationType',
    LOCATION_ID: 'inboundVesselLocationId',
    START_DATE: 'inboundVesselStartDate',
    END_DATE: 'inboundVesselEndDate'
  },
  MAP_SETTINGS: {
    SHOW_PORTS: 'showPorts',
    SHOW_VESSELS_NAMES: 'showVesselsNames',
    SHOW_VESSELS_STATUS: 'showVesselsStatus',
    SHOW_VESSELS: 'showVessels'
  },
  SELECTED_ACTION: 'selectedAction'
};

const VESSEL_SPEED_ICONS = {
  LOW: {
    label: 'Speed < 1',
    icon: <img src="/assets/images/icon-ship-10.png" alt="Vessel Speed 0-1" height={16} />
  },
  MEDIUM: {
    label: 'Speed 1-3',
    icon: <img src="/assets/images/icon-ship-0.png" alt="Vessel Speed 1-3" height={16} />
  },
  HIGH: {
    label: 'Speed > 3',
    icon: <img src="/assets/images/icon-ship-port.png" alt="Vessel Speed >3" height={16} />
  },
  PORT: {
    label: 'Port',
    icon: <PortIcon />
  }
};

const infoItems = Object.values(VESSEL_SPEED_ICONS);

function DashboardActivityMapSection({ isCompactView, onClickFullscreen }) {
  const themeMode = useSelector(getThemeMode);
  const [, setIsMapLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTimeZoneDisplay } = useTimezone();
  const mapRef = useRef(null);
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(null);
  const [activeInboundVesselsFilters, setActiveInboundVesselsFilters] = useState(null);
  const [vesselSearchItemId, setVesselSearchItemId] = useState(null);
  const [vesselSearchItemType, setVesselSearchItemType] = useState(null);
  const mapActionsPopupState = usePopupState({ variant: 'popper', popupId: 'map-actions' });
  const { customScrollbar } = useTheme();

  const { segment, isRoRo } = useSegment();

  const {
    detailsWindowState,
    detailsWindowType,
    itemId,
    inboundVessel: {
      locationId: inboundVesselLocationId,
      locationType: inboundVesselLocationType,
      startDate: inboundVesselStartDate,
      endDate: inboundVesselEndDate
    },
    mapSettings: { showPorts, showVesselsNames, showVessels, showVesselsStatus },
    selectedAction
  } = useMemo(
    () => ({
      detailsWindowState: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE),
      detailsWindowType: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_TYPE),
      itemId: +searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.ITEM_ID),
      inboundVessel: {
        locationId: +searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_ID),
        locationType: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_TYPE),
        startDate: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.START_DATE),
        endDate: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.END_DATE)
      },
      mapSettings: {
        showPorts:
          searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS) === 'true',
        showVesselsNames:
          searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES) ===
          'true',
        showVessels:
          searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS) === 'true',
        showVesselsStatus:
          searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS) ===
          'true'
      },
      selectedAction: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION)
    }),
    [searchParams]
  );

  const { data: top10Ports = [], isFetching: isLoadingTop10Ports } = useTopPorts();

  const {
    lookupLocationsQuery: { data: lookupLocations, isPending: isLoadingLookupLocations }
  } = useStaticLookupQueries();

  const {
    lookupPortsQuery: { data: lookupPorts, isFetching: isLoadingLookupPorts },
    lookupVesselsQuery: { data: vessels, isFetching: isLoadingVessels },
    lookupOperatorVesselHierarchyQuery: {
      data: operatorVesselHierarchy,
      isFetching: isLoadingOperatorVesselHierarchy
    },
    lookupOwnerOperatorVesselHierarchyQuery: {
      data: ownerOperatorVesselHierarchy,
      isFetching: isLoadingOwnerOperatorVesselHierarchy
    },
    vesselsDetailsQuery: { data: vesselsDetails, isPending: isLoadingVesselsDetails }
  } = useDynamicLookupQueries();

  const { vesselsLatestPosition, isLoadingVesselsLatestPosition } =
    useVesselsLatestPosition(vessels);

  const { isLoading: isLoadingVesselsDetailsByFilters, getVesselsBySegmentAndCategory } =
    useVesselsDetailsByFilters();

  const {
    data: globalMonthlyPercentActivityStatus,
    isPending: isLoadingGlobalMonthlyPercentActivityStatus
  } = useGlobalMonthlyPercentActivityStatus();

  const { data: globalPortCongestion, isPending: isLoadingGlobalPortCongestion } =
    useGlobalPortCongestion();

  const { data: globalEmissionIntensity, isPending: isLoadingGlobalEmissionIntensity } =
    useGlobalEmissionIntensity();

  const { data: globalPortStats, isPending: isLoadingGlobalPortStats } = useGlobalPortStats();

  useEffect(() => {
    if (detailsWindowType === WINDOW_TYPE.INBOUND_VESSEL && activeAdvancedFilters) {
      setActiveAdvancedFilters((prev) => ({
        ...prev,
        selectedLocation: null
      }));
    }
  }, [detailsWindowType]);

  useEffect(() => {
    setActiveAdvancedFilters(null);
    setVesselSearchItemId(null);
    setVesselSearchItemType(null);
  }, [segment.id]);

  const vesselsInformationByImo = useMemo(() => {
    const mappedData = new Map();
    if (!vesselsDetails) {
      return mappedData;
    }
    vesselsDetails?.forEach(({ operatorInformation, ownershipInformation, vessel }) => {
      mappedData.set(vessel.imo, {
        operatorInformation: operatorInformation?.[0],
        ownerInformation: ownershipInformation?.[0],
        ...vessel
      });
    });
    return mappedData;
  }, [vesselsDetails]);

  const handleVesselSelect = (vesselImo) => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.ITEM_ID, vesselImo);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE, WINDOW_STATE.OPEN);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION);
      return prev;
    });
  };

  const { filteredInboundVesselsLatestPosition, filteredPorts } = useMemo(() => {
    if (
      !vesselsLatestPosition ||
      detailsWindowType !== WINDOW_TYPE.INBOUND_VESSEL ||
      !inboundVesselLocationType
    ) {
      return {
        filteredInboundVesselsLatestPosition: vesselsLatestPosition,
        filteredPorts: lookupPorts
      };
    }

    const filteredInboundVesselsLatestPosition = vesselsLatestPosition.filter(
      ({ destinationPortId, eta }) => {
        const selectedStartDate = moment(inboundVesselStartDate);
        const selectedEndDate = moment(inboundVesselEndDate);
        const vesselEta = moment(new Date(eta));

        const isMatchDateRange =
          !selectedStartDate.isValid() ||
          !selectedEndDate.isValid() ||
          (vesselEta.isSameOrAfter(selectedStartDate) && vesselEta.isSameOrBefore(selectedEndDate));

        let isMatchLocation = +inboundVesselLocationId === destinationPortId;
        if (inboundVesselLocationType === 'Country') {
          const portsBelongToSelectedCountry = lookupPorts.filter(
            ({ country }) => country.id === +inboundVesselLocationId
          );
          isMatchLocation = portsBelongToSelectedCountry.some(({ id }) => id === destinationPortId);
        }

        return isMatchDateRange && isMatchLocation;
      }
    );

    const filteredPorts = lookupPorts.filter(({ country, id }) =>
      inboundVesselLocationType === 'Country'
        ? +inboundVesselLocationId === country.id
        : +inboundVesselLocationId === id
    );

    return {
      filteredInboundVesselsLatestPosition,
      filteredPorts
    };
  }, [
    vesselsLatestPosition,
    lookupPorts,
    inboundVesselLocationType,
    inboundVesselLocationId,
    inboundVesselStartDate,
    inboundVesselEndDate
  ]);

  const getAdvancedFilterAppliedVessels = (vesselsLatestPosition, activeFilters) => {
    if (!activeFilters || !vesselsLatestPosition) return vesselsLatestPosition;

    return vesselsLatestPosition.filter((singleVesselLatestPosition) => {
      const vesselInfo = vesselsInformationByImo.get(singleVesselLatestPosition.imo);
      if (!vesselInfo) return false;

      const matchesLocationFilter = () => {
        // Check if any location filters are set
        const hasLocationFilter = [
          activeFilters.selectedLocation1,
          activeFilters.selectedLocation2,
          activeFilters.selectedLocation3,
          activeFilters.selectedLocation4
        ].some((location) => location);

        if (!hasLocationFilter) return true;

        const destinationPortId = singleVesselLatestPosition.destinationPortId;

        // Check if vessel matches any of the selected locations
        return [
          activeFilters.selectedLocation1,
          activeFilters.selectedLocation2,
          activeFilters.selectedLocation3,
          activeFilters.selectedLocation4
        ].some((location) => {
          if (!location) return false;

          let isMatchLocation = location.id === destinationPortId;

          if (location.type === 'Country') {
            const portsBelongToSelectedCountry = lookupPorts.filter(
              ({ country }) => country.id === location.id
            );
            isMatchLocation = portsBelongToSelectedCountry.some(
              ({ id }) => id === destinationPortId
            );
          }
          return isMatchLocation;
        });
      };

      const matchesDateFilter = () => {
        const selectedStartDate = moment(activeFilters.startDate);
        const selectedEndDate = moment(activeFilters.endDate);
        const vesselEta = moment(new Date(singleVesselLatestPosition.eta));

        return (
          !activeFilters.startDate ||
          !activeFilters.endDate ||
          !selectedStartDate.isValid() ||
          !selectedEndDate.isValid() ||
          (vesselEta.isSameOrAfter(selectedStartDate) && vesselEta.isSameOrBefore(selectedEndDate))
        );
      };

      const matchesOperatorFilter = () => {
        const hasOperatorFilter = activeFilters.selectedOperators?.length;
        const operatorId = vesselInfo.operatorInformation?.companyId;

        if (!hasOperatorFilter) return true;

        return activeFilters.selectedOperators.some((operator) => operator.id === operatorId);
      };

      const matchesOwnerFilter = () => {
        const hasOwnerFilter = activeFilters.selectedOwners?.length;
        const ownerId = vesselInfo.ownerInformation?.companyId;

        if (!hasOwnerFilter || !ownerId) return true;

        return activeFilters.selectedOwners.some((owner) => owner.id === ownerId);
      };

      const matchesCategoryFilter = () => {
        if (isRoRo && !activeFilters.operatorTypeId) return true;
        if (!isRoRo && !activeFilters.commercialCategoryId) return true;

        const allowedVessels = getVesselsBySegmentAndCategory(
          segment.id,
          isRoRo ? activeFilters.operatorTypeId : activeFilters.commercialCategoryId
        );
        return allowedVessels.some(({ vessel }) => vessel.imo === vesselInfo.imo);
      };

      const matchesCapacityFilter = () => {
        if (isRoRo) {
          const minCarCapacity = activeFilters?.minCarCapacity;
          const maxCarCapacity = activeFilters?.maxCarCapacity;
          const vesselCarCapacity = vesselInfo?.vesselCapacityDetail?.carCapacity;
          return (
            typeof minCarCapacity !== 'number' ||
            typeof maxCarCapacity !== 'number' ||
            (typeof vesselCarCapacity === 'number' &&
              vesselCarCapacity >= minCarCapacity &&
              vesselCarCapacity <= maxCarCapacity)
          );
        } else {
          const vesselMaxLiftingCapacity = vesselInfo?.maxLiftingCapacity;
          const minLiftingCapacity = activeFilters?.minLiftingCapacity;
          const maxLiftingCapacity = activeFilters?.maxLiftingCapacity;
          return (
            typeof minLiftingCapacity !== 'number' ||
            typeof maxLiftingCapacity !== 'number' ||
            (typeof vesselMaxLiftingCapacity === 'number' &&
              vesselMaxLiftingCapacity >= minLiftingCapacity &&
              vesselMaxLiftingCapacity <= maxLiftingCapacity)
          );
        }
      };

      const matchedBuildYearFilter = () => {
        const vesselBuildYear = vesselInfo?.buildYear;
        const maxBuildYear = activeFilters?.maxBuildYear;
        const minBuildYear = activeFilters?.minBuildYear;
        return (
          typeof maxBuildYear !== 'number' ||
          typeof minBuildYear !== 'number' ||
          (typeof vesselBuildYear === 'number' &&
            vesselBuildYear >= minBuildYear &&
            vesselBuildYear <= maxBuildYear)
        );
      };

      const matchesDeadWeightFilter = () => {
        const vesselDeadweight = vesselInfo?.deadweight;
        const minDeadweight = activeFilters?.minDeadweight;
        const maxDeadweight = activeFilters?.maxDeadweight;

        return (
          typeof minDeadweight !== 'number' ||
          typeof maxDeadweight !== 'number' ||
          (typeof vesselDeadweight === 'number' &&
            vesselDeadweight >= minDeadweight &&
            vesselDeadweight <= maxDeadweight)
        );
      };

      const matchesGrossTonnageFilter = () => {
        const vesselGrossTonngae = vesselInfo.grossTonnage;
        const minGrossTonnage = activeFilters.minGrossTonnage;
        const maxGrossTonnage = activeFilters.maxGrossTonnage;

        return (
          typeof minGrossTonnage !== 'number' ||
          typeof maxGrossTonnage !== 'number' ||
          (typeof vesselGrossTonngae === 'number' &&
            vesselGrossTonngae >= minGrossTonnage &&
            vesselGrossTonngae <= maxGrossTonnage)
        );
      };

      return (
        matchesLocationFilter() &&
        matchesDateFilter() &&
        matchesOperatorFilter() &&
        matchesOwnerFilter() &&
        matchesCapacityFilter() &&
        matchedBuildYearFilter() &&
        matchesDeadWeightFilter() &&
        matchesGrossTonnageFilter() &&
        matchesCategoryFilter()
      );
    });
  };

  const getQuickSearchFilterAppliedVessels = (vesselsLatestPosition) => {
    if (!vesselsLatestPosition) return [];
    if (!vesselSearchItemType || !vesselSearchItemId) return vesselsLatestPosition;
    if (vesselSearchItemType === VESSEL_SEARCH_ITEM_TYPE.VESSEL)
      return vesselsLatestPosition.filter(({ imo }) => imo === +vesselSearchItemId);

    return vesselsLatestPosition.filter((singleVesselLatestPosition) => {
      const vesselInfo = vesselsInformationByImo.get(singleVesselLatestPosition.imo);
      if (!vesselInfo) return false;

      const searchItemIdNum = +vesselSearchItemId;

      switch (vesselSearchItemType) {
        case VESSEL_SEARCH_ITEM_TYPE.OPERATOR:
          return vesselInfo.operatorInformation?.companyId === searchItemIdNum;

        case VESSEL_SEARCH_ITEM_TYPE.OWNER:
          return vesselInfo.ownerInformation?.companyId === searchItemIdNum;

        case VESSEL_SEARCH_ITEM_TYPE.COUNTRY:
          return lookupPorts
            .filter(({ country }) => country.id === searchItemIdNum)
            .some(({ id }) => id === singleVesselLatestPosition.destinationPortId);

        case VESSEL_SEARCH_ITEM_TYPE.PORT:
          return singleVesselLatestPosition.destinationPortId === searchItemIdNum;

        default:
          return false;
      }
    });
  };

  const globalFilterAppliedVessels = getQuickSearchFilterAppliedVessels(
    getAdvancedFilterAppliedVessels(
      filteredInboundVesselsLatestPosition,
      activeInboundVesselsFilters ?? activeAdvancedFilters
    )
  );

  const handleMapLoad = (mapInstance) => {
    setIsMapLoaded(true);
    mapRef.current = mapInstance;
    createVesselMarkers(
      mapInstance,
      globalFilterAppliedVessels,
      themeMode,
      getTimeZoneDisplay,
      ({ imo }) => handleVesselSelect(imo),
      showVesselsNames
    );

    if (showPorts) {
      const getQuickSearchFilterAppliedPorts = () => {
        if (vesselSearchItemType === VESSEL_SEARCH_ITEM_TYPE.PORT) {
          return filteredPorts.filter(({ id }) => id === +vesselSearchItemId);
        }
        if (vesselSearchItemType === VESSEL_SEARCH_ITEM_TYPE.COUNTRY) {
          return lookupPorts.filter(({ country }) => country.id === +vesselSearchItemId);
        }

        return filteredPorts;
      };
      createPortMarkers(mapInstance, getQuickSearchFilterAppliedPorts(), themeMode);
    }
    if (showPorts && !lookupPorts.length) {
      setSearchParams((prev) => {
        prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS);
        return prev;
      });
    }

    if (vesselSearchItemId && vesselSearchItemType) {
      setSearchParams((prev) => {
        prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.ITEM_ID);
        prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE);
        return prev;
      });
    }
  };

  const handleSidePanelClose = () => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE, WINDOW_STATE.CLOSED);
      if (selectedAction === ACTIVITY_MAP_ACTIONS.INSIGHT) {
        prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION);
      }
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.ITEM_ID);
      return prev;
    });
    mapRef.current.zoomTo(1);
  };

  const renderSidePanelContent = () => {
    if (detailsWindowState !== WINDOW_STATE.OPEN) return null;

    if (selectedAction === ACTIVITY_MAP_ACTIONS.INSIGHT) {
      return (
        <DashboardActivityMapInsight
          renderInsightContent={renderInsightContent}
          isLoading={
            isLoadingTop10Ports ||
            isLoadingGlobalPortStats ||
            isLoadingGlobalPortCongestion ||
            isLoadingGlobalMonthlyPercentActivityStatus ||
            isLoadingGlobalPortCongestion ||
            isLoadingGlobalEmissionIntensity
          }
          onClose={() => {
            setSearchParams((prev) => {
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE, WINDOW_STATE.CLOSED);
              prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION);
              return prev;
            });
          }}
          title={detailsWindowType === WINDOW_TYPE.INBOUND_VESSEL ? 'Insight' : 'Global Insight'}
        />
      );
    }

    if (detailsWindowState === WINDOW_STATE.OPEN && itemId) {
      return (
        <DashboardActivityMapVesselDetailsWindow
          vesselImo={itemId}
          vesselsLatestPosition={vesselsLatestPosition}
          vesselsInformationByImo={vesselsInformationByImo}
          isLoadingVesselsDetails={isLoadingVesselsDetails}
        />
      );
    }

    return null;
  };

  const renderSearchPanel = () => {
    const { DETAILS_WINDOW_TYPE, INBOUND_VESSEL, ITEM_ID, DETAILS_WINDOW_STATE } =
      ACTIVITY_MAP_SEARCH_PARAM_KEYS;
    const handleInboundVesselSubmit = ({ location, dateRange }) => {
      const { type, id } = location;

      setSearchParams((prev) => {
        prev.set(INBOUND_VESSEL.LOCATION_TYPE, type);
        prev.set(INBOUND_VESSEL.LOCATION_ID, id);
        dateRange?.startDate && prev.set(INBOUND_VESSEL.START_DATE, dateRange.startDate);
        dateRange?.endDate && prev.set(INBOUND_VESSEL.END_DATE, dateRange.endDate);
        return prev;
      });

      setActiveAdvancedFilters(null);
    };

    if (selectedAction === ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER) {
      return (
        <Box width="25vw" height="80vh" sx={{ overflow: 'auto', ...customScrollbar }}>
          <DashboardActivityMapFilter
            onApply={(filters) => {
              setActiveAdvancedFilters(null);
              setActiveInboundVesselsFilters(filters);
            }}
            defaultFilters={activeInboundVesselsFilters}
            onClose={() => {
              setSearchParams((prev) => {
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION);
                return prev;
              });
            }}
            isInboundVesselsFiltering
          />
        </Box>
      );
    }

    return (
      <Box width="25vw">
        <DashboardActivityMapVesselSearchBox
          vesselsLatestPosition={vesselsLatestPosition}
          selectedSearchOptionId={vesselSearchItemId}
          selectedSearchOptionType={vesselSearchItemType}
          onSelectSearchOption={(newSelectedSearchOption) => {
            const { id, type } = newSelectedSearchOption;
            setVesselSearchItemId(id);
            setVesselSearchItemType(type);

            setActiveAdvancedFilters(null);
          }}
          onClearSearchValue={() => {
            setVesselSearchItemId(null);
            setVesselSearchItemType(null);
          }}
          operatorVesselHierarchy={operatorVesselHierarchy}
          ownerOperatorVesselHierarchy={ownerOperatorVesselHierarchy}
          lookupLocations={lookupLocations}
          lookupPorts={lookupPorts}
          isLoading={
            isLoadingOperatorVesselHierarchy ||
            isLoadingOwnerOperatorVesselHierarchy ||
            isLoadingVesselsLatestPosition ||
            isLoadingVesselsDetails ||
            isLoadingLookupLocations ||
            isLoadingLookupPorts
          }
        />
      </Box>
    );
  };

  if (isLoadingVesselsLatestPosition || isLoadingVessels) {
    return <LoopPlaceholder height="100%" />;
  }

  const renderInsightContent = () => {
    if (
      detailsWindowType === WINDOW_TYPE.INBOUND_VESSEL &&
      inboundVesselLocationId &&
      inboundVesselLocationType
    ) {
      return (
        <DashboardActivityMapInboundVesselInsight
          lookupPorts={lookupPorts}
          locationId={inboundVesselLocationId}
          locationType={inboundVesselLocationType}
          vesselsLatestPosition={globalFilterAppliedVessels}
        />
      );
    }
    return (
      <DashboardActivityMapGlobalInsights
        top10Ports={top10Ports}
        lookupPorts={lookupPorts}
        globalPortStats={globalPortStats}
        globalMonthlyPercentActivityStatus={globalMonthlyPercentActivityStatus}
        globalPortCongestion={globalPortCongestion}
        globalEmissionIntensity={globalEmissionIntensity}
      />
    );
  };

  const isOpenSidePanel = detailsWindowState === WINDOW_STATE.OPEN;

  return (
    <Box height="100%" width="100%">
      <Box
        sx={{
          height: '100%',
          width: '100%'
        }}>
        <DashboardActivityMap
          mapRefreshKey={`${themeMode}-${inboundVesselLocationId}-${inboundVesselLocationType}-${inboundVesselStartDate}-${inboundVesselEndDate}-${JSON.stringify(
            activeAdvancedFilters
          )}-${showPorts}-${showVesselsNames}-${vesselSearchItemId}-${vesselSearchItemType}-${isRoRo}-${JSON.stringify(
            activeInboundVesselsFilters
          )}`}
          vesselsLatestPosition={vesselsLatestPosition}
          sidePanelContent={renderSidePanelContent()}
          isOpenSidePanel={isOpenSidePanel}
          onSidePanelClose={handleSidePanelClose}
          onMapLoad={handleMapLoad}
          isCompactView={isCompactView}
          onClickFullscreen={onClickFullscreen}
          renderFloatingComponents={(mapContainerZIndex) => {
            return (
              <>
                <Box
                  position="absolute"
                  top="16px"
                  left="16px"
                  zIndex={mapContainerZIndex + 1}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    pointerEvents: 'none',
                    '& > *': {
                      pointerEvents: 'auto'
                    }
                  }}>
                  {renderSearchPanel()}
                  {selectedAction !== ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER && (
                    <Box width="max-content">
                      <DashboardActivityMapInfoWindow infoItems={infoItems} />
                    </Box>
                  )}
                </Box>
              </>
            );
          }}
          filterProps={{
            onApply: (params) => {
              setActiveInboundVesselsFilters(null);
              setActiveAdvancedFilters(params);
              setVesselSearchItemId(null);
              setVesselSearchItemType(null);
              setSearchParams((prev) => {
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_ID);
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_TYPE);
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.END_DATE);
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.START_DATE);
                return prev;
              });
            },
            defaultFilters: activeAdvancedFilters,
            isDisableApply: isLoadingVesselsLatestPosition || isLoadingVesselsDetailsByFilters,
            isFilterApplied: activeAdvancedFilters && !isInitialValues(activeAdvancedFilters)
          }}
          selectedAction={selectedAction}
          onChangeSelectedAction={(value) => {
            setSearchParams((prev) => {
              prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE);
              if (!value) {
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION);
              } else {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_ACTION, value);
                if (value === ACTIVITY_MAP_ACTIONS.INSIGHT) {
                  prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_STATE, WINDOW_STATE.OPEN);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.ITEM_ID);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.DETAILS_WINDOW_TYPE);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_ID);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.LOCATION_TYPE);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.END_DATE);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.INBOUND_VESSEL.START_DATE);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.VESSEL_SEARCH_ITEM_ID);
                  prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.VESSEL_SEARCH_ITEM_TYPE);
                }
              }
              return prev;
            });
          }}
          mapSettingsProps={{
            showPorts,
            isLoadingLookupPorts,
            onShowPortsChange: (value) => {
              setSearchParams((prev) => {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS, value);
                return prev;
              });
            },
            showVesselsNames,
            onShowVesselsNamesChange: (value) => {
              setSearchParams((prev) => {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES, value);
                return prev;
              });
            },
            showVessels,
            onShowVesselsChange: (value) => {
              setSearchParams((prev) => {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS, value);
                return prev;
              });
            },
            showVesselsStatus,
            onShowVesselsStatusChange: (value) => {
              setSearchParams((prev) => {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS, value);
                return prev;
              });
            }
          }}
          mapActionsPopupState={mapActionsPopupState}
        />
      </Box>
    </Box>
  );
}

DashboardActivityMapSection.propTypes = {
  isCompactView: PropTypes.bool,
  onClickFullscreen: PropTypes.func
};

export default DashboardActivityMapSection;
