import React, { useRef, useState } from 'react';
import { PageHeader, Typography, Box, Modal, CircularProgress } from '@esgian/esgianui';
import VoyageGroupsPopup from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import SimulatedVoyageSection from '../../../components/Sections/VoyageAnalytics/SimulatedVoyageSection/SimulatedVoyageSection';
import simulationResultsData from './simulation-result-data.json';
import { useVoyageGroups } from '../hooks/useVoyageGroups';
import AddToVoyageGroupPopup from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/AddToVoyageGroupPopup';
import {
  generateSimulatedVoyageId,
  VoyageType
} from '../../../components/Sections/VoyageAnalytics/VoyageUtils';
import SimulatedVoyageNote from '../../../components/Sections/VoyageAnalytics/SimulatedVoyageSection/SimulatedVoyageNote';
import CreateVoyageGroupPopup from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/CreateVoyageGroupPopup';
import { locationTypeIdMapping } from '../../../constants';

const extractStorableVoyageIdentity = (voyageIdentity) => {
  if (!voyageIdentity) return null;
  const voyageIdentityToStore = {
    originId: voyageIdentity.origin?.id,
    originType: locationTypeIdMapping.Port,
    destinationId: voyageIdentity.destination?.id,
    destinationType: locationTypeIdMapping.Port,
    viaPorts:
      voyageIdentity.viaPorts?.map((port, index) => ({
        id: port?.id,
        type: locationTypeIdMapping.Port,
        order: index
      })) || [],
    wayPointId: voyageIdentity.wayPoint?.id,
    wayPointType: locationTypeIdMapping.Port,
    fuelTypeId: voyageIdentity.fuelType?.id,
    vesselId: voyageIdentity.vessel?.assetId,
    averageSpeedKts: voyageIdentity.averageSpeedKts,
    myCargoWeightTn: voyageIdentity.myCargoWeightTn,
    euAllowanceCost: voyageIdentity.euAllowanceCost,
    note: voyageIdentity.note
  };
  return { ...voyageIdentityToStore, id: generateSimulatedVoyageId(voyageIdentityToStore) };
};

function SimulatedVoyagePage() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const voyageIdentityRef = useRef();
  const groupIdRef = useRef();
  const {
    addSimulatedVoyagesToGroup,
    isAddingVoyagesToGroup,
    isLoadingVoyageGroups,
    simulatedVoyagesGroups,
    createVoyageGroup,
    isCreatingVoyageGroup
  } = useVoyageGroups();
  const [showAddToVoyageGroupPopup, setShowAddToVoyageGroupPopup] = useState(false);
  const [showSimulatedVoyageNotePopup, setShowSimulatedVoyageNotePopup] = useState(false);
  const [showCreateNewGroupPopup, setShowCreateNewGroupPopup] = useState(false);

  const handleAddVoyageToSimulatedVoyageGroup = async (note) => {
    if (note) {
      voyageIdentityRef.current['note'] = note;
    }
    try {
      await addSimulatedVoyagesToGroup({
        voyageGroupId: groupIdRef.current,
        voyagesToAdd: [extractStorableVoyageIdentity(voyageIdentityRef.current)]
      });
      setShowAddToVoyageGroupPopup(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{ minHeight: '98vh' }}
        flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageHeader id="lbl-simulated-voyage-analytics" title="Voyage Analytics" />
          <VoyageGroupsPopup mode="search" />
        </Box>
        <Box>
          <Typography variant="body2">Simulated voyage route</Typography>
        </Box>

        <SimulatedVoyageSection
          simulationResultData={data}
          onSimulationStart={(searchParameters) => {
            voyageIdentityRef.current = searchParameters;
            setIsLoading(true);
            setTimeout(() => {
              setData(simulationResultsData[0]);
              setIsLoading(false);
            }, 3000);
          }}
          onSaveToGroup={() => {
            setShowAddToVoyageGroupPopup(true);
          }}
        />
      </Box>
      {isLoading && (
        <Modal open>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={4}
            gap={2}>
            <CircularProgress size={60} />
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
              <Typography variant="h6">Simulating Voyage...</Typography>
              <Typography variant="body2">
                It will take approximately a few seconds to 3 minutes
              </Typography>
            </Box>
          </Box>
        </Modal>
      )}
      {showAddToVoyageGroupPopup && (
        <Modal open handleClose={() => setShowAddToVoyageGroupPopup(false)} size="xs">
          <AddToVoyageGroupPopup
            onCreateANewGroup={() => setShowCreateNewGroupPopup(true)}
            savedGroups={simulatedVoyagesGroups}
            onAddVoyageToGroup={async (groupId) => {
              groupIdRef.current = groupId;
              setShowSimulatedVoyageNotePopup(true);
            }}
            isSavingVoyageToGroup={isAddingVoyagesToGroup}
            isLoadingVoyageGroups={isLoadingVoyageGroups}
            voyageGroupType={VoyageType.SIMULATED}
            backgroundColor="inherit"
          />
        </Modal>
      )}

      <SimulatedVoyageNote
        open={showSimulatedVoyageNotePopup}
        onClose={() => {
          handleAddVoyageToSimulatedVoyageGroup();
          setShowSimulatedVoyageNotePopup(false);
        }}
        onSave={(note) => {
          handleAddVoyageToSimulatedVoyageGroup(note);
          setShowSimulatedVoyageNotePopup(false);
        }}
      />

      {showCreateNewGroupPopup && (
        <CreateVoyageGroupPopup
          open
          setModalOpen={setShowCreateNewGroupPopup}
          existingGroups={simulatedVoyagesGroups}
          isSubmitDisabled={isCreatingVoyageGroup}
          onSubmit={async (newVoyageGroupName, clearInput) => {
            try {
              await createVoyageGroup({
                newVoyageGroupName,
                type: VoyageType.SIMULATED
              });
              setShowCreateNewGroupPopup(false);
            } catch (error) {
              clearInput?.();
              setShowCreateNewGroupPopup(false);
            }
          }}
        />
      )}
    </>
  );
}

export default SimulatedVoyagePage;
