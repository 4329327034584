import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Paper, TextField } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { PortSelect, VesselSelect } from '@components/Inputs';
import ViaPorts from './ViaPorts';
import FuelSelect, { FuelTypesByName } from '../../../../Inputs/FuelSelect/FuelSelect';
import { Controller, useForm } from 'react-hook-form';
import { useDynamicLookupQueries } from '@hooks';

function SimulatedVoyageFilter({ onSubmit }) {
  const {
    lookupPortsQuery: { data: lookupPorts }
  } = useDynamicLookupQueries();
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: 'onChange'
  });

  return (
    <Paper component="form" onSubmit={handleSubmit(onSubmit)} sx={{ flexGrow: 1 }}>
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        <Controller
          control={control}
          defaultValue={null}
          name="origin"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PortSelect
              label="Origin*"
              id="port-select-origin"
              placeholder="Search by port"
              selectedPort={value}
              handleChange={onChange}
              portList={lookupPorts}
              errorText={error?.message}
            />
          )}
          rules={{ required: 'Origin is required' }}
        />
        <Controller
          control={control}
          defaultValue={[]}
          name="viaPorts"
          render={({ field: { onChange, value } }) => (
            <ViaPorts onChange={onChange} lookupPorts={lookupPorts} viaPorts={value} />
          )}
        />
        <Controller
          control={control}
          defaultValue={null}
          name="destination"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PortSelect
              label="Destination*"
              id="port-select-destination"
              placeholder="Search by port"
              selectedPort={value}
              handleChange={onChange}
              portList={lookupPorts}
              errorText={error?.message}
            />
          )}
          rules={{ required: 'Destination is required' }}
        />
        <Controller
          control={control}
          defaultValue={null}
          name="wayPoint"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PortSelect
              label="Way Point"
              id="way-point-select"
              placeholder="Select"
              selectedPort={value}
              handleChange={onChange}
              portList={lookupPorts}
              errorText={error?.message}
            />
          )}
        />

        <Divider sx={{ my: 2 }} />

        <Controller
          control={control}
          defaultValue={FuelTypesByName.Heavy_Fuel_Oil}
          name="fuelType"
          render={({ field: { onChange, value } }) => (
            <FuelSelect handleChange={onChange} selectedFuel={value} />
          )}
        />
        <Controller
          control={control}
          defaultValue={null}
          name="vessel"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <VesselSelect
              handleChange={(newSelected) => {
                onChange(newSelected);
              }}
              disabled={false}
              error={!!error}
              helperText={error?.message}
              placeholder="IMO/Name/Operator"
              selectedVessel={value}
              id="vessel-select"
            />
          )}
          rules={{
            validate: {
              required: (value) => {
                return value ? true : 'Vessel is required';
              }
            }
          }}
        />
        <Controller
          control={control}
          defaultValue={12}
          name="averageSpeedKts"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Average speed (kts)*"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: 'Average speed is required',
            min: { value: 0, message: 'Speed must be positive' }
          }}
        />
        <Controller
          control={control}
          defaultValue={0}
          name="myCargoWeightTn"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              type="number"
              label="My cargo weight (T)*"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: 'Cargo weight is required',
            min: { value: 0, message: 'Weight must be positive' }
          }}
        />
        <Controller
          control={control}
          defaultValue={2}
          name="euAllowanceCost"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              type="number"
              label="EU Allowance Cost*"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: 'EU Allowance Cost is required',
            min: { value: 0, message: 'Cost must be positive' }
          }}
        />

        <Button type="submit" disabled={!isValid}>
          Simulate
        </Button>
      </Box>
    </Paper>
  );
}

SimulatedVoyageFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SimulatedVoyageFilter;
