import React, { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  NotFound,
  Paper,
  Tab,
  Tabs,
  Typography,
  TrendBox,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Box
} from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';
import { getVesselPercentageCo2Emission, getVesselEmissionHistoryDetails } from '@api/Emissions';
import { VesselEmissionChart, VesselEmissionTable } from './index';
import { EmissionsDisclaimer, TextWithTooltipIcon } from '@components';
import AccreditationStatementModal from '@components/Modals/AccreditationStatementModal';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function VesselEmissionsSection({ vesselName }) {
  const [percentageChangeData, setPercentageChangeData] = useState(null);
  const [emissionHistoryData, setEmissionHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [noData, setNoData] = useState(true);
  const [periodSelect, setPeriodSelect] = useState(1);
  const params = useParams();

  const { isRoRo } = useSegment();

  const [selectedUnitTab, setSelectedUnitTab] = isRoRo ? useState(1) : useState(3);

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getPercentageChangeData = async (imo) => {
      try {
        setLoading(true);
        await getVesselPercentageCo2Emission(imo, signal).then((result) => {
          setPercentageChangeData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getPercentageChangeData(params.imo);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let periodValue = 'monthly';
    if (periodSelect === 2) {
      periodValue = 'quarterly';
    } else if (periodSelect === 3) {
      periodValue = 'yearly';
    }

    const getVesselEmissionHistoryData = async (imo) => {
      try {
        setLoading(true);
        setNoData(false);

        await getVesselEmissionHistoryDetails(imo, periodValue, signal).then((result) => {
          setEmissionHistoryData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getVesselEmissionHistoryData(params.imo);
    return () => {
      controller.abort();
    };
  }, [periodSelect]);

  const { percentChangeTotalCo2, last12to1monthsVesselEmissionDetails } =
    percentageChangeData || {};

  const last12to1Months = last12to1monthsVesselEmissionDetails || {};
  const { totalCo2: last12to1MonthsTotalCo2 } = last12to1Months;

  const handlePeriodSelect = useCallback(({ target }) => {
    setPeriodSelect(target.value);
  }, []);
  return (
    <Grid item xs={12}>
      <Stack>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Stack sx={{ p: 2 }} direction={'row'} justifyContent={'space-between'}>
                  <Stack spacing={2}>
                    <Typography variant={'h6'}>
                      CO<sub>2</sub> emission History
                    </Typography>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                      <Select
                        variant={'standard'}
                        value={periodSelect}
                        labelId="vessel-emission-history-select-label"
                        onChange={handlePeriodSelect}
                        id="vessel-emission-history-select"
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={1}>Monthly</MenuItem>
                        <MenuItem value={2}>Quarterly</MenuItem>
                        <MenuItem value={3}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <TrendBox
                    paperSx={{ height: 'auto', width: '25em' }}
                    loading={loading}
                    mainValue={last12to1MonthsTotalCo2}
                    paperVariant={'outlined'}
                    header={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle1'} bold>
                            Total tCO<sub>2</sub>
                          </Typography>
                        }
                        tooltipText={
                          'Total tCO2 for the last 12 months compared to the previous set of 12 months'
                        }
                      />
                    }
                    percent={percentChangeTotalCo2 ? Number(percentChangeTotalCo2?.toFixed(2)) : 0}
                  />
                </Stack>
                <Stack sx={{ pl: 2, pr: 2 }}>
                  <Tabs
                    value={selectedUnitTab}
                    onChange={(_, value) => {
                      setSelectedUnitTab(value);
                    }}>
                    {isRoRo && (
                      <Tab
                        value={1}
                        id="co2-ceu-tab"
                        label={
                          <TextWithTooltipIcon
                            label={
                              <Typography variant={'subtitle2'}>
                                CO<sub>2</sub>/CEU
                              </Typography>
                            }
                            tooltipText={
                              'Tonnes of CO₂ emission per CEU(Car equivalent Unit) for the vessel based on the fleet owned by the vessel in the selected time period.'
                            }
                          />
                        }
                      />
                    )}
                    {isRoRo && (
                      <Tab
                        id="gt-nm-tab"
                        value={2}
                        label={
                          <TextWithTooltipIcon
                            label={
                              <Typography variant={'subtitle2'}>
                                CO<sub>2</sub>/(GT&#8226;NM)
                              </Typography>
                            }
                            tooltipText={
                              'Grams of CO₂ emission per GT(Gross tonnage) capacity and distance travelled(NM- nautical miles) for the vessel based on the fleet owned in the selected time period.'
                            }
                          />
                        }
                      />
                    )}

                    <Tab
                      id="dwt-nm-tab"
                      value={3}
                      label={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle2'}>
                              CO<sub>2</sub>/(DWT&#8226;NM)
                            </Typography>
                          }
                          tooltipText={
                            'Grams of CO₂ emission per DWT(Deadweight) capacity and distance travelled(NM- nautical miles) for the vessel  based on the fleet owned in the selected time period.'
                          }
                        />
                      }
                    />
                    <Tab
                      id="total-co2-tab"
                      value={4}
                      label={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle2'}>
                              Total CO<sub>2</sub>
                            </Typography>
                          }
                          tooltipText={
                            'Tonnes of CO₂ emission of the vessel for the fleet owned in the selected time period.'
                          }
                        />
                      }
                    />
                  </Tabs>
                  <Divider />
                </Stack>
                {!noData && (
                  <Box sx={{ p: 2 }}>
                    <Stack spacing={2}>
                      <EmissionsDisclaimer setShowStatement={setShowStatement} />
                      <VesselEmissionChart
                        fileName={
                          vesselName?.split(' ')?.join('-').toLowerCase() + '-co2-emission-history'
                        }
                        selectedUnitTab={selectedUnitTab}
                        periodSelect={periodSelect}
                        loading={loading}
                        emissionHistoryData={emissionHistoryData}
                      />
                    </Stack>
                  </Box>
                )}
                {!loading && noData && (
                  <Box sx={{ p: 2 }}>
                    <NotFound
                      show={true}
                      header={'No Matching Result'}
                      text={'Please change your search parameters'}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {!noData && (
                <VesselEmissionTable
                  fileName={
                    vesselName?.split(' ')?.join('-').toLowerCase() + '-co2-emission-history'
                  }
                  loading={loading}
                  periodSelect={periodSelect}
                  emissionHistoryData={emissionHistoryData}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        <AccreditationStatementModal
          handleClose={() => setShowStatement(false)}
          open={showStatement}
        />
      </Stack>
    </Grid>
  );
}

VesselEmissionsSection.propTypes = {
  vesselName: PropTypes.string
};

VesselEmissionsSection.defaultProps = {
  vesselName: ''
};

export default VesselEmissionsSection;
