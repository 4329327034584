import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@esgian/esgianui';
import { useTimezone } from '@hooks/useTimezone';
import { useTheme } from '@hooks/useTheme';
import { ProfileLink } from '@components/Links';

const InboundVesselInsightVesselTab = ({ vesselsLatestPosition, ports }) => {
  const { customScrollbar } = useTheme();
  const { getTimeZoneDisplay } = useTimezone();

  const groupedByPort = ports.reduce((grouped, singlePort) => {
    const vesselsForPort = vesselsLatestPosition.filter(
      (vessel) => vessel.destinationPortId === singlePort.id
    );

    vesselsForPort.length > 0 && (grouped[singlePort.portName] = vesselsForPort);

    return grouped;
  }, {});

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 2 }}>
      {Object.entries(groupedByPort).map(([portName, vessels]) => (
        <Box key={portName} display="flex" flexDirection="column" gap={1.5}>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '0.95rem' }}>
              Upcoming vessels to {portName}
            </Typography>
            <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
              {vessels?.length || 0} vessels
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden'
            }}>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`
              }}>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={1}
                sx={{
                  p: 0.75,
                  borderRadius: 1
                }}>
                <Typography sx={{ width: '30%', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Vessel name
                </Typography>
                <Typography sx={{ width: '25%', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  ETA
                </Typography>
                <Typography sx={{ width: '20%', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Draught
                </Typography>
                <Typography sx={{ width: '25%', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Position received
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                overflowY: 'auto',
                ...customScrollbar,
                '& > *:nth-of-type(even)': {
                  backgroundColor: (theme) => theme.palette.action.hover
                }
              }}>
              {vessels.map((vessel) => (
                <Box
                  key={vessel.imo}
                  display="flex"
                  justifyContent="space-between"
                  gap={1}
                  sx={{
                    p: 0.75,
                    borderRadius: 1
                  }}>
                  <Box sx={{ width: '30%' }}>
                    <ProfileLink name={vessel.vesselName} profile={'vessel'} id={vessel.imo} />
                  </Box>
                  <Typography
                    sx={{
                      width: '25%',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      fontSize: '0.8rem'
                    }}>
                    {getTimeZoneDisplay(vessel.eta)}
                  </Typography>
                  <Typography
                    sx={{
                      width: '20%',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      fontSize: '0.8rem'
                    }}>
                    {vessel.draught}
                  </Typography>
                  <Typography
                    sx={{
                      width: '25%',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      fontSize: '0.8rem'
                    }}>
                    {getTimeZoneDisplay(vessel.timestamp)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

InboundVesselInsightVesselTab.propTypes = {
  vesselsLatestPosition: PropTypes.array,
  ports: PropTypes.array
};

export default InboundVesselInsightVesselTab;
