import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, getTableCellRenderCII, formatNumberReadable } from '@helpers';
import { Table } from '@esgian/esgianui';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { useSegment } from '@hooks/useSegment';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function VesselCiiRatingTable({ loading, ciiRatingData }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const { isRoRo, isMPP } = useSegment();

  const data = useMemo(() => {
    if (!ciiRatingData) return;
    const { vesselEmissionDetailsList } = ciiRatingData;
    return vesselEmissionDetailsList;
  }, [ciiRatingData]);

  const columns = useMemo(() => {
    return [
      {
        name: 'vesselName',
        label: 'Vessel ',
        options: {
          sort: true,
          filterType: 'multiselect',
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[3]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'currentOperator.operatorId',
        options: { display: false, viewColumns: false, filter: false }
      },
      {
        name: 'currentOperator.operatorLongName',
        label: 'Current operator',
        options: {
          filterType: 'multiselect',
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'imo',
        options: { display: false, viewColumns: false, filter: false }
      },
      {
        name: 'grossTonnage',
        label: 'Gross tonnage',
        options: {
          viewColumns: isRoRo,
          filter: isRoRo,
          download: isRoRo,
          display: isRoRo,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            // Use lite version as it does not affect the filter value which should be a number
            return formatNumberReadable(data[dataIndex]?.grossTonnage?.toFixed(0));
          },
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Gross tonnage'),
          filterOptions: getMinMaxFilterOptions('Gross tonnage')
        }
      },
      {
        name: 'deadWeight',
        label: 'Deadweight',
        options: {
          viewColumns: isMPP,
          filter: isMPP,
          download: isMPP,
          display: isMPP,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            // Use lite version as it does not affect the filter value which should be a number
            return formatNumberReadable(data[dataIndex]?.deadWeight?.toFixed(0));
          },
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Deadweight'),
          filterOptions: getMinMaxFilterOptions('Deadweight')
        }
      },
      {
        name: 'totalCo2',
        options: {
          customHeadLabelRender: () => (
            <div>
              Total tCO<sub>2</sub>
            </div>
          ),
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex?.toFixed(1)) || 0;
          },
          sort: true,
          sortThirdClickReset: true,
          customBodyRenderLite: (dataIndex) => {
            // Use lite version as it does not affect the filter value which should be a number
            return formatNumberReadable(data[dataIndex]?.totalCo2?.toFixed(1));
          },
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Total tCO2'),
          filterOptions: getMinMaxFilterOptions('Total tCO2')
        }
      },
      {
        name: 'totalDistanceNm',
        label: 'Total distance[NM]',
        options: {
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex?.toFixed(1)) || 0;
          },
          sort: true,
          sortThirdClickReset: true,
          customBodyRenderLite: (dataIndex) => {
            // Use lite version as it does not affect the filter value which should be a number
            return formatNumberReadable(data[dataIndex]?.totalDistanceNm?.toFixed(1));
          },
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Total distance[NM]'),
          filterOptions: getMinMaxFilterOptions('Total distance[NM]')
        }
      },
      {
        name: 'cii',
        label: 'CII',
        options: {
          hint: 'gCO2/GT•NM',
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex?.toFixed(1)) || 0;
          },
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('CII'),
          filterOptions: getMinMaxFilterOptions('CII')
        }
      },
      {
        name: 'ciiRating',
        label: 'CII rating',
        options: {
          filterType: 'multiselect',
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getTableCellRenderCII(value, theme);
          }
        }
      }
    ];
  }, [theme, data]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: true,
      tableBodyMaxHeight: '80vh',
      tableBodyHeight: '80vh',
      search: true,
      enableNestedDataAccess: '.',
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: 'vessels-cii-rating'
      },
      print: checkUserHasAccess(user, true),
      viewColumns: false,
      pagination: true,
      elevation: 0,
      toolbar: true,
      rowsPerPage: 25,
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'cii',
        direction: 'desc'
      }
    };
  }, [user]);

  return (
    <Table
      loading={loading}
      mode={theme.mode}
      data={data?.length ? data : []}
      columns={columns || []}
      options={options}
    />
  );
}

VesselCiiRatingTable.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.object
};

VesselCiiRatingTable.defaultProps = {
  ciiRatingData: null,
  loading: false
};

export default VesselCiiRatingTable;
