import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesBrushChart } from '@components/Charts';
import { useNumberFormatter } from '@hooks/useNumberFormatter';

function CeuCapacityChart({ loading, portServiceData, dateList, fileName }) {
  const [seriesMain, setSeriesMain] = useState([]);
  const [seriesBrush, setSeriesBrush] = useState([]);
  const { valueMultiplier } = useNumberFormatter();

  useEffect(() => {
    if (!portServiceData) return;
    const { monthlyFrequencyTotals, frequencyResults } = portServiceData;
    let tempTotal = monthlyFrequencyTotals?.map(({ capacityTotal }) =>
      valueMultiplier(capacityTotal)
    );
    let tempMainSeries = [];
    frequencyResults.map(({ operatorLongName, operatorMonthlyFrequencyResults }) => {
      let operatorData = [];
      operatorMonthlyFrequencyResults.forEach(({ capacityTotal }) => {
        operatorData.push(valueMultiplier(capacityTotal) || 0);
      });
      tempMainSeries.push({ name: operatorLongName, data: operatorData });
    });

    setSeriesMain(tempMainSeries);
    setSeriesBrush([{ name: 'Total', data: tempTotal }]);
  }, [portServiceData]);

  return (
    <TimeSeriesBrushChart
      yAxisTitle={'CEU Capacity'}
      loading={loading}
      seriesBrush={seriesBrush}
      seriesMain={seriesMain}
      dateList={dateList}
      groupName={'CeuCapacityCharts'}
      idBrush={'brushCeuCapacity'}
      idMain={'mainCeuCapacity'}
      fileName={fileName}
    />
  );
}

CeuCapacityChart.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  dateList: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired
};

CeuCapacityChart.defaultProps = {
  loading: false,
  portServiceData: null,
  dateList: []
};

export default CeuCapacityChart;
