import React from 'react';
import { Grid } from '@esgian/esgianui';
import { RegionOverviewMap } from '@components/Maps';

function RegionsOverviewSection() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <RegionOverviewMap />
      </Grid>
    </Grid>
  );
}

RegionsOverviewSection.propTypes = {};

RegionsOverviewSection.defaultProps = {};

export default RegionsOverviewSection;
