import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Menu,
  MenuItem,
  Button,
  ArrowDropDownIcon,
  Typography,
  ToolTip,
  HelpOutlineIcon
} from '@esgian/esgianui';
import { MapViewOptions } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVoyageProfileFilters,
  mapFilterUriKeys,
  updateVoyageProfileFilters
} from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSegment } from '@hooks';
import { MapViewOptionsStateKeys } from '../VoyageSummaryConstants';

function VoyageSummaryMapOptions({ isOngoingVoyage, isVesselDraught }) {
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const { mapOptions } = useSelector(getVoyageProfileFilters);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMPP } = useSegment();

  const qualifiedMapOptions = MapViewOptions.filter((option) => {
    if (isMPP || isOngoingVoyage) {
      return option.id !== 3;
    }
    return option;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = ({ stateKey }) => {
    let obj = { mapOptions: {} };
    obj.mapOptions[stateKey] = !mapOptions[stateKey] ?? false;
    if (MapViewOptionsStateKeys.VOYAGE_INFO === stateKey && !obj.mapOptions[stateKey]) {
      obj.mapOptions[MapViewOptionsStateKeys.DRAUGHT_GRADE] = false;
    }
    dispatch(updateVoyageProfileFilters(obj));
    searchParams.set(stateKey, obj.mapOptions[stateKey]);
    navigate({ search: searchParams.toString() }, { replace: true });
  };
  useEffect(() => {
    let obj = { mapOptions: {} };
    mapFilterUriKeys.forEach((key) => {
      let hasKey = searchParams.has(key);
      if (hasKey) {
        obj.mapOptions[key] = searchParams.get(key) === 'true';
      }
    });
    dispatch(updateVoyageProfileFilters(obj));
  }, [searchParams]);
  return (
    <div>
      <Button
        aria-controls="map-options-menu"
        aria-haspopup="true"
        onClick={handleClick}
        id-={'map-options-button'}
        variant="outlined"
        sx={{
          borderRadius: 1
        }}
        endIcon={<ArrowDropDownIcon />}>
        <Typography color="primary">Map Setting</Typography>
      </Button>
      <Menu
        id="map-options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {qualifiedMapOptions.map((option) => (
          <MenuItem
            key={option.id}
            sx={{
              px: 2,
              py: 0,
              width: '240px',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
            onClick={() => handleOptionChange(option)}
            disabled={
              !isVesselDraught
                ? option.disabled ||
                  (option.stateKey === MapViewOptionsStateKeys.DRAUGHT_GRADE &&
                    !mapOptions[MapViewOptionsStateKeys.VOYAGE_INFO])
                : false
            }>
            <Checkbox checked={mapOptions[option.stateKey] || false} />
            {option.label}
            {option.description && (
              <ToolTip title={option.description}>
                <HelpOutlineIcon sx={{ fontSize: 16, color: 'text.primary' }} />
              </ToolTip>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

VoyageSummaryMapOptions.propTypes = {
  isOngoingVoyage: PropTypes.bool
};

export default VoyageSummaryMapOptions;
