import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';
import { ProfileLink } from '@components/Links';
import { List, ListItem, ListItemText } from '@esgian/esgianui';

const SimulatedVoyageOverview = ({ voyageOverview }) => {
  const items = [
    { title: 'Vessel name:', value: voyageOverview?.vesselName || '-', isLink: false },
    { title: 'Vessel IMO:', value: voyageOverview?.vesselIMO || '-', isLink: false },
    {
      title: 'Origin:',
      value: voyageOverview?.origin ? (
        <ProfileLink profile="port" name={voyageOverview.origin} linkVariant="body1" />
      ) : (
        '-'
      ),
      isLink: true
    },
    {
      title: 'Destination:',
      value: voyageOverview?.destination ? (
        <ProfileLink profile="port" name={voyageOverview.destination} linkVariant="body1" />
      ) : (
        '-'
      ),
      isLink: true
    },
    {
      title: 'Via port:',
      value: voyageOverview?.viaPort ? (
        <ProfileLink profile="port" name={voyageOverview.viaPort} linkVariant="body1" />
      ) : (
        '-'
      ),
      isLink: true
    },
    { title: 'Way point:', value: voyageOverview?.wayPoint || '-', isLink: false },
    { title: 'Fuel type:', value: voyageOverview?.fuelType || '-', isLink: false },
    { title: 'Avg speed (Kts):', value: voyageOverview?.avgSpeed || '-', isLink: false },
    { title: 'Cargo (T):', value: voyageOverview?.cargoT || '-', isLink: false },
    { title: 'EU allowance (€):', value: voyageOverview?.euAllowance || '-', isLink: false }
  ];

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6">Overview</Typography>
        <List>
          {items.map(({ title, value }) => (
            <ListItem
              key={title}
              disablePadding
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListItemText primary={title} />
              <Typography>{value}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

SimulatedVoyageOverview.propTypes = {
  voyageOverview: PropTypes.object
};

export default SimulatedVoyageOverview;
