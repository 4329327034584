import { BaseApiRequest } from '@api/BaseApi';
import { OPERATOR_TOP_EMISSIONS, SEGMENT, VESSEL_TOP_EMISSIONS } from '@constants';
import moment from 'moment';
import { objectToQuery } from '@api/helpers';
const buildVesselsData = (performanceData) => {
  const { operatorPerformance } = performanceData;
  let { operatorFrequencyResult } = operatorPerformance;
  operatorFrequencyResult = operatorFrequencyResult[0];
  let { operatorMonthlyFrequencyResults } = operatorFrequencyResult;
  let data = [];
  operatorMonthlyFrequencyResults.forEach(({ voyageInformation }) => {
    voyageInformation.forEach(({ startDate, endDate, imo }) => {
      data.push({ startDate: startDate, endDate: endDate, imo: imo });
    });
  });
  return { vesselEmissionRequests: data };
};

export const getVesselsEmissions = (performanceData, signal = null) => {
  const data = buildVesselsData(performanceData);
  return BaseApiRequest(signal, `/Emission/Vessels`, 'emissions', 'POST', data);
};
export const getEmissionDetails = (
  startDate,
  endDate,
  operatorIds,
  minFleetSize,
  maxFleetSize,
  minBuildYear,
  maxBuildYear,
  minCapacity,
  maxCapacity,
  segmentTypeId,
  operatorTypeId,
  signal = null
) => {
  const data = {
    operatorEmissionServiceRequestV4Obj: {
      startDate: startDate,
      endDate: endDate,
      operatorIds: operatorIds?.length ? operatorIds : null,
      minFleetSize: minFleetSize,
      maxFleetSize: maxFleetSize,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      minCarCapacity: minCapacity,
      maxCarCapacity: maxCapacity,
      minDeadweight: minCapacity,
      maxDeadweight: maxCapacity,
      segmentTypeId: segmentTypeId,
      operatorTypeId: operatorTypeId,
      numOperators: OPERATOR_TOP_EMISSIONS
    }
  };
  return BaseApiRequest(
    signal,
    `/Operators/EmissionDetails?api-version=1.0`,
    'emissions',
    'POST',
    data
  );
};

export const getOperatorsCiiRating = (
  year,
  operatorIds,
  minFleetSize,
  maxFleetSize,
  minCapacity,
  maxCapacity,
  minBuildYear,
  maxBuildYear,
  segmentTypeId,
  operatorTypeId,
  signal = null
) => {
  const data = {
    operatorsCIIRatingServiceRequestV4Obj: {
      askYear: year,
      operatorIds: operatorIds?.length ? operatorIds : null,
      minFleetSize: minFleetSize,
      maxFleetSize: maxFleetSize,
      minCarCapacity: minCapacity,
      maxCarCapacity: maxCapacity,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      minDeadweight: minCapacity,
      maxDeadweight: maxCapacity,
      segmentTypeId: segmentTypeId,
      operatorTypeId: operatorTypeId,
      numOperators: OPERATOR_TOP_EMISSIONS
    }
  };
  return BaseApiRequest(signal, `/Operators/Cii?api-version=1.0`, 'emissions', 'POST', data);
};

export const getVesselCiiRating = (
  startDate,
  endDate,
  vesselIds,
  minCapacity,
  maxCapacity,
  minBuildYear,
  maxBuildYear,
  segmentTypeId,
  operatorTypeId,
  signal = null
) => {
  const isRoRo = segmentTypeId === SEGMENT.RoRo.id;
  const data = {
    vesselsApisRequestV4Obj: {
      startDate: startDate,
      endDate: endDate,
      imos: vesselIds?.length ? vesselIds : null,
      minCarCapacity: isRoRo ? minCapacity : 0,
      maxCarCapacity: isRoRo ? maxCapacity : 0,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      minDeadweight: isRoRo ? 0 : minCapacity,
      maxDeadweight: isRoRo ? 0 : maxCapacity,
      segmentTypeId: segmentTypeId,
      operatorTypeId: 0,
      numVessels: VESSEL_TOP_EMISSIONS
    }
  };
  return BaseApiRequest(signal, `/Vessels/Cii?api-version=4.0`, 'emissions', 'POST', data);
};

export const getVesselsEmission = (
  startDate,
  endDate,
  vesselIds,
  minCapacity,
  maxCapacity,
  minBuildYear,
  maxBuildYear,
  segmentTypeId,
  operatorTypeId,
  numVessels,
  signal = null
) => {
  let isRoRo = segmentTypeId === SEGMENT.RoRo.id;
  const data = {
    vesselsApisRequestV4Obj: {
      startDate: startDate,
      endDate: endDate,
      imos: vesselIds?.length ? vesselIds : null,
      minCarCapacity: minCapacity,
      maxCarCapacity: isRoRo ? maxCapacity : 0,
      minBuildYear: minBuildYear,
      minDeadweight: minCapacity,
      maxDeadweight: isRoRo ? 0 : maxCapacity,
      segmentTypeId: segmentTypeId,
      operatorTypeId: operatorTypeId,
      maxBuildYear: maxBuildYear,
      numVessels: VESSEL_TOP_EMISSIONS
    }
  };
  return BaseApiRequest(signal, `/Vessels/emission?api-version=4.0`, 'emissions', 'POST', data);
};

export const getVesselPercentageCo2Emission = (imo, signal = null) => {
  return BaseApiRequest(signal, `/Vessel/PercentageCo2Change/${imo}`, 'emissions', 'GET');
};

export const getVesselEmissionHistoryDetails = (imo, periodType, signal = null) => {
  const data = {
    imo: imo,
    periodType: periodType
  };
  return BaseApiRequest(
    signal,
    `/Vessel/EmissionHistoryDetails?api-version=4.0`,
    'emissions',
    'POST',
    data
  );
};

export const getVesselYearlySpeedHistogram = (imo, signal = null) => {
  return BaseApiRequest(signal, `/Vessel/YearlySpeedHistogram/${imo}`, 'emissions', 'GET');
};

export const getOperatorPercentageCo2Emission = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/PercentageCo2Change?${query}`, 'emissions', 'GET');
};

export const getOperatorEmissionHistoryDetails = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/EmissionHistoryDetails?${query}`, 'emissions', 'GET');
};
export const getNumVesselsInAgeRangePerQuarter = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(
    signal,
    `/Operator/NumVesselsInAgeRangePerQuarter?${query}`,
    'emissions',
    'GET'
  );
};

export const getAverageMonthlySpeed = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/AverageMonthlySpeed?${query}`, 'emissions', 'GET');
};

export const getMonthlyPercentActivityStatus = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(
    signal,
    `/Operator/MonthlyPercentActivityStatus?${query}`,
    'emissions',
    'GET'
  );
};

export const getOperatorCiiRatingData = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/Cii?${query}`, 'emissions', 'GET');
};

export const getVesselAvgMonthlySpeed = (imo, start, end, version = 2.0, signal = null) => {
  const startDate = moment(start).format('YYYY-MM-DD');
  const endDate = moment(end).format('YYYY-MM-DD');
  return BaseApiRequest(
    signal,
    `/Vessel/AverageMonthlySpeed/${imo}/${startDate}/${endDate}?api-version=${version}`,
    'emissions',
    'GET'
  );
};

export const getVesselMonthlyPercentActivityStatus = (
  signal = null,
  imo,
  start,
  end,
  version = 3.0
) => {
  const startDate = moment(start).format('YYYY-MM-DD');
  const endDate = moment(end).format('YYYY-MM-DD');
  return BaseApiRequest(
    signal,
    `/Vessel/MonthlyPercentActivityStatus/${imo}/${startDate}/${endDate}?api-version=${version}`,
    'emissions',
    'GET'
  );
};
export const getVesselCiiRatingData = (imo, periodType, signal = null) => {
  const data = {
    imo: imo,
    periodType: periodType
  };
  return BaseApiRequest(signal, `/Vessel/Cii`, 'emissions', 'POST', data);
};

export const getGlobalMonthlyPercentActivityStatus = (
  segmentTypeId,
  operatorTypeId,
  version = '4.0',
  signal = null
) => {
  return BaseApiRequest(
    signal,
    `/Segment/MonthlyPercentActivityStatus/${segmentTypeId}/${operatorTypeId}/?api-version=${version}`,
    'emissions',
    'GET'
  );
};

export const getGlobalEmissionIntensity = (
  segmentTypeId,
  operatorTypeId,
  version = '4.0',
  signal = null
) => {
  return BaseApiRequest(
    signal,
    `/Segment/MonthlyEmissionDetails/${segmentTypeId}/${operatorTypeId}/?api-version=${version}`,
    'emissions',
    'GET'
  );
};
