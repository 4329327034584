import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Divider,
  Box
} from '@esgian/esgianui';
import MonthlyPortWaitingTable from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/MonthlyPortWaitingTable';
import WeeklyPortWaitingChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/WeeklyPortWaitingChart';
import MonthlyPortWaitingChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/MonthlyPortWaitingChart';
import moment from 'moment';
import { useSegment } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import MeasureSelectTooltipContent from './MeasureSelectTooltipContent';

function PortWaitingTimeSection({ portCongestionData, loading, portName, assetLevel, canalName }) {
  const [measureSelect, setMeasureSelect] = useState(1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const { isRoRo } = useSegment();
  function getMeasureInfo(measureSelect, assetLevel) {
    switch (measureSelect) {
      case 1:
        return {
          id: 1,
          type: 'average ' + assetLevel + ' waiting time',
          unit: 'Hours'
        };
      case 2:
        return { id: 2, type: 'total ' + assetLevel + ' waiting time', unit: 'Days' };
      case 3:
        return isRoRo
          ? { id: 3, type: 'CEU Capacity in waiting', unit: 'CEU' }
          : { id: 3, type: 'Deadweight in waiting', unit: 'DWT' };
      case 4:
        return { id: 4, type: 'number of anchor events', unit: 'Anchor events' };
      default:
        return { type: 'waiting-time', unit: '' };
    }
  }

  const yearOptions = useMemo(() => {
    if (!portCongestionData) return [];
    const { operatorCongestions } = portCongestionData;
    let years = operatorCongestions.length ? operatorCongestions[0].years : [];
    return years.map(({ year }) => year);
  }, [portCongestionData]);
  return (
    <Paper variant={'outlined'} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item lg={5.5} className={'section-filter'}>
            <Grid item xs={8}>
              <Box display="flex">
                <FormControl variant="outlined" sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="measure-select-label">
                    <TextWithTooltipIcon
                      label="Measure"
                      tooltipText={<MeasureSelectTooltipContent />}
                      labelVariant={'inherit'}
                      labelBold={false}
                      color={'inherit'}
                      tooltipMaxWidth="600px"
                    />
                  </InputLabel>
                  <Select
                    value={measureSelect}
                    labelId="port-waiting-tab-measure-select-label"
                    variant="outlined"
                    label={
                      <TextWithTooltipIcon
                        label={'Measure'}
                        color={'inherit'}
                        labelVariant={'inherit'}
                        labelBold={false}
                        tooltipMaxWidth="600px"
                        tooltipText={<MeasureSelectTooltipContent />}
                      />
                    }
                    onChange={(event) => setMeasureSelect(parseInt(event.target.value))}
                    id="port-waiting-tab-measure-select"
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value={1}>Avg. waiting time</MenuItem>
                    <MenuItem value={2}>Total waiting time</MenuItem>
                    {isRoRo ? (
                      <MenuItem value={3}>CEU Capacity</MenuItem>
                    ) : (
                      <MenuItem value={3}>Deadweight</MenuItem>
                    )}
                    <MenuItem value={4}>No. of anchor events</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <Divider />
          </Grid>
          <WeeklyPortWaitingChart
            portCongestionData={portCongestionData}
            loading={loading}
            selectedMeasure={getMeasureInfo(measureSelect, assetLevel)}
            fileName={`${portName || canalName?.split(' ').join('-')}-weekly-${
              getMeasureInfo(measureSelect, assetLevel)?.type
            }`}
          />
          <MonthlyPortWaitingChart
            portCongestionData={portCongestionData}
            loading={loading}
            selectedMeasure={getMeasureInfo(measureSelect, assetLevel)}
            fileName={`${portName || canalName?.split(' ').join('-')}-monthly-${
              getMeasureInfo(measureSelect, assetLevel)?.type
            }`}
          />
          <MonthlyPortWaitingTable
            portCongestionData={portCongestionData}
            loading={loading}
            yearOptions={yearOptions}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            selectedMeasure={getMeasureInfo(measureSelect, assetLevel)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

PortWaitingTimeSection.propTypes = {
  portCongestionData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  portName: PropTypes.string
};

PortWaitingTimeSection.defaultProps = {
  portCongestionData: [],
  loading: false,
  portName: ''
};

export default PortWaitingTimeSection;
