import React from 'react';
import { ArrowBackIcon, Box, ColorBox, Typography } from '@esgian/esgianui';
import data from '../SimulatedVoyagePage/simulation-result-data.json';
import SimulatedVoyageResultSummary from '../../../components/Sections/VoyageAnalytics/SimulatedVoyageSection/SimulatedVoyageResultSummary';
import SimulatedVoyageRouteMap from '../../../components/Sections/VoyageAnalytics/SimulatedVoyageSection/SimulatedVoyageRouteMap';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import SimulatedVoyageOverview from './SimulatedVoyageOverview';

const SimulatedVoyageProfilePage = () => {
  const themeMode = useSelector(getThemeMode);
  const voyageDetailsDataFromApiCall = data[0];
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
        <Box sx={{ mr: 2 }}>
          <ColorBox onClick={() => {}}>
            <ArrowBackIcon fontSize={'small'} />
          </ColorBox>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant={'h5'}>
            {`Voyage from ${voyageDetailsDataFromApiCall.voyageOverview.origin} to ${voyageDetailsDataFromApiCall.voyageOverview.destination}`}
          </Typography>
          <Typography variant={'body2'}>{`Notes: Testing`}</Typography>
        </Box>
      </Box>
      <SimulatedVoyageResultSummary
        simulationResultData={voyageDetailsDataFromApiCall}
        renderCustomMapSection={(reducedTransitDetails) => {
          return (
            <Box height="400px" display="flex" justifyContent="space-between" gap={2}>
              <Box flex={1}>
                <SimulatedVoyageOverview
                  voyageOverview={voyageDetailsDataFromApiCall.voyageOverview}
                />
              </Box>
              <Box flex={2}>
                <SimulatedVoyageRouteMap
                  key={themeMode}
                  voyageTransitDetails={reducedTransitDetails}
                />
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default SimulatedVoyageProfilePage;
