import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, DateRangeMobi, FilterIcon, Grid, Stack } from '@esgian/esgianui';
import moment from 'moment/moment';
import OperatorCo2EmissionsAdvancedFilters from '@components/Sections/EmissionsAnalytics/OperatorComparisonSection/OperatorCo2EmissionsSection/OperatorCo2EmissionsFilters/OperatorCo2EmissionsAdvancedFilters';
import OperatorAdvancedMultiSelect from '@components/Inputs/OperatorAdvancedSelect';
import { useDynamicLookupQueries, useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat, getOperatorType } from '@store/features';
import {
  getOprCo2EmissionsAdvFilterCount,
  getOprCo2EmissionsFilters
} from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { updateOprCo2EmissionsFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';

function OperatorCo2EmissionsFilters({ fetchDataSwitch, setFetchDataSwitch }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const dispatch = useDispatch();
  const { lookupOperatorsQuery } = useDynamicLookupQueries();
  const co2EmissionsFilters = useSelector(getOprCo2EmissionsFilters);
  const filterCount = useSelector(getOprCo2EmissionsAdvFilterCount);
  const dateFormat = useSelector(getDateFormat);
  const operatorType = useSelector(getOperatorType);
  const { segmentMinDate } = useSegment();
  const { startDate, endDate, selectType, selectedAssets } = co2EmissionsFilters;

  const updateFilterValue = (payload) => {
    dispatch(updateOprCo2EmissionsFilters(payload));
  };
  const filterOperatorType = (lookup, operatorTypeId) => {
    return lookup.filter((data) => data.operatorTypeId === operatorTypeId);
  };
  const selectedOperators =
    operatorType === 0
      ? lookupOperatorsQuery.data
      : filterOperatorType(lookupOperatorsQuery.data, operatorType);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item className={'section-filter'}>
          <Stack direction={'row'} spacing={2}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
            <OperatorAdvancedMultiSelect
              label={'Operators*'}
              selected={selectedAssets}
              autoEnabledText={'Displaying the top 10 operators given the selected period'}
              handleClose={(values) => {
                updateFilterValue({
                  selectType: values.operatorType,
                  selectedAssets: values.selectedOperators
                });
              }}
              loading={lookupOperatorsQuery.isFetching}
              operators={selectedOperators}
              selectType={selectType}
            />
            <Badge sx={{ alignSelf: 'flex-end' }} badgeContent={filterCount} color="error">
              <Button
                sx={{ height: 'min-content', mb: '0.6em' }}
                startIcon={<FilterIcon />}
                variant={'text'}
                onClick={() => setAdvancedOpen(true)}>
                Advanced Filter
              </Button>
            </Badge>
          </Stack>
        </Grid>
        <OperatorCo2EmissionsAdvancedFilters
          fetchDataSwitch={fetchDataSwitch}
          updateFilterValue={updateFilterValue}
          setFetchDataSwitch={setFetchDataSwitch}
          advancedOpen={advancedOpen}
          setAdvancedOpen={setAdvancedOpen}
        />
      </Grid>
    </Grid>
  );
}

OperatorCo2EmissionsFilters.propTypes = {
  fetchDataSwitch: PropTypes.bool.isRequired,
  setFetchDataSwitch: PropTypes.func.isRequired
};

OperatorCo2EmissionsFilters.defaultProps = {};

export default OperatorCo2EmissionsFilters;
