import { OPERATOR_SELECT_TYPES } from '@constants';
import { CO2_EMISSIONS_SLICE_KEYS } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { FLEET_CII_SLICE_KEYS } from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { getStandardValuesFromUri, setSliceToUri } from '@helpers/uriHelpers';

function findMatchingObject(obj, id, type) {
  if (obj.id === id && obj.type === type) {
    return obj;
  } else if (Array.isArray(obj.children)) {
    for (let child of obj.children) {
      const matchingObject = findMatchingObject(child, id, type);
      if (matchingObject) {
        return matchingObject;
      }
    }
  }
  return null;
}
export const getTypeShortCut = (val) => {
  const isNumber = typeof val === 'number';

  if (val === 'owner' || val === 1) {
    return isNumber ? 'owner' : val;
  }
  if (val === 'operator' || val === 2) {
    return isNumber ? 'operator' : 2;
  }
  return isNumber ? 'vessel' : 3;
};

export const getSelectedVesselsFromUri = (selectedVessels, ownerHierarchy) => {
  let result = [];
  selectedVessels.split(',').map((val) => {
    let valType = getTypeShortCut(parseInt(val.charAt(0)));
    for (let obj of ownerHierarchy) {
      if (valType === 'vessel' || valType === 'operator') {
        let found = findMatchingObject(obj, parseInt(val.slice(1)), valType);
        if (found) {
          result.push(found);
          break;
        }
      } else {
        if (parseInt(obj.id) === parseInt(val.slice(1)) || obj.type === valType) {
          result.push(obj);
          break;
        }
      }
    }
  });
  return result;
};

export const initUrlParams = (filters, section, ownerHierarchy, searchParams) => {
  let sliceKeys = section === 1 ? CO2_EMISSIONS_SLICE_KEYS : FLEET_CII_SLICE_KEYS;
  let ext = section === 1 ? 'vslCo2' : 'vslCii';
  let newFilters = { ...filters };
  newFilters = getStandardValuesFromUri(newFilters, sliceKeys, 6, searchParams);

  let selectedVessels = searchParams.get(`${ext}SelectedAssets`);
  let selectType = searchParams.get(`${ext}SelectType`);
  if (selectType === OPERATOR_SELECT_TYPES.MANUAL && selectedVessels) {
    newFilters.selectedAssets = getSelectedVesselsFromUri(selectedVessels, ownerHierarchy);
    newFilters.selectType = selectType;
  }
  return newFilters;
};

export const setUrlParams = (filters, section, searchParams, setSearchParams) => {
  let sliceKeys = section === 1 ? CO2_EMISSIONS_SLICE_KEYS : FLEET_CII_SLICE_KEYS;
  let uriExt = section === 1 ? 'vslCo2' : 'vslCii';
  searchParams = setSliceToUri(filters, sliceKeys, uriExt, searchParams);
  if (filters.selectType === OPERATOR_SELECT_TYPES.MANUAL) {
    searchParams.set(
      `${uriExt}SelectedAssets`,
      filters.selectedAssets?.map(({ id, type }) => `${getTypeShortCut(type)}${id}`).join(',')
    );
  } else {
    searchParams.delete(`${uriExt}SelectedAssets`);
  }
  setSearchParams(searchParams);
};
