import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlineIcon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuIcon
} from '@esgian/esgianui';
import LocationIcon from '../../../../../Icons/LocationIcon';

const ViaPortItem = forwardRef(
  ({ port, onDelete, dragHandleProps = { attributes: {}, listeners: {} }, ...props }, ref) => {
    return (
      <ListItem
        {...props}
        ref={ref}
        secondaryAction={
          <IconButton disabled={!onDelete} onClick={() => onDelete(port.id)}>
            <DeleteOutlineIcon sx={{ fontSize: 18 }} />
          </IconButton>
        }
        disablePadding>
        <ListItemIcon
          {...dragHandleProps.attributes}
          {...dragHandleProps.listeners}
          sx={{ minWidth: 0, mr: 1, cursor: 'grab' }}>
          <MenuIcon fontSize="small" />
        </ListItemIcon>
        <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary={port.portName} />
      </ListItem>
    );
  }
);

ViaPortItem.propTypes = {
  port: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  dragHandleProps: PropTypes.object
};

ViaPortItem.displayName = 'ViaPortItem';

export default ViaPortItem;
