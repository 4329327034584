import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ViaPortItem from './ViaPortItem';

const SortablePortItem = ({ port, onDelete, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: port.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <ViaPortItem
      ref={setNodeRef}
      style={style}
      dragHandleProps={{ attributes, listeners }}
      port={port}
      onDelete={onDelete}
      {...props}
    />
  );
};

SortablePortItem.propTypes = {
  port: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default SortablePortItem;
