import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  DateRangeMobi,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import { useSegment } from '@hooks';
import PropTypes from 'prop-types';
import { getDateFormat, getGeographicalType, getOperatorType } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSelector } from 'react-redux';
import { MAX_NUM_PORT_CALLS, COMMERCIAL_CATEGORY_TYPE } from '@constants';
import moment from 'moment';
import download from 'downloadjs';
import { getLastPortCallsDownload } from '@api/Port';

function DownloadDateRangeModal({
  showModal,
  setShowModal,
  vesselImo,
  geoId,
  name,
  ownerId,
  oprId
}) {
  const params = useParams();
  const { segment } = useSegment();
  const [selectedType, setSelectedType] = useState('Custom download');
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(new Date());
  const { segmentMinDate } = useSegment();
  const dateFormat = useSelector(getDateFormat);
  const operatorType = useSelector(getOperatorType);
  const geoType = useSelector(getGeographicalType);
  const [errorMessages, setErrorMessages] = useState('');

  const onSelectDate = (startDate, endDate) => {
    setStartDate(moment(startDate).format(dateFormat));
    setEndDate(moment(endDate).format(dateFormat));
  };
  const getPortCallCustomRangeData = async (portId, isDownloadAll) => {
    const controller = new AbortController();
    const { signal } = controller;
    setErrorMessages('');
    getLastPortCallsDownload(
      {
        geographicalType: geoType,
        operatorTypeId: operatorType,
        segmentTypeId: segment.id,
        commercialCategoryId: COMMERCIAL_CATEGORY_TYPE.ALL,
        lastPortCallType: '0',
        startDate: isDownloadAll
          ? moment(segmentMinDate).format(dateFormat)
          : moment(startDate).format(dateFormat),
        endDate: isDownloadAll ? moment().format(dateFormat) : moment(endDate).format(dateFormat),
        numberOfPortCalls: MAX_NUM_PORT_CALLS,
        imos: vesselImo ? [vesselImo] : [],
        operators: oprId ? [oprId] : [],
        owners: ownerId ? [ownerId] : [],
        geoIds: geoId ? [geoId] : []
      },
      signal
    )
      .then((result) => {
        if (!result || result.length === 0) {
          setErrorMessages('No data available for the selected date range.');
          return;
        }
        download(
          result,
          isDownloadAll ? `${name}-All-Port-Calls.csv` : `${name}-Port-Calls.csv`,
          'text/csv'
        );
      })
      .catch((error) => {
        console.log('error', error);
        setErrorMessages('No data available for the selected date range. Please try again.');
      });
  };
  return (
    <Paper variant={'outlined'} sx={{ p: 6 }}>
      <Modal
        sx={{ p: 4 }}
        handleClose={() => setShowModal(false)}
        title={'Download setting'}
        open={showModal}
        size={'sm'}>
        <RadioGroup
          aria-labelledby="port-call-download"
          name="port-call-download"
          value={selectedType}
          onChange={({ target }) => setSelectedType(target.value)}>
          <FormControlLabel
            value={'Custom download'}
            control={<Radio />}
            label={<Typography variant={'body2'}>Custom download</Typography>}
          />
          {selectedType === 'Custom download' && (
            <Stack direction={'row'} spacing={2} sx={{ p: 1 }}>
              <Grid item sx={{ minWidth: 'fullWidth' }}>
                <DateRangeMobi
                  sx={getMonthDateRangePickerSx(startDate, endDate)}
                  onClose={({ value }) => {
                    const { start, end } = getStartEndMonth(value, dateFormat);
                    onSelectDate(start, end);
                  }}
                  dateFormat={dateFormat}
                  minStartDate={segmentMinDate}
                  disableFuture
                  startDate={moment(startDate, dateFormat)}
                  endDate={moment(endDate, dateFormat)}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Grid>
            </Stack>
          )}
          <FormControlLabel
            value={'Download all'}
            control={<Radio />}
            label={<Typography variant={'body2'}>Download all</Typography>}
          />
        </RadioGroup>
        {errorMessages && (
          <Typography color="error" sx={{ mt: 2 }}>
            {errorMessages}
          </Typography>
        )}
        <Divider />
        <Grid item sx={{ pl: 2, pt: 2, pr: 2 }} align={'center'}>
          <Button onClick={() => setShowModal(false)} color={'inherit'}>
            <Typography color="primary">Cancel</Typography>
          </Button>
          <Button
            onClick={() =>
              getPortCallCustomRangeData(params.portId, selectedType === 'Download all')
            }
            variant={'contained'}>
            Download
          </Button>
        </Grid>
      </Modal>
    </Paper>
  );
}

DownloadDateRangeModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  vesselImo: PropTypes.number,
  geoId: PropTypes.number,
  name: PropTypes.string,
  ownerId: PropTypes.number,
  oprId: PropTypes.number
};

DownloadDateRangeModal.defaultProps = {
  showModal: false,
  setShowModal: null,
  vesselImo: null,
  geoId: null,
  name: '',
  ownerId: null,
  oprId: null
};

export default DownloadDateRangeModal;
