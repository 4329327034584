import { useQuery } from '@tanstack/react-query';
import { getVesselNextPortCall } from '@api';

export const useNextPortCall = (imo) => {
  const { data: nextPortCall, isPending: isLoadingNextPortCall } = useQuery({
    queryKey: ['vesselNextPortCall', imo],
    queryFn: ({ signal }) => getVesselNextPortCall(signal, imo, 1.0),
    enabled: !!imo
  });

  return {
    nextPortCall,
    isLoadingNextPortCall
  };
};
