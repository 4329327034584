import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { CircularProgress, Stack } from '@esgian/esgianui';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN, SEGMENT } from '@constants';
import MapControllerBar from '@components/Maps/TradeRouteMap/MapControllerBar';
import MenuDisplayWrapper from '@components/Maps/TradeRouteMap/MenuDisplayWrapper';
import { useNavigate } from 'react-router-dom';
import { useSegment, useTheme } from '@hooks';
import { renderToString } from 'react-dom/server';

mapboxgl.accessToken = MAPBOX_API_TOKEN;
const createPortMarkers = (map, portData, theme, markers, navigate, segment) => {
  if (markers?.length) {
    markers.forEach((mark) => mark.remove());
  }
  let newMarkers = [];
  portData?.forEach(({ cpointLat, cpointLon, portName, portId }) => {
    const {
      typography: { fontFamily },
      palette: {
        tooltip: { background, contrastText }
      }
    } = theme;
    const description = renderToString(
      <div
        style={{
          fontFamily: fontFamily,
          color: contrastText,
          display: 'flex',
          flexDirection: 'column',
          background: background,
          padding: '8px'
        }}>
        <span className={'map-tooltip-text'}>{portName}</span>
      </div>
    );

    const markerEl = document.createElement('div');
    markerEl.className = 'pin-port';
    markerEl.style.backgroundImage = "url('/assets/images/marker-port.svg')";
    markerEl.style.width = '2em';
    markerEl.style.height = '2.5em';
    const marker = new mapboxgl.Marker(markerEl).setLngLat([cpointLon, cpointLat]).addTo(map);

    let markerPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    let path = segment.id === SEGMENT.RoRo.id ? 'roro' : 'mpp';

    markerEl.addEventListener('mouseenter', () => {
      let markerLng = marker.getLngLat().lng;
      markerPopup.setLngLat([markerLng, cpointLat]).setHTML(description).addTo(map);
    });
    markerEl.addEventListener('mouseleave', () => markerPopup.remove());

    markerEl.addEventListener('click', () =>
      navigate(`/${path}/commercial-analytics/port/${portId}`)
    );

    newMarkers.push(markerEl);
  });
  return newMarkers;
};

const createTradeRoutePoints = (map, portdata) => {
  const { coordinatesFirstPeriod } = portdata;
  if (map.current.getLayer('trade-route-heat')) {
    map.current.removeLayer('trade-route-heat');
  }
  if (map.current.getSource('aisPoints')) {
    map.current.removeSource('aisPoints');
  }

  let markers = coordinatesFirstPeriod?.map(({ lat, lon }) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lon, lat]
      }
    };
  });
  let data = {
    type: 'FeatureCollection',
    features: markers
  };
  map.current.addSource('aisPoints', {
    type: 'geojson',
    data: data
  });

  map.current.addLayer(
    {
      id: 'trade-route-heat',
      type: 'circle',
      source: 'aisPoints',
      paint: {
        'circle-radius': {
          base: 1.75,
          stops: [
            [12, 1.5],
            [22, 100]
          ]
        },
        'circle-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          'rgba(255,70,30,0.2)',
          5,
          'rgba(255,70,30,0.6)',
          10,
          'rgba(255,70,30,1)'
        ]
      }
    },
    'aeroway-polygon'
  );
};

function TradeRouteMap({ loading, portData, selectedQuarter }) {
  const mapContainer = useRef(null);
  const [selectedPort, setSelectedPort] = useState(null);
  const [newPortsOnly, setNewPortsOnly] = useState(false);
  const [showPortMarkers, setShowPortMarkers] = useState(true);
  const [portMarkersData, setPortMarkersData] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [markers, setMarkers] = useState([]);
  const map = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const navigate = useNavigate();
  const segment = useSegment();
  const { theme } = useTheme();

  useEffect(() => {
    if (map.current) {
      // Remove existing trade route points layer
      map.current.removeLayer('trade-route-heat');
      map.current.removeSource('aisPoints');
    }
    setMapReady(false);
    map.current = new mapboxgl.Map({
      projection: 'mercator',
      height: '70vh',
      container: mapContainer.current,
      style: theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      zoom: 1.25
    });
    map.current.on('load', () => {
      if (map.current) {
        setMapReady(true);
        if (portData) {
          createTradeRoutePoints(map, portData);
        }
      }
    });
    return () => {
      map.current = null;
    };
  }, [theme]);

  useEffect(() => {
    if (map.current && portData && map.current.isStyleLoaded()) {
      createTradeRoutePoints(map, portData);
    }
  }, [map.current, portData, map.current?.isStyleLoaded()]);

  useEffect(() => {
    if (!map.current || !mapReady || !portData) return; // initialize map only once
    const { firstPeriod, portDiff } = portData;
    const data = newPortsOnly
      ? firstPeriod?.filter(({ portId }) => portDiff.includes(portId))
      : firstPeriod;
    setPortMarkersData(data);
    setMarkers(createPortMarkers(map.current, data, theme, markers, navigate, segment));
  }, [newPortsOnly, mapReady, portData]);

  useEffect(() => {
    if (!markers.length) return; // initialize map only once
    markers?.forEach((mark) => {
      mark.style.visibility = showPortMarkers ? 'visible' : 'hidden';
    });
  }, [showPortMarkers]);

  map?.current?.on('zoom', () => {
    if (map.current) {
      markers?.forEach((mark) => {
        let multiplier = map.current.getZoom() > 2.5 ? 2.5 : map.current.getZoom();
        let element = mark;
        if (element) {
          element.style.width = `${12 * multiplier}px`;
          element.style.height = `${17 * multiplier}px`;
        }
      });
    }
  });

  const showMapLoader = useMemo(() => {
    return !mapReady || loading;
  }, [mapReady, loading]);

  const {
    palette: {
      border: { light, dark }
    }
  } = theme;
  return (
    <Stack direction={'row'}>
      <Stack direction={'row'}>
        <MapControllerBar setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} />
        <MenuDisplayWrapper
          loading={loading}
          selectedPort={selectedPort}
          setSelectedPort={setSelectedPort}
          showPortMarkers={showPortMarkers}
          setShowPortMarkers={setShowPortMarkers}
          newPortsOnly={newPortsOnly}
          setNewPortsOnly={setNewPortsOnly}
          map={map.current}
          selectedMenu={selectedMenu}
          mapHeight={'70vh'}
          ports={portMarkersData}
          selectedQuarter={selectedQuarter}
        />
      </Stack>
      <div
        ref={mapContainer}
        style={{
          borderTop: `1px solid ${theme.mode === 'dark' ? dark : light}`,
          borderRadius: '0px 0px 4px 0px',
          height: '70vh',
          width: '82%',
          filter: showMapLoader ? 'blur(2px)' : ''
        }}
        className="trade-route-map-container">
        {showMapLoader && (
          <CircularProgress
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: 'calc(50% - 100px)',
              left: 'calc(50% - 100px)'
            }}
            size={100}
          />
        )}
      </div>
    </Stack>
  );
}

TradeRouteMap.propTypes = {
  portData: PropTypes.object,
  loading: PropTypes.bool,
  selectedQuarter: PropTypes.string.isRequired
};

TradeRouteMap.defaultProps = {
  portData: null,
  loading: false
};

export default TradeRouteMap;
