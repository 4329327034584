// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root > div > main > div {
  margin-bottom: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ShipAnalytics/ShipAnalysisPage/stylingOverride.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":["#root > div > main > div {\n  margin-bottom: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
