import {
  FaqPage,
  OperatorComparisonPage,
  OperatorProfilePage,
  OperatorsOverviewPage,
  OwnerProfilePage,
  OwnersOverviewPage,
  PortProfilePage,
  PortsOverviewPage,
  RegionProfilePage,
  RegionsOverviewPage,
  ShipAnalysisPage,
  VesselComparisonPage,
  VesselProfilePage,
  VesselsOverviewPage
} from '@pages';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { SEGMENT } from '@constants';
import CountryProfilePage from '@pages/ShipAnalytics/CountryProfilePage';
import CountriesOverviewPage from '@pages/ShipAnalytics/CountriesOverviewPage';
import DashboardPage from '@pages/ShipAnalytics/DashboardPage';
import CompletedVoyagePage from '@pages/VoyageAnalytics/CompletedVoyagePage';
import VoyageGroupPage from '@pages/VoyageAnalytics/VoyageGroupPage';
import VoyageProfilePage from '@pages/VoyageAnalytics/CompletedVoyageProfilePage';
import OngoingVoyageProfilePage from './pages/VoyageAnalytics/OngoingVoyageProfilePage/OngoingVoyageProfilePage';
import OngoingVoyagePage from '@pages/VoyageAnalytics/OngoingVoyagePage/OngoingVoyagePage';
import CanalsOverviewPage from '@pages/ShipAnalytics/CanalsOverviewPage';
import CanalProfilePage from '@pages/ShipAnalytics/CanalProfilePage';
import SimulatedVoyagePage from './pages/VoyageAnalytics/SimulatedVoyagePage';
import SimulatedVoyageGroupPage from './pages/VoyageAnalytics/SimulatedVoyageGroupPage';
import SimulatedVoyageProfilePage from './pages/VoyageAnalytics/SimulatedVoyageProfilePage';
import DashboardActivityMapPage from './pages/DashboardActivityMapPage/DashboardActivityMapPage';

export const getRoutes = (user, segment) => {
  if (!user) {
    return [
      {
        isRedirect: true,
        path: '*',
        element: <Navigate to="/login" />
      }
    ];
  }
  let routes = [];
  const {
    profile: { permissionList }
  } = user;

  if (permissionList.includes(SEGMENT.RoRo.value)) {
    routes = RORO_ROUTES;
  }
  if (permissionList.includes(SEGMENT.MPP.value)) {
    routes = [...routes, ...MPP_ROUTES];
  }
  routes.push(
    {
      isRedirect: true,
      path: '/',
      element: (
        <Navigate
          to={segment.id === SEGMENT.RoRo.id ? '/roro/dashboard' : '/mpp/commercial-analytics'}
        />
      )
    },
    {
      isRedirect: true,
      path: '*',
      element: <Navigate to="/" />
    }
  );
  return routes;
};

export const RORO_ROUTES = [
  {
    isRedirect: true,
    path: '/roro/emissions-analytics',
    element: <Navigate to="/roro/emissions-analytics/operator-comparison" />
  },
  {
    path: '/roro/commercial-analytics',
    title: 'roro - SA',
    shipAnalytics: true,
    element: <ShipAnalysisPage />
  },
  {
    path: '/roro/dashboard',
    title: 'roro - Dashboard',
    shipAnalytics: true,
    element: <DashboardPage />
  },
  {
    path: '/roro/commercial-analytics/owners',
    title: 'roro - owners overview',
    element: <OwnersOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/owner/:id',
    title: 'roro - owner profile',
    element: <OwnerProfilePage />
  },
  {
    path: '/roro/commercial-analytics/operators',
    title: 'roro - operators overview',
    element: <OperatorsOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/operator/:id',
    title: 'roro - operator profile',
    element: <OperatorProfilePage />
  },
  {
    path: '/roro/commercial-analytics/vessel/:imo',
    title: 'roro - vessel profile',
    element: <VesselProfilePage />
  },
  {
    path: '/roro/commercial-analytics/vessels',
    title: 'roro - vessels overview',
    element: <VesselsOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/region/:regionId',
    title: 'roro - region profile',
    element: <RegionProfilePage />
  },
  {
    path: '/roro/commercial-analytics/faq',
    title: 'roro - faq',
    element: <FaqPage />
  },
  {
    path: '/roro/commercial-analytics/ports',
    title: 'roro - ports overview',
    element: <PortsOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/port/:portId',
    title: 'roro - port profile',
    element: <PortProfilePage />
  },
  {
    path: '/roro/commercial-analytics/canals',
    title: 'roro - canals overview',
    element: <CanalsOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/canal/:canalId',
    title: 'roro - canals overview',
    element: <CanalProfilePage />
  },
  {
    path: '/roro/commercial-analytics/country/:countryId',
    title: 'roro - country profile',
    element: <CountryProfilePage />
  },
  {
    path: '/roro/commercial-analytics/regions',
    title: 'roro - regions overview',
    element: <RegionsOverviewPage />
  },
  {
    path: '/roro/commercial-analytics/countries',
    title: 'roro - countries overview',
    element: <CountriesOverviewPage />
  },
  {
    path: '/roro/emissions-analytics/operator-comparison',
    title: 'roro - operator comparison',
    element: <OperatorComparisonPage />
  },
  {
    path: '/roro/emissions-analytics/vessel-comparison',
    title: 'roro - vessel comparison',
    element: <VesselComparisonPage />
  },
  {
    path: '/roro/completed-voyages',
    title: 'roro - completed voyage',
    element: <CompletedVoyagePage />
  },
  {
    path: '/roro/completed-voyages/:imo',
    title: 'roro - completed voyages details',
    element: <VoyageProfilePage />
  },
  {
    path: '/roro/ongoing-voyages/:imo',
    title: 'roro - ongoing voyages details',
    element: <OngoingVoyageProfilePage />
  },
  {
    path: '/roro/voyage-group/:voyageGroupId',
    title: 'roro - voyage group',
    element: <VoyageGroupPage />
  },
  {
    path: '/roro/ongoing-voyages',
    title: 'roro - ongoing voyage',
    element: <OngoingVoyagePage />
  },
  {
    path: '/roro/simulated-voyage',
    title: 'roro - simulated voyage',
    element: <SimulatedVoyagePage />
  },
  {
    path: '/roro/simulated-voyage-group/:groupId',
    title: 'roro - simulated voyage group',
    element: <SimulatedVoyageGroupPage />
  },
  {
    path: '/roro/simulated-voyage-summary/:voyageId',
    title: 'roro - simulated voyage summary',
    element: <SimulatedVoyageProfilePage />
  },
  {
    path: '/roro/dashboard-activity-map',
    title: 'roro - dashboard activity map',
    element: <DashboardActivityMapPage />
  }
];

export const MPP_ROUTES = [
  {
    isRedirect: true,
    path: '/mpp/commercial-analytics',
    title: 'mpp - SA ',
    shipAnalytics: true,
    element: <ShipAnalysisPage />
  },
  {
    path: '/mpp/commercial-analytics/operators',
    title: 'mpp - operators overview',
    element: <OperatorsOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/operator/:id',
    title: 'mpp - operator profile',
    element: <OperatorProfilePage />
  },
  {
    path: '/mpp/commercial-analytics/vessels',
    title: 'mpp - vessels overview',
    element: <VesselsOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/vessel/:imo',
    title: 'mpp - vessel profile',
    element: <VesselProfilePage />
  },
  {
    path: '/mpp/commercial-analytics/region/:regionId',
    title: 'mpp - region profile',
    element: <RegionProfilePage />
  },
  {
    path: '/mpp/commercial-analytics/regions',
    element: <RegionsOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/ports',
    title: 'mpp - ports overview',
    element: <PortsOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/port/:portId',
    title: 'mpp - port profile',
    element: <PortProfilePage />
  },
  {
    path: '/mpp/commercial-analytics/canals',
    title: 'mpp - canals overview',
    element: <CanalsOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/canal/:canalId',
    title: 'mpp - canals overview',
    element: <CanalProfilePage />
  },
  {
    path: '/mpp/commercial-analytics/countries',
    element: <CountriesOverviewPage />
  },
  {
    path: '/mpp/commercial-analytics/country/:countryId',
    element: <CountryProfilePage />
  },
  {
    path: '/mpp/emissions-analytics/operator-comparison',
    title: 'mpp - operator comparison',
    element: <OperatorComparisonPage />
  },
  {
    path: '/mpp/emissions-analytics/vessel-comparison',
    title: 'mpp - vessel comparison',
    element: <VesselComparisonPage />
  },
  {
    path: '/mpp/completed-voyages',
    title: 'mpp - completed voyage',
    element: <CompletedVoyagePage />
  },
  {
    path: '/mpp/completed-voyages/:imo',
    title: 'mpp - completed voyages details',
    element: <VoyageProfilePage />
  },
  {
    path: '/mpp/voyage-group/:voyageGroupId',
    title: 'mpp - voyage group',
    element: <VoyageGroupPage />
  },
  {
    path: '/mpp/ongoing-voyages',
    title: 'mpp - ongoing voyage',
    element: <OngoingVoyagePage />
  },
  {
    path: '/mpp/ongoing-voyages/:imo',
    title: 'mpp - ongoing voyages details',
    element: <OngoingVoyageProfilePage />
  },
  {
    path: '/mpp/simulated-voyage',
    title: 'mpp - simulated voyage',
    element: <SimulatedVoyagePage />
  },
  {
    path: '/mpp/simulated-voyage-group/:groupId',
    title: 'mpp - simulated voyage group',
    element: <SimulatedVoyageGroupPage />
  },
  {
    path: '/mpp/simulated-voyage-summary/:voyageId',
    title: 'mpp - simulated voyage summary',
    element: <SimulatedVoyageProfilePage />
  },
  {
    path: '/mpp/dashboard-activity-map',
    title: 'mpp - dashboard activity map',
    element: <DashboardActivityMapPage />
  }
];
