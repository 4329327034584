import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function PortPairServicePerformance({ servicePerformanceData }) {
  const { avg, min, max, score } = servicePerformanceData;
  return (
    <Grid item xs={6}>
      <Paper variant={'outlined'}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              background: ({ palette }) => palette.background.default,
              p: 2,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
            <Typography variant={'body2'} bold color={'text.secondary'}>
              Port Pair Service Performance
            </Typography>
          </Grid>
          <Grid
            item
            container
            sx={{
              p: '16px 8px 8px 16px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
            <Grid item xs={6}>
              <Typography variant={'body2'}>Average sailings per month</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{avg}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              p: '16px 8px 8px 16px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
            <Grid item xs={6}>
              <Typography variant={'body2'}>Minimum frequency per month</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{min}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              p: '16px 8px 8px 16px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
            <Grid item xs={6}>
              <Typography variant={'body2'}>Maximum frequency per month</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{max}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              p: '16px 8px 8px 16px'
            }}>
            <Grid item xs={6}>
              <TextWithTooltipIcon
                label={<Typography variant={'body2'}>Consistency Score</Typography>}
                tooltipText={tooltipConsistencyContents}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{score}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

PortPairServicePerformance.propTypes = {
  servicePerformanceData: PropTypes.object
};

PortPairServicePerformance.defaultProps = { servicePerformanceData: {} };

export default PortPairServicePerformance;
