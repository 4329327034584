import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow, Typography, Box } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, getTableCellRender } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useNumberFormatter } from '@hooks/useNumberFormatter';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function CeuCapacityTable({ handleCellClick, loading, portServiceData, dateList }) {
  const [data, setData] = useState([]);

  //total,avg,min,max,consScore
  const [lowScores, setLowScores] = useState([0, 0, 0, 0, 0]);
  const [highScores, setHighScores] = useState([0, 0, 0, 0, 0]);
  const [columns, setColumns] = useState([]);
  const { valueMultiplier } = useNumberFormatter();
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (portServiceData && dateList) {
      let tempData = [];
      const tempLows = [0, 0, 0, 0, 0];
      const tempHighs = [0, 0, 0, 0, 0];

      const { frequencyResults } = portServiceData;
      frequencyResults?.forEach((operator, i) => {
        let {
          capacityTotal: total,
          capacityMin: min,
          capacityMax: max,
          operatorLongName,
          operatorMonthlyFrequencyResults,
          operatorId,
          capacityStats
        } = operator;
        let { consistencyScore, average } = capacityStats || {};
        total = valueMultiplier(total);
        min = valueMultiplier(min);
        max = valueMultiplier(max);
        average = valueMultiplier(average || 0);

        if (i === 0) {
          tempLows[0] = total;
          tempLows[1] = average;
          tempLows[2] = min;
          tempLows[3] = max;
          tempLows[4] = consistencyScore;
        }
        if (tempHighs[0] < total) {
          tempHighs[0] = total;
        }
        if (tempLows[0] > total) {
          tempLows[0] = total;
        }
        if (tempHighs[1] < average) {
          tempHighs[1] = average;
        }
        if (tempLows[1] > average) {
          tempLows[1] = average;
        }
        if (tempHighs[2] < min) {
          tempHighs[2] = min;
        }
        if (tempLows[2] > min) {
          tempLows[2] = min;
        }

        if (tempHighs[3] < max) {
          tempHighs[3] = max;
        }
        if (tempLows[3] > max) {
          tempLows[3] = max;
        }

        if (tempHighs[4] < consistencyScore) {
          tempHighs[4] = consistencyScore;
        }
        if (tempLows[4] > consistencyScore) {
          tempLows[4] = consistencyScore;
        }
        let obj = {
          consistencyScore: consistencyScore,
          operatorMonthlyFrequencyResults: operatorMonthlyFrequencyResults,
          average: average,
          total: total,
          min: min,
          max: max,
          operatorName: operatorLongName,
          operatorId: operatorId
        };
        dateList?.forEach((monthDate, i) => {
          let monthObj = {};
          monthObj[monthDate.split(' ').join('_')] = valueMultiplier(
            operatorMonthlyFrequencyResults[i]?.capacityTotal
          );
          obj = { ...obj, ...monthObj };
        });
        tempData.push(obj);
      });

      setLowScores(tempLows);
      setHighScores(tempHighs);
      setData(tempData);
    }
  }, [portServiceData, dateList]);

  useEffect(() => {
    if (!portServiceData) {
      return;
    }
    const {
      palette: {
        tables: {
          boxShadow: { insetRight }
        }
      }
    } = theme;
    let columnDef = [
      {
        name: 'operatorName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: insetRight
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          download: false,
          viewColumns: false,
          filter: false
        }
      }
    ];

    const tableEnd = [
      {
        name: 'total',
        label: 'Total',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)',
                textWrap: 'nowrap'
              }
            };
          },
          customHeadLabelRender: () => <div style={{ width: '50px' }}>Total</div>,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              formatNumberReadable(highScores[0]?.toFixed(0)),
              formatNumberReadable(lowScores[0]?.toFixed(0)),
              formatNumberReadable(dataIndex.toFixed(0))
            );
          }
        }
      },
      {
        name: 'average',
        label: 'Avg',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                textWrap: 'nowrap'
              }
            };
          },
          customHeadLabelRender: () => <div style={{ width: '50px' }}>Avg</div>,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              formatNumberReadable(highScores[1]?.toFixed(0)),
              formatNumberReadable(lowScores[1]?.toFixed(0)),
              formatNumberReadable(dataIndex?.toFixed(0))
            );
          }
        }
      },
      {
        name: 'min',
        label: 'Min',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                textWrap: 'nowrap'
              }
            };
          },
          customHeadLabelRender: () => <div style={{ width: '50px' }}>Min</div>,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              formatNumberReadable(highScores[2]?.toFixed(0)),
              formatNumberReadable(lowScores[2]?.toFixed(0)),
              formatNumberReadable(dataIndex?.toFixed(0))
            );
          }
        }
      },
      {
        name: 'max',
        label: 'Max',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                textWrap: 'nowrap'
              }
            };
          },
          customHeadLabelRender: () => <div style={{ width: '50px' }}>Max</div>,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              formatNumberReadable(highScores[3]?.toFixed(0)),
              formatNumberReadable(lowScores[3]?.toFixed(0)),
              formatNumberReadable(dataIndex?.toFixed(0))
            );
          }
        }
      },
      {
        name: 'consistencyScore',
        label: 'Consistency Score',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customHeadLabelRender: () => {
            return (
              <TextWithTooltipIcon
                tooltipText={tooltipConsistencyContents}
                label={'Consistency Score'}
                labelVariant={'body2'}
                variant={'body2'}
              />
            );
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[4], lowScores[4], dataIndex);
          }
        }
      }
    ];
    dateList?.map((value, i) => {
      columnDef.push({
        name: value.split(' ').join('_'),
        label: value,
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                textWrap: 'nowrap'
              }
            };
          },
          setCellHeaderProps: () => {
            let shadow = {};
            if (i === 0) {
              shadow = 'inset 3px 0px 2px -1px rgb(0 0 0 / 20%)';
            } else if (i === dateList.length - 1) {
              shadow = 'inset -3px 0px 2px -1px rgb(0 0 0 / 20%)';
            }
            return {
              style: {
                boxShadow: `${shadow}`
              }
            };
          },

          customBodyRender: (value, rowIndex) => {
            if (!data || !portServiceData) {
              return '--';
            }

            const { columnIndex } = rowIndex;
            const { monthlyFrequencyTotals } = portServiceData;
            const monthlyDetails = monthlyFrequencyTotals[columnIndex - 2];
            const cellValue = value?.toFixed(0);
            let oprId = rowIndex.rowData[1];
            let period = rowIndex.columnIndex - 2;

            return (
              <div
                style={{
                  cursor: value > 0 ? 'pointer' : 'default'
                }}
                onClick={() => {
                  if (value > 0) {
                    handleCellClick(oprId, period);
                  }
                }}>
                {getTableCellRender(
                  formatNumberReadable(valueMultiplier(monthlyDetails?.capacityMax?.toFixed(3))),
                  formatNumberReadable(valueMultiplier(monthlyDetails?.capacityMin?.toFixed(3))),
                  formatNumberReadable(parseFloat(cellValue))
                )}
              </div>
            );
          }
        }
      });
    });
    setColumns([...columnDef, ...tableEnd]);
  }, [dateList, data]);

  const options = {
    customTableBodyFooterRender: () => {
      if (!portServiceData) {
        return;
      }
      let { monthlyFrequencyTotals } = portServiceData;
      let total = 0;
      return (
        <tbody id={'custom-table-footer'}>
          <TableRow
            key={`total-row-footer`}
            sx={{ backgroundColor: ({ palette }) => palette.background.default }}>
            <TableCell sx={{ boxShadow: ({ palette }) => palette.tables.boxShadow.insetRight }}>
              <Typography variant={'caption'} bold>
                Total
              </Typography>
            </TableCell>
            {monthlyFrequencyTotals.map(({ capacityTotal }, i) => {
              total += capacityTotal || 0;
              return (
                <TableCell key={`total-${i}`} sx={{ textWrap: 'nowrap' }}>
                  <Typography variant={'caption'} bold sx={{ p: 1, m: '4px' }}>
                    {formatNumberReadable(valueMultiplier(capacityTotal).toFixed(0)) || '0'}
                  </Typography>
                </TableCell>
              );
            })}
            <TableCell style={{ boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)' }}>
              <Typography variant={'caption'} bold sx={{ p: 0, m: '2px' }}>
                {formatNumberReadable(valueMultiplier(total).toFixed(0))}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'CEU-capacity'
    },
    print: false,
    viewColumns: false,
    pagination: false,
    elevation: 0,
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'total',
      direction: 'desc'
    }
  };

  return (
    <Box>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' },
          MUIDataTableToolbar: {
            styleOverrides: {
              root: {
                minHeight: 0
              }
            }
          }
        }}
        loading={loading}
        mode={theme.mode}
        title={''}
        data={data.length ? data : []}
        columns={columns}
        options={options}
      />
    </Box>
  );
}

CeuCapacityTable.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  handleCellClick: PropTypes.func.isRequired,
  dateList: PropTypes.arrayOf(PropTypes.string)
};

CeuCapacityTable.defaultProps = {
  loading: false,
  portServiceData: null,
  dateList: []
};

export default CeuCapacityTable;
