import React from 'react';
import { Alert, Typography } from '@esgian/esgianui';

function PublicBetaEmissionsDisclaimer() {
  return (
    <Alert severity={'info'}>
      <Typography variant={'body2'}>
        Public Beta– Please note that we are refining the emission estimation model for MPP vessels
        and the values will be recalculated based on the refined model. Users will be notified after
        recalculation.
      </Typography>
    </Alert>
  );
}

PublicBetaEmissionsDisclaimer.propTypes = {};

PublicBetaEmissionsDisclaimer.defaultProps = {};

export default PublicBetaEmissionsDisclaimer;
