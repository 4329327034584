import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { formatNumberReadable, formatToDecimalPlaces } from '@helpers';
import { TextWithTooltipIcon } from '@components';

const OverallVoyageCo2eTable = ({ overallVoyage, isLoading }) => {
  const columns = [
    { name: 'voyageLeg', label: 'Voyage Leg' },
    { name: 'origin', label: 'Origin' },
    { name: 'destination', label: 'Destination' },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      tooltip:
        'CO₂e (kg) Emission from energy production (also referred to as Well to Tank (WTT) Emission)  of the vessel for the selected voyage'
    },
    {
      name: 'operational',
      label: 'Operational',
      tooltip:
        'CO₂e (kg) Emission from vessel operation (also referred to as Tank to Wake ( TTW) Emission)  of the vessel for the selected voyage'
    },
    {
      name: 'overall',
      label: 'Overall',
      tooltip:
        'Sum of CO₂e (kg) Emission from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) of the vessel for the selected voyage'
    }
  ];

  const getSummary = () => {
    if (!overallVoyage) return [];
    return [
      {
        label: (
          <TextWithTooltipIcon
            sx={{ display: 'inline-flex', alignItems: 'center', gap: '0.1em' }}
            label="Total CO₂e (kg)"
            tooltipText={'Total CO₂e emission of the vessel for the selected voyage'}
          />
        ),
        value: formatNumberReadable(
          formatToDecimalPlaces(overallVoyage.totalGhgEmissionOverallKg, 0)
        )
      }
    ];
  };

  const getData = () => {
    if (!overallVoyage) return [];
    const voyageData = overallVoyage.voyageLegEmissionIntensities.map((d) => ({
      voyageLeg: { value: `#${d.voyageLeg}` },
      origin: { value: d.fromPortName },
      destination: { value: d.toPortName },
      energyProduction: {
        value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionEneryProductionKg, 0))
      },
      operational: {
        value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionOperationalKg, 0))
      },
      overall: { value: formatNumberReadable(formatToDecimalPlaces(d.ghgEmissionOverallKg, 0)) }
    }));

    const totalRow = {
      voyageLeg: { value: 'Total' },
      origin: { value: '-' },
      destination: { value: '-' },
      energyProduction: {
        value: formatNumberReadable(
          formatToDecimalPlaces(overallVoyage.totalGhgEmissionEneryProductionKg, 0)
        )
      },
      operational: {
        value: formatNumberReadable(
          formatToDecimalPlaces(overallVoyage.totalGhgEmissionOperationalKg, 0)
        )
      },
      overall: {
        value: formatNumberReadable(
          formatToDecimalPlaces(overallVoyage.totalGhgEmissionOverallKg, 0)
        )
      }
    };

    return [...voyageData, totalRow];
  };

  return (
    <TableWithSummary
      title="Overall voyage"
      columns={columns}
      data={getData()}
      summary={getSummary()}
      isLoading={isLoading}
      id="overall-voyage-co2e-table"
    />
  );
};

OverallVoyageCo2eTable.propTypes = {
  overallVoyage: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyageCo2eTable;
