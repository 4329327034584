import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { VESSEL_MAX_CAPACITY, VESSEL_MAX_CAPACITY_DWT, VESSEL_MIN_BUILD_YEAR } from '@constants';
import moment from 'moment/moment';
import { AdvancedFilterDrawer, FilterSlider, Grid } from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { getVesselCompSection } from '@store/features/filters/SectionSlice/SectionSlice';
import {
  resetVslCo2EmissionsAdvFilters,
  resetVslFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';

function VesselComparisonAdvancedFilters({
  advancedOpen,
  setAdvancedOpen,
  updateFilterValue,
  filters: propFilters
}) {
  const [filters, setFilters] = useState({});
  const { isRoRo, segment } = useSegment();
  const section = useSelector(getVesselCompSection);
  const dispatch = useDispatch();
  const { minCapacity, maxCapacity, minBuildYear, maxBuildYear } = filters;
  const handleClearClick = () => {
    dispatch(
      section === 1 ? resetVslCo2EmissionsAdvFilters(segment) : resetVslFleetCiiFilters(segment)
    );
    setFilters(propFilters);
    setAdvancedOpen(false);
  };

  const handleApplyClick = useCallback(() => {
    updateFilterValue(filters);
    setAdvancedOpen(false);
  }, [filters]);

  const updateTempFilters = useCallback(
    (payload) => {
      setFilters({ ...filters, ...payload });
    },
    [filters]
  );

  useEffect(() => {
    setFilters({ ...propFilters });
  }, [propFilters]);

  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            step={isRoRo ? 100 : 10000}
            marks={[
              { value: 0, label: 0 },
              {
                value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT,
                label: isRoRo
                  ? `${VESSEL_MAX_CAPACITY / 1000}k`
                  : `${VESSEL_MAX_CAPACITY_DWT / 1000}k`
              }
            ]}
            value={[minCapacity, isRoRo ? maxCapacity : VESSEL_MAX_CAPACITY_DWT]}
            max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT}
            min={0}
            title={isRoRo ? 'Vessel capacity (CEU)' : 'Deadweight (DWT)'}
            onChange={(val) => updateTempFilters({ minCapacity: val[0], maxCapacity: val[1] })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            marks={[
              { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
              { value: moment().year(), label: moment().year() }
            ]}
            value={[minBuildYear, maxBuildYear]}
            max={moment().year()}
            min={VESSEL_MIN_BUILD_YEAR}
            title={'Build year'}
            onChange={(val) => updateTempFilters({ minBuildYear: val[0], maxBuildYear: val[1] })}
          />
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

VesselComparisonAdvancedFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  updateFilterValue: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

VesselComparisonAdvancedFilters.defaultProps = {
  advancedOpen: false
};

export default VesselComparisonAdvancedFilters;
