import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box } from '@esgian/esgianui';
import { checkUserHasAccess, getHorizontalBarOptions } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function TransitTimeChart({ loading, performanceData, fileName }) {
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState([]);
  const { theme, customScrollbar } = useTheme();
  const user = useSelector(getUser);

  useEffect(() => {
    if (performanceData) {
      const { operatorPerformance } = performanceData;
      let { transitResult } = operatorPerformance;
      transitResult = transitResult[0];
      let { operatorMonthlyTransitResults, transitStats } = transitResult;
      let { average } = transitStats;

      const categories = [];
      const data = [];
      let vesselCount = 1;
      operatorMonthlyTransitResults.forEach(({ voyageInformation }) => {
        voyageInformation.forEach(({ vesselName, transitTime }) => {
          categories.push(`(#${vesselCount})${vesselName}`);
          data.push(transitTime?.toFixed(1));
          vesselCount++;
        });
      });
      setOptions({
        ...getHorizontalBarOptions(theme, categories),
        ...{
          tooltip: {
            y: {
              title: {
                formatter: () => 'Transit time(days): '
              }
            }
          },
          chart: {
            background: 'transparent',
            toolbar: {
              tools: {
                download: checkUserHasAccess(user, true)
              },
              export: {
                csv: {
                  filename: fileName
                },
                svg: {
                  filename: fileName
                },
                png: {
                  filename: fileName
                }
              }
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          annotations: {
            position: 'front',
            xaxis: [
              {
                x: average,
                strokeDashArray: 5,
                borderColor: '#5db1f0',
                label: {
                  position: 'right',
                  textAnchor: 'middle',
                  borderColor: '#5db1f0',
                  style: {
                    color: '#fff',
                    background: '#5db1f0'
                  },
                  text: `Average(${average?.toFixed(1)})`
                }
              }
            ]
          }
        }
      });
      setSeries([{ data: data }]);
    }
  }, [theme, performanceData]);

  const getHeight = () => {
    if (series[0]) {
      if (series[0].data.length < 30) {
        return undefined;
      } else {
        return series[0].data.length * 20.618;
      }
    }
    return undefined;
  };
  return (
    <Box
      sx={{
        maxHeight: '50vh',
        display: 'block',
        overflow: loading ? '' : 'auto',
        ...customScrollbar
      }}>
      <ApexChart
        width={'98%'}
        loading={loading}
        height={getHeight()}
        options={options || {}}
        type={'bar'}
        data={series}
        fileName={fileName}
      />
    </Box>
  );
}

TransitTimeChart.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired
};

TransitTimeChart.defaultProps = {
  performanceData: null,
  loading: false
};

export default TransitTimeChart;
