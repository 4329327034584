import React from 'react';
import { Grid, List, ListItem, Typography } from '@esgian/esgianui';
import sidebarMenu from '@assets/images/faq/sidebar-menu.png';
import hyperLink from '@assets/images/faq/hyper-link.png';
import tableFunctions from '@assets/images/faq/table-functions.png';
import tablePagination from '@assets/images/faq/table-pagination.png';
import vesselProfile from '@assets/images/faq/vessel-profile.png';
import ownerOperatorProfile from '@assets/images/faq/owner-operator-profile.png';

function VesselOperatorOwner() {
  return (
    <Grid item xs={12} id={'profiles'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>4. Vessel, Operator, Owner Overview</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item sx={{ pr: 2, textAlign: 'center' }}>
              <img srcSet={sidebarMenu} src={sidebarMenu} />
              <Typography variant={'body2'} color={'text.secondary'}>
                Expanded Sidebar
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom>
                From the sidebar menu, you can access a list of vessels, operators and owners. Each
                page shows an overview of the data we have registered on each. You can click on a
                vessel or company name to get to the respective profile of each entity.{' '}
              </Typography>
              <Typography gutterBottom>
                Anywhere there is a hyperlink (blue text) in the solution you will be guided to
                either a vessel or company profile page.
              </Typography>
              <img srcSet={hyperLink} src={hyperLink} />
              <Typography variant={'body2'} color={'text.secondary'}>
                Hyperlink Example
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            All tables can be filtered ascending or descending based on the column of your choosing.
            Just click on the column to adjust the sorting.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={8}>
              <Grid container direction={'column'}>
                <Grid item>
                  <Typography component={'span'} bold>
                    Search:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    Click for free text search in the table
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={'span'} bold>
                    Download:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    Click and choose the file format to download the table data
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={'span'} bold>
                    Filter:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    Click to apply a filter to any column in the table
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <img srcSet={tableFunctions} src={tableFunctions} />
              <Typography variant={'body2'} color={'text.secondary'}>
                Table functions
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Large tables will by default have a limited number of records in the main view. In the
            bottom right corner of such tables you can expand the number of rows and flip pages in
            the table:
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <img srcSet={tablePagination} src={tablePagination} />
          <Typography variant={'body2'} color={'text.secondary'}>
            Table page navigation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant={'h6'} id={'vessel-profile'}>
            4.1 Vessel Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Whenever you see that a vessel name is formatted with a blue font it can be clicked to
            show the Vessel Profile.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>The vessel profile currently includes:</Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              pt: 0,
              pl: 2,
              listStyleType: 'disc',
              '& .MuiListItem-root': {
                display: 'list-item'
              }
            }}>
            <ListItem sx={{ pl: 1 }}>Vessel Details</ListItem>
            <ListItem sx={{ pl: 1 }}>Vessels latest position</ListItem>
            <ListItem sx={{ pl: 1 }}>Operator History</ListItem>
            <ListItem sx={{ pl: 1 }}>Owner History</ListItem>
            <ListItem sx={{ pl: 1 }}>Port Calls (coming soon)</ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <img srcSet={vesselProfile} src={vesselProfile} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant={'h6'} id={'owner-operator-profile'}>
            4.2 Owner/Operator Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Whenever you see that a company name (Owner/Operator) is formatted with a blue font it
            can be clicked to show the Company Profile.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>The Company Profile currently includes:</Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              pt: 0,
              pl: 2,
              listStyleType: 'disc',
              '& .MuiListItem-root': {
                display: 'list-item'
              }
            }}>
            <ListItem sx={{ pl: 1 }}>Fleet Statistics</ListItem>
            <ListItem sx={{ pl: 1 }}>Company Details</ListItem>
            <ListItem sx={{ pl: 1 }}>Latest Vessel Positions</ListItem>
            <ListItem sx={{ pl: 1 }}>Owner History</ListItem>
            <ListItem sx={{ pl: 1 }}>Fleet List with Vessel Details</ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <img srcSet={ownerOperatorProfile} src={ownerOperatorProfile} width={'100%'} />
        </Grid>
      </Grid>
    </Grid>
  );
}

VesselOperatorOwner.propTypes = {};

VesselOperatorOwner.defaultProps = {};

export default VesselOperatorOwner;
