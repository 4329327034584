import React from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, CountryFlag, Link, Stack, ToolTip, Typography } from '@esgian/esgianui';
import { useSegment } from '@hooks';
import { useNavigate } from 'react-router-dom';
function ProfileLink({
  id,
  name,
  profile,
  linkVariant,
  disabled,
  countryCode,
  uriParams,
  showArrow,
  vesselOldNames,
  textAlign = 'left',
  textColor,
  fontSize
}) {
  const { uriExt } = useSegment();
  const navigate = useNavigate();

  if (disabled) {
    return (
      <Typography
        variant={linkVariant}
        color={textColor ?? 'text.secondary'}
        sx={{ ...(fontSize && { fontSize }) }}>
        {name}
      </Typography>
    );
  }
  return (
    <ToolTip
      placement={'right'}
      title={
        vesselOldNames
          ? vesselOldNames.toUpperCase()
          : `Go to ${profile} profile for ${profile === 'vessel' ? 'imo' : 'id'} ${id}`
      }>
      <div style={{ display: ' inline-flex' }}>
        <Stack direction={'row'} spacing={1}>
          {profile === 'country' && countryCode && (
            <CountryFlag size={15} countryCode={countryCode} />
          )}

          <Link
            underline="hover"
            variant={linkVariant}
            href={`${uriExt}/commercial-analytics/${profile}/${id}?${uriParams}`}
            onClick={(e) => {
              navigate(`${uriExt}/commercial-analytics/${profile}/${id}?${uriParams}`);
              e.preventDefault();
            }}
            sx={{
              textAlign,
              cursor: 'pointer',
              color: textColor,
              ...(fontSize && { fontSize })
            }}>
            {name}
            {showArrow && <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />}
          </Link>
        </Stack>
      </div>
    </ToolTip>
  );
}

ProfileLink.propTypes = {
  id: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  uriParams: PropTypes.string,
  profile: PropTypes.oneOf([
    'region',
    'vessel',
    'operator',
    'owner',
    'port',
    'country',
    'voyage',
    'canal'
  ]).isRequired,
  linkVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  disabled: PropTypes.bool,
  countryCode: PropTypes.string,
  showArrow: PropTypes.bool,
  vesselOldNames: PropTypes.any,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string
};

ProfileLink.defaultProps = {
  id: null,
  name: null,
  countryCode: null,
  linkVariant: 'caption',
  uriParams: '',
  disabled: false,
  showArrow: false,
  vesselOldNames: null
};

export default ProfileLink;
