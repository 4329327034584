import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';

import { APP_NAME } from '@constants';
import { GeneralSlice, AuthSlice } from '@store/features';

import FiltersReducer from '@store/features/filters/FiltersReducer';

// Create the middleware instance and methods

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.

// create event listener for when the segment changes

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    general: GeneralSlice,
    filters: FiltersReducer
  }
});

store.subscribe(
  throttle(() => {
    const state = store.getState();
    const serializedState = JSON.stringify({
      userId: state.auth?.user?.id ?? null,
      browserId: state.auth?.browserId ?? null,
      general: state.general,
      filters: state.filters,
      isRedux: true
    });
    if (state.general.init) {
      sessionStorage.setItem(APP_NAME, serializedState);
    }
  }, 1000)
);
