import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getLineChartOptions = (user, categories, theme, fileName) => {
  return {
    chart: {
      height: 350,
      type: 'line',
      background: theme.palette.charts.background,
      zoom: {
        enabled: false
      },
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: ['#82e2cb'],

    dataLabels: {
      enabled: true
    },
    theme: {
      mode: theme.mode
    },

    stroke: {
      width: [1],
      curve: 'smooth'
    },

    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: categories
    },
    tooltip: {
      y: {
        title: {
          formatter: () => 'Spread: '
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.45,
        gradientToColors: ['#94e59f'],
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0.2,
        stops: [0, 100]
      }
    }
  };
};

function SpreadAnalysisChart({ loading, performanceData, fileName }) {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData) {
      return;
    }
    const { operatorPerformance } = performanceData;
    let { transitResult } = operatorPerformance;
    transitResult = transitResult[0];
    let { operatorMonthlyTransitResults } = transitResult;

    const categories = [];
    const data = [];
    operatorMonthlyTransitResults.forEach(({ voyageInformation }) => {
      voyageInformation.forEach(({ startDate, vesselSpread }) => {
        categories.push(moment(startDate).format('DD/MM/yyyy'));
        data.push(vesselSpread || 0);
      });
    });
    setSeries([{ data: data }]);
    setOptions(getLineChartOptions(user, categories, theme, fileName));
  }, [performanceData, theme]);

  return <ApexChart options={options} type={'area'} loading={loading} data={series} height={480} />;
}

SpreadAnalysisChart.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired
};

SpreadAnalysisChart.defaultProps = {
  performanceData: null,
  loading: false
};

export default SpreadAnalysisChart;
