import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InfoBox, ShipsIcon, StorageIcon, TrendingUpIcon } from '@esgian/esgianui';
import { OwnersTable } from '@components/Tables';

function OwnerOverviewSection({ ownerData, loading }) {
  const { owners, fleetSize, averageAge, averageCapacity, totalCapacity } = ownerData || {};
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'owner-overview-fleet-size'}>
        <InfoBox
          loading={loading}
          mainValue={fleetSize ? fleetSize : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'owner-overview-fleet-size-label'}
          mainValueId={'owner-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'owner-overview-avg-age'}>
        <InfoBox
          loading={loading}
          mainValue={averageAge ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'owner-overview-avg-age-label'}
          mainValueId={'owner-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'owner-overview-avg-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'CEU'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'owner-overview-avg-capacity-label'}
          mainValueId={'owner-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'owner-overview-total-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={totalCapacity ? totalCapacity : ''}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'CEU'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'owner-overview-total-capacity-label'}
          mainValueId={'owner-overview-total-capacity-value'}
        />
      </Grid>
      <Grid item xs={12}>
        <OwnersTable owners={owners} loading={loading} />
      </Grid>
    </Grid>
  );
}

OwnerOverviewSection.propTypes = {
  ownerData: PropTypes.object,
  loading: PropTypes.bool
};

OwnerOverviewSection.defaultProps = {
  ownerData: null,
  loading: false
};

export default OwnerOverviewSection;
