import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  PageHeader,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography
} from '@esgian/esgianui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  PortServiceLevelSection,
  OperatorPerformanceSection,
  ActivityMapSection
} from '@components/Sections/ShipAnalytics';
import { cleanUpCurrentParams } from '@helpers';
import { useSegment } from '@hooks';
import { PortActivitySection, DashboardActivityMapSection } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getComAnalyticsSection,
  setComAnalyticsSection
} from '@store/features/filters/CommercialAnalyticsPage/CommercialAnalyticsReducer';
import './stylingOverride.css';

const MAP_VIEW_MODE_KEY = 'mapViewMode';

function ShipAnalysisPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [init, setInit] = useState(false);
  const section = useSelector(getComAnalyticsSection);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRoRo } = useSegment();

  const isMapFullScreen = searchParams.get(MAP_VIEW_MODE_KEY) === 'fullscreen';

  //removing whitespace for Commercial Analytics Breadcrumb

  const routeChange = useCallback((path) => {
    navigate(path);
  }, []);

  const handleSectionChange = (_, val) => {
    const params = cleanUpCurrentParams(searchParams);
    params.set('section', val);
    setSearchParams(params);
    dispatch(setComAnalyticsSection(val));
  };

  useEffect(() => {
    if (searchParams.has('section')) {
      dispatch(setComAnalyticsSection(parseInt(searchParams.get('section'))));
    } else {
      searchParams.set('section', section);
      setSearchParams(searchParams);
    }
    setInit(true);
  }, []);

  const { sectionName, sectionShortName } = useMemo(() => {
    let sectionName = 'Activity Map';
    if (section === 2) {
      sectionName = 'Port Activity';
    } else if (section === 3) {
      sectionName = 'Service Level';
    } else if (section === 4) {
      sectionName = 'Operator Performance';
    } else if (section === 5) {
      sectionName = 'Port paring analysis';
    }
    const sectionShortName = sectionName.replace(/\s/g, '-').toLowerCase();

    return { sectionName: sectionName, sectionShortName: sectionShortName };
  }, [section]);

  const toggleMapFullScreen = () => {
    setSearchParams((prev) => {
      const isAlreadyFullScreen = prev.get(MAP_VIEW_MODE_KEY);
      if (isAlreadyFullScreen) {
        prev.delete(MAP_VIEW_MODE_KEY);
      } else {
        prev.set(MAP_VIEW_MODE_KEY, 'fullscreen');
      }
      return prev;
    });
  };

  const renderedDashboardActivityMap = (
    <DashboardActivityMapSection
      isCompactView={!isMapFullScreen}
      onClickFullscreen={toggleMapFullScreen}
    />
  );

  if (isMapFullScreen) {
    return (
      <Box height="98vh" width="100%">
        {renderedDashboardActivityMap}
      </Box>
    );
  }

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item xs={12}>
        <PageHeader
          title={'Commercial Analytics'}
          id={'lbl-commercial-analytics'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                sx={{ cursor: 'pointer' }}
                id="home-breadcrumb"
                underline="hover"
                color="inherit"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                id="ship-analysis-breadcrumb"
                color="inherit"
                onClick={() => {
                  dispatch(setComAnalyticsSection(1));
                  routeChange('/roro/commercial-analytics');
                }}>
                Commercial Analytics
              </Link>
              <Typography id={sectionShortName} color="primary">
                {sectionName}
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Tabs onChange={handleSectionChange} value={section || 1}>
            <Tab value={1} id="activity-map-tab" label={'Activity Map'} />
            <Tab value={2} id="port-activity-tab" label={'Port Activity'} />
            <Tab value={3} id="service-level-tab" label={'Service Level'} />
            {isRoRo && (
              <Tab value={4} id="operator-performance-tab" label={'Operator Performance'} />
            )}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Slide direction="right" in={section === 1 && init} mountOnEnter unmountOnExit>
          <div>
            <ActivityMapSection renderDashboardActivityMap={() => renderedDashboardActivityMap} />
          </div>
        </Slide>
        <Slide direction="right" in={section === 2 && init} mountOnEnter unmountOnExit>
          <div>
            <PortActivitySection />
          </div>
        </Slide>
        <Slide direction="right" in={section === 3 && init} mountOnEnter unmountOnExit>
          <div>
            <PortServiceLevelSection />
          </div>
        </Slide>
        <Slide direction="right" in={section === 4 && init} mountOnEnter unmountOnExit>
          <div>
            <OperatorPerformanceSection />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
}

ShipAnalysisPage.propTypes = {};

export default ShipAnalysisPage;
