import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@esgian/esgianui';
import { useTheme } from '@hooks';

export const FuelTypesByName = {
  Marine_Diesel_Oil: { id: 1, name: 'Marine Diesel Oil (MDO)' },
  Heavy_Fuel_Oil: { id: 2, name: 'Heavy Fuel Oil (HFO)' },
  Light_Fuel_Oil: { id: 3, name: 'Light Fuel Oil (LFO)' },
  LNG_medium_speed: { id: 4, name: 'Liquefied Natural Gas (LNG) medium speed' },
  LNG_slow_speed: { id: 5, name: 'Liquefied Natural Gas (LNG) slow speed' },
  LPG_butane: { id: 6, name: 'Liquefied Petroleum Gas (LPG) butane' },
  LPG_propane: { id: 7, name: 'Liquefied Petroleum Gas (LPG) propane' },
  Methanol: { id: 8, name: 'Methanol' },
  Ethanol: { id: 9, name: 'Ethanol' },
  Electric: { id: 10, name: 'Electric' },
  Marine_Gas_Oil_Rigs: { id: 11, name: 'Marine Gas Oil - Rigs' },
  Marine_Diesel_Oil_Rigs: { id: 12, name: 'Marine Diesel Oil - Rigs' },
  Bio_Diesel: { id: 13, name: 'Bio Diesel (50% rapeseed, 40% used cooking oil, 10% soyabean)' }
};

const fuelTypes = Object.values(FuelTypesByName);

function FuelSelect({
  label = 'Fuel type',
  placeholder = 'Select fuel type',
  selectedFuel = {},
  handleChange,
  id = 'fuel-select',
  disablePortal = true
}) {
  const { customScrollbar } = useTheme();

  return (
    <Autocomplete
      id={id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fullWidth
      onChange={(_, fuel) => handleChange(fuel)}
      disableClearable
      ListboxProps={{ sx: { ...customScrollbar } }}
      disablePortal={disablePortal}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      getOptionLabel={(option) => option.name}
      value={selectedFuel}
      options={fuelTypes}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ minWidth: '200px' }}
          placeholder={placeholder}
          fullWidth
          {...params}
          label={label}
        />
      )}
    />
  );
}

FuelSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectedFuel: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  disablePortal: PropTypes.bool
};

export default FuelSelect;
