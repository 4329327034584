import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  VesselTransitTimeAndSpread,
  VoyagePerformanceAnalysis
} from '@components/Sections/ShipAnalytics/OperatorPerformanceSection';
import { Alert, Grid, Paper, Typography } from '@esgian/esgianui';

function TransitTimeAndSpreadOverview({ dateRangeList, performanceData, vesselsEmissions }) {
  const [operatorName, setOperatorName] = useState('');

  const voyagePerformanceData = useMemo(() => {
    if (!performanceData) return [];
    let tempData = [];
    const { operatorPerformance } = performanceData;
    let { transitResult } = operatorPerformance;
    transitResult = transitResult[0];
    const { transitMin, transitMax, transitStats, vesselSpreadAnalysis, operatorShortName } =
      transitResult;
    setOperatorName(operatorShortName);
    const { average, consistencyScore } = transitStats;
    tempData.push({
      title: 'Transit Time Analysis',
      min: transitMin,
      max: transitMax,
      avg: average,
      score: consistencyScore,
      name: operatorShortName
    });
    const { max, min, operatorStats } = vesselSpreadAnalysis;
    const { average: spreadAvg, consistencyScore: spreadScore } = operatorStats;
    tempData.push({
      title: 'Vessel Spread Analysis',
      min: min,
      max: max,
      avg: spreadAvg,
      score: spreadScore
    });
    return tempData;
  }, [performanceData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Paper>
          <Grid container direction={'column'} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Service Level - Transit Time & Spread</Typography>
            </Grid>
            {!vesselsEmissions?.length && (
              <Grid item xs={12}>
                <Alert severity={'info'}>
                  Voyage emissions are being calculated, please allow some time for this...
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} sx={{ maxWidth: '100% !important' }} id={'test'}>
              <VesselTransitTimeAndSpread
                includeSpread
                dateRangeList={dateRangeList}
                performanceData={performanceData}
                vesselsEmissions={vesselsEmissions}
                operatorShortName={operatorName}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Voyage Performance Analysis</Typography>
          </Grid>
          <VoyagePerformanceAnalysis voyagePerformanceData={voyagePerformanceData} />
        </Grid>
      </Grid>
    </Grid>
  );
}

TransitTimeAndSpreadOverview.propTypes = {
  performanceData: PropTypes.object,
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  vesselsEmissions: PropTypes.arrayOf(PropTypes.object)
};

TransitTimeAndSpreadOverview.defaultProps = {
  vesselsEmissions: null,
  performanceData: null,
  dateRangeList: []
};

export default TransitTimeAndSpreadOverview;
