import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryConstants';
import { toast } from 'react-toastify';
import { getVoyagesDetails } from '../../../api/Voyage';

const VoyageDetailsQueryKey = ['voyageDetails'];

const getVoyageDetailsQueryKey = (imo, fromPortId, toPortId, startDate, endDate) =>
  `${VoyageDetailsQueryKey}~${imo}~${fromPortId}~${toPortId}~${startDate}~${endDate}`;

export const uesGetVoyageDetails = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const controller = new AbortController();
  const { signal } = controller;

  const imo = params.imo;
  const fromPortId = searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID);
  const toPortId = searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID);
  const startDate = searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE);
  const endDate =
    searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE) !== 'null'
      ? searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE)
      : null;

  const filterParams = {
    imo
  };
  if (fromPortId) filterParams['fromPortId'] = parseInt(fromPortId);
  if (toPortId) filterParams['toPortId'] = parseInt(toPortId);
  if (startDate) filterParams['startDate'] = startDate;
  if (endDate) filterParams['endDate'] = endDate;

  const {
    data: voyageDetails,
    isPending: isLoadingVoyageDetails,
    isFetching: isFetchingVoyageDetails
  } = useQuery({
    queryKey: getVoyageDetailsQueryKey(imo, fromPortId, toPortId, startDate, endDate),
    queryFn: async () => {
      try {
        const [data] = await getVoyagesDetails([filterParams], signal);
        return data;
      } catch (error) {
        toast.error('Unable to fetch voyage data.', {
          position: 'top-right'
        });
      }
    }
  });

  return { voyageDetails, isLoadingVoyageDetails, isFetchingVoyageDetails };
};
