import { useCallback } from 'react';

export const useNumberFormatter = () => {
  const valueMultiplier = useCallback((value, multiplier = 1000) => {
    if (isNaN(value)) {
      return undefined;
    }
    return value * multiplier;
  }, []);

  return { valueMultiplier };
};
