import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getSegment, getUser, isUserLoading } from '@store/features';

function PrivateRoute({ children }) {
  const loadingUser = useSelector(isUserLoading);
  const user = useSelector(getUser);
  const segment = useSelector(getSegment);

  if ((loadingUser && !user) || !segment) {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 112px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress size={'5em'} />
      </div>
    );
  }
  if (checkUserHasAccess(user, false, segment)) {
    return children;
  }
  return <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  loadingUser: PropTypes.bool
};

PrivateRoute.defaultProps = {
  loadingUser: true
};

export default PrivateRoute;
