import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import RegionsOverviewSection from '@components/Sections/ShipAnalytics/RegionsOverviewSection';

function RegionsOverviewPage() {
  const navigate = useNavigate();
  const routeChange = useCallback((path) => {
    navigate(path);
  }, []);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          title={'Regions Overview'}
          id={'lbl-region-overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="gps-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange('/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="Regions-overview">
                Regions Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        <RegionsOverviewSection />
      </Grid>
    </Grid>
  );
}

RegionsOverviewPage.propTypes = {};

RegionsOverviewPage.defaultProps = {};

export default RegionsOverviewPage;
