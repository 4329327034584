import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@esgian/esgianui';
import moment from 'moment';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal/UnderlyingCanalStatsModal';
import { getDirection } from '@helpers';
import { useTheme } from '@hooks/useTheme';

function CanalTransitsTable({
  startDate = null,
  endDate = null,
  loading = false,
  canalTransits = null,
  timeFrame = 'weekly',
  fileName = ''
}) {
  const user = useSelector(getUser);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const { customScrollbar, theme } = useTheme();

  const weeklyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let startDateClone = startDate.clone();
    let endDateClone = endDate.clone();
    let dataSet = [];
    while (
      startDateClone &&
      endDateClone &&
      startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
    ) {
      let weekNumber = startDateClone.isoWeek();
      let yearNumber = startDateClone.isoWeekYear();
      if (startDateClone.isoWeekYear() < startDateClone.year()) {
        startDateClone.add(1, 'isoWeek');
        continue;
      }
      let key = `${yearNumber}_${weekNumber}`;
      dataSet.push({
        year: yearNumber,
        week: weekNumber,
        value: 0,
        id: key,
        modalData: {}
      });
      startDateClone.add(1, 'isoWeek');
    }

    canalTransits?.forEach(
      ({
        canalArrival,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit,
        headingDirection,
        operatorId
      }) => {
        let arrival = moment(canalArrival);

        let weekNumber = arrival.clone().isoWeek();
        let yearNumber = arrival.clone().isoWeekYear();
        if (!dataSet.find(({ year }) => year === yearNumber)) {
          if (dataSet.find(({ year }) => year === yearNumber + 1)) {
            yearNumber++;
            weekNumber = 1;
          } else {
            yearNumber--;
            weekNumber = 52;
          }
        }
        let key = `${yearNumber}_${weekNumber}`;
        let currentVal = dataSet.find(({ id }) => id === key);
        currentVal.value++;

        if (!currentVal.modalData[`${imo}_${canalArrival}`]) {
          currentVal.modalData[`${imo}_${canalArrival}`] = {
            vesselName: vesselName,
            imo: imo,
            operatorLongName: operatorLongName,
            canalArrival: canalArrival,
            canalDeparture: canalDeparture,
            transit: moment.duration(transit).asHours().toFixed(2),
            headingDirection: getDirection(headingDirection),
            year: yearNumber,
            period: weekNumber,
            operatorId: operatorId
          };
        }
      }
    );

    return dataSet;
  }, [canalTransits]);

  const monthlyTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let start = startDate.clone();
    let end = endDate.clone();
    let dataSet = [];
    while (start && end && start.isSameOrBefore(end)) {
      let monthNumber = start.month();
      let yearNumber = start.year();
      let key = `${yearNumber}_${monthNumber}`;
      dataSet.push({
        year: yearNumber,
        month: monthNumber,
        value: 0,
        id: key,
        modalData: {}
      });
      start.add(1, 'month');
    }
    canalTransits?.forEach(
      ({
        canalArrival,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit,
        headingDirection,
        operatorId
      }) => {
        let arrival = moment.utc(canalArrival);
        let monthNumber = moment.utc(arrival.clone()).month();
        let yearNumber = arrival.clone().year();
        let key = `${yearNumber}_${monthNumber}`;

        let currentVal = dataSet.find(({ id }) => id === key);
        currentVal.value++;

        if (!currentVal.modalData[`${imo}_${canalArrival}`]) {
          currentVal.modalData[`${imo}_${canalArrival}`] = {
            vesselName: vesselName,
            imo: imo,
            operatorLongName: operatorLongName,
            canalArrival: canalArrival,
            canalDeparture: canalDeparture,
            transit: moment.duration(transit).asHours().toFixed(2),
            headingDirection: getDirection(headingDirection),
            year: yearNumber,
            period: moment().month(monthNumber).format('MMM'),
            operatorId: operatorId
          };
        }
      }
    );
    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  if (timeFrame === 'weekly') {
    weeklyTableData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  } else {
    monthlyTableData.map(({ year }) => {
      if (!yearGroups.find(({ mentionedYear }) => mentionedYear === year)) {
        yearGroups.push({
          mentionedYear: year,
          yearCount: 1
        });
      } else {
        yearGroups.find(({ mentionedYear }) => mentionedYear === year).yearCount++;
      }
    });
  }

  let timeColumns = [];

  let timeValues = [];

  if (timeFrame === 'weekly') {
    timeValues = weeklyTableData.map(({ value, id, modalData }) => ({ value, id, modalData }));
  } else {
    timeValues = monthlyTableData.map(({ value, id, modalData }) => ({ value, id, modalData }));
  }

  let total = {
    value: 0,
    modalData: {}
  };

  weeklyTableData.forEach(({ value, modalData }) => {
    total.value += value;
    total.modalData = { ...total.modalData, ...modalData };
  });

  const weeklyColumns = useMemo(() => {
    if (!weeklyTableData.length) return;

    return weeklyTableData.map(({ id, week, value }) => {
      return {
        name: id,
        label: 'Week ' + week,
        options: {
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: () => {
            return value || 0;
          }
        }
      };
    });
  }, [weeklyTableData]);

  const monthlyColumns = useMemo(() => {
    if (!monthlyTableData.length) return;

    return monthlyTableData.map(({ id, month, year, value }) => {
      return {
        name: id,
        label: moment().month(month).format('MMM') + ' ' + year,
        options: {
          sort: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: () => {
            return value || 0;
          }
        }
      };
    });
  }, [monthlyTableData]);
  if (timeFrame === 'weekly' && weeklyColumns) {
    timeColumns = weeklyColumns;
  } else if (monthlyColumns) {
    timeColumns = monthlyColumns;
  }

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    elevation: 0,
    downloadOptions: {
      filename: fileName
    },
    setTableProps: () => ({
      id: timeFrame === 'weekly' ? 'weekly-canal-transits-table' : 'monthly-canal-transits-table'
    }),
    print: false,
    viewColumns: false,
    pagination: false,
    toolbar: true,
    tableBodyMaxHeight: '20vh',
    tableBodyHeight: '20vh',
    rowsPerPageOptions: [10, 25, 50, 100]
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 440, ...customScrollbar }} mode={theme.mode}>
        <BasicTable stickyHeader aria-label="sticky table" options={options}>
          {timeFrame === 'weekly' && (
            <TableHead>
              <TableRow>
                {[...yearGroups].map(({ mentionedYear, yearCount }) => (
                  <TableCell key={mentionedYear} align="center" colSpan={yearCount}>
                    <b>{mentionedYear ?? ''}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableHead>
            <TableRow>
              {[...timeColumns, 'Total'].map((val) => {
                return (
                  <TableCell key={timeColumns.indexOf(val)} align="center" colSpan={1}>
                    <b>{val != 'Total' ? val.label : val}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {[...timeValues].map(({ value, id, modalData }) => (
                <TableCell
                  colSpan={1}
                  sx={{
                    cursor: 'pointer'
                  }}
                  align="center"
                  key={timeValues.indexOf(value) + id}
                  onClick={() => {
                    setModalOpen(true);
                    setModalData(modalData);
                  }}>
                  {value}
                </TableCell>
              ))}
              <TableCell
                colSpan={1}
                sx={{
                  cursor: 'pointer'
                }}
                align="center"
                onClick={() => {
                  setModalOpen(true);
                  setModalData(total.modalData);
                }}>
                {total.value}
              </TableCell>
            </TableRow>
          </TableBody>
        </BasicTable>
      </TableContainer>
      {modalOpen && Object.keys(modalData).length > 0 && (
        <UnderlyingCanalStatsModal
          modalOpen={modalOpen}
          modalData={modalData}
          setModalOpen={setModalOpen}
          setModalData={setModalData}
        />
      )}
    </>
  );
}

CanalTransitsTable.propTypes = {
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  timeFrame: PropTypes.string,
  fileName: PropTypes.string
};

export default CanalTransitsTable;
