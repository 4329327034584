import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import moment from 'moment';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const quarterToMonth = (quarter) => {
  switch (quarter) {
    case 'Q1':
      return '01';
    case 'Q2':
      return '03';
    case 'Q3':
      return '06';
    case 'Q4':
      return '09';
  }
};

const getPeriod = (periodSelect) => {
  let periodName = 'Month';
  if (periodSelect === 2) {
    periodName = 'Quarter';
  }
  if (periodSelect === 3) {
    periodName = 'Year';
  }
  return periodName;
};

function OperatorEmissionTable({ loading, emissionHistoryData, periodSelect, fileName }) {
  const [newData, setNewData] = useState(null);
  const { isRoRo } = useSegment();
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!emissionHistoryData) return;
    const { operatorPeriodEmissionDetailsList: periodData } = emissionHistoryData;

    let tempData = {
      co2Ceu: [],
      gtnm: [],
      dwtnm: [],
      totalCo2: [],
      totDist: []
    };
    // call function to get data in default state
    for (let item of periodData) {
      tempData.totalCo2.push(item.totalCo2 || 0);
      tempData.co2Ceu.push(item.co2ByCeu || 0);
      tempData.totDist.push(item.totalDistanceNm || 0);
      tempData.gtnm.push(item.co2ByGrossTonnageDistance || 0);
      tempData.dwtnm.push(item.co2ByDeadweightDistance || 0);
    }

    let filteredPeriodData;

    if (!isRoRo && periodSelect === 3) {
      filteredPeriodData = periodData.slice(1);
    } else {
      filteredPeriodData = periodData;
    }

    setNewData(filteredPeriodData);
  }, [emissionHistoryData]);

  const columns = [
    {
      name: 'periodStr',
      label: getPeriod(periodSelect),
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            if (periodSelect === 1) {
              let val1 = moment(obj1.data, 'MMM yyyy').unix();
              let val2 = moment(obj2.data, 'MMM yyyy').unix();
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            } else if (periodSelect === 2) {
              let quarterAndYear1 = obj1.data?.split(' ');
              let quarterAndYear2 = obj2.data?.split(' ');

              let val1 = moment(
                `${quarterToMonth(quarterAndYear1[0])} ${quarterAndYear1[1]}`,
                'MM yyyy'
              ).unix();
              let val2 = moment(
                `${quarterToMonth(quarterAndYear2[0])} ${quarterAndYear2[1]}`,
                'MM yyyy'
              ).unix();
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            } else if (periodSelect === 3) {
              let val1 = moment(obj1.data, 'yyyy').unix();
              let val2 = moment(obj2.data, 'yyyy').unix();
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            }
          };
        },
        sortThirdClickReset: true,
        filter: true,
        filterType: 'multiselect',
        sort: true
      }
    },
    {
      name: 'totalDistanceNm',
      label: 'Distance[NM]',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const formattedValue = parseFloat(value?.toFixed(0));
          return formatNumberReadable(formattedValue.toString());
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Total distance'),
        filterOptions: getMinMaxFilterOptions('Total distance')
      }
    },
    {
      name: 'co2ByCeu',
      label: 'tCO2/CEU',
      options: {
        display: isRoRo,
        download: isRoRo,
        filter: isRoRo,
        sort: isRoRo,
        customBodyRender: (value) => {
          return parseFloat(value?.toFixed(2));
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('tCO2/CEU'),
        filterOptions: getMinMaxFilterOptions('tCO2/CEU')
      }
    },
    {
      name: 'co2ByGrossTonnageDistance',
      label: 'gCO2/(GT•NM)',
      options: {
        display: isRoRo,
        download: isRoRo,
        filter: isRoRo,
        sort: isRoRo,
        customBodyRender: (value) => {
          return parseFloat(value?.toFixed(2));
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('gCO2/(GT•NM)'),
        filterOptions: getMinMaxFilterOptions('gCO2/(GT•NM)')
      }
    },
    {
      name: 'co2ByDeadweightDistance',
      label: 'gCO2/(DWT•NM)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return parseFloat(value?.toFixed(2));
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('gCO2/(DWT•NM)'),
        filterOptions: getMinMaxFilterOptions('gCO2/(DWT•NM)')
      }
    },
    {
      name: 'totalCo2',
      label: 'Total tCO2',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const formattedValue = parseFloat(value?.toFixed(0));
          return formatNumberReadable(formattedValue.toString());
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Total tCO2'),
        filterOptions: getMinMaxFilterOptions('Total tCO2')
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: fileName
    },
    print: checkUserHasAccess(user, true),
    viewColumns: false,
    pagination: false,
    elevation: 0,
    tableBodyMaxHeight: '70vh',
    tableBodyHeight: '70vh',
    toolbar: true,
    sortOrder: {
      name: 'periodStr',
      direction: 'desc'
    }
  };
  return (
    <Table
      loading={loading}
      mode={theme.mode}
      data={newData?.length ? newData : []}
      columns={columns}
      options={options}
    />
  );
}

OperatorEmissionTable.propTypes = {
  emissionHistoryData: PropTypes.object,
  loading: PropTypes.bool,
  periodSelect: PropTypes.number,
  fileName: PropTypes.string.isRequired
};

OperatorEmissionTable.defaultProps = {
  emissionHistoryData: null,
  loading: false,
  periodSelect: 1
};

export default OperatorEmissionTable;
