import React from 'react';
import { Box } from '@esgian/esgianui';
import DashboardActivityMapSection from '../../components/Sections/ShipAnalytics/DashboardActivityMapSection';

function DashboardActivityMapPage() {
  return (
    <Box height="98vh" width="100%">
      <DashboardActivityMapSection />
    </Box>
  );
}

export default DashboardActivityMapPage;
