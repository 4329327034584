import { useSelector } from 'react-redux';
import { getTimeZone } from '@store/features';
import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '@constants';

export const useTimezone = () => {
  const timeZone = useSelector(getTimeZone);

  const timeZoneIsUtc = useMemo(() => {
    return timeZone === 'UTC';
  }, [timeZone]);

  const getTimeZoneDisplay = useCallback(
    (dateTime, format = DATE_TIME_FORMAT) => {
      if (!dateTime) return null;
      const timeLabel = timeZoneIsUtc
        ? 'UTC'
        : `UTC${moment(dateTime).tz('Europe/Oslo').format('Z')}`;

      const formattedDate = timeZoneIsUtc
        ? moment.utc(dateTime).format(format)
        : moment.utc(dateTime).local().format(format);

      return `${formattedDate} (${timeLabel})`;
    },
    [timeZoneIsUtc]
  );

  return { timeZoneIsUtc, getTimeZoneDisplay, timeZone };
};
