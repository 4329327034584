import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, fiveColorsIndication }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true,
      stackType: 'normal'
    },
    dataLabels: {
      enabled: true
    },
    colors: fiveColorsIndication,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Rating',
        align: 'center'
      }
    },
    yaxis: {
      min: 0,
      max: (max) => max * 1.2,
      title: {
        text: 'No. of vessels with CII rating'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'center'
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true
    },
    legend: {
      position: 'top',
      showForSingleSeries: true,
      horizontalAlign: 'left'
    }
  };
};
function OperatorCiiRatingChart({ ciiRatingData, loading, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(null);
  const [categories, setCategories] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!ciiRatingData) return;
    const { operatorYearlyCIIRating: ratingData } = ciiRatingData;
    let tempData = {
      aRating: [],
      bRating: [],
      cRating: [],
      dRating: [],
      eRating: []
    };

    let tempCategories = Object.keys(ratingData);
    setCategories(tempCategories);

    for (let val of tempCategories) {
      tempData.aRating.push(ratingData[val].countARating);
      tempData.bRating.push(ratingData[val].countBRating);
      tempData.cRating.push(ratingData[val].countCRating);
      tempData.dRating.push(ratingData[val].countDRating);
      tempData.eRating.push(ratingData[val].countERating);
    }

    setSeries([
      { name: 'A', data: tempData.aRating },
      { name: 'B', data: tempData.bRating },
      { name: 'C', data: tempData.cRating },
      { name: 'D', data: tempData.dRating },
      { name: 'E', data: tempData.eRating }
    ]);
  }, [ciiRatingData]);

  useEffect(() => {
    if (!categories?.length) return;
    setOptions(getOptions(categories, theme, user, fileName));
  }, [theme, categories]);

  return (
    <ApexChart loading={loading} height={480} options={options || {}} type={'bar'} data={series} />
  );
}
OperatorCiiRatingChart.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.object,
  fileName: PropTypes.string
};
OperatorCiiRatingChart.defaultProps = {
  ciiRatingData: null,
  loading: false,
  fileName: ''
};
export default OperatorCiiRatingChart;
