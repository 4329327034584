import { useQuery } from '@tanstack/react-query';
import { getGlobalMonthlyPercentActivityStatus } from '@api';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getOperatorType } from '@store/features';

export const useGlobalMonthlyPercentActivityStatus = () => {
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);

  return useQuery({
    queryKey: ['globalMonthlyPercentActivityStatus', segment.id, operatorType],
    queryFn: ({ signal }) =>
      getGlobalMonthlyPercentActivityStatus(segment.id, operatorType, 4.0, signal),
    enabled: Boolean(typeof segment.id === 'number' && typeof operatorType === 'number')
  });
};
