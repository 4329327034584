import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@esgian/esgianui';
import moment from 'moment';
import OperatorPortShareSubTable from '@components/Tables/YtdPortCallStatsTable/OperatorPortShareSubTable';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function YtdPortCallStatsTable({
  loading,
  subTableHeader,
  topPortsData,
  selectedYear,
  handleSelectYear
}) {
  const { theme } = useTheme();
  const { segmentName } = useSegment();
  const { user } = useSelector(getUser);
  const columns = [
    {
      name: 'portId',
      options: { display: false, viewColumns: false, filter: false }
    },
    {
      name: 'portName',
      label: 'Port Name',
      options: {
        filter: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'port'} name={rowData[1]} id={rowData[0]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'prevYearYTDPortCalls',
      label: selectedYear === moment().year() ? `YTD ${selectedYear - 1}` : `${selectedYear - 1}`,
      options: {
        filter: true,
        customBodyRender: (dataIndex) => {
          return formatNumberReadable(dataIndex?.toString());
        }
      }
    },
    {
      name: 'ytdPortCalls',
      label: selectedYear === moment().year() ? `YTD ${selectedYear}` : `${selectedYear}`,
      options: {
        filter: false,
        customBodyRender: (dataIndex) => {
          return formatNumberReadable(dataIndex?.toString());
        }
      }
    },
    {
      name: 'difference',
      label: 'Difference',
      options: {
        filter: true,
        customBodyRender: (dataIndex) => {
          return formatNumberReadable(dataIndex?.toString());
        }
      }
    },
    {
      name: 'increase',
      label: 'Increase%',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dataIndex) => {
          if (parseFloat(dataIndex)) {
            if (dataIndex >= 0) {
              return (
                <Stack direction={'row'}>
                  <ArrowDropUpIcon color={'success'} />
                  <Typography variant={'body2'} color={'text.success'}>
                    {parseFloat(dataIndex?.toFixed(2))}%
                  </Typography>
                </Stack>
              );
            } else {
              return (
                <Stack direction={'row'}>
                  <ArrowDropDownIcon color={'error'} />
                  <Typography variant={'body2'} color={'text.error'}>
                    {parseFloat(dataIndex?.toFixed(2))}%
                  </Typography>
                </Stack>
              );
            }
          }
          return '-';
        }
      }
    }
  ];

  const tableSubHeaderDisplay = subTableHeader || `Top 3 Operators YTD ${selectedYear}`;

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: '55vh',
    tableBodyHeight: '55vh',
    toolbar: checkUserHasAccess(user, true),
    filter: false,
    search: false,
    print: false,
    download: checkUserHasAccess(user, true),
    viewColumns: false,
    elevation: 0,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    downloadOptions: {
      filename: `Number of Port Calls in Top ${topPortsData?.length} ${segmentName} Ports.csv`
        .toLowerCase()
        .split(' ')
        .join('-')
    },
    setTableProps: () => {
      return {
        id: 'port-calls-table'
      };
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const actualRowData = topPortsData[rowMeta.dataIndex];
      return (
        <TableRow
          sx={{
            background: ({ palette }) => palette.background.paper,
            boxShadow: 'inset 0px 0px 5px 0px rgb(0 0 0 / 11%)'
          }}>
          <TableCell colSpan={colSpan}>
            <OperatorPortShareSubTable
              selectedYear={selectedYear}
              subTableHeader={tableSubHeaderDisplay}
              data={actualRowData?.topOperatorsPerPortYTD}
            />
          </TableCell>
        </TableRow>
      );
    },
    sortOrder: {
      name: 'ytdPortCalls',
      direction: 'desc'
    }
  };

  const years = useMemo(() => {
    let res = [];
    for (let i = moment('01.01.2022').year(); i <= moment().year(); i++) {
      res.push(i);
    }
    return res;
  }, []);
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={12} sx={{ height: '4em' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'h6'} id={'port-calls-header'}>
                    {`Number of Port Calls in Top ${topPortsData?.length} ${segmentName} Ports`}
                  </Typography>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="top-port-year-label">Year</InputLabel>
                    <Select
                      id="top-port-year-select"
                      value={selectedYear}
                      label="Year"
                      onChange={handleSelectYear}>
                      {years?.map((year) => (
                        <MenuItem key={`select-${year}`} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} id={'table-body'}>
          <Table
            customStyle={{
              MUIDataTableFilterList: { display: 'none' },
              MUIDataTableToolbar: {
                styleOverrides: {
                  root: {
                    minHeight: 0
                  }
                }
              }
            }}
            loading={loading}
            mode={theme.mode}
            title={''}
            data={topPortsData?.length ? topPortsData : []}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

YtdPortCallStatsTable.propTypes = {
  topPortsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  selectedYear: PropTypes.number,
  handleSelectYear: PropTypes.func.isRequired,
  subTableHeader: PropTypes.string
};

YtdPortCallStatsTable.defaultProps = {
  topPortsData: [],
  subTableHeader: null,
  loading: false,
  selectedYear: moment().year()
};

export default YtdPortCallStatsTable;
