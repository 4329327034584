import moment from 'moment/moment';

export const VoyageType = {
  COMPLETED: 'COMPLETED',
  ONGOING: 'ONGOING',
  SIMULATED: 'SIMULATED'
};

export const getVoyageLink = (uriExt, voyageDetails, voyageGroupType) => {
  const formattedDepartureDate = moment(voyageDetails.departureDate).format('YYYY-MM-DDTHH:mm:ss');
  const formattedArrivalDate =
    voyageGroupType === VoyageType.ONGOING
      ? null
      : moment(voyageDetails.arrivalDate).format('YYYY-MM-DDTHH:mm:ss');

  return `${uriExt}/${voyageGroupType === VoyageType.ONGOING ? 'ongoing' : 'completed'}-voyages/${
    voyageDetails.imo
  }?departureDate=${formattedDepartureDate}&arrivalDate=${formattedArrivalDate}&fromPortId=${
    voyageDetails.fromPortId
  }&toPortId=${voyageDetails.toPortId}`;
};

export const generateVoyageIdFromSavedVoyage = (voyage) =>
  `${voyage.imo}-${voyage.fromPortId}-${voyage.departureDate}`;

export const generateSimulatedVoyageId = (voyage) => {
  const {
    originId,
    originType,
    destinationId,
    destinationType,
    viaPorts,
    wayPointId,
    wayPointType,
    fuelTypeId,
    vesselId,
    averageSpeedKts,
    myCargoWeightTn,
    euAllowanceCost
  } = voyage;

  const viaPortsString = viaPorts
    ? viaPorts.map((port) => `${port.id}-${port.type}-${port.order}`).join('_')
    : '';

  return `${originId}-${originType}_${destinationId}-${destinationType}_${viaPortsString}_${
    wayPointId || ''
  }-${
    wayPointType || ''
  }_${fuelTypeId}_${vesselId}_${averageSpeedKts}_${myCargoWeightTn}_${euAllowanceCost}`;
};

export const getTransitDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

export const getTimeDaysAgo = (numDays) =>
  moment().startOf('day').subtract(numDays, 'days').toISOString();
