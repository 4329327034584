import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, CloseIcon } from '@esgian/esgianui';

const DashboardActivityMapDrawerHeader = ({ title, onClose }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">{title}</Typography>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

DashboardActivityMapDrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DashboardActivityMapDrawerHeader;
