import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConsistencyChart } from '@components/Charts';

function sortByConsistencyScore(a, b) {
  if (a?.transitStats?.consistencyScore > b?.transitStats?.consistencyScore) {
    return -1;
  }
  if (a?.transitStats?.consistencyScore < b?.transitStats?.consistencyScore) {
    return 1;
  }
  return 0;
}

function TransitTimeConsistencyChart({ loading, portServiceData, fileName }) {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    try {
      if (portServiceData) {
        const { transitResults } = portServiceData;
        const sortedTransitResults = [...transitResults].sort(sortByConsistencyScore);
        setCategories(sortedTransitResults.map(({ operatorLongName }) => operatorLongName || []));
        setSeries([
          {
            data: sortedTransitResults.map(({ transitStats }) => {
              return transitStats?.consistencyScore ? transitStats?.consistencyScore : 0;
            })
          }
        ]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  }, [portServiceData]);
  return (
    <ConsistencyChart
      categories={categories}
      series={series}
      loading={loading}
      fileName={fileName}
    />
  );
}

TransitTimeConsistencyChart.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

TransitTimeConsistencyChart.defaultProps = { loading: false, portServiceData: null };

export default TransitTimeConsistencyChart;
