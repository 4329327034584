import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@esgian/esgianui';
import { PortTimeVsPortCallsChart, PortTimeYtdComparisonChart } from '@components/Charts';

function PortStatisticsSection({ portStats, loading, portName }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PortTimeVsPortCallsChart
          loading={loading}
          portName={portName}
          portStats={portStats}
          fileName={`port-time-vs-port-calls-${portName?.toLowerCase().split(' ').join('-')}`}
        />
      </Grid>
      <Grid item xs={6}>
        <PortTimeYtdComparisonChart
          portStats={portStats}
          loading={loading}
          portName={portName}
          fileName={`port-time-change-${portName?.toLowerCase().split(' ').join('-')}`}
        />
      </Grid>
    </Grid>
  );
}

PortStatisticsSection.propTypes = {
  loading: PropTypes.bool,
  portStats: PropTypes.object,
  portName: PropTypes.string
};

PortStatisticsSection.defaultProps = {
  loading: false,
  portStats: null,
  portName: ''
};

export default PortStatisticsSection;
