import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  BasicTable,
  Box,
  Stack,
  TableHead,
  TableRow,
  TableCell,
  ArrowDropUpIcon,
  ArrowDropDownIcon,
  TableBody
} from '@esgian/esgianui';
import moment from 'moment';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal/UnderlyingCanalStatsModal';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks/useTheme';
import { getDirection } from '@helpers';
import { ProfileLink } from '@components/Links';

function OperatorTransitsTable({
  loading = false,
  fileName = '',
  canalTransits = null,
  startDate = null,
  endDate = null
}) {
  const user = useSelector(getUser);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sortOrder, setSortOrder] = useState({ column: 'ytdPortCalls', direction: 'desc' });
  const { customScrollbar, theme } = useTheme();

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      fixedHeader: true,
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: fileName
      },
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [startDate]);

  let operatorTotals = { prevYearYTDPortCalls: 0, ytdPortCalls: 0, difference: 0 };

  //calculating the initial numbers
  const operatorTableData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let tempDataSet = [];
    let mainDataSet = [];
    canalTransits?.forEach(
      ({
        operatorId,
        operatorShortName,
        canalArrival,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit,
        headingDirection
      }) => {
        if (!operatorId) {
          return;
        }
        if (
          !tempDataSet.find((operator) => {
            return operator.operatorId === operatorId;
          })
        ) {
          tempDataSet.push({
            difference: 0,
            differenceShare: 0,
            increase: 0,
            increaseShare: 0,
            operatorId: operatorId,
            operatorShortName: operatorShortName,
            prevYearYTDPortCalls: 0,
            prevYearYTDPortCallsShare: 0,
            ytdPortCalls: 0,
            ytdPortCallsShare: 0,
            ytdModalData: {},
            prevModalData: {}
          });
        }
        //calculating YTD numbers
        let arrival = moment(canalArrival);
        if (arrival.isBetween(startDate, endDate)) {
          let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
          currentVal.ytdPortCalls++;
          if (!currentVal.ytdModalData[`${imo}_${canalArrival}}`]) {
            currentVal.ytdModalData[`${imo}_${canalArrival}`] = {
              vesselName: vesselName,
              imo: imo,
              operatorLongName: operatorLongName,
              canalArrival: canalArrival,
              canalDeparture: canalDeparture,
              transit: moment.duration(transit).asHours().toFixed(2),
              headingDirection: getDirection(headingDirection),
              year: moment(arrival).year(),
              period: 'YTD',
              operatorId: operatorId
            };
          }
        }
        //calulating previous YTD numbers
        if (
          arrival.isBetween(
            startDate.clone().subtract(1, 'year'),
            endDate.clone().subtract(1, 'year')
          )
        ) {
          let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
          currentVal.prevYearYTDPortCalls++;
          if (!currentVal.prevModalData[`${imo}_${canalArrival}}`]) {
            currentVal.prevModalData[`${imo}_${canalArrival}`] = {
              vesselName: vesselName,
              imo: imo,
              operatorLongName: operatorLongName,
              canalArrival: canalArrival,
              canalDeparture: canalDeparture,
              transit: moment.duration(transit).asHours().toFixed(2),
              headingDirection: getDirection(headingDirection),
              year: moment(arrival).year(),
              period: 'YTD',
              operatorId: operatorId
            };
          }
        }
        //calculating the diff between current YTD and previous YTD
        let currentVal = tempDataSet.find((operator) => operator.operatorId === operatorId);
        if (currentVal.prevYearYTDPortCalls !== 0) {
          currentVal.difference = currentVal.ytdPortCalls - currentVal.prevYearYTDPortCalls;
        }
        //calculating the increase between previous YTD and current YTD
        let tempIncrease = 0;
        if (currentVal.prevYearYTDPortCalls !== 0) {
          tempIncrease = (
            (currentVal.ytdPortCalls * 100) /
            currentVal.prevYearYTDPortCalls
          ).toFixed(2);
        } else {
          tempIncrease = '-';
        }
        if (tempIncrease === 0 || tempIncrease === '-') {
          currentVal.increase = tempIncrease;
        } else {
          currentVal.increase =
            tempIncrease === 0
              ? 0
              : parseInt(tempIncrease) === parseFloat(tempIncrease)
              ? (tempIncrease - 100).toFixed(0)
              : (tempIncrease - 100).toFixed(2);
        }
      }
    );
    tempDataSet.forEach((operator) => {
      if (operator.ytdPortCalls !== 0 || operator.prevYearYTDPortCalls !== 0) {
        mainDataSet.push(operator);
      }
    });
    return mainDataSet;
  }, [canalTransits, startDate, endDate]);

  const mainHeaders = useMemo(() => {
    return [
      { key: 1, name: ' ' },
      { key: 2, name: 'No. of Canal Transits' },
      { key: 3, name: ' ' },
      { key: 4, name: ' ' },
      { key: 5, name: 'Share of Canal Transits' }
    ];
  }, []);

  const subHeaders = useMemo(() => {
    return [
      { key: 'operatorShortName', name: 'Operator', direction: '' },
      {
        key: 'prevYearYTDPortCalls',
        name: `YTD ${startDate.clone().subtract(1, 'year').year()}`,
        direction: ''
      },
      { key: 'ytdPortCalls', name: `YTD ${startDate.clone().year()}`, direction: '' },
      { key: 'increase', name: 'Increase %', direction: '' },
      {
        key: 'prevYearYTDPortCallsShare',
        name: `YTD ${startDate.clone().subtract(1, 'year').year()}`,
        direction: ''
      },
      { key: 'ytdPortCallsShare', name: `YTD ${startDate.clone().year()}`, direction: '' },
      { key: 'increaseShare', name: 'Increase %', direction: '' }
    ];
  }, [startDate]);

  operatorTableData.forEach((operator) => {
    operatorTotals['prevYearYTDPortCalls'] += operator.prevYearYTDPortCalls;
    operatorTotals['ytdPortCalls'] += operator.ytdPortCalls;
    operatorTotals['difference'] += operator.difference;
  });
  //calculating the shares
  operatorTableData.forEach((operator) => {
    const tempYTDCalls =
      operatorTotals['ytdPortCalls'] === 0
        ? 0
        : (operator.ytdPortCalls * 100) / operatorTotals['ytdPortCalls'];
    operator.ytdPortCallsShare =
      parseInt(tempYTDCalls) === parseFloat(tempYTDCalls)
        ? tempYTDCalls.toFixed(0)
        : tempYTDCalls.toFixed(2);

    const tempPrevCalls =
      operatorTotals['prevYearYTDPortCalls'] === 0
        ? 0
        : (operator.prevYearYTDPortCalls * 100) / operatorTotals['prevYearYTDPortCalls'];
    operator.prevYearYTDPortCallsShare =
      parseInt(tempPrevCalls) === parseFloat(tempPrevCalls)
        ? tempPrevCalls.toFixed(0)
        : tempPrevCalls.toFixed(2);

    const tempDifference = (operator.difference * 100) / operatorTotals['difference'];
    operator.differenceShare =
      parseInt(tempDifference) === parseFloat(tempDifference)
        ? tempDifference.toFixed(0)
        : tempDifference.toFixed(2);

    let tempIncrease = 0;
    if (operator.prevYearYTDPortCalls !== 0) {
      tempIncrease = (
        (parseFloat(operator.ytdPortCallsShare) * 100) /
        parseFloat(operator.prevYearYTDPortCallsShare)
      ).toFixed(2);
    } else {
      tempIncrease = '-';
    }
    if (tempIncrease === 0 || tempIncrease === '-') {
      operator.increaseShare = tempIncrease;
    } else {
      operator.increaseShare =
        tempIncrease === 0
          ? 0
          : parseInt(tempIncrease) === parseFloat(tempIncrease)
          ? (tempIncrease - 100).toFixed(0)
          : (tempIncrease - 100).toFixed(2);
    }
  });

  const sortedTableData = useMemo(() => {
    const tempSort = [...operatorTableData].sort((firstOp, secondOp) => {
      if (sortOrder.column !== 'operatorShortName') {
        if (sortOrder.direction === 'asc') {
          if (Number(firstOp[sortOrder.column]) > Number(secondOp[sortOrder.column])) {
            return 1;
          }
          if (Number(firstOp[sortOrder.column]) < Number(secondOp[sortOrder.column])) {
            return -1;
          }
          return 0;
        } else {
          if (Number(firstOp[sortOrder.column]) < Number(secondOp[sortOrder.column])) {
            return 1;
          }
          if (Number(firstOp[sortOrder.column]) > Number(secondOp[sortOrder.column])) {
            return -1;
          }
          return 0;
        }
      } else {
        if (sortOrder.direction === 'asc') {
          if (firstOp[sortOrder.column] > secondOp[sortOrder.column]) {
            return 1;
          }
          if (firstOp[sortOrder.column] < secondOp[sortOrder.column]) {
            return -1;
          }
          return 0;
        } else {
          if (firstOp[sortOrder.column] < secondOp[sortOrder.column]) {
            return 1;
          }
          if (firstOp[sortOrder.column] > secondOp[sortOrder.column]) {
            return -1;
          }
          return 0;
        }
      }
    });
    return tempSort;
  }, [JSON.stringify(sortOrder), operatorTableData]);

  const handleSortClick = (column) => {
    let direction = 'asc';
    let tempColumn = column;
    if (column === sortOrder.column) {
      if (sortOrder.direction === 'asc') {
        direction = 'desc';
      } else {
        direction = 'asc';
      }
    }
    setSortOrder({ column: tempColumn, direction: direction });
  };

  return (
    <>
      <Paper sx={{ p: 2, ...customScrollbar }} mode={theme.mode}>
        {operatorTableData && (
          <BasicTable stickyHeader aria-label="sticky table" options={options}>
            <TableHead>
              <TableRow>
                {[
                  ...mainHeaders.map((val) => (
                    <TableCell key={val.key} align="center" colSpan={1}>
                      {val.name}
                    </TableCell>
                  ))
                ]}
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                {[
                  ...subHeaders.map((val) => {
                    let isCurrent = sortOrder.column === val.key;
                    let icon = undefined;
                    if (isCurrent) {
                      if (sortOrder.direction === 'asc') {
                        icon = <ArrowDropUpIcon />;
                      } else {
                        icon = <ArrowDropDownIcon />;
                      }
                    }

                    return (
                      <TableCell
                        key={val.key}
                        align="center"
                        colSpan={1}
                        sx={{
                          height: '100%',
                          cursor: 'pointer',
                          borderRadius: '5px',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            background: 'rgba(238,238,238,0.3)'
                          },
                          padding: '0px 16px 0px 16px'
                        }}
                        onClick={() => {
                          handleSortClick(val.key);
                        }}>
                        <Box>
                          <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            direction={'row'}
                            spacing={2}>
                            {val.name}
                            {icon}
                          </Stack>
                        </Box>
                      </TableCell>
                    );
                  })
                ]}
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                sortedTableData.map((operator, i) => {
                  const {
                    operatorShortName,
                    operatorId,
                    prevYearYTDPortCalls,
                    ytdPortCalls,
                    increase,
                    prevYearYTDPortCallsShare,
                    ytdPortCallsShare,
                    increaseShare,
                    prevModalData,
                    ytdModalData
                  } = operator;
                  return (
                    <TableRow key={`${i.increase}-${operatorShortName}`}>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh' }}>
                        <ProfileLink
                          profile={'operator'}
                          id={operatorId}
                          name={operatorShortName}
                        />
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh', cursor: 'pointer' }}
                        onClick={() => {
                          setModalOpen(true);
                          setModalData(prevModalData);
                        }}>
                        {prevYearYTDPortCalls}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh', cursor: 'pointer' }}
                        onClick={() => {
                          setModalOpen(true);
                          setModalData(ytdModalData);
                        }}>
                        {ytdPortCalls}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh' }}>
                        <Stack direction={'row'}>
                          {increase > 0 && <ArrowDropUpIcon color={'success'} />}
                          {increase < 0 && <ArrowDropDownIcon color={'error'} />}
                          {increase !== '-' ? increase + '%' : increase}
                        </Stack>
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh', cursor: 'pointer' }}
                        onClick={() => {
                          setModalOpen(true);
                          setModalData(prevModalData);
                        }}>
                        {prevYearYTDPortCallsShare}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh', cursor: 'pointer' }}
                        onClick={() => {
                          setModalOpen(true);
                          setModalData(ytdModalData);
                        }}>
                        {ytdPortCallsShare}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        align="center"
                        sx={{ minWidth: '10vh', maxWidth: '12vh' }}>
                        <Stack direction={'row'}>
                          {increaseShare > 0 && <ArrowDropUpIcon color={'success'} />}
                          {increaseShare < 0 && <ArrowDropDownIcon color={'error'} />}
                          {increaseShare !== '-' ? increaseShare + '%' : increaseShare}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ]}
            </TableBody>
          </BasicTable>
        )}
      </Paper>
      {modalOpen && Object.keys(modalData).length > 0 && (
        <UnderlyingCanalStatsModal
          modalOpen={modalOpen}
          modalData={modalData}
          setModalOpen={setModalOpen}
          setModalData={setModalData}
        />
      )}
    </>
  );
}

OperatorTransitsTable.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default OperatorTransitsTable;
