import { useCallback } from 'react';
import { useDynamicLookupQueries } from './useDynamicLookupQueries';

export const useVesselLookup = () => {
  const {
    lookupOperatorVesselHierarchyQuery: { data: operatorHierarchy }
  } = useDynamicLookupQueries();

  const getVesselListFromOperators = useCallback(
    (operators) => {
      let vesselIds = [];

      for (const operator of operators) {
        const foundOperator = operatorHierarchy?.find(({ id }) => {
          return parseInt(id) === parseInt(operator.id);
        });

        if (foundOperator) {
          vesselIds = vesselIds.concat(foundOperator.children.map(({ id }) => id));
        }
      }

      return vesselIds;
    },
    [operatorHierarchy]
  );

  return { getVesselListFromOperators };
};
