import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Typography } from '@esgian/esgianui';
import {
  applyLayerPaintProperties,
  getLayerConfig,
  getRouteSource
} from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const normalLineWidth = 1;
const highlightedLineWidth = 4;
const normalLineOpacity = 0.7;
const highlightedLineOpacity = 1;

const routeColors = {
  normal: '#80DFEB',
  highlighted: '#DD53FF'
};

function SimulatedVoyageGroupRouteMap({ savedVoyageDetails, hoveredVoyageId }) {
  const themeMode = useSelector(getThemeMode);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  // TODO: WORK on ID
  const routes = useMemo(
    () =>
      savedVoyageDetails.map((v) => ({
        routes: v.voyageTransitDetails?.transitDetails ?? [],
        id: `${Date.now()} + ${Math.random()}`
      })),
    [savedVoyageDetails]
  );

  useEffect(() => {
    if (mapRef.current || !routes?.length) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      zoom: 1
    });

    mapRef.current = map;

    map.on('load', () => {
      routes.forEach((r) => {
        map.addSource(r.id, getRouteSource([{ route: r.routes, color: routeColors.normal }]));
        map.addLayer(
          getLayerConfig(r.id, {
            opacity: normalLineOpacity,
            lineWidth: normalLineWidth
          })
        );
      });
      setIsMapLoaded(true);
    });

    return () => map.remove();
  }, [routes, themeMode]);

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) return;

    routes.forEach((r) => {
      if (!mapRef.current.getLayer(r.id)) return;

      if (hoveredVoyageId === r.id) {
        applyLayerPaintProperties(mapRef.current, r.id, {
          lineWidth: highlightedLineWidth,
          lineColor: routeColors.highlighted,
          lineOpacity: highlightedLineOpacity
        });
      } else {
        applyLayerPaintProperties(mapRef.current, r.id, {
          lineWidth: normalLineWidth,
          lineColor: routeColors.normal,
          lineOpacity: normalLineOpacity
        });
      }
    });
  }, [hoveredVoyageId, isMapLoaded, routes]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 1,
          padding: '5px 10px',
          borderRadius: '4px'
        }}>
        Simulated voyage on map
      </Typography>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

SimulatedVoyageGroupRouteMap.propTypes = {
  savedVoyageDetails: PropTypes.object,
  hoveredVoyageId: PropTypes.string
};

export default SimulatedVoyageGroupRouteMap;
