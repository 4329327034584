import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@esgian/esgianui';
import NumberOfSailingsTable from '@components/Sections/ShipAnalytics/OperatorPerformanceSection/SubSections/PerformanceIndicator/ServiceLevel/ServiceLevelOverview/NumberOfSailingsTable';
import PortPairServicePerformance from '@components/Sections/ShipAnalytics/OperatorPerformanceSection/SubSections/PerformanceIndicator/ServiceLevel/ServiceLevelOverview/PortPairServicePerformance';

function ServiceLevelOverview({ dateRangeList, performanceData }) {
  const [servicePerformanceData, setServicePerformanceData] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]);

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      setMonthlyData([]);
      setServicePerformanceData(null);
      return;
    }
    const { operatorPerformance } = performanceData;
    let { operatorFrequencyResult } = operatorPerformance;
    operatorFrequencyResult = operatorFrequencyResult[0];
    const {
      frequencyStats,
      operatorMonthlyFrequencyResults,
      frequencyMax,
      frequencyMin,
      frequencyTotal
    } = operatorFrequencyResult;
    const { average, consistencyScore } = frequencyStats;

    let sailingsMonth = [];
    dateRangeList?.map((month, i) => {
      let obj = { month: month, portCalls: operatorMonthlyFrequencyResults[i]?.portCalls };
      sailingsMonth.push(obj);
    });
    sailingsMonth.push({ month: 'Total', portCalls: frequencyTotal, highlight: true });

    setMonthlyData(sailingsMonth);
    setServicePerformanceData({
      avg: average?.toFixed(2),
      min: frequencyMin,
      max: frequencyMax,
      score: consistencyScore
    });
  }, [dateRangeList, performanceData]);

  return (
    <Grid item container spacing={2}>
      {!!monthlyData.length && <NumberOfSailingsTable monthlyData={monthlyData} />}
      {!!servicePerformanceData && (
        <PortPairServicePerformance servicePerformanceData={servicePerformanceData} />
      )}
    </Grid>
  );
}

ServiceLevelOverview.propTypes = {
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  performanceData: PropTypes.object
};

ServiceLevelOverview.defaultProps = {
  dateRangeList: [],
  performanceData: null
};

export default ServiceLevelOverview;
