import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Stack, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import {
  getGlobalPortsStats,
  getGlobalMonthlyPercentActivityStatus,
  getGlobalEmissionIntensity,
  getOperatorInformation,
  fetchLookupPorts,
  getPortCongestion
} from '@api';
import { GEOGRAPHICAL_TYPES, OPERATOR_TYPE } from '@constants';
import { OperatorTypeSelect } from '@components/Inputs';
import DashboardSection from '@components/Sections/ShipAnalytics/DashboardSection';
import moment from 'moment/moment';
import hesnesLogo from '@assets/images/hesnes_logo.png';
import { useSelector } from 'react-redux';
import { getDateFormat, getOperatorType, getSegment } from '@store/features';
import { useSegment } from '@hooks';

function DashboardPage() {
  const [loading, setLoading] = useState(true);
  const [globalPortStats, setGlobalPortStats] = useState(null);
  const [globalPortCongestion, setGlobalPortCongestion] = useState(null);
  const [globalFleetActivity, setGlobalFleetActivity] = useState(null);
  const [globalEmissionIntensity, setGlobalEmissionIntensity] = useState(null);
  const [activePorts, setActivePorts] = useState(null);
  const [operatorInformation, setOperatorInformation] = useState(null);
  const dateFormat = useSelector(getDateFormat);
  const startDate = moment().subtract(12, 'month').startOf('month').format(dateFormat);
  const endDate = moment().subtract(1, 'month').endOf('month').format(dateFormat);
  const currentDate = moment().format(dateFormat);

  const segment = useSelector(getSegment);
  const operatorType = useSelector(getOperatorType);
  const navigate = useNavigate();
  const { isRoRo } = useSegment();

  const routeChange = useCallback((path) => {
    navigate(path);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getParamData = async () => {
      try {
        await Promise.all([
          getGlobalPortsStats(
            {
              startDate: startDate,
              endDate: currentDate,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id
            },
            signal
          ).then((result) => {
            setGlobalPortStats(result);
          }),
          getPortCongestion(
            {
              startDate: startDate,
              endDate: endDate,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              GeoType: GEOGRAPHICAL_TYPES.ALL
            },
            signal
          ).then((result) => {
            setGlobalPortCongestion(result);
          }),
          getGlobalMonthlyPercentActivityStatus(segment.id, operatorType, 4.0, signal).then(
            (result) => {
              setGlobalFleetActivity(result);
            }
          ),
          getGlobalEmissionIntensity(segment.id, operatorType, 4.0, signal).then((result) => {
            setGlobalEmissionIntensity(result);
          }),
          fetchLookupPorts(
            { SegmentTypeId: segment.id, OperatorTypeId: operatorType },
            signal
          ).then((result) => {
            setActivePorts(result);
          }),

          getOperatorInformation(signal).then((result) => {
            setOperatorInformation(result);
          })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    setLoading(true);
    getParamData();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [operatorType, segment]);

  const kpiData = useMemo(() => {
    if (!operatorInformation?.length) return null;
    let shortSeaVessels = 0,
      deepSeaVessels = 0,
      shortSeaOpr = [],
      deepSeaOpr = [];
    operatorInformation?.forEach(({ operatorTypeId, segmentTypeId, operatorId }) => {
      if (segmentTypeId !== segment.id) return;
      if (operatorTypeId === OPERATOR_TYPE.SHORT_SEA) {
        shortSeaVessels++;
        shortSeaOpr.push(operatorId);
      }
      if (operatorTypeId === OPERATOR_TYPE.DEEP_SEA) {
        deepSeaVessels++;
        deepSeaOpr.push(operatorId);
      }
    });
    shortSeaOpr = new Set(shortSeaOpr);
    deepSeaOpr = new Set(deepSeaOpr);
    shortSeaOpr = [...shortSeaOpr].length;
    deepSeaOpr = [...deepSeaOpr].length;
    return {
      ssVessels: shortSeaVessels,
      dsVessels: deepSeaVessels,
      totVessels: deepSeaVessels + shortSeaVessels,
      totOpr: deepSeaOpr + shortSeaOpr,
      ssOpr: shortSeaOpr,
      dsOpr: deepSeaOpr
    };
  }, [operatorInformation, segment]);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item xs={12}>
        <PageHeader
          loading={loading}
          title={'Dashboard'}
          id={'dashboard-title'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/dashboard')}>
                Home
              </Link>
              <Typography color="primary" id="dashboard">
                Dashboard
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      {isRoRo && (
        <Grid item xs={12}>
          <OperatorTypeSelect />
        </Grid>
      )}
      <Grid item xs={12}>
        <DashboardSection
          kpiData={kpiData}
          isDashboard
          endDate={endDate}
          globalPortStats={globalPortStats}
          globalPortCongestion={globalPortCongestion}
          globalFleetActivity={globalFleetActivity}
          globalEmissionIntensity={globalEmissionIntensity}
          loading={loading}
          activePorts={activePorts}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography variant={'body2'} sx={{ pb: 2, pt: 2 }}>
            In association with
          </Typography>
          <img alt={'hesnes-company-logo'} srcSet={hesnesLogo} src={hesnesLogo} width={'10%'} />
        </Stack>
      </Grid>
    </Grid>
  );
}

DashboardPage.propTypes = {};

DashboardPage.defaultProps = {};

export default DashboardPage;
