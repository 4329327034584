import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import ActionModal from '../../../Common/ActionModal';
import { PortSelect } from '@components/Inputs';

export const PortSelectionType = {
  DEPARTURE: 'departure',
  ARRIVAL: 'arrival'
};

const SelectDepartureAndArrivalPort = ({
  open,
  onClose,
  departurePorts,
  arrivalPorts,
  currentDeparturePort,
  currentArrivalPort,
  onSave,
  isDisabledAgree,
  portSelectionType
}) => {
  const [selectedDeparturePort, setSelectedDeparturePort] = useState(departurePorts[0]);
  const [selectedArrivalPort, setSelectedArrivalPort] = useState(arrivalPorts[0]);

  const resetSelection = () => {
    setSelectedDeparturePort(undefined);
    setSelectedArrivalPort(undefined);
  };

  const renderPortSelect = (type) => {
    const isArrival = type === 'arrival';
    return (
      <PortSelect
        label={`Select ${type === PortSelectionType.DEPARTURE ? 'Departure' : 'Arrival'} Port`}
        id={`${type}-port-select`}
        placeholder={`Select ${
          type === PortSelectionType.DEPARTURE ? 'Departure' : 'Arrival'
        } port...`}
        selectedPort={isArrival ? selectedArrivalPort : selectedDeparturePort}
        handleChange={isArrival ? setSelectedArrivalPort : setSelectedDeparturePort}
        portList={isArrival ? arrivalPorts : departurePorts}
        disablePortal={false}
        getOptionLabel={(option) => `${option.portName} (${option.portCode})`}
        renderOption={(props, option) => (
          <PortSelectOption {...props} option={option} type={type} />
        )}
        getOptionDisabled={(option) =>
          isArrival
            ? option.departureDate === currentDeparturePort?.departureDate &&
              option.portId === currentDeparturePort?.portId
            : option.arrivalDate === currentArrivalPort?.arrivalDate &&
              option.portId === currentArrivalPort?.portId
        }
      />
    );
  };

  return (
    <ActionModal
      open={open}
      onCancel={() => {
        resetSelection();
        onClose();
      }}
      agreeButtonLabel="Update"
      cancelButtonLabel="Cancel"
      isDisabledAgree={isDisabledAgree}
      onAgree={() => {
        onSave({
          selectedArrivalPort:
            portSelectionType === PortSelectionType.ARRIVAL
              ? selectedArrivalPort
              : currentArrivalPort,
          selectedDeparturePort:
            portSelectionType === PortSelectionType.DEPARTURE
              ? selectedDeparturePort
              : currentDeparturePort
        });
        resetSelection();
      }}
      content={
        <Box display={'flex'} flexDirection={'column'} gap={4} py={4}>
          {renderPortSelect(portSelectionType)}
        </Box>
      }
      title={`Select ${
        portSelectionType === PortSelectionType.DEPARTURE ? 'Departure' : 'Arrival'
      } Port`}
    />
  );
};

SelectDepartureAndArrivalPort.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  departurePorts: PropTypes.arrayOf(PropTypes.object),
  arrivalPorts: PropTypes.arrayOf(PropTypes.object),
  currentDeparturePort: PropTypes.object,
  currentArrivalPort: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  isDisabledAgree: PropTypes.bool,
  portSelectionType: PropTypes.oneOf(['departure', 'arrival']).isRequired
};

export default SelectDepartureAndArrivalPort;

const PortSelectOption = ({ option, type, ...props }) => {
  const { Typography, Box } = require('@esgian/esgianui');
  const moment = require('moment');
  const { DATE_TIME_FORMAT } = require('@constants');

  return (
    <li {...props} key={`${option.portCode}-${option.departureDate}-${option.arrivalDate}`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          p: 1
        }}>
        <Typography variant="subtitle2">{`${option.portName} (${option.portCode})`}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="span"
            sx={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              mr: 1,
              backgroundColor: 'text.secondary',
              maskImage:
                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z'/%3E%3C/svg%3E\")",
              maskSize: 'cover',
              maskPosition: 'center',
              maskRepeat: 'no-repeat'
            }}
          />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontStyle: 'italic',
              borderLeft: '2px solid #80DFEB',
              paddingLeft: '6px',
              backgroundColor: 'rgba(128, 223, 235, 0.1)',
              borderRadius: '0 2px 2px 0',
              padding: '2px 4px',
              display: 'inline-block',
              maxWidth: 'fit-content',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(128, 223, 235, 0.2)',
                transform: 'translateX(3px)'
              }
            }}>
            {moment(option[`${type}Date`]).format(DATE_TIME_FORMAT)}
          </Typography>
        </Box>
      </Box>
    </li>
  );
};

PortSelectOption.propTypes = {
  option: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['departure', 'arrival']).isRequired
};
