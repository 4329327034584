import React from 'react';
import PropTypes from 'prop-types';

const PageLinkIcon = ({ color = 'black', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.83301 9.83342V6.03857C4.83301 5.70609 4.95074 5.42212 5.18621 5.18665C5.42167 4.95119 5.70565 4.83345 6.03812 4.83345H11.7407L9.85736 2.9501L10.5702 2.2373L13.6663 5.33344L10.5702 8.43599L9.85736 7.73344L11.7407 5.83342H6.03812C5.97829 5.83342 5.92914 5.85265 5.89067 5.89112C5.85221 5.92959 5.83297 5.97874 5.83297 6.03857V9.83342H4.83301ZM3.53814 13.6668C3.20566 13.6668 2.92169 13.549 2.68622 13.3135C2.45075 13.0781 2.33301 12.7941 2.33301 12.4616V2.87192H3.33299V12.4616C3.33299 12.5215 3.35222 12.5706 3.39069 12.6091C3.42916 12.6475 3.47831 12.6668 3.53814 12.6668H11.1278C11.1877 12.6668 11.2368 12.6475 11.2753 12.6091C11.3138 12.5706 11.333 12.5215 11.333 12.4616V9.83342H12.333V12.4616C12.333 12.7941 12.2152 13.0781 11.9798 13.3135C11.7443 13.549 11.4603 13.6668 11.1278 13.6668H3.53814Z"
      fill={color}
    />
  </svg>
);

PageLinkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default PageLinkIcon;
