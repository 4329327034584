import React from 'react';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ToolTip,
  HelpOutlineIcon
} from '@esgian/esgianui';
import PropTypes from 'prop-types';

const Dropdown = ({ label, selectedValue, onSelect, options, size, isLoading }) => {
  return (
    <FormControl fullWidth size={size}>
      {label && <InputLabel id="select-label">{label}</InputLabel>}
      <Select
        labelId="select-label"
        value={selectedValue}
        disabled={isLoading}
        label={label ? label : undefined}
        onChange={(e) => {
          onSelect(e.target.value);
        }}>
        {options.map((singleOption) => (
          <MenuItem key={singleOption.value} value={singleOption.value}>
            {singleOption.label}
            {singleOption.tooltip && (
              <ToolTip title={singleOption.tooltip}>
                <IconButton size={'small'}>
                  <HelpOutlineIcon />
                </IconButton>
              </ToolTip>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      tooltip: PropTypes.string
    })
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isLoading: PropTypes.bool
};

export default Dropdown;
