import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import SimulatedVoyageGroupRouteMap from './SimulatedVoyageGroupRouteMap';
import SimulatedVoyageList from './SimulatedVoyageList';

const SimulatedVoyageGroupSection = ({ savedVoyagesDetails }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box height="400px">
        <SimulatedVoyageGroupRouteMap savedVoyageDetails={savedVoyagesDetails} />
      </Box>
      <Box flex={1}>
        <SimulatedVoyageList voyagesDetails={savedVoyagesDetails} />
      </Box>
    </Box>
  );
};

SimulatedVoyageGroupSection.propTypes = {
  savedVoyagesDetails: PropTypes.array
};

export default SimulatedVoyageGroupSection;
