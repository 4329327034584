import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { ToolTip } from '@esgian/esgianui';

const FavoriteIcon = ({
  isFavorite,
  onToggle,
  size = 16,
  disabled = false,
  disabledMessage = ''
}) => {
  const themeMode = useSelector(getThemeMode);

  const handleClick = () => {
    if (!disabled && onToggle) {
      onToggle(!isFavorite);
    }
  };

  const notFavoriteColor = themeMode ? 'white' : 'black';
  const iconColor = isFavorite ? 'yellow' : 'none';
  const strokeColor = isFavorite ? 'none' : notFavoriteColor;
  const strokeWidth = isFavorite ? '0' : '2';

  const icon = (
    <svg
      onClick={handleClick}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1
      }}
      fill={iconColor}
      stroke={strokeColor}
      strokeWidth={strokeWidth}>
      <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
    </svg>
  );

  return disabled ? (
    <ToolTip title={disabledMessage}>
      <span>{icon}</span>
    </ToolTip>
  ) : (
    icon
  );
};

FavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string
};

export default FavoriteIcon;
