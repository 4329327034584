import { Stack, Typography } from '@esgian/esgianui';
import React from 'react';

export const tooltipTransitContents = (
  <Stack>
    <Typography variant={'caption'}>
      Maximum limit of days in transit between selected locations
    </Typography>
  </Stack>
);
