import React, { useEffect, useState } from 'react';
import { Grid, NotFound, Paper, Typography, Stack } from '@esgian/esgianui';
import OperatorCo2EmissionsFilters from '@components/Sections/EmissionsAnalytics/OperatorComparisonSection/OperatorCo2EmissionsSection/OperatorCo2EmissionsFilters';
import moment from 'moment/moment';
import { OPERATOR_SELECT_TYPES } from '@constants';
import { useSearchParams } from 'react-router-dom';
import { getEmissionDetails } from '@api/Emissions';
import { EmissionComparisonChart, EmissionComparisonTable } from '@components/Sections';
import { AccreditationStatementModal } from '@components/Modals';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { getOperatorType } from '@store/features';
import {
  CO2_EMISSIONS_SLICE_KEYS,
  getOprCo2EmissionsFilters
} from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { updateOprCo2EmissionsFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import OprCo2EmissionsTabs from '@components/Sections/EmissionsAnalytics/OperatorComparisonSection/OperatorCo2EmissionsSection/OprCo2EmissionsTabs';
import { EmissionsDisclaimer } from '@components';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSelectedOperatorsToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';
import { SERVICE_LEVEL_SLICE_KEYS } from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import { useDynamicLookupQueries } from '@hooks';

const initUrlParams = (filters, lookupOperators, searchParams) => {
  let newFilters = { ...filters };

  newFilters = getStandardValuesFromUri(newFilters, SERVICE_LEVEL_SLICE_KEYS, 6, searchParams);
  const operators = getSelectedOperatorsFromUri(
    'oprCo2SelectedAssets',
    newFilters.selectType,
    lookupOperators,
    searchParams
  );
  if (operators) {
    newFilters.selectedAssets = operators;
  }

  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  searchParams = setSliceToUri(filters, CO2_EMISSIONS_SLICE_KEYS, 'oprCo2', searchParams);
  searchParams = setSelectedOperatorsToUrl(
    filters.selectedAssets,
    filters.selectType,
    'oprCo2SelectedAssets',
    searchParams
  );

  setSearchParams(searchParams);
};

function OperatorCo2EmissionsSection() {
  const [emissionsData, setEmissionsData] = useState(null);
  const [fetchDataSwitch, setFetchDataSwitch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showStatement, setShowStatement] = useState(false);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const dispatch = useDispatch();
  const { segment } = useSegment();
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();
  const co2EmissionsFilters = useSelector(getOprCo2EmissionsFilters);
  const operatorType = useSelector(getOperatorType);

  useEffect(() => {
    if (lookupOperators?.length) {
      let newFilters = initUrlParams(co2EmissionsFilters, lookupOperators, searchParams);
      dispatch(updateOprCo2EmissionsFilters(newFilters));
      setInit(true);
    }
  }, [lookupOperators]);

  useEffect(() => {
    if (init) {
      setUrlParams(co2EmissionsFilters, searchParams, setSearchParams);
    }
  }, [co2EmissionsFilters, init]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getEmissionsData = async () => {
      const { startDate, endDate, selectType, selectedAssets } = co2EmissionsFilters;
      try {
        if (!init || !moment(startDate).isValid() || !moment(endDate).isValid()) {
          return;
        }
        let operatorIds = null;

        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && !selectedAssets.length) {
          setNoData(true);
          setEmissionsData(null);
          return;
        }
        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && selectedAssets.length) {
          operatorIds = selectedAssets.map(({ id }) => id);
        }

        setLoading(true);
        setNoData(false);

        const response = await getEmissionDetails(
          startDate,
          endDate,
          operatorIds,
          co2EmissionsFilters.minFleetSize,
          co2EmissionsFilters.maxFleetSize,
          co2EmissionsFilters.minBuildYear,
          co2EmissionsFilters.maxBuildYear,
          co2EmissionsFilters.minCapacity,
          co2EmissionsFilters.maxCapacity,
          segment.id,
          operatorType,
          signal
        );
        const { operatorEmissionDetailsList } = response;
        if (!operatorEmissionDetailsList.length) {
          setNoData(true);
          setEmissionsData(null);
        } else {
          setEmissionsData(response);
        }
        setLoading(false);
      } catch (error) {
        setNoData(true);
        setLoading(false);
        setEmissionsData(null);
      }
    };
    getEmissionsData();
    return () => {
      controller.abort();
    };
  }, [co2EmissionsFilters, fetchDataSwitch, init, segment, operatorType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <Typography variant={'h6'}>
                Operator CO<sub>2</sub> emission comparison
              </Typography>
              <OperatorCo2EmissionsFilters
                setFetchDataSwitch={setFetchDataSwitch}
                fetchDataSwitch={fetchDataSwitch}
              />
              <OprCo2EmissionsTabs />
              {!loading && noData && (
                <Grid item xs={12}>
                  <NotFound
                    show={true}
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                </Grid>
              )}
              {!noData && (
                <Stack spacing={2}>
                  <EmissionsDisclaimer setShowStatement={setShowStatement} />
                  <EmissionComparisonChart loading={loading} emissionsData={emissionsData} />
                </Stack>
              )}
            </Stack>
            {!noData && <EmissionComparisonTable loading={loading} emissionsData={emissionsData} />}
          </Stack>
        </Paper>
      </Grid>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

OperatorCo2EmissionsSection.propTypes = {};

OperatorCo2EmissionsSection.defaultProps = {};

export default OperatorCo2EmissionsSection;
