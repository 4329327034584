import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, HelpOutlineIcon, Paper, Typography } from '@esgian/esgianui';
import SimulatedVoyageEmissionSummary from './SimulatedVoyageEmissionSummary.js';
import MapPlaceholder from '../../../Common/MapPlaceholder.js';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import VoyagePortWaitingTime from '../VoyageSummarySection/VoyageSummaryPortWaitingTime/VoyagePortWaitingTime.jsx';
import SimulatedVoyageGHGEmission from './SimulatedVoyageGHGEmission.js';
import SimulatedVoyageCo2Emission from './SimulatedVoyageCo2Emission.js';
import SimulatedVoyageMyCargoEstimatedEtsTable from './SimulatedVoyageMyCargoEstimatedEtsTable.js';
import SimulatedVoyageSpeedChart from './SimulatedVoyageSpeedChart.js';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/index.js';

const SimulatedVoyageResultSummary = ({ simulationResultData, renderCustomMapSection }) => {
  const [showInfo, setShowInfo] = useState(true);
  const themeMode = useSelector(getThemeMode);

  const reducedTransitDetails = useMemo(() => {
    if (!simulationResultData) {
      return;
    }
    const reducedVoyageTransitDetails =
      simulationResultData.voyageTransitDetails.transitDetails.reduce((acc, curr) => {
        const currentDay = moment(curr.timeStamp).format(DATE_FORMAT);
        if (currentDay in acc) {
          return acc;
        }
        return { ...acc, [currentDay]: curr };
      }, {});
    return {
      ...simulationResultData.voyageTransitDetails,
      transitDetails: Object.values(reducedVoyageTransitDetails)
    };
  }, [simulationResultData]);

  const renderedSimulatedEmissionTitle = (
    <Typography variant="h6">Simulated Emission and ETS</Typography>
  );

  if (!simulationResultData) {
    return (
      <Box flexGrow={1} display="flex" flexDirection="column" gap={2}>
        <Box flexBasis="450px">
          <MapPlaceholder key={themeMode} />
        </Box>
        {renderedSimulatedEmissionTitle}
        <Box>
          <SimulatedVoyageEmissionSummary />
        </Box>
      </Box>
    );
  }
  return (
    <Box flex={1} display="flex" flexDirection="column" gap={2} overflow="auto">
      {renderCustomMapSection?.(reducedTransitDetails)}
      {renderedSimulatedEmissionTitle}
      <SimulatedVoyageEmissionSummary {...simulationResultData.emissionSummary} />
      <Box display="flex" gap={1}>
        <Typography variant="h6">Details of Voyage,</Typography>
        {renderedSimulatedEmissionTitle}
      </Box>
      {showInfo && (
        <Alert
          onClose={() => setShowInfo(false)}
          icon={<HelpOutlineIcon sx={{ color: 'black' }} />}
          severity="info"
          sx={{ backgroundColor: 'text.secondary', color: 'black' }}>
          The ETS and Emission Estimates are simulated based on a practical speed profile based on
          the average speed input by the user. The total transit time estimated also considers
          current average port time and port waiting times at corresponding ports.
        </Alert>
      )}
      <Box display="flex" gap={2} flexBasis="300px">
        <Paper sx={{ p: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">Speed</Typography>
          <SimulatedVoyageSpeedChart voyageTransitDetails={reducedTransitDetails} />
        </Paper>
        <Paper sx={{ p: 2, flex: 1 }}>
          <VoyagePortWaitingTime
            voyagePortCalls={simulationResultData.voyagePortCalls.portCalls}
            hideAverageData
          />
        </Paper>
      </Box>
      <Box>
        <SimulatedVoyageMyCargoEstimatedEtsTable
          myCargoEuEtsAllowanceCost={simulationResultData.myCargoEuEtsAllowanceCost}
        />
      </Box>
      <Box>
        <SimulatedVoyageGHGEmission
          myCargoGhgEmissionSummary={simulationResultData.myCargoGhgEmissionSummary}
        />
      </Box>
      <Box>
        <SimulatedVoyageCo2Emission ciiRating={simulationResultData.ciiRating} />
      </Box>
    </Box>
  );
};

SimulatedVoyageResultSummary.propTypes = {
  simulationResultData: PropTypes.object,
  renderCustomMapSection: PropTypes.func
};

export default SimulatedVoyageResultSummary;
