import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { useTheme } from '@hooks';

const AddToVoyageGroupPopup = ({
  savedGroups,
  onCreateANewGroup,
  onAddVoyageToGroup,
  isSavingVoyageToGroup,
  isLoadingVoyageGroups,
  width,
  backgroundColor
}) => {
  const themeMode = useSelector(getThemeMode);
  const { customScrollbar } = useTheme();

  const hasAnyGroup = savedGroups.length > 0;

  return (
    <Box
      width={width ?? '300px'}
      sx={{ backgroundColor: backgroundColor ?? (themeMode ? 'black' : undefined) }}>
      <Typography variant={'body1'} p={1}>
        Add the voyage to...
      </Typography>
      {!hasAnyGroup && !isLoadingVoyageGroups && (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
          variant="body1"
          color="gray"
          px={1}>
          You have no group yet, create one to start
        </Typography>
      )}
      {isLoadingVoyageGroups && (
        <Typography variant="body1" p={1}>
          Loading...
        </Typography>
      )}
      {hasAnyGroup && !isLoadingVoyageGroups && (
        <List sx={{ maxHeight: '200px', overflow: 'auto', ...customScrollbar }}>
          {savedGroups.map((group) => {
            return (
              <ListItem key={group.id} disablePadding>
                <ListItemButton
                  sx={{ py: 0.5, px: 1 }}
                  onClick={() => onAddVoyageToGroup(group.id)}
                  disabled={isSavingVoyageToGroup}>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'body1' }}
                      primary={group.name}
                    />
                    <Typography variant="caption">({group.voyages.length} voyages)</Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}

      <Button
        variant="text"
        p={2}
        sx={{ marginTop: '8px' }}
        size={'small'}
        onClick={() => onCreateANewGroup()}>
        + CREATE A NEW GROUP
      </Button>
    </Box>
  );
};

AddToVoyageGroupPopup.propTypes = {
  onCreateANewGroup: PropTypes.func.isRequired,
  savedGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSavingVoyageToGroup: PropTypes.bool,
  isLoadingVoyageGroups: PropTypes.bool,
  onAddVoyageToGroup: PropTypes.func,
  width: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default AddToVoyageGroupPopup;
