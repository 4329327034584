import React from 'react';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { FaqSection } from '@components/Sections/ShipAnalytics';

function FaqPage() {
  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange('/roro/commercial-analytics')}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          title={`Frequently Asked Questions`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary">FAQ</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FaqSection />
      </Grid>
    </Grid>
  );
}

FaqPage.propTypes = {};

FaqPage.defaultProps = {};

export default FaqPage;
