import { combineReducers } from '@reduxjs/toolkit';
import CompletedVoyageSlice from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import OngoingVoyagesSlice from '@store/features/filters/VoyageAnalyticsPage/OngoingVoyage/OngoingVoyagesSlice';
import VoyageProfileSlice from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VoyageAnalyticsReducer = combineReducers({
  completedVoyages: CompletedVoyageSlice,
  ongoingVoyages: OngoingVoyagesSlice,
  voyageProfile: VoyageProfileSlice
});

export default VoyageAnalyticsReducer;

export const getRegionProfileSection = (state) => {
  return state.filters.regionProfile.section;
};
