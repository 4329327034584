import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { TextWithTooltipIcon } from '@components';
import { EU_ALLOWANCE_FOR_MY_CARGO, ETS_COST_PER_TONNE } from '@constants/tooltips';
import { formatNumberReadable, formatToDecimalPlaces } from '@helpers';

const MyCargoEstimatedETSTable = ({
  myCargoEuEtsAllowanceSummary,
  isLoading,
  setMyCargoWeightForCustomizedCalculation,
  setMyEtsCostForCustomizedCalculation,
  myCargoWeightForCustomizedCalculation,
  myEtsCostForCustomizedCalculation
}) => {
  const columns = [
    { name: 'items', label: 'Items' },
    { name: 'amount', label: 'Amount' }
  ];

  const createTableData = () => {
    if (!myCargoEuEtsAllowanceSummary) return [];

    return [
      {
        items: { value: 'My Cargo (MT)' },
        amount: {
          value: formatNumberReadable(
            formatToDecimalPlaces(myCargoWeightForCustomizedCalculation, 1)
          ),
          isEditable: true
        }
      },
      {
        items: {
          value: 'ETS cost per tonne CO₂(€)',
          tooltip: ETS_COST_PER_TONNE
        },
        amount: {
          value: formatNumberReadable(formatToDecimalPlaces(myEtsCostForCustomizedCalculation, 2)),
          isEditable: true
        }
      },
      {
        items: { value: 'ETS cost for my cargo (€)' },
        amount: {
          value: formatNumberReadable(
            formatToDecimalPlaces(myCargoEuEtsAllowanceSummary.totalMyCargoEstimatedEuEtsCost, 0)
          )
        }
      },
      {
        items: { value: 'EU Allowances for my cargo', tooltip: EU_ALLOWANCE_FOR_MY_CARGO },
        amount: {
          value: formatNumberReadable(
            formatToDecimalPlaces(myCargoEuEtsAllowanceSummary.totalMyCargoEuEtsAllowanceTn, 0)
          )
        }
      }
    ];
  };

  const createSummary = () => {
    if (!myCargoEuEtsAllowanceSummary) return [];

    return [
      {
        label: 'EU ETS Cost for my cargo (€)',
        value: formatNumberReadable(
          formatToDecimalPlaces(myCargoEuEtsAllowanceSummary.totalMyCargoEstimatedEuEtsCost, 0)
        )
      },
      {
        label: (
          <TextWithTooltipIcon
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.1em'
            }}
            label="EU Allowances for my cargo"
            tooltipText={EU_ALLOWANCE_FOR_MY_CARGO}
          />
        ),
        value: formatNumberReadable(
          formatToDecimalPlaces(myCargoEuEtsAllowanceSummary.totalMyCargoEuEtsAllowanceTn, 0)
        )
      }
    ];
  };

  const handleUpdate = (rowIndex, columnName, newValue) => {
    if (rowIndex === 0) {
      setMyCargoWeightForCustomizedCalculation(newValue);
    } else if (rowIndex === 1) {
      setMyEtsCostForCustomizedCalculation(newValue);
    }
  };

  return (
    <TableWithSummary
      title="Estimated ETS for My cargo"
      columns={columns}
      data={createTableData()}
      summary={createSummary()}
      isLoading={isLoading}
      onUpdate={handleUpdate}
      id="my-cargo-ets-table"
    />
  );
};

MyCargoEstimatedETSTable.propTypes = {
  myCargoEuEtsAllowanceSummary: PropTypes.object,
  isLoading: PropTypes.bool,
  setMyCargoWeightForCustomizedCalculation: PropTypes.func,
  setMyEtsCostForCustomizedCalculation: PropTypes.func,
  myCargoWeightForCustomizedCalculation: PropTypes.number,
  myEtsCostForCustomizedCalculation: PropTypes.number
};

export default MyCargoEstimatedETSTable;
