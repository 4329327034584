import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, getTableCellRender } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useSelector } from 'react-redux';
import { useTheme } from '@hooks';
import { getUser } from '@store/features';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

const createTableDataFormat = (portServiceData, dateList) => {
  let tempData = [];
  //median,avg,min,max,consScore
  const tempLows = [0, 0, 0, 0, 0];
  const tempHighs = [0, 0, 0, 0, 0];
  if (portServiceData && dateList) {
    const { transitResults } = portServiceData;
    transitResults.forEach((operator, i) => {
      const {
        transitMedian: median,
        transitMin: min,
        transitMax: max,
        operatorLongName,
        operatorMonthlyTransitResults,
        operatorId,
        transitStats
      } = operator;
      let { consistencyScore, average } = transitStats || {};
      average = average || 0;
      if (i === 0) {
        tempLows[0] = median;
        tempLows[1] = average;
        tempLows[2] = min;
        tempLows[3] = max;
        tempLows[4] = consistencyScore;
      }
      if (tempHighs[0] < median) {
        tempHighs[0] = median;
      }
      if (tempLows[0] > median) {
        tempLows[0] = median;
      }
      if (tempHighs[1] < average) {
        tempHighs[1] = average;
      }
      if (tempLows[1] > average) {
        tempLows[1] = average;
      }
      if (tempHighs[2] < min) {
        tempHighs[2] = min;
      }
      if (tempLows[2] > min) {
        tempLows[2] = min;
      }
      if (tempHighs[3] < max) {
        tempHighs[3] = max;
      }
      if (tempLows[3] > max) {
        tempLows[3] = max;
      }
      if (tempHighs[4] < consistencyScore) {
        tempHighs[4] = consistencyScore;
      }
      if (tempLows[4] > consistencyScore) {
        tempLows[4] = consistencyScore;
      }

      let obj = {
        consistencyScore: consistencyScore,
        operatorMonthlyTransitResults: operatorMonthlyTransitResults,
        average: average,
        median: median,
        min: min,
        max: max,
        operatorName: operatorLongName,
        operatorId: operatorId
      };
      dateList.forEach((monthDate, i) => {
        let monthObj = {};
        let val = operatorMonthlyTransitResults[i]?.operatorTransitAverage || 0;
        if (!val) {
          val = null;
        } else {
          val = val.toFixed(1);
        }
        monthObj[monthDate.split(' ').join('_')] = val;
        obj = { ...obj, ...monthObj };
      });
      tempData.push(obj);
    });
  }

  return {
    tempData,
    tempLows,
    tempHighs
  };
};

function TransitTimeAnalysisTable({ handleCellClick, loading, portServiceData, dateList }) {
  const [data, setData] = useState([]);
  //mean,avg,min,max,consScore
  const [lowScores, setLowScores] = useState([0, 0, 0, 0, 0]);
  const [highScores, setHighScores] = useState([0, 0, 0, 0, 0]);
  const [columns, setColumns] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const options = {
    customTableBodyFooterRender: () => {
      if (!portServiceData) {
        return;
      }
      const { monthlyTransitTotals } = portServiceData;

      return (
        <tbody id={'custom-table-footer'}>
          <TableRow
            key={`average-row-footer`}
            sx={{ backgroundColor: ({ palette }) => palette.background.default }}>
            <TableCell sx={{ boxShadow: ({ palette }) => palette.tables.boxShadow.insetRight }}>
              <Typography variant={'caption'} bold>
                Average
              </Typography>
            </TableCell>
            {monthlyTransitTotals.map((monthData, i) => {
              return (
                <TableCell sx={{ pl: 3 }} key={`average-${i}`}>
                  <Typography variant={'caption'} bold>
                    {monthData?.average?.toFixed(1) || '0'}
                  </Typography>
                </TableCell>
              );
            })}
            <TableCell style={{ boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)' }} />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'transit-time-analysis'
    },
    print: false,
    viewColumns: false,
    pagination: false,
    // toolbar: !!user,
    elevation: 0,
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'median',
      direction: 'asc'
    }
  };

  useEffect(() => {
    const { tempLows, tempHighs, tempData } = createTableDataFormat(portServiceData, dateList);
    setLowScores(tempLows);
    setHighScores(tempHighs);
    setData(tempData);
  }, [portServiceData, dateList]);

  useEffect(() => {
    if (!portServiceData) {
      return;
    }

    let columnDef = [
      {
        name: 'operatorName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: theme.palette.tables.boxShadow.insetRight
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      }
    ];

    const tableEnd = [
      {
        name: 'median',
        label: 'Median',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              highScores[0]?.toFixed(1),
              lowScores[0]?.toFixed(1),
              dataIndex?.toFixed(1) || '-',
              true
            );
          }
        }
      },
      {
        name: 'average',
        label: 'Avg',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              highScores[1]?.toFixed(1),
              lowScores[1]?.toFixed(1),
              dataIndex?.toFixed(1) || '-',
              true
            );
          }
        }
      },
      {
        name: 'min',
        label: 'Min',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              highScores[2]?.toFixed(1),
              lowScores[2]?.toFixed(1),
              dataIndex?.toFixed(1) || '-',
              true
            );
          }
        }
      },
      {
        name: 'max',
        label: 'Max',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(
              highScores[3]?.toFixed(1),
              lowScores[3]?.toFixed(1),
              dataIndex?.toFixed(1) || '-',
              true
            );
          }
        }
      },
      {
        name: 'consistencyScore',
        label: 'Consistency Score',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customHeadLabelRender: () => {
            return (
              <TextWithTooltipIcon
                tooltipText={tooltipConsistencyContents}
                label={'Consistency Score'}
                labelVariant={'body2'}
                variant={'body2'}
              />
            );
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[4], lowScores[4], dataIndex);
          }
        }
      }
    ];
    dateList?.map((value, i) => {
      columnDef.push({
        name: value.split(' ').join('_'),
        label: value,
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellHeaderProps: () => {
            let shadow = {};
            if (i === 0) {
              shadow = 'inset 3px 0px 2px -1px rgb(0 0 0 / 20%)';
            } else if (i === dateList.length - 1) {
              shadow = 'inset -3px 0px 2px -1px rgb(0 0 0 / 20%)';
            }
            return {
              style: {
                boxShadow: `${shadow}`
              }
            };
          },

          customBodyRender: (dataIndex, rowIndex) => {
            if (!data || !portServiceData) {
              return '-';
            }
            const { columnIndex } = rowIndex;

            const { monthlyTransitTotals } = portServiceData;
            const monthlyDetails = monthlyTransitTotals[columnIndex - 2];
            let oprId = rowIndex.rowData[1];
            let period = rowIndex.columnIndex - 2;
            return (
              <div
                style={{
                  cursor: dataIndex > 0 ? 'pointer' : 'default'
                }}
                onClick={() => handleCellClick(oprId, period)}>
                {getTableCellRender(
                  monthlyDetails?.max,
                  monthlyDetails?.max,
                  dataIndex,
                  true,
                  true,
                  false
                )}
              </div>
            );
          }
        }
      });
    });
    setColumns([...columnDef, ...tableEnd]);
  }, [dateList, data]);

  return (
    <Table
      loading={loading}
      mode={theme.mode}
      title={''}
      data={data.length ? data : []}
      columns={columns}
      options={options}
      customStyle={{
        MUIDataTableFilterList: { display: 'none' },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              minHeight: 0
            }
          }
        }
      }}
    />
  );
}

TransitTimeAnalysisTable.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  handleCellClick: PropTypes.func.isRequired,
  dateList: PropTypes.arrayOf(PropTypes.string)
};

TransitTimeAnalysisTable.defaultProps = {
  loading: false,
  portServiceData: null,
  dateList: []
};

export default TransitTimeAnalysisTable;
