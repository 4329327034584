import React from 'react';
import PropTypes from 'prop-types';
import { VoyagesTable } from '@components/Tables';

function CompletedVoyageSection({ loading, completedVoyagesData }) {
  return (
    <VoyagesTable
      voyageSearchResults={completedVoyagesData}
      fileName={'completed-voyages'}
      loading={loading}
      title="Completed Voyages"
      hideColumns={[
        'portName',
        'countryName',
        'regionName',
        'nextPortName',
        'nextPortCountryName',
        'nextPortRegionName',
        'nextPortEta'
      ]}
      initialSortColumn={'startDate'}
    />
  );
}

CompletedVoyageSection.propTypes = {
  loading: PropTypes.bool,
  completedVoyagesData: PropTypes.arrayOf(PropTypes.object)
};

CompletedVoyageSection.defaultProps = {
  loading: false,
  completedVoyagesData: []
};

export default CompletedVoyageSection;
