import React from 'react';
import { Grid, List, ListItem, Typography } from '@esgian/esgianui';
import navigation from '@assets/images/faq/general-navigation.png';
import userSettings from '@assets/images/faq/user-and-setting.png';
import tableFunctions from '@assets/images/faq/table-functions.png';
import tablePagination from '@assets/images/faq/table-pagination.png';

function GeneralNavigation() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={'h6'}>
          3.1 General Navigation&#9654;&#65039;
        </Typography>
      </Grid>
      <Grid item sx={{ pr: 2 }}>
        <img srcSet={navigation} src={navigation} height={'650px'} width={'500px'} />
        <Typography color={'text.secondary'} variant={'body2'} sx={{ textAlign: 'center' }}>
          Application Sidebar Menu
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography>The following items are available in the sidebar menu:</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item'
            }
          }}>
          <ListItem>Dashboard - Greenpact Ships dashboard</ListItem>
          <ListItem>My Fleet - Greenpact Ships</ListItem>
          <ListItem>Commercial Analytics</ListItem>
          <ListItem>Emission Analytics - Greenpact Ships</ListItem>
          <ListItem>Voyage Analytics - Greenpact Ships</ListItem>
          <ListItem>Upgrade Analytics - Greenpact Ships</ListItem>
        </List>
        <Typography>
          The menu can be collapsed and opened to your liking using the collapse/reveal function in
          the bottom left corner.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ pt: 2 }}>
          On the top right corner you will find the following functions:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img srcSet={userSettings} src={userSettings} />
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Light mode/Dark mode:{' '}
        </Typography>
        <Typography component={'span'}>Click the switch to change the view</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          Light mode/Dark mode:{' '}
        </Typography>
        <Typography component={'span'}>
          Click the envelop to send us feedback or send an email to{' '}
          <a href="mailto: hello@esgian.com">hello@esgian.com</a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          FAQ:{' '}
        </Typography>
        <Typography component={'span'}>Click to show FAQ</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'} bold>
          User Profile:{' '}
        </Typography>
        <Typography component={'span'}>
          Click on the profile picture to log out and manage user settings and change the password.
        </Typography>
      </Grid>
      <Grid item />
      <Grid item xs={12}>
        <Typography gutterBottom bold>
          Tables
        </Typography>
        <Typography gutterBottom>
          All tables can be filtered ascending or descending based on the column of your choosing.
          Just click on the column to adjust the sorting.
        </Typography>
        <Typography gutterBottom>
          Some tables include functions to search, download and filter the data:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={8}>
            <Grid container direction={'column'}>
              <Grid item>
                <Typography component={'span'} bold>
                  Search:{' '}
                </Typography>
                <Typography component={'span'}>Click for free text search in the table</Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} bold>
                  Download:{' '}
                </Typography>
                <Typography component={'span'}>
                  Click and choose the file format to download the table data
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} bold>
                  Filter:{' '}
                </Typography>
                <Typography component={'span'}>
                  Click to apply a filter to any column in the table
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <img srcSet={tableFunctions} src={tableFunctions} />
            <Typography variant={'body2'} color={'text.secondary'}>
              Table functions
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Large tables will by default have a limited number of records in the main view. In the
          bottom right corner of such tables you can expand the number of rows and flip pages in the
          table:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img srcSet={tablePagination} src={tablePagination} />
        <Typography variant={'body2'} color={'text.secondary'}>
          Table page navigation
        </Typography>
      </Grid>
    </Grid>
  );
}

GeneralNavigation.propTypes = {};

GeneralNavigation.defaultProps = {};

export default GeneralNavigation;
