import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Paper, Stack } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import {
  getDateBetweenFilterListOptions,
  getDateBetweenFilterOptions,
  getMinMaxFilterListOptions,
  getMinMaxFilterOptions
} from '@components/Tables/helpers';
import { checkUserHasAccess, tableTitlePaddingLeft } from '@helpers';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@constants';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
  LAST_PORT_CALLS_RECORDS_TEXT,
  LESS_THAN_250_RECORDS
} from '@constants/tooltips';
import DownloadDateRangeModal from '@components/Inputs/DownloadDateRangeModal/DownloadDateRangeModal';
import { useTimezone } from '@hooks/useTimezone';

const getTimeDisplay = (startDate, endDate, hoursOnly) => {
  const start = moment(startDate, DATE_TIME_FORMAT);
  const end = moment(endDate, DATE_TIME_FORMAT);

  // If either date is invalid, return an error message
  if (!start.isValid() || !end.isValid()) {
    return 'Invalid date';
  }

  let duration = moment.duration(end.diff(start));
  hoursOnly = duration.asHours();
  return `${hoursOnly?.toFixed(2)} `;
};

function VesselPortCallsTable({
  title,
  loading,
  portCalls,
  hideColumns,
  name,
  vesselImo,
  geoId,
  ownerId,
  oprId
}) {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const [showModal, setShowModal] = useState(false);
  const { getTimeZoneDisplay } = useTimezone();

  const columns = [
    {
      name: 'vesselName',
      label: 'Vessel',
      options: {
        filter: !hideColumns.includes('vesselName'),
        filterType: 'multiselect',
        sort: true,
        display: !hideColumns.includes('vesselName'),
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'vessel'} name={dataIndex} id={rowData[1]} />
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        display: !hideColumns.includes('imo'),
        download: !hideColumns.includes('imo'),
        sort: true,
        filter: !hideColumns.includes('imo'),
        filterType: 'multiselect'
      }
    },
    {
      name: 'operatorShortName',
      label: 'Operator',
      options: {
        display: !hideColumns.includes('operatorName'),
        download: !hideColumns.includes('operatorName'),
        sort: true,
        filter: !hideColumns.includes('operatorName'),
        filterType: 'multiselect',
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'operator'} name={dataIndex} id={parseInt(rowData[3])} />
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'portId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'portName',
      label: 'Port',
      options: {
        display: !hideColumns.includes('portName'),
        filter: !hideColumns.includes('portName'),
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'port'} name={dataIndex} id={rowData[4]} />
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'countryId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'countryName',
      label: 'Country',
      options: {
        display: !hideColumns.includes('countryName'),
        filter: !hideColumns.includes('countryName'),
        download: !hideColumns.includes('countryName'),
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'country'} name={dataIndex} id={rowData[6]} />
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'portCode',
      label: 'UNLOCODE',
      options: {
        display: !hideColumns.includes('portCode'),
        sort: true,
        sortThirdClickReset: true,
        filter: !hideColumns.includes('portCode'),
        filterType: 'multiselect'
      }
    },

    {
      name: 'startDate',
      label: 'Arrival',
      options: {
        display: !hideColumns.includes('startDate'),
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return getTimeZoneDisplay(dataIndex);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival', DATE_FORMAT),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', DATE_TIME_FORMAT)
      }
    },

    {
      name: 'imoDraught',
      label: 'IMO Draught',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'arrivalDraught',
      label: 'Draught on arrival',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const imoDraught = rowIndex.rowData[10];
          const percentArrivalDraught = rowIndex.rowData[12];

          const dataToDisplay = dataIndex !== null && dataIndex !== undefined ? dataIndex : '-';
          const draughtToDisplay =
            imoDraught !== null && imoDraught !== undefined ? imoDraught?.toFixed(1) : '-';
          const percentToDisplay =
            percentArrivalDraught !== null && percentArrivalDraught !== undefined
              ? percentArrivalDraught?.toFixed(1)
              : '-';

          return `${dataToDisplay}/${draughtToDisplay} (${percentToDisplay}%)`;
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Draught on Arrival'),
        filterOptions: getMinMaxFilterOptions('Draught on Arrival')
      }
    },
    {
      name: 'percentArrivalDraught',
      label: 'Arrival draught percentage',
      options: {
        display: false,
        filter: false,
        download: true,
        customBodyRender: (dataIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            return `${dataIndex?.toFixed(1)}%`;
          }
          return '-';
        }
      }
    },
    {
      name: 'endDate',
      label: 'Departure',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isAfter(val2) ? 1 : -1;
            }
            return val1.isBefore(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return getTimeZoneDisplay(dataIndex);
          }
          return '-';
        },
        display: !hideColumns.includes('endDate'),
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Departure'),
        filterOptions: getDateBetweenFilterOptions('Departure Date', DATE_TIME_FORMAT)
      }
    },

    {
      name: 'departureDraught',
      label: 'Draught on departure',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const imoDraught = rowIndex.rowData[10];
          const percentDepartureDraught = rowIndex.rowData[15];

          const dataToDisplay = dataIndex !== null && dataIndex !== undefined ? dataIndex : '-';
          const draughtToDisplay =
            imoDraught !== null && imoDraught !== undefined ? imoDraught?.toFixed(1) : '-';
          const percentToDisplay =
            percentDepartureDraught !== null && percentDepartureDraught !== undefined
              ? percentDepartureDraught?.toFixed(1)
              : '-';

          return `${dataToDisplay}/${draughtToDisplay} (${percentToDisplay}%)`;
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Draught on Departure'),
        filterOptions: getMinMaxFilterOptions('Draught on Departure')
      }
    },
    {
      name: 'percentDepartureDraught',
      label: 'Departure draught percentage',
      options: {
        display: false,
        filter: false,
        download: false,
        customBodyRender: (dataIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            return `${dataIndex?.toFixed(1)}%`;
          }
        }
      }
    },
    {
      name: 'timeInPort',
      label: 'Port Time (hours)',
      options: {
        display: !hideColumns.includes('timeInPort'),
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null
            ? getTimeDisplay(
                moment(rowData[9]).format(DATE_TIME_FORMAT),
                moment(rowData[13]).format(DATE_TIME_FORMAT)
              )
            : '-';
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Days in Port'),
        filterOptions: getMinMaxFilterOptions('Days in Port')
      }
    }
  ];
  const options = {
    selectableRows: 'none',
    // customToolbar: loadAllToolbar,
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    setTableProps: () => {
      return {
        id: 'vessel-port-calls-table'
      };
    },
    onDownload: () => {
      setShowModal(true);
      return false;
    },

    print: false,
    viewColumns: false,
    pagination: true,
    toolbar: true,
    elevation: 0,
    tableBodyMaxHeight: '80vh',
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'endDate',
      direction: 'desc'
    }
  };

  return (
    <Paper id={'vessel-portcalls-table'}>
      <Table
        customStyle={tableTitlePaddingLeft}
        loading={loading}
        mode={theme.mode}
        title={
          <Stack sx={{ pb: 2, pt: 2 }}>
            <Typography id={'vessel-port-calls-title'} variant={'h6'}>
              {title}
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              {portCalls?.length <= 249
                ? LESS_THAN_250_RECORDS + ' ' + portCalls?.length
                : LAST_PORT_CALLS_RECORDS_TEXT}
            </Typography>
          </Stack>
        }
        data={portCalls?.length ? portCalls : []}
        columns={columns}
        options={options}
      />
      {showModal && (
        <DownloadDateRangeModal
          showModal={showModal}
          setShowModal={setShowModal}
          vesselImo={vesselImo}
          geoId={geoId}
          ownerId={ownerId}
          oprId={oprId}
          name={name}
        />
      )}
    </Paper>
  );
}

VesselPortCallsTable.propTypes = {
  title: PropTypes.string,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  portCalls: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  assetLevel: PropTypes.oneOf(['country', 'vessel', 'operator', 'owner', 'port', 'region'])
    .isRequired,
  vesselImo: PropTypes.number,
  geoId: PropTypes.number,
  ownerId: PropTypes.number,
  oprId: PropTypes.number
};

VesselPortCallsTable.defaultProps = {
  title: 'Most Recent Vessels Port Calls',
  name: '',
  loading: false,
  portCalls: [],
  hideColumns: [],
  vesselImo: null,
  geoId: null,
  ownerId: null,
  oprId: null
};

export default VesselPortCallsTable;
