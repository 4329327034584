import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { getRoutes } from './Routes';
import { SEGMENT } from '@constants';

const usePageTracking = () => {
  const location = useLocation();

  const getSASection = (params) => {
    let section = params.get('section');
    switch (section) {
      default:
      case 1:
        return 'Activty Map';
      case 2:
        return 'Port Activity';
      case 3:
        return 'Service Level';
      case 4:
        return 'Operator Performance';
    }
  };

  const getTitle = (currentPath, params) => {
    let pathArray = currentPath.split('/');
    let segmentId = pathArray[1] === 'roro' ? SEGMENT.RoRo.id : SEGMENT.MPP.id;
    let route = getRoutes({ id: segmentId })?.find(({ isRedirect, path }) => {
      return !!matchPath(path, currentPath) && !isRedirect;
    });
    if (route?.shipAnalytics) {
      return `${route?.title} - ${getSASection(new URLSearchParams(params))}`;
    }
    return route?.title;
  };

  useEffect(() => {
    const title = getTitle(location.pathname, location.search);
    if (title) {
      // ReactGA.send({
      //   hitType: 'pageview',
      //   page: window.location.pathname,
      //   title: title
      // });
    }
  }, [location]);
};

export default usePageTracking;
