import { useQuery } from '@tanstack/react-query';
import { getVesselCiiRatingData } from '@api';

export const useCiiRatingData = (imo) => {
  const { data: ciiRatingData, isPending: isLoadingCiiRatingData } = useQuery({
    queryKey: ['vesselCiiRating', imo],
    queryFn: ({ signal }) => getVesselCiiRatingData(imo, 'monthly', signal),
    enabled: !!imo
  });

  return {
    ciiRatingData,
    isLoadingCiiRatingData
  };
};
