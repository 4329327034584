import React, { useEffect } from 'react';
import {
  Breadcrumbs,
  Grid,
  Link,
  PageHeader,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography
} from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import { OPERATOR_PROFILE_FLEET_CII_RATING_TEXT } from '@constants/tooltips';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cleanUpCurrentParams } from '@helpers';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { setOperatorCompSection } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import { getOperatorCompSection } from '@store/features/filters/SectionSlice/SectionSlice';
import { Co2EmissionsSection, FleetCiiRatingSection } from '@components/Sections';
import { OperatorTypeSelect } from '@components/Inputs';

function OperatorComparisonPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breadcrumbHome, isRoRo } = useSegment();
  const section = useSelector(getOperatorCompSection);

  const routeChange = (path) => {
    navigate(path);
  };
  const handleSectionChange = (_, val) => {
    const params = cleanUpCurrentParams(searchParams);
    dispatch(setOperatorCompSection(val));
    params.set('section', val);
    setSearchParams(params);
  };

  useEffect(() => {
    let sectionParam = searchParams.has('section') ? parseInt(searchParams.get('section')) : null;
    if (sectionParam && sectionParam !== section) {
      dispatch(setOperatorCompSection(sectionParam));
    }
  }, []);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item xs={12}>
        <PageHeader
          title={'Emissions Analytics'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange(breadcrumbHome)}>
                Home
              </Link>
              <Link underline="none" color="inherit">
                Emissions Analytics
              </Link>
              <Typography color="primary">Operator Comparison</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      {isRoRo && (
        <Grid item xs={12}>
          <OperatorTypeSelect />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper>
          <Tabs onChange={handleSectionChange} value={section || 1}>
            <Tab
              value={1}
              label={
                <Typography variant={'subtitle2'}>
                  CO<sub>2</sub> emissions
                </Typography>
              }
            />
            <Tab
              value={2}
              label={
                <TextWithTooltipIcon
                  label={<Typography variant={'subtitle2'}>Fleet CII rating</Typography>}
                  tooltipText={OPERATOR_PROFILE_FLEET_CII_RATING_TEXT}
                />
              }
            />
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <Co2EmissionsSection />
          </div>
        </Slide>
        <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
          <div>
            <FleetCiiRatingSection />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
}

OperatorComparisonPage.propTypes = {};

OperatorComparisonPage.defaultProps = {};

export default OperatorComparisonPage;
