import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

const LocationIcon = ({ size = 18, color, ...props }) => {
  const themeMode = useSelector(getThemeMode);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 4c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3z"
        fill={color || (themeMode ? '#ffffff' : '#000000')}
      />
    </svg>
  );
};

LocationIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default LocationIcon;
