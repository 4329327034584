import { Box, Grid, Typography, CircularProgress } from '@esgian/esgianui';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Summary from './Summary';
import FuelConsumptionTable from '@components/Tables/EmissionAndETSTables/FuelConsumptionTable';
import MainEngineFuelTable from '@components/Tables/EmissionAndETSTables/MainEngineFuelTable';
import MainGeneratorFuelTable from '@components/Tables/EmissionAndETSTables/MainGeneratorFuelTable';
import FuelConsumptionOverTimeChart from './FuelConsumptionOverTimeChart';
import EmissionOverTimeChart from './EmissionOverTimeChart';
import ExpandableWrapper from '../../../ExpandableWrapper';
import MyCargoEstimatedETSTable from '../../../Tables/EmissionAndETSTables/EstimatedETS/MyCargoEstimatedETSTable';
import MyCargoExpandedEstimatedETSTable from '../../../Tables/EmissionAndETSTables/EstimatedETS/MyCargoExpandedEstimatedETSTable';
import OverallVoyageEstimatedEtsTable from '../../../Tables/EmissionAndETSTables/EstimatedETS/OverallVoyageEstimatedEtsTable';
import OverallVoyageExpandedEstimatedEtsTable from '../../../Tables/EmissionAndETSTables/EstimatedETS/OverallVoyageExpandedEstimatedEtsTable';
import MyCargoCo2eEmissionTable from '../../../Tables/EmissionAndETSTables/Co2eEmissionTables/MyCargoCo2eEmissionTable';
import OverallVoyageCo2eTable from '../../../Tables/EmissionAndETSTables/Co2eEmissionTables/OverallVoyageCo2eTable';
import MyCargoExpandedCo2eEmissionTable from '../../../Tables/EmissionAndETSTables/Co2eEmissionTables/MyCargoExpandedCo2eEmissionTable';
import OverallVoyageExpandedCo2eEmissionTable from '../../../Tables/EmissionAndETSTables/Co2eEmissionTables/OverallVoyageExpandedCo2eEmissionTable';
import Co2EmissionTable from '../../../Tables/EmissionAndETSTables/Co2EmissionTable';

function EmissionAndEtsSection({
  summary,
  isLoadingData,
  myCargoWeightForCustomizedCalculation,
  fetchEmissionAndEtsData,
  myEtsCostForCustomizedCalculation,
  setMyCargoWeightForCustomizedCalculation,
  setMyEtsCostForCustomizedCalculation,
  loadedData
}) {
  const isInitialLoad = useRef(false);

  useEffect(() => {
    if (
      !isInitialLoad.current &&
      myCargoWeightForCustomizedCalculation &&
      myEtsCostForCustomizedCalculation
    ) {
      // Fetch updated data when customized calculation parameters change, avoiding the initial load
      fetchEmissionAndEtsData(
        myCargoWeightForCustomizedCalculation,
        myEtsCostForCustomizedCalculation
      );
    }
  }, [myCargoWeightForCustomizedCalculation, myEtsCostForCustomizedCalculation]);
  return (
    <Box display="flex" gap={2} flexDirection="column">
      {isLoadingData ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </Box>
      ) : (
        <>
          <Summary summary={summary} isLoading={isLoadingData} />
          <ExpandableWrapper
            title="Estimated ETS"
            contents={[
              {
                main: (
                  <MyCargoEstimatedETSTable
                    isLoading={isLoadingData}
                    myCargoEuEtsAllowanceSummary={loadedData?.myCargoEuEtsAllowanceSummary}
                    myCargoEuEtsAllowanceCost={loadedData?.myCargoEuEtsAllowanceCost}
                    myCargoWeightForCustomizedCalculation={myCargoWeightForCustomizedCalculation}
                    myEtsCostForCustomizedCalculation={myEtsCostForCustomizedCalculation}
                    setMyCargoWeightForCustomizedCalculation={
                      setMyCargoWeightForCustomizedCalculation
                    }
                    setMyEtsCostForCustomizedCalculation={setMyEtsCostForCustomizedCalculation}
                  />
                ),
                expanded: [
                  <MyCargoExpandedEstimatedETSTable
                    key={0}
                    isLoading={isLoadingData}
                    myCargoEuEtsAllowanceCost={loadedData?.myCargoEuEtsAllowanceCost}
                  />
                ]
              },
              {
                main: (
                  <OverallVoyageEstimatedEtsTable
                    isLoading={isLoadingData}
                    overallVoyageEuEtsAllowanceSummary={
                      loadedData?.overallVoyageEuEtsAllowanceSummary
                    }
                  />
                ),
                expanded: [
                  <OverallVoyageExpandedEstimatedEtsTable
                    key={0}
                    isLoading={isLoadingData}
                    overallVoyageEuEtsAllowanceCost={loadedData?.overallVoyageEuEtsAllowanceCost}
                  />
                ]
              }
            ]}
          />

          <ExpandableWrapper
            title="CO₂e Emission (GHG Emission)"
            contents={[
              {
                main: (
                  <MyCargoCo2eEmissionTable
                    myCargoToc={loadedData?.myCargoTocEmission ?? {}}
                    emissionSummary={loadedData?.myCargoGhgEmissionSummary ?? {}}
                    isLoading={isLoadingData}
                  />
                ),
                expanded: [
                  <MyCargoExpandedCo2eEmissionTable
                    key={0}
                    myCargoToc={loadedData?.myCargoTocEmission ?? {}}
                    myCargoHoc={loadedData?.myCargoHocEmission ?? {}}
                    isLoading={isLoadingData}
                  />
                ]
              },
              {
                main: (
                  <OverallVoyageCo2eTable
                    overallVoyage={loadedData?.overallVoyageGhgEmission}
                    isLoading={isLoadingData}
                  />
                ),
                expanded: [
                  <OverallVoyageExpandedCo2eEmissionTable
                    key={0}
                    overallVoyage={loadedData?.overallVoyageGhgEmission}
                    isLoading={isLoadingData}
                  />
                ]
              }
            ]}
          />
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              CO<sub>2</sub> Emission
            </Typography>
            <Co2EmissionTable
              title={'Overall Voyage'}
              filename={'co2-emission'}
              ciiRating={loadedData?.ciiRating}
              isLoading={isLoadingData}
            />
          </Box>

          <Box>
            <EmissionOverTimeChart
              dailyEmissions={loadedData?.dailyEmissions}
              isLoading={isLoadingData}
            />
          </Box>

          <Box display="flex" gap={2}>
            <Box flex={1}>
              <FuelConsumptionTable
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
            <Box flex={2}>
              <FuelConsumptionOverTimeChart
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MainEngineFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainEngineFuel={loadedData?.fuelDetails?.mainEngineFuelDurations ?? []}
              />
            </Grid>
            <Grid item xs={6}>
              <MainGeneratorFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainGeneratorFuel={loadedData?.fuelDetails?.mainGeneratorFuelDurations ?? []}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

EmissionAndEtsSection.propTypes = {
  summary: PropTypes.object,
  isLoadingData: PropTypes.bool.isRequired,
  myCargoWeightForCustomizedCalculation: PropTypes.number,
  fetchEmissionAndEtsData: PropTypes.func.isRequired,
  myEtsCostForCustomizedCalculation: PropTypes.number,
  setMyCargoWeightForCustomizedCalculation: PropTypes.func.isRequired,
  setMyEtsCostForCustomizedCalculation: PropTypes.func.isRequired,
  loadedData: PropTypes.shape({
    myCargoEuEtsAllowanceSummary: PropTypes.object,
    myCargoEuEtsAllowanceCost: PropTypes.object,
    overallVoyageEuEtsAllowanceSummary: PropTypes.object,
    overallVoyageEuEtsAllowanceCost: PropTypes.object,
    myCargoTocEmission: PropTypes.object,
    myCargoHocEmission: PropTypes.object,
    myCargoGhgEmissionSummary: PropTypes.object,
    overallVoyageGhgEmission: PropTypes.object,
    ciiRating: PropTypes.object,
    dailyEmissions: PropTypes.array,
    fuelDetails: PropTypes.shape({
      fuelTypeIdsFuelNames: PropTypes.object,
      mainEngineFuelDurations: PropTypes.array,
      mainGeneratorFuelDurations: PropTypes.array
    })
  })
};

export default EmissionAndEtsSection;
