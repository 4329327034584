import ActivityMapSlice from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import PortActivitySlice from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';
import { combineReducers } from '@reduxjs/toolkit';
import ServiceLevelSlice from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import OperatorPerformanceSlice from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
import createSectionSlice from '@store/features/filters/SectionSlice/SectionSlice';

const ComAnalyticsSectionSlice = createSectionSlice('comAnalyticsSectionSlice');

const CommercialAnalyticsReducer = combineReducers({
  section: ComAnalyticsSectionSlice.reducer,
  activityMap: ActivityMapSlice,
  portActivity: PortActivitySlice,
  serviceLevel: ServiceLevelSlice,
  operatorPerformance: OperatorPerformanceSlice
});
export default CommercialAnalyticsReducer;

export const getComAnalyticsSection = (state) => {
  return state.filters.commercialAnalytics.section;
};

export const { setSection: setComAnalyticsSection } = ComAnalyticsSectionSlice.actions;
