import React, { useState, useEffect } from 'react';
import { Grid, Button, DateRangeMobi, Stack } from '@esgian/esgianui';
import moment from 'moment';
import { LocationSelect } from '@components/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useDynamicLookupQueries, useSegment, useStaticLookupQueries } from '@hooks';
import OperatorAdvancedMultiSelect from '@components/Inputs/OperatorAdvancedSelect';
import {
  getPortActivityFilters,
  updatePortActivityFilters
} from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';

function PortActivityFilters() {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const dispatch = useDispatch();
  const portActivityFilters = useSelector(getPortActivityFilters);
  const dateFormat = useSelector(getDateFormat);
  const { segmentMinDate } = useSegment();
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();
  const {
    lookupLocationsQuery: { data: lookupLocations }
  } = useStaticLookupQueries();

  const [tempFilters, setTempFilters] = useState(portActivityFilters);

  const updateTempFilterValue = (payload) => {
    setTempFilters((prevFilters) => ({ ...prevFilters, ...payload }));
  };

  const handleApply = () => {
    dispatch(updatePortActivityFilters(tempFilters));
  };

  const { startDate, endDate, selectedLocation, selectedOperators, selectType } = tempFilters;

  const checkValidFilters = selectedLocation && startDate && endDate;

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Stack className={'section-filter'}>
            <LocationSelect
              label={'Location*'}
              placeholder={'Search by port, city or region'}
              handleChange={(val) => {
                updateTempFilterValue({ selectedLocation: val });
              }}
              selectedLocation={selectedLocation}
              locationList={lookupLocations}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateTempFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <OperatorAdvancedMultiSelect
              selected={selectedOperators}
              autoEnabledText={'Displaying the top 5 operators given the selected period'}
              handleClose={(values) => {
                updateTempFilterValue({
                  selectType: values.operatorType,
                  selectedOperators: values.selectedOperators
                });
              }}
              loading={!lookupOperators}
              autoButtonTitle={'Top 5'}
              operators={lookupOperators}
              selectType={selectType}
              label={'Operators*'}
              showAdvanceButton={true}
            />
          </Stack>

          <Button
            onClick={handleApply}
            variant={'contained'}
            color={'primary'}
            sx={{ height: 'min-content' }}
            disabled={!checkValidFilters}>
            Apply Filters
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

PortActivityFilters.propTypes = {};

PortActivityFilters.defaultProps = {};

export default PortActivityFilters;
