import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, tableTitlePaddingLeft } from '@helpers';
import { ProfileLink } from '@components/Links';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { useSegment } from '@hooks/useSegment';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function EmissionComparisonTable({ loading, emissionsData, assetType }) {
  const [data, setData] = useState([]);
  const { isRoRo, isMPP } = useSegment();
  const user = useSelector(getUser);
  const {
    theme: { mode }
  } = useTheme();

  const getDisplayFields = (field) => {
    if (field !== assetType) {
      return { display: false, download: false, viewColumns: false, filter: false };
    }
  };

  useEffect(() => {
    if (!emissionsData) return;
    const { operatorEmissionDetailsList, vesselEmissionDetailsList } = emissionsData;
    if (assetType === 'operator') {
      setData(operatorEmissionDetailsList);
    } else {
      let tempData = [];
      for (let vessel of vesselEmissionDetailsList) {
        tempData.push({
          ...vessel,
          operatorId: vessel.currentOperator.operatorId,
          operatorLongName: vessel.currentOperator.operatorLongName,
          ownerId: vessel.currentOwner?.ownerId,
          ownerLongName: vessel.currentOwner?.ownerLongName
        });
      }
      setData(tempData);
    }
  }, [emissionsData]);

  const columns = [
    {
      name: 'vesselName',
      label: 'Vessel',
      options: {
        ...getDisplayFields('vessel'),
        sort: true,
        filterType: 'multiselect',
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filterType: 'textField',
        ...getDisplayFields('vessel'),
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'operatorLongName',
      label: `${assetType === 'operator' ? 'Operator' : 'Current operator'}`,
      options: {
        filterType: 'multiselect',
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'operator'} id={rowData[3]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: { display: false, download: false, viewColumns: false, filter: false }
    },
    {
      name: 'ownerLongName',
      label: `Current owner`,
      options: {
        viewColumns: isRoRo && assetType === 'vessel',
        filter: isRoRo && assetType === 'vessel',
        download: isRoRo && assetType === 'vessel',
        display: isRoRo && assetType === 'vessel',
        sort: true,
        filterType: 'multiselect',
        sortThirdClickReset: true,
        setCellProps: () => {
          return {
            style: {
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'owner'} id={rowData[5]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'ownerId',
      options: { display: false, download: false, viewColumns: false, filter: false }
    },
    {
      name: 'grossTonnage',
      label: 'Gross tonnage',
      options: {
        viewColumns: isRoRo && assetType === 'vessel',
        filter: isRoRo && assetType === 'vessel',
        download: isRoRo && assetType === 'vessel',
        display: isRoRo && assetType === 'vessel',
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          // Use lite version as it does not affect the filter value which should be a number
          return formatNumberReadable(data[dataIndex]?.grossTonnage?.toFixed(0));
        },
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Gross tonnage'),
        filterOptions: getMinMaxFilterOptions('Gross tonnage')
      }
    },
    {
      name: 'deadWeight',
      label: 'Deadweight',
      options: {
        viewColumns: isMPP && assetType === 'vessel',
        filter: isMPP && assetType === 'vessel',
        download: isMPP && assetType === 'vessel',
        display: isMPP && assetType === 'vessel',
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          // Use lite version as it does not affect the filter value which should be a number
          return formatNumberReadable(data[dataIndex]?.deadWeight?.toFixed(0));
        },
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Deadweight'),
        filterOptions: getMinMaxFilterOptions('Deadweight')
      }
    },
    {
      name: 'totalDistanceNm',
      label: 'Total distance[NM]',
      options: {
        customBodyRenderLite: (dataIndex) => {
          // Use lite version as it does not affect the filter value which should be a number
          return formatNumberReadable(data[dataIndex]?.totalDistanceNm?.toFixed(0));
        },
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Total distance'),
        filterOptions: getMinMaxFilterOptions('Total distance')
      }
    },
    {
      name: 'co2ByCeu',
      options: {
        viewColumns: isRoRo,
        filter: isRoRo,
        download: isRoRo,
        display: isRoRo,
        customHeadLabelRender: () => (
          <div>
            {assetType === 'operator' ? 'Avg. ' : ''} tCO<sub>2</sub>/CEU
          </div>
        ),
        customBodyRender: (dataIndex) => {
          return parseFloat(dataIndex?.toFixed(1));
        },
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions(
          `${assetType === 'operator' ? 'Avg. ' : ''}tCO2/CEU`
        ),
        filterOptions: getMinMaxFilterOptions(`${assetType === 'operator' ? 'Avg. ' : ''}tCO2/CEU`)
      }
    },
    {
      name: 'co2ByGrossTonnageDistance',
      options: {
        viewColumns: !(isMPP && assetType === 'operator'),
        filter: !(isMPP && assetType === 'operator'),
        download: !(isMPP && assetType === 'operator'),
        display: !(isMPP && assetType === 'operator'),
        customHeadLabelRender: () => (
          <div>
            gCO<sub>2</sub>/(GT•NM)
          </div>
        ),
        ...getDisplayFields('operator'),
        customBodyRender: (value) => {
          return parseFloat(value?.toFixed(1));
        },
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('tCO2/(GT•NM)'),
        filterOptions: getMinMaxFilterOptions('tCO2/(GT•NM)')
      }
    },
    {
      name: 'co2ByDeadweightDistance',
      options: {
        customHeadLabelRender: () => (
          <div>
            gCO<sub>2</sub>/(DWT•NM)
          </div>
        ),
        customBodyRender: (value) => {
          return parseFloat(value?.toFixed(1));
        },
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('tCO2/(DWT•NM)'),
        filterOptions: getMinMaxFilterOptions('tCO2/(DWT•NM)')
      }
    },
    {
      name: 'totalCo2',
      options: {
        customHeadLabelRender: () => (
          <div>
            Total tCO<sub>2</sub>
          </div>
        ),
        customBodyRenderLite: (dataIndex) => {
          return formatNumberReadable(data[dataIndex]?.totalCo2.toFixed(0) || 0);
        },
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Total tCO2'),
        filterOptions: getMinMaxFilterOptions('Total tCO2')
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    tableBodyMaxHeight: '80vh',
    tableBodyHeight: '80vh',
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `${assetType}-emission-comparison`
    },
    print: checkUserHasAccess(user, true),
    viewColumns: false,
    pagination: true,
    elevation: 0,
    toolbar: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: isRoRo ? 'co2ByCeu' : 'totalCo2',
      direction: 'desc'
    }
  };

  return (
    <Table
      customStyle={tableTitlePaddingLeft}
      loading={loading}
      mode={mode}
      title={
        <Typography variant={'h6'}>
          {assetType === 'operator' ? 'Operator' : 'Vessel'} CO<sub>2</sub> emission
        </Typography>
      }
      data={data?.length ? data : []}
      columns={columns}
      options={options}
    />
  );
}

EmissionComparisonTable.propTypes = {
  emissionsData: PropTypes.object,
  loading: PropTypes.bool,
  assetType: PropTypes.string
};

EmissionComparisonTable.defaultProps = {
  emissionsData: null,
  loading: false,
  assetType: 'operator'
};

export default EmissionComparisonTable;
