import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ColorBox, Paper, Stack, Table, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function RegionActivityTable({ loading, regionData, dateList }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { theme } = useTheme();
  const user = useSelector(getUser);

  useEffect(() => {
    if (!regionData || !dateList.length) return;
    const { operatorTotals, monthlyDateTotals } = regionData;
    let tempData = [];
    let tempColumns = [
      {
        name: 'date',
        label: 'Month, Year',
        options: {
          sort: true,
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = obj1.data;
              let val2 = obj2.data;
              return (
                (moment(val1, 'MMM yyyy').valueOf() - moment(val2, 'MMM yyyy').valueOf()) *
                (order === 'asc' ? 1 : -1)
              );
            };
          }
        }
      },
      {
        name: 'globalFleet',
        label: 'Total',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          }
        }
      }
    ];
    operatorTotals?.map(({ operatorNameShort }) => {
      tempColumns.push({
        name: operatorNameShort,
        label: operatorNameShort,
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            let percent = 0;
            if (dataIndex) {
              // Monthly totals comes in reverse of dataIndex. So used the flipped index value
              percent = (dataIndex / rowData[1]) * 100;
              percent = percent > 1 ? percent?.toFixed(0) : percent?.toFixed(1);
            }
            return (
              <Stack alignItems={'center'} direction={'row'}>
                <Typography variant={'caption'} component={'span'}>
                  {dataIndex}
                </Typography>
                <ColorBox color={'info'}>
                  <Typography variant={'caption'}>{percent}%</Typography>
                </ColorBox>
              </Stack>
            );
          }
        }
      });
    });

    dateList.forEach((date, i) => {
      let obj = { date: date };
      operatorTotals.forEach(({ monthlyDateTotals: oprTotals, operatorNameShort }) => {
        obj[operatorNameShort] = oprTotals ? oprTotals[i]?.total : null;
      });
      obj['globalFleet'] = monthlyDateTotals[i]?.total;
      tempData.push(obj);
    });
    setData(tempData.reverse());
    setColumns(tempColumns);
  }, [regionData, dateList]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      downloadOptions: {
        filename: 'region-fleet-history.csv'
      },
      download: checkUserHasAccess(user, true),
      print: checkUserHasAccess(user, true),
      elevation: 0,
      viewColumns: false,
      pagination: false,
      toolbar: checkUserHasAccess(user, true),
      tableBodyMaxHeight: '80vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'date',
        direction: 'desc'
      }
    };
  }, [user]);
  return (
    <Paper>
      <Table
        skeletonRows={5}
        loading={loading}
        mode={theme.mode}
        title={'Number of unique vessels total and by operator per month'}
        data={data.length ? data : []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

RegionActivityTable.propTypes = {
  loading: PropTypes.bool,
  dateList: PropTypes.arrayOf(PropTypes.string),
  regionData: PropTypes.object
};

RegionActivityTable.defaultProps = {
  loading: false,
  dateList: [],
  regionData: null
};

export default RegionActivityTable;
