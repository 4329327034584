import { Stack, Typography } from '@esgian/esgianui';
import React from 'react';

export const tooltipConsistencyContents = (
  <Stack>
    <Typography variant={'caption'}>
      The consistency score is “relative standard deviation” between the values in frequency /
      number of sailings and transit time among the operators in the chosen period.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      The values are capped between 1 (lowest) to 5 (highest) and looks at the relative difference
      between the values.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      We do not penalize carriers with lower frequency - it only looks at the difference between the
      numbers.{' '}
    </Typography>
    <br />
    <Typography variant={'caption'}>
      As an example - if an operator has monthly sailing frequency of 1-1-1-1-1 in five months =
      this gives a max score of 5/5.
    </Typography>
    <Typography variant={'caption'}>
      If another operator has 4-4-4-4-4, it will also only give max 5/5.
    </Typography>
    <br />

    <Typography variant={'caption'}>
      Normally, most operators have a certain degree of variation, so say 1-4-5-3-1 will give a
      lower score than 5/5 as the numbers do vary month by month.
    </Typography>
  </Stack>
);
