import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Grid,
  NotFound,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@esgian/esgianui';
import PortCallsByOperatorTable from '@components/Sections/ShipAnalytics/PortActivitySection/PortCallsByOperatorTable';
import PortCallConsistencyChart from '@components/Sections/ShipAnalytics/PortActivitySection/PortCallConsistencyChart';
import PortCallFrequencyChart from '@components/Sections/ShipAnalytics/PortActivitySection/PortCallFrequencyChart';
import { getPortActivity } from '@api';
import { getDateList, formatPeriodDisplay } from '@helpers';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import PortActivityFilters from '@components/Sections/ShipAnalytics/PortActivitySection/PortActivityFilters';
import MissingOperatorsDisplay from '@components/MissingOperatorsDisplay';
import { DATE_FORMAT, DATE_TIME_FORMAT, OPERATOR_SELECT_TYPES } from '@constants';
import { useDynamicLookupQueries, useSegment, useStaticLookupQueries } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import {
  getPortActivityFilters,
  PORT_ACTIVITY_SLICE_KEYS,
  setPortActivitySection,
  updatePortActivityFilters
} from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';
import {
  setSelectedLocationToUrl,
  setSelectedOperatorsToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';

const initUrlParams = (portActivityFilters, lookupLocations, lookupOperators, searchParams) => {
  let newFilters = { ...portActivityFilters };
  searchParams.forEach((value, key) => {
    if (key === 'section') {
      return;
    }
    let filterKey = `${key[2].toLowerCase()}${key.slice(3)}`;
    if (PORT_ACTIVITY_SLICE_KEYS[filterKey] === 'number') {
      newFilters[filterKey] = parseInt(value);
    }
    if (PORT_ACTIVITY_SLICE_KEYS[filterKey] === 'string') {
      newFilters[filterKey] = value;
    }
    if (PORT_ACTIVITY_SLICE_KEYS[filterKey] === 'date') {
      newFilters[filterKey] = moment(value, DATE_FORMAT).isValid()
        ? moment(value, DATE_FORMAT).format(DATE_FORMAT)
        : null;
    }
  });
  let location = parseInt(searchParams.get('paSelectedLocation'));
  let locationType = searchParams.get('paSelectedLocationType');

  if (location && locationType) {
    newFilters.selectedLocation =
      lookupLocations.find(({ id, type }) => id === location && type === locationType) || null;
  }
  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  const ext = 'pa';
  const { selectedOperators, selectType, selectedLocation } = filters;

  searchParams = setSliceToUri(filters, PORT_ACTIVITY_SLICE_KEYS, ext, searchParams);
  searchParams = setSelectedOperatorsToUrl(
    selectedOperators,
    selectType,
    `${ext}SelectedOperators`,
    searchParams
  );
  searchParams = setSelectedLocationToUrl(selectedLocation, 'paSelectedLocation', searchParams);

  setSearchParams(searchParams);
};

const getMissingOperators = (operatorResults, selectedOperators) => {
  return selectedOperators.filter((object1) => {
    return !operatorResults.some((object2) => {
      return object2.operatorId === object1.id;
    });
  });
};

function PortActivitySection() {
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [noData, setNoData] = useState(true);
  const [portActivityData, setPortActivityData] = useState(null);
  const [missingOperators, setMissingOperators] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { segment, isRoRo } = useSegment();
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();
  const {
    lookupLocationsQuery: { data: lookupLocations }
  } = useStaticLookupQueries();
  const portActivityFilters = useSelector(getPortActivityFilters);
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const { subSection, startDate, endDate, selectedLocation, selectedOperators, selectType } =
    portActivityFilters;

  const dateList = useMemo(() => {
    if (!startDate || !endDate) return [];
    return getDateList(startDate, endDate);
  }, [startDate, endDate]);

  const dateString = useMemo(() => {
    const CodePOL = selectedLocation?.name.toLowerCase().replace(/\s/g, '-');
    return `${CodePOL}-${moment(startDate).format('MMYY')}-${moment(endDate).format('MMYY')}`;
  }, [selectedLocation, startDate, endDate]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const loadPortServiceData = async () => {
      try {
        if (
          !init ||
          !selectedLocation ||
          !moment(startDate).isValid() ||
          !moment(endDate).isValid()
        ) {
          return;
        }
        const operatorIds = selectedOperators.map(({ id }) => id);

        setLoading(true);
        setNoData(false);

        await getPortActivity(
          selectedLocation?.type,
          selectedLocation.id,
          moment(startDate).startOf('day').format(DATE_TIME_FORMAT),
          moment(endDate).isSame(moment(), 'day')
            ? moment(endDate).subtract(1, 'day').endOf('day').format(DATE_TIME_FORMAT)
            : moment(endDate).endOf('day').format(DATE_TIME_FORMAT),
          operatorIds,
          selectType === OPERATOR_SELECT_TYPES.AUTO,
          operatorType,
          segment.id,
          commercialCategoryType,
          signal
        ).then((response) => {
          const { operatorResults } = response;
          if (!operatorResults.length) {
            setNoData(true);
            setPortActivityData(null);
          } else {
            let missing = getMissingOperators(operatorResults, selectedOperators);
            setMissingOperators(missing);
            setPortActivityData(response);
            setNoData(false);
          }
          setLoading(false);
        });
      } catch (error) {
        setNoData(true);
        setLoading(false);
        setPortActivityData(null);
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };

    loadPortServiceData();
    return () => {
      controller.abort();
    };
  }, [
    selectedLocation,
    startDate,
    endDate,
    init,
    selectType,
    operatorType,
    commercialCategoryType,
    selectedOperators
  ]);

  useEffect(() => {
    if (init) {
      setUrlParams(portActivityFilters, searchParams, setSearchParams);
    }
  }, [portActivityFilters, init]);

  useEffect(() => {
    if (lookupLocations?.length && lookupOperators?.length) {
      let newFilters = initUrlParams(
        portActivityFilters,
        lookupLocations,
        lookupOperators,
        searchParams
      );

      dispatch(updatePortActivityFilters(newFilters));
      setInit(true);
    }
  }, [lookupLocations, lookupOperators]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, maxWidth: '100%' }}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid id={'port-activity-chart'} item xs={12}>
              <Grid item container xs={12}>
                <Grid item>
                  <Typography variant={'h6'} id={'port-activity-header'}>
                    Port Activity
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <PortActivityFilters />
            <Grid item xs={12}>
              <Tabs
                value={subSection}
                onChange={(_, val) => {
                  dispatch(setPortActivitySection(val));
                }}
                variant="scrollable"
                aria-label="scrollable prevent tabs example">
                <Tab value={1} label="Port Call Frequency" id={'port-call-frequency-tab'} />
                <Tab value={2} label="Port Call Consistency" id={'port-call-consistency-tab'} />
              </Tabs>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <MissingOperatorsDisplay
                noData={noData}
                alertText={
                  'Some of the selected operators does not have any port activity between the selected dates and ports.'
                }
                setMissingOperators={setMissingOperators}
                setSelectedOperators={(operators) => {
                  dispatch(updatePortActivityFilters({ selectedOperators: operators }));
                }}
                selectedOperators={selectedOperators}
                missingOperators={missingOperators}
              />
              {!loading && noData && (
                <NotFound
                  show={true}
                  header={'No Matching Result'}
                  text={'Please change your search parameters'}
                />
              )}
              {subSection === 1 && !noData && (
                <PortCallFrequencyChart
                  loading={loading}
                  selectedOperators={selectedOperators}
                  fileName={`PortCallFrequency-${dateString}`}
                  portActivityData={portActivityData}
                  dateList={dateList}
                />
              )}
              {subSection === 2 && !noData && (
                <PortCallConsistencyChart
                  loading={loading}
                  selectedOperators={selectedOperators}
                  selectedPort={selectedLocation}
                  fileName={`PortCallConsistency-${dateString}`}
                  portActivityData={portActivityData}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {!loading && !noData && (
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Paper sx={{ maxWidth: '100%' }}>
            <Grid container spacing={2} id={'port-activity-table'}>
              <Grid item xs={12}>
                <Stack sx={{ p: 2 }}>
                  <Typography variant={'h6'} id={'table-header'}>
                    Total number of port calls by operator{' '}
                  </Typography>
                  <Typography color={'text.secondary'} variant={'body2'} id={'table-subheader'}>
                    From {formatPeriodDisplay(startDate, endDate)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ pl: 2, pr: 2 }}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <PortCallsByOperatorTable
                  selectedLocation={selectedLocation}
                  loading={loading}
                  portCallData={portActivityData}
                  dateList={dateList}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

PortActivitySection.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedPort: PropTypes.object
};

PortActivitySection.defaultProps = {
  startDate: null,
  endDate: null,
  selectedPort: {}
};

export default PortActivitySection;
