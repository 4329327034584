import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Stack, Typography, Skeleton } from '@esgian/esgianui';
import CanalVesselPortCallsTable from '@components/Tables/CanalVesselPortCallsTable';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useTheme } from '@hooks';
import { VesselsMap } from '@components/Maps';
import AdvancedTooltip from '@components/Maps/VesselsMap/AdvancedTooltip';

const HIDDEN_COLUMNS = {
  departures: [
    'Speed',
    'Heading',
    'Estimated Time of Arrival',
    'Position received',
    'ETA',
    'Destination LOCODE',
    'Canal Arrival',
    'Time until arrival'
  ],
  arrivals: [
    'Estimated Time of Arrival',
    'Origin',
    'Origin ATD',
    'Arrival',
    'Departure',
    'Time in transit (hours)',
    'Destination LOCODE',
    'Canal arrival',
    'Direction',
    'Destination ETA',
    'Destination ATA',
    'Canal Arrival',
    'Speed',
    'Heading',
    'Destination'
  ],
  inTransit: [
    'Estimated Time of Arrival',
    'Arrival',
    'Departure',
    'Time in transit (hours)',
    'Direction',
    'Origin',
    'Origin ATD',
    'Destination ETA',
    'Destination ATA',
    'Time until arrival',
    'Operator',
    'Speed',
    'Heading',
    'Destination LOCODE',
    'Position received',
    'IMO'
  ]
};

function CanalOverviewSection({
  loading = false,
  canalDeparturesData = [],
  canalArrivalsData = [],
  canalInTransitData = [],
  drawPolygon = '',
  canalLat = 0,
  canalLong = 0,
  canalCode = '',
  canalName = ''
}) {
  const { theme } = useTheme();

  let vesselsData = [];
  if (canalArrivalsData) {
    vesselsData = canalArrivalsData;
  }
  if (canalInTransitData) {
    vesselsData = [...vesselsData, ...canalInTransitData];
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Stack direction={'row'} spacing={2} sx={{ p: 2 }}>
                <Stack spacing={2}>
                  <Typography variant={'body2'} bold>
                    Name:
                  </Typography>
                  <Typography variant={'body2'} bold>
                    Location(lat/lon):
                  </Typography>
                  <Typography variant={'body2'} bold>
                    UNLOCODE:
                  </Typography>
                </Stack>
                {loading ? (
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <Skeleton variant="rectangular" height={'100%'} />
                    <Skeleton variant="rectangular" height={'100%'} />
                    <Skeleton variant="rectangular" height={'100%'} />
                  </Stack>
                ) : (
                  <Stack spacing={2}>
                    <Typography variant={'body2'}>{canalName}</Typography>
                    <Typography variant={'body2'}>{canalLat + ', ' + canalLong}</Typography>
                    <Typography variant={'body2'}>{canalCode}</Typography>
                  </Stack>
                )}
              </Stack>
              {canalInTransitData.length > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <br />
              {canalInTransitData.length > 0 && (
                <Grid item xs={12}>
                  <CanalVesselPortCallsTable
                    assetLevel={'canal'}
                    canalDeparturesData={canalInTransitData}
                    hideColumns={HIDDEN_COLUMNS.inTransit}
                    title={'In Transit'}
                    loading={loading}
                    fileName={`Vessels-In-Transit-${canalName.replace(' ', '-')}.csv`}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <VesselsMap
                loading={loading || !canalLong || !canalLat}
                showSpeedLegend
                height={'50vh'}
                zoom={7.2}
                projection={'mercator'}
                mapStyle={theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE}
                vessels={vesselsData ?? []}
                center={[canalLong, canalLat] ?? [0, 0]}
                regionPolygon={drawPolygon ?? null}
                isCanal={true}
              />
              {!loading && !vesselsData && (
                <AdvancedTooltip
                  loading={loading}
                  mapHeight={'50vh'}
                  vessels={null}
                  isCanal={true}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CanalVesselPortCallsTable
          assetLevel={'canal'}
          canalDeparturesData={canalDeparturesData}
          hideColumns={HIDDEN_COLUMNS.departures}
          title={'Recent Departures'}
          loading={loading}
          fileName={`Recent-Departures-${canalName.replace(' ', '-')}.csv`}
        />
      </Grid>
      <Grid item xs={12}>
        <CanalVesselPortCallsTable
          assetLevel={'canal'}
          canalDeparturesData={canalArrivalsData}
          hideColumns={HIDDEN_COLUMNS.arrivals}
          title={'Expected Arrivals'}
          loading={loading}
          fileName={`Upcoming-Port-Calls-${canalName.replace(' ', '-')}.csv`}
        />
      </Grid>
    </>
  );
}

CanalOverviewSection.propTypes = {
  canalDeparturesData: PropTypes.arrayOf(PropTypes.object),
  canalArrivalsData: PropTypes.arrayOf(PropTypes.object),
  canalInTransitData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  drawPolygon: PropTypes.string,
  canalLat: PropTypes.number,
  canalLong: PropTypes.number,
  canalCode: PropTypes.string,
  canalName: PropTypes.string
};

export default CanalOverviewSection;
