import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@esgian/esgianui';
import PortsInRegionTable from '@components/Tables/PortsInRegionTable';
import { RegionMap } from '@components/Maps';
import { UpcomingVesselPortCallsTable, VesselPortCallsTable } from '@components/Tables';
import { useQueries } from '@tanstack/react-query';
import { getActivePorts, getLatestPortCalls, getRegionUpcomingPortCalls } from '@api';
import { DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import { useParams } from 'react-router-dom';

function RegionOverviewSection({ regionName, geoId }) {
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();

  const overviewQueries = useQueries({
    queries: [
      {
        queryKey: ['activeRegionPorts', operatorType, segment, commercialCategoryType],
        placeholderData: null,
        queryFn: ({ signal }) => {
          return getActivePorts(
            {
              geoIds: [params.regionId],
              geoType: GEOGRAPHICAL_TYPES.REGION,
              operatorType: operatorType,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((resp) => resp)
            .catch(() => []);
        }
      },
      {
        queryKey: ['regionUpcomingPortCalls', operatorType, segment, commercialCategoryType],
        placeholderData: [],
        queryFn: ({ signal }) => {
          return getRegionUpcomingPortCalls(
            {
              regionId: params.regionId,
              NumberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((resp) => resp)
            .catch(() => []);
        }
      },
      {
        queryKey: ['regionLastPortCalls', operatorType, segment, commercialCategoryType],
        placeholderData: [],
        queryFn: ({ signal }) => {
          return getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.REGION,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              geoIds: [params.regionId]
            },
            signal
          )
            .then((resp) => resp)
            .catch(() => []);
        }
      }
    ]
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PortsInRegionTable
            loading={overviewQueries[0].isFetching}
            regionName={regionName}
            regionPortsData={overviewQueries[0].data}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <RegionMap
              loading={overviewQueries[0].isFetching}
              height={'40vh'}
              portData={overviewQueries[0].data}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <VesselPortCallsTable
            loading={overviewQueries[2].isFetching}
            portCalls={overviewQueries[2].data}
            assetLevel={'region'}
            name={regionName}
            geoId={geoId}
          />
        </Grid>
        <Grid item xs={12}>
          <UpcomingVesselPortCallsTable
            assetLevel={'region'}
            title={'Upcoming Vessels Port Calls'}
            upcomingPortCalls={overviewQueries[1].data}
            loading={overviewQueries[1].isFetching}
            name={regionName}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

RegionOverviewSection.propTypes = {
  regionName: PropTypes.string,
  geoId: PropTypes.number
};

RegionOverviewSection.defaultProps = {
  regionName: null,
  geoId: null
};

export default RegionOverviewSection;
