import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks/useTheme';

function SimulatedVoyageSpeedChart({ voyageTransitDetails }) {
  const { theme, themeMode } = useTheme();

  const seriesData = voyageTransitDetails?.transitDetails?.map((route) => {
    return {
      x: new Date(route.timeStamp),
      y: route.speed
    };
  });

  const series = [
    {
      name: 'Speed (kts)',
      data: seriesData
    }
  ];

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      background: theme.palette.charts.background
    },
    colors: ['#80DFEB'],

    stroke: {
      width: [2],
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd'
      }
    },
    yaxis: {
      labels: {
        formatter: (val) => (val === null ? '-' : val.toFixed(1))
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left'
    },
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.mode
    },
    annotations: {
      xaxis: [
        {
          x: new Date(),
          borderColor: '#EF5350',
          strokeDashArray: 0
        }
      ]
    }
  };

  return (
    <Box height={'100%'} width={'100%'}>
      <ApexChart options={options} type={'line'} data={series} height={'100%'} width={'100%'} />
    </Box>
  );
}

SimulatedVoyageSpeedChart.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired
};

export default SimulatedVoyageSpeedChart;
