import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@esgian/esgianui';
import OverviewTab from './OverviewTab';
import VoyageTab from './VoyageTab';
import { PageLinkIcon } from '@components';
import { useTheme, useNextPortCall, useLatestPortCalls } from '@hooks';
import { ProfileLink } from '@components/Links';

const VesselDetailsWindow = ({
  vesselImo,
  vesselsLatestPosition,
  vesselsInformationByImo,
  isLoadingVesselsDetails
}) => {
  const { customScrollbar } = useTheme();
  const selectedVesselInformation = vesselsInformationByImo.get(+vesselImo);
  const [isVoyageTabSelected, setIsVoyageTabSelected] = useState(false);

  const { latestPortCalls, isLoadingLatestPortCalls } = useLatestPortCalls(+vesselImo);
  const { nextPortCall, isLoadingNextPortCall } = useNextPortCall(vesselImo);

  const selectedVesselLatestPosition = vesselsLatestPosition?.find(
    (vessel) => vessel.imo === +vesselImo
  );
  const selectedVesselNextPortCall = nextPortCall ?? {};

  const selectedVesselLatestPortCall = latestPortCalls?.[0] ?? {};

  const renderTabContent = () => {
    if (isVoyageTabSelected) {
      return (
        <VoyageTab
          selectedVesselNextPortCall={selectedVesselNextPortCall}
          isLoadingNextPortCall={isLoadingNextPortCall}
          latestPortCalls={latestPortCalls}
          isLoadingLatestPortCalls={isLoadingLatestPortCalls}
        />
      );
    }

    return (
      <OverviewTab
        selectedVesselLatestPosition={selectedVesselLatestPosition}
        selectedVesselInformation={selectedVesselInformation}
        selectedVesselLatestPortCall={selectedVesselLatestPortCall}
        selectedVesselNextPortCall={selectedVesselNextPortCall}
        isLoadingVesselsDetails={isLoadingVesselsDetails}
      />
    );
  };

  return (
    <Box p={2} display="flex" flexDirection="column" gap={2} height="100%">
      <Typography variant="h6">{selectedVesselLatestPosition?.vesselName}</Typography>
      <Box display="flex" gap={1}>
        <Button
          variant={isVoyageTabSelected ? 'outlined' : 'contained'}
          size="small"
          color={isVoyageTabSelected ? 'secondary' : 'primary'}
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVoyageTabSelected(false)}>
          Overview
        </Button>
        <Button
          size="small"
          variant={isVoyageTabSelected ? 'contained' : 'outlined'}
          color={isVoyageTabSelected ? 'primary' : 'secondary'}
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVoyageTabSelected(true)}>
          Voyage
        </Button>
      </Box>
      <Box
        display="flex"
        gap={2}
        flexDirection="column"
        flex={1}
        overflow="auto"
        sx={customScrollbar}>
        {renderTabContent()}
      </Box>
      <Box textAlign="center" mt="auto">
        <Button startIcon={<PageLinkIcon />} sx={{ textTransform: 'none' }}>
          <ProfileLink
            name={selectedVesselInformation?.vesselName}
            profile={'vessel'}
            id={vesselImo}
            textColor="black"
          />
        </Button>
      </Box>
    </Box>
  );
};

VesselDetailsWindow.propTypes = {
  vesselImo: PropTypes.string.isRequired,
  vesselsLatestPosition: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselsInformationByImo: PropTypes.object,
  isLoadingVesselsDetails: PropTypes.bool
};

export default VesselDetailsWindow;
