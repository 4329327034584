import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, goals, theme, user, speedHistogramData, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      height: 350,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: [twoColorsContrast[1], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Speed [kn]'
      }
    },
    yaxis: [
      {
        min: 0,
        title: {
          text: '% of time'
        },
        decimalsInFloat: 2
      }
    ],
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          formatter: function (val, opt) {
            const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`;
            }
            return val;
          }
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: function ({ series, dataPointIndex, w }) {
        const {
          config: {
            xaxis: { categories }
          }
        } = w;
        return `<div><div style='display: flex; flex-direction: column; gap: 8px'>
        <div style='width: 100%; padding: 8px; display: flex; background: ${
          mode === 'light' ? 'rgba(229,231,234,0.24)' : '#010101'
        }; border-bottom: 1px solid ${mode === 'light' ? 'rgba(229,231,234, 1)' : '#111'};'>
            <div>${categories[dataPointIndex]} kn</div>
        </div>
        <div style='padding-left: 16px; padding-right: 16px; padding-bottom: 8px'><div style='display: flex; flex-direction: column; gap: 8px'>
          <div style='flex-direction: row; display: flex; gap: 13px; align-items: center;'>
            <div style='width: 10px; height:10px; background-color: ${twoColorsContrast[1]}'></div>
            <div style='font-family: Nato Sans, sans-serif; font-size: 0.852rem'>Last 12 months:    <b>${series[0][
              dataPointIndex
            ]?.toFixed(2)}</b>%</div>
          </div>
          <div style='flex-direction: row; display: flex; gap: 8px; align-items: center;'>
            <div style='width: 15px; height:3px; background-color: ${twoColorsContrast[0]}'></div>
            <div style='font-family: Nato Sans, sans-serif; font-size: 0.852rem'>${
              moment().year() - 1
            }:    <b>${goals[dataPointIndex]}</b>%</div>
          </div>
          </div>
        </div>`;
      }
    },
    legend: {
      position: 'top',
      showForSingleSeries: true,
      horizontalAlign: 'left',
      customLegendItems: ['Last 12 months', moment().year() - 1]
    }
  };
};

function VesselSpeedHistogram({ loading, speedHistogramData, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(null);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!speedHistogramData?.length) return;
    const {
      palette: {
        charts: { twoColorsContrast }
      }
    } = theme;

    const { orderedSpeedSegments } = speedHistogramData[0];
    let goals = [];
    let tempSeries =
      orderedSpeedSegments.map((val) => {
        goals.push(speedHistogramData[1].percentSpeedSegments[val]?.toFixed(2) || 0);
        return {
          x: [val],
          y: [speedHistogramData[0].percentSpeedSegments[val] + ''],
          goals: [
            {
              name: speedHistogramData[1].label,
              value: speedHistogramData[1].percentSpeedSegments[val].toFixed(2),
              strokeHeight: 3,
              strokeColor: twoColorsContrast[0]
            }
          ]
        };
      }) || [];
    setSeries([{ name: speedHistogramData[0].label, data: tempSeries }]);
    setOptions(
      getOptions(orderedSpeedSegments, goals, theme, user, speedHistogramData || [], fileName)
    );
  }, [speedHistogramData, theme]);

  return (
    <>
      <ApexChart
        loading={!series?.length || loading}
        height={480}
        options={options || {}}
        type={'bar'}
        data={series}
      />
    </>
  );
}
VesselSpeedHistogram.propTypes = {
  loading: PropTypes.bool,
  speedHistogramData: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string.isRequired
};
VesselSpeedHistogram.defaultProps = {
  speedHistogramData: [],
  loading: false
};
export default VesselSpeedHistogram;
