import React, { useState } from 'react';
import {
  Box,
  Button,
  DeleteOutlineIcon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Popover,
  Typography
} from '@esgian/esgianui';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useParams, useSearchParams } from 'react-router-dom';
import { useVoyageGroups } from '@pages/VoyageAnalytics/hooks/useVoyageGroups';
import VoyageGroupListPopup from './VoyageGroupListPopup';
import PropTypes from 'prop-types';
import AddToVoyageGroupPopup from './AddToVoyageGroupPopup';
import CreateVoyageGroupPopup from './CreateVoyageGroupPopup';
import { useGetVoyageType } from '../../../../pages/VoyageAnalytics/hooks/useGetVoyageType';
import { VoyageType } from '../VoyageUtils';
import FavoriteIcon from '../../../Icons/FavoriteIcon';

export const NUM_MAX_FAVORITE_GROUP = 1;

const VoyageGroupsPopup = ({ mode }) => {
  const isSearchPage = mode === 'search';

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenManageModal, setIsOpenManageModal] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [voyageGroupToEdit, setVoyageGroupToEdit] = useState();

  const params = useParams();

  const {
    ongoingVoyagesGroups,
    completedVoyagesGroups,
    simulatedVoyagesGroups,
    isLoadingVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    createVoyageGroup,
    isCreatingVoyageGroup,
    renameVoyageGroup,
    isRenamingVoyageGroup,
    addVoyagesToGroup,
    isAddingVoyagesToGroup,
    updateVoyageGroups,
    isUpdatingVoyageGroups
  } = useVoyageGroups();
  const [searchParams] = useSearchParams();

  const voyageGroupType = useGetVoyageType();

  const fromPortIdParam = searchParams.get('fromPortId');
  const toPortIdParam = searchParams.get('toPortId');
  const departureDateParam = searchParams.get('departureDate');
  const arrivalDateParam = searchParams.get('arrivalDate');

  const voyageIdentity = {
    imo: params.imo,
    fromPortId: fromPortIdParam === 'null' ? null : fromPortIdParam,
    toPortId: toPortIdParam === 'null' ? null : toPortIdParam,
    departureDate: departureDateParam,
    arrivalDate: arrivalDateParam
  };

  const isDisabledManageModalActions = isDeletingVoyageGroup || isUpdatingVoyageGroups;

  const filteredGroups =
    voyageGroupType === VoyageType.ONGOING
      ? ongoingVoyagesGroups
      : voyageGroupType === VoyageType.SIMULATED
      ? simulatedVoyagesGroups
      : completedVoyagesGroups;
  const updatedGroupPerId = filteredGroups?.reduce((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {});

  const handleAddVoyageToGroup = (groupId) => {
    if (!voyageIdentity.imo || !voyageIdentity.departureDate) {
      return;
    }
    addVoyagesToGroup({
      voyageGroupId: groupId,
      voyagesToAdd: [voyageIdentity]
    });
  };

  const isMaxFavoriteGroupSelected =
    !updatedGroupPerId ||
    Object.values(updatedGroupPerId).filter((v) => v.isFavorite).length >= NUM_MAX_FAVORITE_GROUP;

  return (
    <>
      <Box>
        <Button
          onClick={({ currentTarget }) => {
            setAnchorEl(currentTarget);
          }}
          id="voyage-save-group-button"
          variant="text"
          sx={{
            height: 'fit-content',
            size: '0.875rem',
            textTransform: 'none'
          }}
          startIcon={<FavoriteBorderIcon sx={{ fontSize: 16 }} />}>
          <Typography variant="body2">
            {isSearchPage ? `MY VOYAGE GROUP` : 'SAVE TO GROUP'}
          </Typography>
        </Button>
        <Popover
          onClose={() => {
            setAnchorEl(undefined);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}>
          {isSearchPage ? (
            <VoyageGroupListPopup
              setModalOpen={setModalOpen}
              savedGroups={filteredGroups}
              isSavingVoyageToGroup={isAddingVoyagesToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
              setIsOpenManageModal={setIsOpenManageModal}
              voyageGroupType={voyageGroupType}
            />
          ) : (
            <AddToVoyageGroupPopup
              onCreateANewGroup={() => setModalOpen(true)}
              savedGroups={filteredGroups}
              onAddVoyageToGroup={handleAddVoyageToGroup}
              isSavingVoyageToGroup={isAddingVoyagesToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
              voyageGroupType={voyageGroupType}
            />
          )}
        </Popover>
      </Box>
      <CreateVoyageGroupPopup
        open={modalOpen || voyageGroupToEdit}
        setModalOpen={setModalOpen}
        existingGroups={filteredGroups}
        isSubmitDisabled={isCreatingVoyageGroup || isRenamingVoyageGroup}
        onSubmit={async (newVoyageGroupName, clearInput) => {
          try {
            if (voyageGroupToEdit) {
              await renameVoyageGroup({
                newName: newVoyageGroupName,
                voyageGroupToRename: voyageGroupToEdit
              });
              clearInput?.();
              setVoyageGroupToEdit(undefined);
            } else {
              const newGroup = await createVoyageGroup({
                newVoyageGroupName,
                type: voyageGroupType
              });
              clearInput?.();
              setModalOpen(false);
              handleAddVoyageToGroup(newGroup.id);
            }
          } catch (error) {
            console.error(
              voyageGroupToEdit ? 'Failed to rename voyage group' : 'Failed to create voyage group'
            );
          }
        }}
        voyageGroupToEdit={voyageGroupToEdit}
        setVoyageGroupToEdit={setVoyageGroupToEdit}
      />
      <Modal
        title="Manage my voyage group"
        open={isOpenManageModal}
        handleClose={() => setIsOpenManageModal(false)}>
        {filteredGroups.length > 0 ? (
          <>
            <List dense>
              {filteredGroups.map((v) => {
                const isFavoriteGroup = updatedGroupPerId?.[v.id]?.isFavorite;
                const isDisableAddToFavorite = !isFavoriteGroup && isMaxFavoriteGroupSelected;
                return (
                  <ListItem
                    disabled={isDisabledManageModalActions}
                    key={v.id}
                    secondaryAction={
                      <Box display="flex">
                        <Button
                          size={'small'}
                          variant="text"
                          onClick={() => {
                            setVoyageGroupToEdit(v);
                          }}>
                          Edit
                        </Button>
                        <IconButton
                          size={'small'}
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            deleteVoyageGroup(v);
                          }}>
                          <DeleteOutlineIcon fontSize={'small'} />
                        </IconButton>
                      </Box>
                    }>
                    <Box display="flex" gap={1} alignItems="center">
                      {updatedGroupPerId && (
                        <IconButton sx={{ p: 0 }}>
                          <FavoriteIcon
                            isFavorite={!!isFavoriteGroup}
                            disabled={isDisableAddToFavorite}
                            disabledMessage={`Max ${NUM_MAX_FAVORITE_GROUP} favorite groups allowed`}
                          />
                        </IconButton>
                      )}
                      <ListItemText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        primary={v.name}
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <Typography sx={{ my: 2 }}>No saved groups</Typography>
        )}
        <Box display="flex" gap={2} justifyContent="center" alignItems="center">
          <Button
            disabled={isDisabledManageModalActions}
            variant="text"
            onClick={() => setIsOpenManageModal(false)}>
            Close
          </Button>
          <Button
            disabled={isDisabledManageModalActions}
            onClick={async () => {
              await updateVoyageGroups({ updatedGroups: Object.values(updatedGroupPerId) });
              setIsOpenManageModal(false);
            }}
            style={{ width: '100px' }}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

VoyageGroupsPopup.defaultProps = {
  mode: 'search'
};

VoyageGroupsPopup.propTypes = {
  mode: PropTypes.string
};

export default VoyageGroupsPopup;
