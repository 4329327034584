import { Box, InfoOutlineIcon, Paper, Stack, ToolTip, Typography } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';
import { formatToDecimalPlaces } from '@helpers';

const VoyageSummaryShortestRouteOverviewFloatingWindow = ({
  shortestPathDistanceNm,
  voyageTransitDetails
}) => {
  const { themeMode } = useSelector(getGeneralSlice);

  const shortestTransitTime =
    (shortestPathDistanceNm * voyageTransitDetails?.transitDays) /
    voyageTransitDetails?.totalDistance;

  return (
    <Paper
      sx={{
        zIndex: 20,
        width: '20rem',
        background: themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)',
        p: '0.5rem 0rem'
      }}>
      <Stack pl={2} pr={2} spacing={0.5}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="4" fill="#CD91DC" />
          </svg>
          <Typography variant={'body2'} color="primary.contrastText">
            Shortest Route
          </Typography>
          <ToolTip
            title={
              'Predefined shortest routes by Esgian between ports visited in the voyage.  Used for emissions reporting.'
            }>
            <InfoOutlineIcon sx={{ fontSize: 16, color: 'primary.contrastText' }} />
          </ToolTip>
        </Box>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} color="primary.contrastText">
            Distance (NM):
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {formatToDecimalPlaces(shortestPathDistanceNm, 2)}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} color="primary.contrastText">
            Transit Time:
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {formatToDecimalPlaces(shortestTransitTime, 2)} days
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default VoyageSummaryShortestRouteOverviewFloatingWindow;

VoyageSummaryShortestRouteOverviewFloatingWindow.propTypes = {
  shortestPathDistanceNm: PropTypes.number.isRequired,
  voyageTransitDetails: PropTypes.object
};
