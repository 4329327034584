import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesBrushChart } from '@components/Charts';

const getSeries = (portServiceData) => {
  const { monthlyFrequencyTotals, frequencyResults } = portServiceData;
  let resultMain = [];
  frequencyResults.forEach(({ operatorLongName, operatorMonthlyFrequencyResults }) => {
    resultMain.push({
      name: operatorLongName,
      data: operatorMonthlyFrequencyResults.map(({ portCalls }) => portCalls)
    });
  });

  return {
    main: resultMain,
    brush: [
      { name: 'Total', data: monthlyFrequencyTotals.map(({ portCallTotal }) => portCallTotal) }
    ]
  };
};

function ServiceFrequencyChart({ loading, portServiceData, dateList, fileName }) {
  const [seriesMain, setSeriesMain] = useState([]);
  const [seriesBrush, setSeriesBrush] = useState([]);

  useEffect(() => {
    if (portServiceData) {
      const { main, brush } = getSeries(portServiceData);
      setSeriesMain(main);
      setSeriesBrush(brush);
    }
  }, [portServiceData]);

  return (
    <TimeSeriesBrushChart
      seriesBrush={seriesBrush}
      dateList={dateList}
      seriesMain={seriesMain}
      loading={loading}
      groupName={'serviceFrequencyCharts'}
      idBrush={'brushServiceFrequency'}
      idMain={'mainServiceFrequency'}
      fileName={fileName}
    />
  );
}

ServiceFrequencyChart.propTypes = {
  dateList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  themeMode: PropTypes.bool,
  portServiceData: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

ServiceFrequencyChart.defaultProps = {
  dateList: [],
  loading: false,
  themeMode: false,
  portServiceData: null
};

export default ServiceFrequencyChart;
