import React, { useState, useCallback, useMemo } from 'react';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  FormControl,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  NotFound
} from '@esgian/esgianui';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { getCanalDepartures } from '@api';
import { useParams } from 'react-router-dom';
import { DATE_FORMAT } from '@constants';
import OperatorTransitsChart from './OperatorTransitsChart/OperatorTransitsChart';
import OperatorTransitsTable from './OperatorTransitsTable/OperatorTransitsTable';

function CanalOperatorStatisticsSection() {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();
  const endDate = moment();
  let startDate = moment('2022-01-01');

  const handleSelectYear = useCallback(({ target }) => {
    setSelectedYear(target.value);
  }, []);

  const years = useMemo(() => {
    let res = [];
    for (let i = startDate.year(); i <= endDate.year(); i++) {
      res.push(i);
    }
    return res;
  }, []);

  const canalOperatorsQuery = useQuery({
    queryKey: [
      'canal',
      'operator-statistics',
      segment.id,
      operatorType,
      commercialCategoryType,
      params
    ],
    enabled: !!startDate && !!endDate,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      const body = {
        start: `${moment(startDate).format(DATE_FORMAT)}Z`,
        end: `${moment(endDate).add(1, 'day').format(DATE_FORMAT)}Z`,
        operatorTypeId: queryKey[3],
        segmentTypeId: queryKey[2],
        commercialCategoryId: queryKey[4],
        canalIds: [params.canalId],
        includePortCalls: false
      };
      return getCanalDepartures(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });

  let canalName = !canalOperatorsQuery.isFetching ? canalOperatorsQuery.data[0]?.canalName : '';

  return (
    <>
      {(canalOperatorsQuery.isFetching ||
        (canalOperatorsQuery.isFetched && canalOperatorsQuery.data.length >= 2)) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>
                      Top Operators and Share of Canal Transits
                    </Typography>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                      <InputLabel id="top-port-year-label">Year</InputLabel>
                      <Select
                        id="top-port-year-select"
                        value={selectedYear}
                        label="Year"
                        onChange={handleSelectYear}>
                        {years?.map((year) => (
                          <MenuItem key={`select-${year}`} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <OperatorTransitsChart
                    canalTransits={canalOperatorsQuery.data}
                    loading={canalOperatorsQuery.isFetching}
                    fileName={`operator-stats-${canalName?.toLowerCase().split(' ').join('-')}`}
                    startDate={moment(selectedYear + '-01-01')}
                    endDate={moment().subtract(moment().year() - selectedYear, 'years')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%' }}>
            <OperatorTransitsTable
              startDate={moment(selectedYear + '-01-01')}
              endDate={moment().subtract(moment().year() - selectedYear, 'years')}
              canalTransits={canalOperatorsQuery.data}
              loading={canalOperatorsQuery.isFetching}
            />
          </Grid>
        </Grid>
      )}
      {canalOperatorsQuery.isFetched && canalOperatorsQuery.data.length < 2 && (
        <Grid item xs={12}>
          <NotFound
            show={true}
            header={'No Matching Result'}
            text={'Please change your search parameters'}
          />
        </Grid>
      )}
    </>
  );
}

CanalOperatorStatisticsSection.propTypes = {};

CanalOperatorStatisticsSection.defaultProps = {};

export default CanalOperatorStatisticsSection;
