import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Button } from '@esgian/esgianui';

function InsightHeaderWithTimeFilter({
  title,
  subtitle,
  renderChildren,
  filterOptions = ['3M', '6M']
}) {
  const [isSecondOptionSelected, setIs2ndOptionSelected] = useState(false);
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box display="flex" gap={2} justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </Box>
        {filterOptions !== null && (
          <Box display="flex" gap={1} alignItems="center" justifyContent="flex-end">
            <Box display="flex" gap={1}>
              <Button
                sx={{
                  m: 0,
                  p: 0.2,
                  minWidth: 0,
                  borderRadius: 0,
                  textDecoration: isSecondOptionSelected ? 'underline' : 'none',
                  pointerEvents: !isSecondOptionSelected ? 'none' : 'auto'
                }}
                variant="text"
                onClick={() => setIs2ndOptionSelected(false)}>
                {filterOptions[0]}
              </Button>
              <Button
                sx={{
                  m: 0,
                  p: 0.2,
                  minWidth: 0,
                  borderRadius: 0,
                  textDecoration: !isSecondOptionSelected ? 'underline' : 'none',
                  pointerEvents: isSecondOptionSelected ? 'none' : 'auto'
                }}
                variant="text"
                onClick={() => setIs2ndOptionSelected(true)}>
                {filterOptions[1]}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box>{renderChildren(isSecondOptionSelected)}</Box>
    </Paper>
  );
}

InsightHeaderWithTimeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  filterOptions: PropTypes.array,
  renderChildren: PropTypes.func
};

export default InsightHeaderWithTimeFilter;
