import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@esgian/esgianui';

function EmissionsDisclaimer({ setShowStatement }) {
  return (
    <Alert severity={'info'}>
      <Typography variant={'body2'}>
        This analysis is based on modelled hourly emission from vessels using Esgian’s emission
        estimation methodology which was accredited by Smart Freight Center (Click{' '}
        <Typography
          variant={'body2'}
          color={'primary'}
          component={'span'}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            setShowStatement(true);
          }}>
          here
        </Typography>{' '}
        for Accreditation statement).
      </Typography>
    </Alert>
  );
}

EmissionsDisclaimer.propTypes = { setShowStatement: PropTypes.func.isRequired };

EmissionsDisclaimer.defaultProps = {};

export default EmissionsDisclaimer;
