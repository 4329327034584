import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Modal, Grid, Paper, ArrowForwardIcon, Link } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme, useSegment } from '@hooks';
import { DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT } from '@constants/tooltips';
import { useNavigate } from 'react-router-dom';
import { useTimezone } from '@hooks/useTimezone';

const UnderlyingServicePortModal = ({ modalData, setModalData }) => {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { uriExt } = useSegment();
  const { getTimeZoneDisplay } = useTimezone();

  const tableColumns = [
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'portOfLoad',
      label: 'POL',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'startDate',
      label: 'ATA POL',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'percentArrivalDraught',
      label: 'Arrival Percentage Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'imoDraught',
      label: 'IMO Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'portOfDischarge',
      label: 'POD',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'endDate',
      label: 'ATA POD',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'percentDepartureDraught',
      label: 'Departure Percentage Draught',
      options: {
        display: false
      }
    },

    {
      name: 'arrivalDraught',
      label: 'Draught on arrival',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentArrivalDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[5]);
            return `${dataIndex}/${
              rowIndex.rowData[5] !== null ? rowIndex.rowData[5]?.toFixed(1) : 'N/A'
            } (${(percentArrivalDraught * 100).toFixed(1)}%)`;
          } else {
            return 'N/A';
          }
        }
      }
    },
    {
      name: 'departureDraught',
      label: 'Draught on departure',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentDepartureDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[5]);
            return `${dataIndex}/${
              rowIndex.rowData[5] !== null ? rowIndex.rowData[5]?.toFixed(1) : 'N/A'
            } (${(percentDepartureDraught * 100).toFixed(1)}%)`;
          } else {
            return 'N/A';
          }
        }
      }
    },
    {
      name: 'portOfLoadId',
      label: 'POL ID',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'portOfDischargeId',
      label: 'POD ID',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'voyageProfileId',
      label: 'Voyage Profile',
      options: {
        sort: false,
        filter: false,
        download: false,
        filterType: 'textField',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          let imo = rowData[1];
          let params = {
            departureDate: rowData[3],
            arrivalDate: rowData[7],
            fromPortId: rowData[11],
            toPortId: rowData[12]
          };
          let paramsEncoded = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          return (
            <Link
              underline="hover"
              variant={'caption'}
              href={`${uriExt}/completed-voyages/${imo}?${paramsEncoded}`}
              onClick={(e) => {
                navigate(`${uriExt}/completed-voyages/${imo}?${paramsEncoded}`);
                e.preventDefault();
              }}
              sx={{ textAlign: 'left', cursor: 'pointer' }}>
              View
              <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />
            </Link>
          );
        }
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    tableBodyMaxHeight: '500px',
    filter: false,
    search: false,
    download: true,
    print: false,
    viewColumns: false,
    downloadOptions: {
      filename: `${modalData?.operatorLongName}  - ${moment(modalData?.month).format('MMM yyyy')}`
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return (
        '\uFEFF' +
        buildHead(columns) +
        buildBody(
          data.map((item) => {
            return item;
          })
        )
      );
    },
    elevation: 0,
    toolbar: checkUserHasAccess(user, true),
    pagination: false
  };

  return (
    <Modal
      handleClose={() => setModalData(null)}
      title={`${modalData?.operatorLongName}  - ${moment(modalData?.month).format('MMM yyyy')}`}
      open={!!modalData}
      size={'lg'}>
      <Grid container>
        <Grid item>
          <Typography variant="body2" sx={{ p: 1 }}>
            <b>Services</b>: {modalData?.voyageInformation?.length}
          </Typography>
        </Grid>
      </Grid>
      <Paper variant={'outlined'}>
        <Table
          customStyle={{
            MUIDataTableFilterList: { display: 'none' },
            MUIDataTableToolbar: {
              styleOverrides: {
                root: {
                  minHeight: 0
                }
              }
            }
          }}
          mode={theme.mode}
          title={''}
          data={modalData?.voyageInformation}
          columns={tableColumns}
          options={tableOptions}
        />
      </Paper>
    </Modal>
  );
};

UnderlyingServicePortModal.propTypes = {
  modalData: PropTypes.object,
  setModalData: PropTypes.func.isRequired,
  dateList: PropTypes.arrayOf(PropTypes.string)
};

UnderlyingServicePortModal.defaultProps = {
  modalData: null,
  dateList: []
};

export default UnderlyingServicePortModal;
