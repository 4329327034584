import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@esgian/esgianui';
import ActionModal from '../../../Common/ActionModal';

const SimulatedVoyageNote = ({ open, onClose, onSave, isAddingNote }) => {
  const [note, setNote] = useState('');
  const maxCharacters = 80;

  const handleNoteChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxCharacters) {
      setNote(inputValue);
    }
  };

  return (
    <ActionModal
      title="Add Note to Simulated Voyage"
      open={open}
      onCancel={onClose}
      onAgree={() => onSave(note)}
      agreeButtonLabel="Save"
      cancelButtonLabel="Skip"
      isDisabledAgree={isAddingNote || note.trim().length === 0}
      content={
        <Box my={2}>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            label="Notes"
            value={note}
            onChange={handleNoteChange}
            placeholder="Write a note for this simulated voyage"
            inputProps={{ maxLength: maxCharacters }}
            helperText={`${note.length}/${maxCharacters} characters`}
            FormHelperTextProps={{ style: { textAlign: 'right' } }}
          />
        </Box>
      }
    />
  );
};

SimulatedVoyageNote.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isAddingNote: PropTypes.bool
};

export default SimulatedVoyageNote;
