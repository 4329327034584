import React from 'react';
import PropTypes from 'prop-types';
import { Box, ApexChart, Typography } from '@esgian/esgianui';
import InsightHeaderWithTimeFilter from './InsightHeaderWithTimeFilter';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getDateFormat } from '@store/features';
import { formatToDecimalPlaces } from '@helpers';
import { useGlobalEmissionIntensityData } from '../../../../Charts/GlobalEmissionIntensityChart/GlobalEmissionIntensityChart';

function GlobalEmissionIntensityInsight({ globalEmissionIntensity }) {
  const dateFormat = useSelector(getDateFormat);
  const endDate = moment().subtract(1, 'month').endOf('month').format(dateFormat);

  const { series, details, options } = useGlobalEmissionIntensityData(
    globalEmissionIntensity,
    endDate
  );

  const mainInsight = [
    {
      label: 'Total CO2 (Tonnes)',
      value: +formatToDecimalPlaces(details.diffTotalCo2, 1),
      increase: +formatToDecimalPlaces(details.percentDiffTotalCo2, 1)
    },
    {
      label: 'Distance (NM)',
      value: +formatToDecimalPlaces(details.diffDistanceNm, 1),
      increase: +formatToDecimalPlaces(details.percentDiffDistanceNm, 1)
    },
    {
      label: 'g CO2/ GT.NM',
      value: +formatToDecimalPlaces(details.diffTotalCo2ByGtNm, 1),
      increase: +formatToDecimalPlaces(details.percentDiffTotalCo2ByGtNm, 1)
    }
  ];

  return (
    <InsightHeaderWithTimeFilter
      title="Global Emission Intensity"
      filterOptions={null}
      renderChildren={() => (
        <Box display="flex" flexDirection="column" gap={2}>
          <ApexChart
            loading={false}
            data={series}
            height={350}
            options={options || {}}
            type={'bar'}
          />
          <Box display="flex" flexDirection="column" gap={1}>
            <Box>
              <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary' }}>
                Main Insights
              </Typography>
              <Typography variant="caption">Latest completed month vs previous month</Typography>
            </Box>

            <ul
              style={{
                padding: 0,
                margin: 0,
                paddingLeft: 32,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {mainInsight.map((item) => (
                <li key={item.label}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                    <Box display="flex" gap={0.5} alignItems="center">
                      <Typography sx={{ fontSize: '14px' }}>{item.value}</Typography>
                      <Typography
                        sx={{
                          color: item.increase > 0 ? 'success.main' : 'error.main',
                          fontSize: '0.7rem'
                        }}>
                        {item.increase > 0 ? `+${item.increase}%` : `${item.increase}%`}
                      </Typography>
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    />
  );
}

GlobalEmissionIntensityInsight.propTypes = {
  globalEmissionIntensity: PropTypes.object.isRequired
};

export default GlobalEmissionIntensityInsight;
