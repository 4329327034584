import { Box } from '@esgian/esgianui';
import React from 'react';
import Dropdown from './Dropdown';
import PropTypes from 'prop-types';

export const calculationTypeOptions = [
  { label: 'Esgian calculation', value: 'esgian', tooltip: 'Esgian calculation' },
  { label: 'Customised calculation', value: 'customised', tooltip: 'Customised calculation' }
];

const CalculationTypeDropdown = ({ selectedCalculationType, onSelect, isLoading }) => {
  return (
    <Box width="280px">
      <Dropdown
        options={calculationTypeOptions}
        selectedValue={selectedCalculationType}
        onSelect={onSelect}
        size="small"
        isLoading={isLoading}
      />
    </Box>
  );
};

CalculationTypeDropdown.propTypes = {
  selectedCalculationType: PropTypes.oneOf(calculationTypeOptions.map((o) => o.value)).isRequired,
  onSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default CalculationTypeDropdown;
