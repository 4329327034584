import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormHelperText } from '@esgian/esgianui';
import { PortSelect } from '@components/Inputs';
import { useTheme } from '@hooks';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortablePortItem from './SortablePortItem';
import ViaPortItem from './ViaPortItem';

const MAX_VIA_PORTS = 20;

function ViaPorts({ onChange, lookupPorts }) {
  const [viaPorts, setViaPorts] = useState([]);
  const [isAddingViaPort, setIsAddingViaPort] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const { customScrollbar } = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    onChange(viaPorts);
  }, [viaPorts, onChange]);

  const isMaximumReached = viaPorts.length >= MAX_VIA_PORTS;

  const handleDelete = (id) => {
    setViaPorts((prev) => prev.filter((p) => p.id !== id));
  };

  const renderPortList = () => {
    if (viaPorts.length === 0) {
      return null;
    }
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        <SortableContext
          items={viaPorts.map((port) => port.id)}
          strategy={verticalListSortingStrategy}>
          <Box sx={{ ...customScrollbar, maxHeight: 170, overflow: 'auto' }}>
            {viaPorts.map((port) => (
              <SortablePortItem key={port.id} port={port} onDelete={handleDelete} />
            ))}
          </Box>
        </SortableContext>
        <DragOverlay>
          {activeId ? <ViaPortItem port={viaPorts.find((p) => p.id === activeId)} /> : null}
        </DragOverlay>
      </DndContext>
    );
  };

  const renderAddPortButton = () => {
    if (isMaximumReached) {
      return (
        <FormHelperText error sx={{ textTransform: 'uppercase' }}>
          max {MAX_VIA_PORTS} ports
        </FormHelperText>
      );
    }
    if (isAddingViaPort) {
      return (
        <PortSelect
          label={`Via Port`}
          id={`via-port-select`}
          placeholder="Search by port"
          selectedPort={null}
          handleChange={(selectedPort) => {
            setViaPorts((prev) => [...prev, selectedPort]);
            setIsAddingViaPort(false);
          }}
          portList={lookupPorts}
          getOptionDisabled={(option) => viaPorts.some((port) => port.id === option.id)}
        />
      );
    }
    return (
      <Button
        sx={{ alignSelf: 'flex-start' }}
        variant="text"
        onClick={() => setIsAddingViaPort(true)}>
        + via port (optional)
      </Button>
    );
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setViaPorts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {renderPortList()}
      {renderAddPortButton()}
    </Box>
  );
}

ViaPorts.propTypes = {
  onChange: PropTypes.func.isRequired,
  lookupPorts: PropTypes.array.isRequired
};

export default ViaPorts;
