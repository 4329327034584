import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';

function OperatorCIIDisclaimer({ setShowStatement }) {
  const { isRoRo } = useSegment();
  return (
    <Alert severity={'info'}>
      <Typography variant={'body2'}>
        This analysis is based on modelled hourly emission from vessels using Esgian’s emission
        estimation methodology
        {isRoRo && (
          <>
            {' '}
            which was accredited by Smart Freight Center (Click{' '}
            <Typography
              variant={'body2'}
              color={'primary'}
              component={'span'}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                setShowStatement(true);
              }}>
              here
            </Typography>{' '}
            for Accreditation statement)
          </>
        )}
        . CII ratings are very sensitive to minor inaccuracies in the modelled CO<sub>2</sub>{' '}
        estimation which can cause the vessels to be rated high or low by one or two rating. The CII
        ratings in the analysis act as good estimates for CII rating of a vessel. The ratings for
        incomplete years are estimated based on rating limits of the corresponding year for the
        fleet assuming the fleet operated and emitted the same as latest completed year.
      </Typography>
    </Alert>
  );
}

OperatorCIIDisclaimer.propTypes = { setShowStatement: PropTypes.func.isRequired };

OperatorCIIDisclaimer.defaultProps = {};

export default OperatorCIIDisclaimer;
