import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getVesselAvgMonthlySpeed } from '@api';
import { DATE_FORMAT } from '@constants';

export const useAverageMonthlySpeed = (imo) => {
  const { data: avgMonthlySpeed, isPending: isLoadingAvgMonthlySpeed } = useQuery({
    queryKey: ['vesselAvgMonthlySpeed', imo],
    queryFn: ({ signal }) =>
      getVesselAvgMonthlySpeed(
        imo,
        moment().startOf('day').subtract(1, 'day').subtract(24, 'months').format(DATE_FORMAT),
        moment().startOf('day').subtract(1, 'day').format(DATE_FORMAT),
        2.0,
        signal
      ),
    enabled: !!imo
  });

  return {
    avgMonthlySpeed,
    isLoadingAvgMonthlySpeed
  };
};
