import React from 'react';
import {
  PerformanceIndicatorMenu,
  PerformanceVsSegmentMenu
} from '@components/Sections/ShipAnalytics/OperatorPerformanceSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOperatorPerformanceFilters,
  setOperatorPerformanceSubSection
} from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';

function SubSectionsMenus() {
  const dispatch = useDispatch();
  const { tabSection, subSection } = useSelector(getOperatorPerformanceFilters);
  if (tabSection === 1) {
    return (
      <PerformanceIndicatorMenu
        subSection={subSection}
        setSubSection={(val) => {
          dispatch(setOperatorPerformanceSubSection(val));
        }}
      />
    );
  }
  return (
    <PerformanceVsSegmentMenu
      subSection={subSection}
      setSubSection={(val) => {
        dispatch(setOperatorPerformanceSubSection(val));
      }}
    />
  );
}

SubSectionsMenus.propTypes = {};

SubSectionsMenus.defaultProps = {};

export default SubSectionsMenus;
