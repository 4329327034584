import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesBrushChart } from '@components/Charts';

function RegionActivityChart({ dateList, regionData, loading, fileName }) {
  const [seriesMain, setSeriesMain] = useState([]);
  const [seriesBrush, setSeriesBrush] = useState([]);

  useEffect(() => {
    if (!regionData) return;
    const { monthlyDateTotals, operatorTotals } = regionData;
    let tempTotal = monthlyDateTotals?.map(({ total }) => total);
    let tempMainSeries = [];
    operatorTotals?.map(({ operatorName, monthlyDateTotals }) => {
      let operatorData = [];
      monthlyDateTotals?.forEach(({ total }) => {
        operatorData.push(total);
      });
      tempMainSeries.push({ name: operatorName, data: operatorData });
    });

    setSeriesMain(tempMainSeries);
    setSeriesBrush([{ name: 'Total', data: tempTotal }]);
  }, [regionData]);

  return (
    <TimeSeriesBrushChart
      loading={loading}
      seriesBrush={seriesBrush}
      seriesMain={seriesMain}
      dateList={dateList}
      fileName={fileName}
      groupName={'regionProfileChart'}
      idBrush={'brushRegionActivity'}
      idMain={'mainRegionActivity'}
    />
  );
}

RegionActivityChart.propTypes = {
  dateList: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired,
  regionData: PropTypes.object,
  loading: PropTypes.bool
};

RegionActivityChart.defaultProps = {
  regionData: null,
  dateList: [],
  loading: false
};

export default RegionActivityChart;
