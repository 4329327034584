import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Paper, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { checkUserHasAccess, formatNumberReadable, tableTitlePaddingLeft } from '@helpers';
import { getFilterInputValidationOption, getFilterInputValidationList } from '../helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function VesselsTable({ title, loading, vessels, hideColumns, fileName }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const columns = useMemo(() => {
    return [
      {
        name: 'vesselName',
        label: 'Vessel Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink
                profile={'vessel'}
                id={rowData[1]}
                name={dataIndex}
                vesselOldNames={rowData[16] ? rowData[16] : rowData[0]}
              />
            ) : (
              '-'
            );
          }
        }
      },
      {
        name: 'imo',
        label: 'IMO Number',
        options: {
          display: !hideColumns.includes('imo'),
          download: !hideColumns.includes('imo'),
          filter: !hideColumns.includes('imo'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('IMO Number')
        }
      },
      {
        name: 'mmsi',
        label: 'MMSI',
        options: {
          filterType: 'custom',
          display: !hideColumns.includes('mmsi'),
          download: !hideColumns.includes('mmsi'),
          filter: !hideColumns.includes('mmsi'),
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('MMSI')
        }
      },
      {
        name: 'callSign',
        label: 'Call Sign',
        options: {
          filterType: 'textField',
          sort: true,
          display: !hideColumns.includes('callSign'),
          download: !hideColumns.includes('callSign'),
          filter: !hideColumns.includes('callSign'),
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return dataIndex === '' ? '-' : dataIndex;
          }
        }
      },
      {
        name: 'ownerLongName',
        label: 'Owner',
        options: {
          sort: true,
          display: !hideColumns.includes('ownerLongName'),
          download: !hideColumns.includes('ownerLongName'),
          filter: !hideColumns.includes('ownerLongName'),
          filterType: 'textField',
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'owner'} id={rowData[5]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'ownerId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },

      {
        name: 'operatorShortName',
        label: 'Operator',
        options: {
          display: !hideColumns.includes('operatorShortName'),
          download: !hideColumns.includes('operatorShortName'),
          filter: !hideColumns.includes('operatorShortName'),
          sort: true,
          filterType: 'textField',
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} id={rowData[7]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'class',
        label: 'Class',
        options: {
          display: !hideColumns.includes('class'),
          download: !hideColumns.includes('class'),
          filter: !hideColumns.includes('class'),
          filterType: 'multiselect',
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return dataIndex === null ? '-' : dataIndex;
          }
        }
      },
      {
        name: 'buildYear',
        label: 'Build Year',
        options: {
          display: !hideColumns.includes('buildYear'),
          download: !hideColumns.includes('buildYear'),
          filter: !hideColumns.includes('buildYear'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Year'),
          filterOptions: getMinMaxFilterOptions('Build Year'),
          customBodyRender: (dataIndex) => {
            return dataIndex === null ? '-' : dataIndex;
          }
        }
      },
      {
        name: 'flagState',
        label: 'Flag State',
        options: {
          display: !hideColumns.includes('flagState'),
          download: !hideColumns.includes('flagState'),
          filter: !hideColumns.includes('flagState'),
          filterType: 'multiselect',
          sort: true,
          sortThirdClickReset: true
        }
      },
      {
        name: 'grossTonnage',
        label: 'GT (GrossTonnage)',
        options: {
          display: !hideColumns.includes('grossTonnage'),
          download: !hideColumns.includes('grossTonnage'),
          filter: !hideColumns.includes('grossTonnage'),
          sort: true,
          filterType: 'custom',
          customBodyRender: (dataIndex) => {
            return formatNumberReadable(dataIndex?.toString());
          },
          sortThirdClickReset: true,
          customFilterListOptions: getMinMaxFilterListOptions('Gross Tonnage'),
          filterOptions: getMinMaxFilterOptions('GT(GrossTonnage)')
        }
      },
      {
        name: 'deadweight',
        label: 'DWT (Deadweight)',
        options: {
          display: !hideColumns.includes('deadweight'),
          download: !hideColumns.includes('deadweight'),
          filter: !hideColumns.includes('deadweight'),
          sort: true,
          filterType: 'custom',
          customBodyRender: (dataIndex) => {
            return formatNumberReadable(dataIndex?.toString());
          },
          customFilterListOptions: getMinMaxFilterListOptions('Deadweight'),
          filterOptions: getMinMaxFilterOptions('DWT(Deadweight)'),
          sortThirdClickReset: true
        }
      },
      {
        name: 'loadCapacity',
        label: 'Load Capacity(CEU)',
        options: {
          display: !hideColumns.includes('loadCapacity'),
          download: !hideColumns.includes('loadCapacity'),
          filter: !hideColumns.includes('loadCapacity'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'custom',
          customBodyRender: (dataIndex) => {
            return formatNumberReadable(dataIndex?.toString());
          },
          customFilterListOptions: getMinMaxFilterListOptions('Capacity'),
          filterOptions: getMinMaxFilterOptions('Load Capacity')
        }
      },
      {
        name: 'maxLiftingCapacity',
        label: 'Max Lifting capacity',
        options: {
          display: !hideColumns.includes('maxLiftingCapacity'),
          download: !hideColumns.includes('maxLiftingCapacity'),
          filter: !hideColumns.includes('maxLiftingCapacity'),
          sort: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('Max Lifting capacity'),
          filterOptions: getMinMaxFilterOptions('Max Lifting capacity'),
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            const formattedData =
              typeof dataIndex === 'number' ? formatNumberReadable(dataIndex) : dataIndex;
            return formattedData === null ? '-' : formattedData;
          }
        }
      },
      {
        name: 'numCranes',
        label: 'No. Cranes',
        options: {
          display: !hideColumns.includes('numCranes'),
          download: !hideColumns.includes('numCranes'),
          filter: !hideColumns.includes('numCranes'),
          sort: true,
          filterType: 'custom',
          customFilterListOptions: getMinMaxFilterListOptions('No. Cranes'),
          filterOptions: getMinMaxFilterOptions('No. Cranes'),
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return dataIndex === null ? '-' : dataIndex;
          }
        }
      },
      {
        name: 'formerVesselName',
        label: 'Former Vessel Names',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      }
    ];
  }, [hideColumns]);

  const options = useMemo(() => {
    return {
      onDownload: (buildHead, buildBody, columns, data) => {
        return (
          '\uFEFF' +
          buildHead(columns) +
          buildBody(
            data.map((item) => {
              item.data = item.data?.map((val) => {
                if (val === '-') {
                  return null;
                }
                return val;
              });
              return item;
            })
          )
        );
      },
      selectableRows: 'none',
      responsive: 'standard',
      filter: true,
      search: true,
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: fileName + '.csv'
      },
      setTableProps: () => {
        return {
          id: 'vessel-table'
        };
      },
      elevation: 0,
      print: false,
      viewColumns: false,
      pagination: true,
      toolbar: true,
      tableBodyMaxHeight: '80vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'vesselName',
        direction: 'asc'
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no vessels found'
        }
      }
    };
  }, [user, fileName]);

  return (
    <Paper>
      <Table
        customStyle={tableTitlePaddingLeft}
        loading={loading}
        mode={theme.mode}
        title={
          <Typography id={'vessel-title'} variant={'h6'}>
            {title}
          </Typography>
        }
        data={vessels?.length ? vessels : []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

VesselsTable.propTypes = {
  title: PropTypes.string,
  vessels: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired
};

VesselsTable.defaultProps = {
  title: '',
  vessel: [],
  loading: false,
  hideColumns: []
};

export default VesselsTable;
