import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '@components/Tables/EmissionAndETSTables/TableWithSummary';
import { formatNumberReadable } from '@helpers';

const SimulatedVoyageMyCargoEstimatedEtsTable = ({ myCargoEuEtsAllowanceCost }) => {
  const columns = [
    { name: 'origin', label: 'Origin' },
    { name: 'destination', label: 'Destination' },
    { name: 'co2Emission', label: 'CO₂ For my cargo (T)' },
    { name: 'phaseInFactor', label: 'Phase in factor' },
    { name: 'applicability', label: 'Applicability' },
    {
      name: 'etsAllowance',
      label: 'Eu Allowances Cost (€)',
      tooltip:
        'EU Allowances (EUAs) are a type of carbon allowance that allows companies covered by the EU ETS to emit a certain amount of  CO₂e. EUAs can be bought and sold on the market, and the variable market price of EUAs reflects the cost of reducing emissions.'
    },
    { name: 'estimatedEtsCostEuro', label: 'EU ETS Cost (€)' }
  ];

  const createTableData = () => {
    if (!myCargoEuEtsAllowanceCost) return [];

    return myCargoEuEtsAllowanceCost.voyageLegMyCargoEuEtsAllowanceCosts.map((leg) => ({
      origin: { value: leg.fromPortName },
      destination: { value: leg.toPortName },
      co2Emission: { value: formatNumberReadable(leg.co2Tn.toFixed(2)) },
      phaseInFactor: { value: leg.phaseInFactor.toFixed(2) },
      applicability: { value: leg.applicabilityFactor.toFixed(2) },
      etsAllowance: { value: formatNumberReadable(leg.euEtsAllowanceTn.toFixed(2)) },
      estimatedEtsCostEuro: { value: formatNumberReadable(leg.estimatedEtsCost.toFixed(2)) }
    }));
  };

  const createSummary = () => {
    if (!myCargoEuEtsAllowanceCost) return [];
    return [
      {
        label: 'ETS cost for my cargo (€)',
        value: formatNumberReadable(
          myCargoEuEtsAllowanceCost.totalMyCargoEstimatedEuEtsCost?.toFixed(0)
        )
      },
      {
        label: 'EU Allowances for my cargo (€)',
        value: formatNumberReadable(
          myCargoEuEtsAllowanceCost.totalMyCargoEuEtsAllowanceTn?.toFixed(0)
        )
      }
    ];
  };

  return (
    <TableWithSummary
      title="Estimated ETS for my cargo"
      columns={columns}
      data={createTableData()}
      summary={createSummary()}
    />
  );
};

SimulatedVoyageMyCargoEstimatedEtsTable.propTypes = {
  myCargoEuEtsAllowanceCost: PropTypes.object
};

export default SimulatedVoyageMyCargoEstimatedEtsTable;
