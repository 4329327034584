import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@esgian/esgianui';
import { useTheme } from '@hooks';
import InboundVesselPortTab from './InboundVesselPortTab';
import InboundVesselInsightVesselTab from './InboundVesselInsightVesselTab';

const DashboardActivityMapInboundVesselInsight = ({
  lookupPorts,
  locationId,
  locationType,
  vesselsLatestPosition
}) => {
  const { customScrollbar } = useTheme();
  const [isVesselTabSelected, setIsVesselTabSelected] = useState(false);

  const renderTabContent = () => {
    let ports = lookupPorts.filter((port) => port.id === +locationId);

    if (locationType === 'Country') {
      ports = lookupPorts.filter(({ country }) => country.id === +locationId);
    }
    if (isVesselTabSelected) {
      return (
        <InboundVesselInsightVesselTab
          vesselsLatestPosition={vesselsLatestPosition}
          ports={ports}
        />
      );
    }

    return <InboundVesselPortTab ports={ports} />;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} height="100%">
      <Box display="flex" gap={1}>
        <Button
          variant={isVesselTabSelected ? 'outlined' : 'contained'}
          size="small"
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVesselTabSelected(false)}>
          Port
        </Button>
        <Button
          size="small"
          variant={isVesselTabSelected ? 'contained' : 'outlined'}
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVesselTabSelected(true)}>
          Vessel
        </Button>
      </Box>
      <Box
        display="flex"
        gap={2}
        flexDirection="column"
        flex={1}
        overflow="auto"
        sx={customScrollbar}>
        {renderTabContent()}
      </Box>
    </Box>
  );
};

DashboardActivityMapInboundVesselInsight.propTypes = {
  locationId: PropTypes.string,
  locationType: PropTypes.string,
  lookupPorts: PropTypes.array,
  vesselsLatestPosition: PropTypes.array
};

export default DashboardActivityMapInboundVesselInsight;
