const addNewPageIfNeeded = (pdf, yOffset, cellHeight, bottomMargin = 20) => {
  const pageHeight = pdf.internal.pageSize.height;
  if (yOffset + cellHeight + bottomMargin >= pageHeight) {
    pdf.addPage();
    yOffset = 10; // Reset yOffset for the new page
  }
  return yOffset;
};

const calculateCellWidths = (pdf, headers, data) => {
  const columnWidths = headers.map((header) => pdf.getTextWidth(header) + 4); // Start with header widths
  data.forEach((row) => {
    row.forEach((cell, index) => {
      const cellWidth = pdf.getTextWidth(cell) + 4; // Add some padding
      if (cellWidth > columnWidths[index]) {
        columnWidths[index] = cellWidth;
      }
    });
  });
  return columnWidths;
};

const generatePortData = (pdf, data, yOffset, addNewPageIfNeeded) => {
  if (!pdf || !data || !Array.isArray(data)) {
    console.error('Invalid input for generatePortData');
    return yOffset;
  }

  yOffset += 10;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(16);
  pdf.text('Voyage summary report', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = [
    'Port Name',
    'Time of Arrival',
    'Time of Departure',
    'Draught at Arrival(m)',
    'Draught at Departure(m)',
    'Distance Computed(km)',
    'Estimated Cargo Weight(t)'
  ];

  const tableData = data.map((portCall) => [
    portCall?.portName || '',
    portCall?.arrivalDate ? new Date(portCall.arrivalDate).toLocaleString() : '',
    portCall?.departureDate ? new Date(portCall.departureDate).toLocaleString() : '',
    portCall?.arrivalDraught && portCall?.imoDraught && portCall?.percentArrivalDraught != null
      ? `${portCall.arrivalDraught}/${portCall.imoDraught}(${portCall.percentArrivalDraught.toFixed(
          1
        )}%)`
      : '',
    portCall?.departureDraught && portCall?.imoDraught && portCall?.percentDepartureDraught != null
      ? `${portCall.departureDraught}/${
          portCall.imoDraught
        }(${portCall.percentDepartureDraught.toFixed(1)}%)`
      : '',
    portCall?.distanceKm != null ? portCall.distanceKm.toFixed(0) : '',
    portCall?.cargoWeightTn != null ? portCall.cargoWeightTn.toFixed(0) : ''
  ]);

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);
  const pageWidth = pdf.internal.pageSize.width;

  if (totalTableWidth > pageWidth - 20) {
    const scaleFactor = (pageWidth - 20) / totalTableWidth;
    cellWidths = cellWidths.map((width) => width * scaleFactor);
  }

  const cellHeight = 15;
  const startX = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    const lines = pdf.splitTextToSize(header, cellWidths[index] - 2);
    lines.forEach((line, lineIndex) => {
      pdf.text(line, currentX + 1, yOffset + 5 * (lineIndex + 1));
    });
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    let maxLines = 1;

    // Calculate the maximum number of lines for the current row
    const cellLines = row.map((cell, cellIndex) => {
      const lines = pdf.splitTextToSize(cell || '', cellWidths[cellIndex] - 2);
      maxLines = Math.max(maxLines, lines.length);
      return lines;
    });

    // Set the height of the cell based on the number of lines
    const cellRowHeight = maxLines * 10;

    cellLines.forEach((lines, cellIndex) => {
      lines.forEach((line, lineIndex) => {
        pdf.text(line, currentX + 2, yOffset + 10 * (lineIndex + 1) - 5);
      });
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellRowHeight);
      currentX += cellWidths[cellIndex];
    });

    yOffset += cellRowHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellRowHeight);
  });

  return yOffset;
};

const generateActivityProfile = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 50;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Activity Profile', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = ['Status', 'Percentage of Time During Voyage'];
  const tableData = [
    { label: 'In Transit', value: data.percentTransit.toFixed(1) + '%' },
    { label: 'At Port', value: data.percentAtPort.toFixed(1) + '%' },
    { label: 'StandBy', value: data.percentStandby.toFixed(1) + '%' }
  ];

  const pageWidth = pdf.internal.pageSize.width;
  const margin = 10;
  const availableWidth = pageWidth - 2 * margin;

  const columnWidths = [0.4, 0.6]; // Define proportions for each column
  const cellWidths = columnWidths.map((width) => width * availableWidth);

  const cellHeight = 10;
  const startX = margin;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];
    pdf.text(row.value, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};
const generateWaitingTime = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Port Time and Port Waiting Time', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 10; // Add some space below the title

  const tableHeaders = ['Port Name', 'Port Time (Days)', 'Port Waiting Time (Days)'];
  const tableData = [
    ...data.portCalls.map((portCall) => [
      portCall.portName,
      portCall.portTimeDays.toFixed(2),
      portCall.portWaitingTimeDays.toFixed(2)
    ]),
    ['Average', data.averagePortTimeDays.toFixed(2), data.averagePortWaitingTimeDays.toFixed(2)]
  ];
  // Calculate cell widths to cover full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    if (row[0] === 'Average') {
      pdf.setFont('helvetica', 'bold');
    } else {
      pdf.setFont('helvetica', 'normal');
    }
    row.forEach((cell, cellIndex) => {
      pdf.text(cell, currentX + 2, yOffset + cellHeight - 2);
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellHeight);
      currentX += cellWidths[cellIndex];
    });
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const generateCargoGhgEmissionSummary = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(16);
  pdf.text('GHG Emission for Scope 3 Emission reporting', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = ['My cargo (t)', (data.myCargoGhgEmissionSummary.myCargoTn || 0).toFixed(0)];
  const tableData = [
    {
      label: 'TOC GHG Emission for my cargo (kg)',
      value: (data.myCargoGhgEmissionSummary.tocGhgEmissionKg || 0).toFixed(0)
    },
    {
      label: 'HOC GHG Emission for my cargo (kg)',
      value: (data.myCargoGhgEmissionSummary.hocGhgEmissionKg || 0).toFixed(0)
    },
    {
      label: 'Total GHG Emission for my cargo (kg)',
      value: (data.myCargoGhgEmissionSummary.totalGhgEmission || 0).toFixed(0)
    }
  ];

  // Calculate cell widths to cover full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(
    pdf,
    tableHeaders,
    tableData.map((row) => [row.label, row.value])
  );

  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    if (row.label === 'Total GHG Emission for my cargo (kg)') {
      pdf.setFont('helvetica', 'bold');
    } else {
      pdf.setFont('helvetica', 'normal');
    }
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];
    pdf.text(row.value.toString(), currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};
const myCargoTocSummary = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('My cargo TOC Emission', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = [
    'Origin',
    'Destination',
    'Shortest Feasible Distance (km)',
    'Transport Activity (t-km)',
    'Energy Production',
    'Operational',
    'Overall'
  ];

  // const subHeaders = [' ', 'GHG Emission (kg CO2e)'];

  const tableData = data.myCargoTocEmission.voyageLegMyCargoEmissionIntensities.map((data) => [
    data.fromPortName || '',
    data.toPortName || '',
    (data.distanceKm || 0).toFixed(0),
    (data.myTocActivity || 0).toFixed(0),
    (data.ghgEmissionEneryProductionKg || 0).toFixed(0),
    (data.ghgEmissionOperationalKg || 0).toFixed(0),
    (data.ghgEmissionOverallKg || 0).toFixed(0)
  ]);

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);
  const pageWidth = pdf.internal.pageSize.width - 20;

  if (totalTableWidth > pageWidth) {
    const scaleFactor = pageWidth / totalTableWidth;
    cellWidths = cellWidths.map((width) => width * scaleFactor);
  }

  const cellHeight = 10;
  const startX = 10;
  let currentX = startX;

  // Draw sub-header row
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  // Span the first four columns with empty sub-header
  const firstSubHeaderWidth = cellWidths.slice(0, 4).reduce((a, b) => a + b, 0);
  pdf.rect(currentX, yOffset, firstSubHeaderWidth, cellHeight);
  currentX += firstSubHeaderWidth;

  // Span the last three columns with 'GHG Emission (kg CO2e)' sub-header
  const secondSubHeaderWidth = cellWidths.slice(4, 7).reduce((a, b) => a + b, 0);
  pdf.text('GHG Emission (kg CO2e)', currentX + 20, yOffset + cellHeight - 2);
  pdf.rect(currentX, yOffset, secondSubHeaderWidth, cellHeight);

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  // Draw main header row
  currentX = startX;
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    row.forEach((cell, cellIndex) => {
      pdf.text(cell, currentX + 2, yOffset + cellHeight - 2);
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellHeight);
      currentX += cellWidths[cellIndex];
    });
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const myCargoHocTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('My cargo HOC Emission', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = [
    'Port Name',
    'Operational HOC Emission intensity (kgCO2e/t)',
    'HOC activity (t)',
    'GHG Emission(kgCO2e)'
  ];

  const tableData = data.myCargoHocEmission.voyageLegMyCargoHocEmissions.map((data) => [
    data.portName,
    (data.operationalHocEmissionIntensity || 0).toFixed(1),
    (data.hocActivity || 0).toFixed(1),
    (data.hocGhgEmission || 0).toFixed(0)
  ]);

  // Calculate cell widths to cover the full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  const headerCellHeight = 15;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    // Adjust y position of text to be vertically centered in the taller header cell
    const textY = yOffset + headerCellHeight / 2 + 3;
    pdf.text(header, currentX + 2, textY);
    pdf.rect(currentX, yOffset, cellWidths[index], headerCellHeight);
    currentX += cellWidths[index];
  });

  yOffset += headerCellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, headerCellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    row.forEach((cell, cellIndex) => {
      pdf.text(cell, currentX + 2, yOffset + cellHeight - 2);
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellHeight);
      currentX += cellWidths[cellIndex];
    });
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const overallVoyageTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Overall voyage', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = ['Origin', 'Destination', 'Energy Production', 'Operational', 'Overall'];
  // const subHeaders = ['CO2e Emission (GHG Emission) (kg)'];

  const tableData = data.overallVoyageGhgEmission.voyageLegEmissionIntensities.map((data) => [
    data.fromPortName || '',
    data.toPortName || '',
    (data.ghgEmissionEneryProductionKg || 0).toFixed(0),
    (data.ghgEmissionOperationalKg || 0).toFixed(0),
    (data.ghgEmissionOverallKg || 0).toFixed(0)
  ]);

  // Calculate cell widths to cover full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  // Draw sub-header row
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);

  // Span the first two columns with empty sub-header
  const firstSubHeaderWidth = cellWidths.slice(0, 2).reduce((a, b) => a + b, 0);
  pdf.rect(currentX, yOffset, firstSubHeaderWidth, cellHeight);
  currentX += firstSubHeaderWidth;

  // Span the last three columns with sub-header
  const secondSubHeaderWidth = cellWidths.slice(2).reduce((a, b) => a + b, 0);
  pdf.text('CO2e Emission (GHG Emission) [kg]', currentX + 20, yOffset + cellHeight - 2);
  pdf.rect(currentX, yOffset, secondSubHeaderWidth, cellHeight);

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  // Draw main header row
  currentX = startX;
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    row.forEach((cell, cellIndex) => {
      pdf.text(cell, currentX + 2, yOffset + cellHeight - 2);
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellHeight);
      currentX += cellWidths[cellIndex];
    });
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const overallVoyageEmissionIntensityTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Overall voyage emission intensity', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = [
    'Origin',
    'Destination',
    'Estimated Cargo \nWeight (t)',
    'Shortest Distance (km)',
    'Transport Activity \n(t-km)',
    'Energy Production',
    'Operational',
    'Overall'
  ];

  // const subHeaders = [' ', 'Emission intensity (kgCO2e/(t.km)'];

  const tableData = data.overallVoyageGhgEmission.voyageLegEmissionIntensities.map((data) => [
    data.fromPortName || '',
    data.toPortName || '',
    (data.cargoWeightTn || 0).toFixed(0),
    (data.distanceKm || 0).toFixed(0),
    (data.transportActivity || 0).toFixed(2),
    (data.emissionIntensityEneryProduction || 0).toFixed(4),
    (data.emissionIntensityOperational || 0).toFixed(4),
    (data.emissionIntensityOverall || 0).toFixed(4)
  ]);

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);
  const pageWidth = pdf.internal.pageSize.width - 20;

  if (totalTableWidth > pageWidth) {
    const scaleFactor = pageWidth / totalTableWidth;
    cellWidths = cellWidths.map((width) => width * scaleFactor);
  }

  const cellHeight = 10;
  const headerCellHeight = 15; // Increase header cell height
  const startX = 10;
  let currentX = startX;

  // Draw sub-header row
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  // Span the first four columns with empty sub-header
  const firstSubHeaderWidth = cellWidths.slice(0, 4).reduce((a, b) => a + b, 0);
  pdf.rect(currentX, yOffset, firstSubHeaderWidth, cellHeight);
  currentX += firstSubHeaderWidth;

  // Span the last three columns with 'GHG Emission (kg CO2e)' sub-header
  const secondSubHeaderWidth = cellWidths.slice(4, 8).reduce((a, b) => a + b, 0);
  pdf.text('Emission intensity (kgCO2e/(t.km)', currentX + 20, yOffset + cellHeight - 2);
  pdf.rect(currentX, yOffset, secondSubHeaderWidth, cellHeight);

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  // Draw main header row
  currentX = startX;
  tableHeaders.forEach((header, index) => {
    const lines = pdf.splitTextToSize(header, cellWidths[index] - 2);
    lines.forEach((line, lineIndex) => {
      pdf.text(line, currentX + 2, yOffset + 5 * (lineIndex + 1));
    });
    pdf.rect(currentX, yOffset, cellWidths[index], headerCellHeight);
    currentX += cellWidths[index];
  });

  yOffset += headerCellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, headerCellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    row.forEach((cell, cellIndex) => {
      pdf.text(cell, currentX + 2, yOffset + cellHeight - 2);
      pdf.rect(currentX, yOffset, cellWidths[cellIndex], cellHeight);
      currentX += cellWidths[cellIndex];
    });
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const costEstMyCargoTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);
  const heading = 'EU ETS Cost Estimation';
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(16);
  pdf.text(heading, 10, yOffset);
  yOffset += 10;

  const subheading =
    'Assumed EU Allowance price(€) : ' + data.myCargoEuEtsAllowanceSummary.etsCostPerCo2;
  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(12);
  pdf.text(subheading, 10, yOffset);
  yOffset += 10;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('My Cargo', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = ['My cargo (t)', (data.myCargoGhgEmissionSummary.myCargoTn || 0).toFixed(0)];
  const tableData = [
    {
      label: 'EU Allowance for my cargo',
      value: (data.myCargoEuEtsAllowanceSummary?.totalMyCargoEuEtsAllowanceTn || 0).toFixed(0)
    },
    {
      label: 'ETS cost for my cargo(€)',
      value: (data.myCargoEuEtsAllowanceSummary?.totalMyCargoEstimatedEuEtsCost || 0).toFixed(0)
    }
  ];

  // Calculate cell widths to cover full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(
    pdf,
    tableHeaders,
    tableData.map((row) => [row.label, row.value])
  );
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    if (row.label === 'ETS cost for my cargo(€)') {
      pdf.setFont('helvetica', 'bold');
    } else {
      pdf.setFont('helvetica', 'normal');
    }
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];
    pdf.text(row.value.toString(), currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const euEtsOverallVoyageTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Overall Voyage', 10, yOffset);
  pdf.setDrawColor(0);

  yOffset += 5;
  const tableHeaders = [
    'EU Allowance for the voyage',
    (data.overallVoyageEuEtsAllowanceCost.totalEtsAllowanceTn || 0).toFixed(0)
  ];
  const tableData = [
    {
      label: 'ETS cost for the voyage(€)',
      value: (data.overallVoyageEuEtsAllowanceSummary.voyageEtsCost || 0).toFixed(0)
    }
  ];

  // Calculate cell widths to cover the full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(
    pdf,
    tableHeaders,
    tableData.map((row) => [row.label, row.value])
  );
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    if (row.label === 'ETS cost for the voyage(€)') {
      pdf.setFont('helvetica', 'bold');
    } else {
      pdf.setFont('helvetica', 'normal');
    }
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];

    pdf.text(row.value.toString(), currentX + 2, yOffset + cellHeight - 2);
    pdf.setFont('helvetica', 'normal');
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const voyagesEstEtsCostTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);

  const tableHeaders = [
    'Origin',
    'Destination',
    'CO2\nEmission',
    'Applicab\nility',
    'Phase in\nfactor',
    'EU\nAllowances (EUA)',
    'EU ETS Cost\nper Tonne CO2(€)',
    'EU ETS\nCost (€)',
    'Share of\nMy cargo',
    'EU ETS Cost\nfor my cargo (€)'
  ];
  const baseData = data.overallVoyageEuEtsAllowanceCost.voyageLegEuEtsAllowanceCosts;
  const additionalData = data.myCargoEuEtsAllowanceCost.voyageLegMyCargoEuEtsAllowanceCosts;

  const mapData = (base, additional) => [
    base.fromPortName || '-',
    base.toPortName || '-',
    base.co2Tn != null ? base.co2Tn.toFixed(0) : '-',
    base.applicabilityFactor != null ? base.applicabilityFactor.toFixed(0) : '-',
    base.phaseInFactor != null ? base.phaseInFactor.toFixed(1) : '-',
    base.euEtsAllowanceTn != null ? base.euEtsAllowanceTn.toFixed(0) : '-',
    base.etsCostPerTonCo2 != null ? base.etsCostPerTonCo2.toFixed(2) : '-',
    base.estimatedEtsCost != null ? base.estimatedEtsCost.toFixed(0) : '-',
    additional.myCargoShare != null ? additional.myCargoShare.toFixed(1) + '%' : '-',
    additional.myCargoEstimatedEtsCost != null ? additional.myCargoEstimatedEtsCost.toFixed(0) : '-'
  ];

  // Combine data
  const tableData = baseData.map((base, index) => {
    const additional = additionalData[index];
    return mapData(base, additional);
  });

  // Calculate cell widths and handle text wrapping if necessary
  const calculateCellWidths = (pdf, headers, data) => {
    const cellWidths = headers.map((header) => pdf.getTextWidth(header)); // Start with header widths

    data.forEach((row) => {
      row.forEach((cell, index) => {
        const cellWidth = pdf.getTextWidth(cell) + 4;
        if (cellWidth > cellWidths[index]) {
          cellWidths[index] = cellWidth;
        }
      });
    });

    return cellWidths;
  };

  let cellWidths = calculateCellWidths(pdf, tableHeaders, tableData);
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);
  const pageWidth = pdf.internal.pageSize.width;

  if (totalTableWidth > pageWidth - 20) {
    const scaleFactor = (pageWidth - 20) / totalTableWidth;
    cellWidths = cellWidths.map((width) => width * scaleFactor);
  }

  const cellHeight = 10;
  const startX = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(9);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 0.5, yOffset + cellHeight - 5);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;

    // Calculate the maximum height of the row
    let rowHeight = cellHeight;
    const wrappedTextArray = row.map((cell, cellIndex) => {
      const wrappedText = pdf.splitTextToSize(cell, cellWidths[cellIndex] - 4);
      const cellHeight =
        wrappedText.length * (pdf.internal.getLineHeight() / pdf.internal.scaleFactor);
      if (cellHeight > rowHeight) {
        rowHeight = cellHeight;
      }
      return wrappedText;
    });

    // Add new page if needed
    yOffset = addNewPageIfNeeded(pdf, yOffset, rowHeight);

    // Draw the row
    row.forEach((cell, cellIndex) => {
      const wrappedText = wrappedTextArray[cellIndex];
      let textY = yOffset + pdf.internal.getLineHeight() / pdf.internal.scaleFactor;
      const textX = currentX + 2;

      wrappedText.forEach((line) => {
        pdf.text(line, textX, textY);
        textY += pdf.internal.getLineHeight() / pdf.internal.scaleFactor;
      });

      pdf.rect(currentX, yOffset, cellWidths[cellIndex], rowHeight);
      currentX += cellWidths[cellIndex];
    });

    yOffset += rowHeight;
  });

  return yOffset;
};

const CO2EmissionTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);
  const heading = 'CO2 Emission';
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text(heading, 10, yOffset);
  yOffset += 5;

  const tableHeaders = ['Total CO2 (t)', (data.ciiRating.totalCo2Ttw || 0).toFixed(0)];
  const tableData = [
    {
      label: 'CII ( g CO2/GT.NM)',
      value: (data.ciiRating.ciiValueTtw || 0).toFixed(2)
    },
    {
      label: 'CII Rating',
      value: data.ciiRating.ciiRatingTtw || 0
    }
  ];

  // Calculate cell widths to cover the full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(
    pdf,
    tableHeaders,
    tableData.map((row) => [row.label, row.value])
  );
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];
    pdf.text(row.value.toString(), currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

const fuelConsumptionTable = (pdf, data, yOffset, addNewPageIfNeeded) => {
  yOffset += 20;
  yOffset = addNewPageIfNeeded(pdf, yOffset, 0);
  const heading = 'Fuel Consumption';
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text(heading, 10, yOffset);
  yOffset += 5;

  const tableHeaders = ['Fuel type', 'Consumption(t)'];
  const tableData = [
    {
      label: 'Marine Diesel Oil (MDO)',
      value: (data?.fuelDetails.fuelConsumption[1] || 0).toFixed(0)
    },
    {
      label: 'Heavy Fuel Oil (HFO)',
      value: (data?.fuelDetails.fuelConsumption[2] || 0).toFixed(0) || 0
    }
  ];

  // Calculate cell widths to cover the full width of the page
  const pageWidth = pdf.internal.pageSize.width;
  const startX = 10;
  const availableWidth = pageWidth - startX * 2; // Left and right margins

  let cellWidths = calculateCellWidths(
    pdf,
    tableHeaders,
    tableData.map((row) => [row.label, row.value])
  );
  const totalTableWidth = cellWidths.reduce((acc, width) => acc + width, 0);

  if (totalTableWidth < availableWidth) {
    const extraSpace = availableWidth - totalTableWidth;
    const increasePerCell = extraSpace / cellWidths.length;
    cellWidths = cellWidths.map((width) => width + increasePerCell);
  }

  const cellHeight = 10;
  let currentX = startX;

  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(10);
  tableHeaders.forEach((header, index) => {
    pdf.text(header, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[index], cellHeight);
    currentX += cellWidths[index];
  });

  yOffset += cellHeight;
  yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);

  pdf.setFont('helvetica', 'normal');
  tableData.forEach((row) => {
    currentX = startX;
    pdf.text(row.label, currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[0], cellHeight);
    currentX += cellWidths[0];
    pdf.text(row.value.toString(), currentX + 2, yOffset + cellHeight - 2);
    pdf.rect(currentX, yOffset, cellWidths[1], cellHeight);
    yOffset += cellHeight;
    yOffset = addNewPageIfNeeded(pdf, yOffset, cellHeight);
  });

  return yOffset;
};

export {
  addNewPageIfNeeded,
  generatePortData,
  generateActivityProfile,
  generateWaitingTime,
  generateCargoGhgEmissionSummary,
  myCargoTocSummary,
  myCargoHocTable,
  overallVoyageTable,
  overallVoyageEmissionIntensityTable,
  costEstMyCargoTable,
  euEtsOverallVoyageTable,
  voyagesEstEtsCostTable,
  CO2EmissionTable,
  fuelConsumptionTable
};
