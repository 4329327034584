import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@esgian/esgianui';
import { useDynamicLookupQueries, useTheme } from '@hooks';

function VesselSelect({
  handleChange,
  placeholder = 'IMO/Name',
  selectedVessel = null,
  disabled = false,
  error = false,
  helperText = null,
  id = 'vessel-select',
  label
}) {
  const { customScrollbar } = useTheme();
  const { lookupOperatorVesselHierarchyQuery } = useDynamicLookupQueries();

  const flattenedOptions = useMemo(() => {
    if (lookupOperatorVesselHierarchyQuery.isFetching) return [];

    const operatorHierarchy = [...lookupOperatorVesselHierarchyQuery.data];
    return operatorHierarchy.reduce((acc, operator) => {
      const childrenWithOperator = operator.children.map((vessel) => ({
        ...vessel,
        operator: operator.name
      }));
      return [...acc, ...childrenWithOperator];
    }, []);
  }, [lookupOperatorVesselHierarchyQuery.data]);
  const isLoading = lookupOperatorVesselHierarchyQuery.isFetching;
  return (
    <Autocomplete
      loading={isLoading}
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      id={id}
      disabled={disabled || isLoading}
      disableClearable
      value={selectedVessel}
      onChange={(_event, newValue) => {
        handleChange(newValue);
      }}
      groupBy={(option) => option.operator}
      getOptionLabel={(option) => `${option.name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={flattenedOptions}
      renderOption={(props, option) => (
        <li {...props} key={`vessel-${option.id}`}>
          <Typography variant="body2">{option.name}</Typography>
        </li>
      )}
      renderGroup={(params) => (
        <li key={params.key} style={{ padding: '0 16px' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {params.group}
          </Typography>
          <Box>{params.children}</Box>
        </li>
      )}
      filterOptions={(options, state) => {
        return options;
        const inputValue = state.inputValue.toString().toLowerCase();
        return options.filter((option) => {
          const vesselName = option.name.toLowerCase();
          const operatorName = option.operator.toLowerCase();
          const imo = option.id.toString();
          return (
            vesselName.includes(inputValue) ||
            operatorName.includes(inputValue) ||
            imo.includes(inputValue)
          );
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          label={isLoading ? <CircularProgress size={25} /> : label || 'Select Vessel'}
        />
      )}
    />
  );
}

VesselSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  selectedVessel: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string
};

export default VesselSelect;
