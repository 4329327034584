import React from 'react';
import { Divider, Grid, Paper } from '@esgian/esgianui';
import Content from '@components/Sections/ShipAnalytics/FaqSection/Content';
import SignUpAndUserSupport from '@components/Sections/ShipAnalytics/FaqSection/SignUpAndUserSupport';
import Modules from '@components/Sections/ShipAnalytics/FaqSection/Modules/Modules';
import VesselOperatorOwner from '@components/Sections/ShipAnalytics/FaqSection/VesselOperatorOwner';
import GeneralQuestions from '@components/Sections/ShipAnalytics/FaqSection/GeneralQuestions';

function FaqSection() {
  return (
    <Paper variant={'outlined'} sx={{ p: 2 }}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <Content />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <SignUpAndUserSupport />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Modules />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <VesselOperatorOwner />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <GeneralQuestions />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

FaqSection.propTypes = {};

FaqSection.defaultProps = {};

export default FaqSection;
