import { useQuery } from '@tanstack/react-query';
import { getGlobalPortsStats } from '@api';
import { useSelector } from 'react-redux';
import { getOperatorType, getSegment } from '@store/features';
import moment from 'moment';
import { getDateFormat } from '@store/features';

export const useGlobalPortStats = (props = {}) => {
  const segment = useSelector(getSegment);
  const operatorType = useSelector(getOperatorType);
  const dateFormat = useSelector(getDateFormat);

  const {
    startDate = moment().subtract(12, 'month').startOf('month').format(dateFormat),
    endDate = moment().format(dateFormat)
  } = props;

  return useQuery({
    queryKey: ['globalPortStats', startDate, endDate, segment.id, operatorType],
    queryFn: ({ signal }) =>
      getGlobalPortsStats(
        {
          startDate,
          endDate,
          operatorTypeId: operatorType,
          segmentTypeId: segment.id
        },
        signal
      ),
    enabled: Boolean(
      startDate && endDate && typeof segment.id === 'number' && typeof operatorType === 'number'
    )
  });
};
