import { useQuery } from '@tanstack/react-query';
import { getGlobalEmissionIntensity } from '@api';
import { useSelector } from 'react-redux';
import { getOperatorType, getSegment } from '@store/features';

export const useGlobalEmissionIntensity = () => {
  const segment = useSelector(getSegment);
  const operatorType = useSelector(getOperatorType);

  return useQuery({
    queryKey: ['globalEmissionIntensity', segment.id, operatorType],
    queryFn: ({ signal }) => getGlobalEmissionIntensity(segment.id, operatorType, 4.0, signal),
    enabled: Boolean(typeof segment.id === 'number' && typeof operatorType === 'number')
  });
};
