import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import Top10PortsInsight from '../DashboardActivityMapInboundVesselComponents/Top10PortsInsight';
import GlobalPortTimeInsight from './GlobalPortTimeInsight';
import VesselsStatusInsight from './VesselsStatusInsight';
import { useTheme } from '@hooks/useTheme';
import GlobalPortWaitingTimeInsight from './GlobalPortWaitingTimeInsight';
import GlobalEmissionIntensityInsight from './GlobalEmissionIntensityInsight';

function DashboardActivityMapGlobalInsights({
  top10Ports,
  lookupPorts,
  globalPortStats,
  vesselsLatestPosition,
  globalMonthlyPercentActivityStatus,
  globalPortCongestion,
  globalEmissionIntensity
}) {
  const { customScrollbar } = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ ...customScrollbar }}>
      <Top10PortsInsight top10Ports={top10Ports} lookupPorts={lookupPorts} />
      <VesselsStatusInsight
        vesselsLatestPosition={vesselsLatestPosition}
        globalMonthlyPercentActivityStatus={globalMonthlyPercentActivityStatus}
      />
      <GlobalPortTimeInsight globalPortStats={globalPortStats} />
      <GlobalPortWaitingTimeInsight globalPortCongestion={globalPortCongestion} />
      <GlobalEmissionIntensityInsight globalEmissionIntensity={globalEmissionIntensity} />
    </Box>
  );
}

DashboardActivityMapGlobalInsights.propTypes = {
  top10Ports: PropTypes.array,
  lookupPorts: PropTypes.array,
  globalPortStats: PropTypes.object,
  vesselsLatestPosition: PropTypes.array,
  globalMonthlyPercentActivityStatus: PropTypes.object,
  globalPortCongestion: PropTypes.object,
  globalEmissionIntensity: PropTypes.object
};

export default DashboardActivityMapGlobalInsights;
