import { BaseApiRequest } from '@api/BaseApi';

export const SAVED_VOYAGE_GROUPS_KEY = 'savedVoyageGroups';

export const getVoyageSearchDetails = (body, signal) => {
  return BaseApiRequest(signal, `/Voyages/Search?api-version=1.0`, 'voyage', 'POST', body);
};
export const getVoyagesDetails = (filterParams, signal) => {
  const body = {
    voyagesTransitDetails: filterParams,
    voyageIds: null,
    voyageGroupId: null
  };
  return BaseApiRequest(signal, '/Voyages/TransitDetails?api-version=1.0', 'voyage', 'POST', body);
};

export const getVoyageEmissionDetails = (filterParams, signal) => {
  const body = {
    voyagesEmissionDetails: [filterParams],
    voyageIds: null,
    voyageGroupId: null
  };
  return BaseApiRequest(
    signal,
    '/Voyages/EmissionDetails?api-version=1.0',
    'voyage',
    'POST',
    body,
    { 'Content-type': 'application/json' }
  );
};

export const getSavedVoyageGroups = (email, signal) => {
  return BaseApiRequest(
    signal,
    `/UserData/${email}/${SAVED_VOYAGE_GROUPS_KEY}?api-version=1.0`,
    'users',
    'GET'
  );
};

export const updateVoyageGroup = (email, updatedData, signal) => {
  const body = {
    userEmail: email,
    dataKey: SAVED_VOYAGE_GROUPS_KEY,
    userInformation: JSON.stringify(updatedData)
  };
  return BaseApiRequest(signal, `/UserData?api-version=1.0`, 'users', 'PUT', body, {
    'Content-type': 'application/json'
  });
};

export const getReducedTransitDetails = (filterParams, signal) => {
  const body = {
    voyagesReducedTransitDetails: filterParams
  };
  return BaseApiRequest(
    signal,
    '/Voyages/ReducedTransitDetails?api-version=1.0',
    'voyage',
    'POST',
    body
  );
};
