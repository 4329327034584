import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { checkUserHasAccess, formatNumberReadable, tableTitlePaddingLeft } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function OwnersTable({ owners, loading, title, height }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const columns = [
    {
      name: 'ownerLongName',
      label: 'Owner Name',
      options: {
        sort: true,
        filterType: 'multiselect',
        sortThirdClickReset: true,
        setCellProps: () => {
          return {
            style: {
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'owner'} id={rowData[1]} name={dataIndex} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'ownerId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'ownerShortName',
      label: 'Short Name',
      options: {
        filterType: 'multiselect',
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'fleetSize',
      label: 'Fleet Size',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Size'),
        filterOptions: getMinMaxFilterOptions('Fleet Size')
      }
    },
    {
      name: 'avgAge',
      label: 'Avg Age',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          return dataIndex?.toFixed(2) || '-';
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Age'),
        filterOptions: getMinMaxFilterOptions('Avg Age')
      }
    },
    {
      name: 'avgCapacity',
      label: 'Avg Capacity (CEU)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          return formatNumberReadable(dataIndex?.toFixed(2)) || '-';
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Avg Capacity'),
        filterOptions: getMinMaxFilterOptions('Avg Capacity')
      }
    },
    {
      name: 'totalCapacity',
      label: 'Total Capacity (CEU)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customBodyRender: (dataIndex) => {
          return formatNumberReadable(dataIndex?.toString()) || '-';
        },
        customFilterListOptions: getMinMaxFilterListOptions('Capacity'),
        filterOptions: getMinMaxFilterOptions('Total Capacity')
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'owners-overview.csv'
    },
    setTableProps: () => {
      return {
        id: 'owners-table'
      };
    },
    tableBodyMaxHeight: height,
    elevation: 0,
    print: false,
    viewColumns: false,
    pagination: true,
    toolbar: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'fleetSize',
      direction: 'desc'
    }
  };
  return (
    <Paper>
      <Table
        customStyle={tableTitlePaddingLeft}
        skeletonRows={6}
        loading={loading}
        mode={theme.mode}
        title={
          <Typography id={'owner-title'} variant={'h6'}>
            {title}
          </Typography>
        }
        data={owners}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}
OwnersTable.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  height: PropTypes.string
};

OwnersTable.defaultProps = {
  owners: [],
  loading: false,
  title: 'Owners',
  height: '80vh'
};

export default OwnersTable;
