import { createSlice } from '@reduxjs/toolkit';
import { COMMERCIAL_CATEGORY_TYPE, OPERATOR_TYPE, GEOGRAPHICAL_TYPES, APP_NAME } from '@constants';

const initSideMenuItemsOpen = { emissionsOpen: false, saOpen: false, vaOpen: false };
const getSessionState = () => {
  try {
    const serializedState = sessionStorage.getItem(APP_NAME);
    if (!serializedState) return undefined;
    const stateObj = JSON.parse(serializedState);
    if (stateObj.general.version >= 1.1) {
      return { ...stateObj.general, init: true };
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

const initialState = {
  themeMode: !!JSON.parse(localStorage.getItem('esgian-theme-mode')),
  sideMenuOpen: !!JSON.parse(localStorage.getItem('esgian-menu-open')),
  dropDownMenuOpen: initSideMenuItemsOpen,
  segment: null,
  version: 1.1,
  init: true,
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  timeZone: 'UTC',
  operatorType: OPERATOR_TYPE.ALL,
  commercialCategoryType: COMMERCIAL_CATEGORY_TYPE.ALL,
  geographicalType: GEOGRAPHICAL_TYPES.ALL
};

const GeneralSlice = createSlice({
  name: 'General',
  initialState: getSessionState() ?? initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
      localStorage.setItem('esgian-theme-mode', `${action.payload}`);
    },
    setInit: (state, action) => {
      state.init = action.payload;
    },
    setSideMenuOpen: (state, action) => {
      if (!action.payload) {
        GeneralSlice.caseReducers.closeAllSideMenuItems(state);
      }
      state.sideMenuOpen = action.payload;
      localStorage.setItem('esgian-menu-open', `${action.payload}`);
    },
    setSideMenuItemOpen: (state, action) => {
      state.dropDownMenuOpen = action.payload;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
    },
    setSegment: (state, action) => {
      state.segment = action.payload;
      state.operatorType = OPERATOR_TYPE.ALL;
      state.commercialCategoryType = COMMERCIAL_CATEGORY_TYPE.ALL;
    },
    setOperatorType: (state, action) => {
      state.operatorType = action.payload;
    },
    setCommercialCategoryType: (state, action) => {
      state.commercialCategoryType = action.payload;
    },
    setGeographicalType: (state, action) => {
      state.geographicalType = action.payload;
    },
    updateGeneralSlice: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    closeAllSideMenuItems: (state) => (state.dropDownMenuOpen = initSideMenuItemsOpen)
  }
});
export const getGeneralSlice = (state) => state.general;
export const getSideMenuOpen = (state) => state.general.sideMenuOpen;
export const getDateFormat = (state) => state.general.dateFormat;
export const getDateTimeFormat = (state) => state.general.dateTimeFormat;
export const getDropDownMenuOpen = (state) => state.general.dropDownMenuOpen;
export const getThemeMode = (state) => state.general.themeMode;
export const getSegment = (state) => state.general.segment;
export const getOperatorType = (state) => state.general.operatorType;
export const getTimeZone = (state) => state.general.timeZone;
export const getCommercialCategoryType = (state) => state.general.commercialCategoryType;
export const getGeographicalType = (state) => state.general.geographicalType;
export const {
  setThemeMode,
  setSegment,
  setCommercialCategoryType,
  setGeographicalType,
  setSideMenuOpen,
  setSideMenuItemOpen,
  setInit,
  updateGeneralSlice,
  setOperatorType,
  closeAllSideMenuItems,
  setTimeZone
} = GeneralSlice.actions;

export default GeneralSlice.reducer;
