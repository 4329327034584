import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '@components/Tables/EmissionAndETSTables/TableWithSummary';

const SimulatedVoyageGHGEmission = ({ myCargoGhgEmissionSummary }) => {
  const { tocGhgEmissionKg, hocGhgEmissionKg, totalGhgEmission } = myCargoGhgEmissionSummary;

  const columns = [
    { name: 'items', label: 'Items' },
    { name: 'amount', label: 'Amount' }
  ];

  const data = [
    {
      items: { value: 'TOC GHG Emission for my cargo (kg)' },
      amount: { value: tocGhgEmissionKg?.toFixed(0) || '--' }
    },
    {
      items: { value: 'HOC GHG Emission for my cargo (kg)' },
      amount: { value: hocGhgEmissionKg?.toFixed(0) || '--' }
    },
    {
      items: { value: 'Total GHG Emission for my cargo (kg)' },
      amount: { value: totalGhgEmission?.toFixed(0) || '--' }
    }
  ];

  return <TableWithSummary title="CO₂e Emission (GHG Emission)" columns={columns} data={data} />;
};

SimulatedVoyageGHGEmission.propTypes = {
  myCargoGhgEmissionSummary: PropTypes.shape({
    tocGhgEmissionKg: PropTypes.number,
    hocGhgEmissionKg: PropTypes.number,
    totalGhgEmission: PropTypes.number
  })
};

export default SimulatedVoyageGHGEmission;
