import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { DashboardActivityMapActions } from './DashboardActivityMapFloatingComponents';
import { Box, ChevronRightIcon, IconButton } from '@esgian/esgianui';
import DashboardActivityMapDrawer, { ACTIVITY_MAP_ACTIONS } from './DashboardActivityMapDrawer';
import { MapFullscreenButton } from '../../Common';
import { mapFullscreenButtonParentStyle } from '../../Common/MapFullscreenButton';
import { useTheme } from '@hooks';
import MapComponent from './MapComponent';

const MAP_CONTAINER_Z_INDEX = 1;

const SIDE_PANEL_WIDTH = '30vw';

function DashboardActivityMap({
  onMapLoad,
  sidePanelContent,
  onSidePanelClose,
  isOpenSidePanel,
  isCompactView,
  onClickFullscreen,
  renderFloatingComponents,
  filterProps,
  selectedAction,
  onChangeSelectedAction,
  mapActionsPopupState,
  mapSettingsProps,
  mapRefreshKey
}) {
  const themeMode = useSelector(getThemeMode);
  const mapContainerRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const { customScrollbar } = useTheme();

  const handleMapCreated = useCallback((map) => {
    mapInstanceRef.current = map;
  }, []);

  // Resize the map when the side panel closes
  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.resize();
    }
  }, [isOpenSidePanel, selectedAction]);

  return (
    <>
      <Box height="100%" width="100%" display="flex">
        <Box
          ref={mapContainerRef}
          height="100%"
          width="100%"
          position="relative"
          zIndex={MAP_CONTAINER_Z_INDEX}>
          <MapComponent
            key={mapRefreshKey}
            mapContainerRef={mapContainerRef}
            onMapLoad={onMapLoad}
            themeMode={themeMode}
            onMapCreated={handleMapCreated}
          />
          {renderFloatingComponents?.(MAP_CONTAINER_Z_INDEX)}
          <Box position="absolute" top="16px" right="16px" zIndex={2}>
            <DashboardActivityMapActions
              onInsightClick={() => onChangeSelectedAction(ACTIVITY_MAP_ACTIONS.INSIGHT)}
              onFilterClick={() => onChangeSelectedAction(ACTIVITY_MAP_ACTIONS.FILTER)}
              onMySaveClick={() => onChangeSelectedAction(ACTIVITY_MAP_ACTIONS.MY_SAVE)}
              onMapSettingsClick={() => onChangeSelectedAction(ACTIVITY_MAP_ACTIONS.MAP_SETTINGS)}
              onInboundVesselsFilterClick={() =>
                onChangeSelectedAction(ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER)
              }
              isInboundVesselsFilterApplied={
                selectedAction === ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER
              }
              isFilterApplied={filterProps?.isFilterApplied}
              isSavedApplied={false}
              mapActionsPopupState={mapActionsPopupState}
              renderPopupContent={() => {
                if (selectedAction === ACTIVITY_MAP_ACTIONS.INSIGHT) {
                  return null;
                }
                return (
                  <DashboardActivityMapDrawer
                    selectedAction={selectedAction}
                    onClose={() => {
                      mapActionsPopupState.close();
                      onChangeSelectedAction(null);
                    }}
                    filterProps={filterProps}
                    mapSettingsProps={mapSettingsProps}
                  />
                );
              }}
            />
          </Box>
          <Box sx={mapFullscreenButtonParentStyle}>
            <MapFullscreenButton isFullScreen={!isCompactView} onClick={onClickFullscreen} />
          </Box>
        </Box>

        {isOpenSidePanel && (
          <Box
            width={SIDE_PANEL_WIDTH}
            height="100%"
            position="relative"
            zIndex={MAP_CONTAINER_Z_INDEX + 1}>
            <IconButton
              onClick={() => onSidePanelClose()}
              sx={{
                position: 'absolute',
                top: `50%`,
                left: 0,
                width: '32px',
                height: '72px',
                zIndex: 4,
                transform: 'translateY(-50%) translateX(-100%)',
                backgroundColor: themeMode ? '#000' : '#fff',
                '&:hover': {
                  backgroundColor: themeMode ? '#333' : '#f0f0f0'
                },
                borderRadius: 0
              }}>
              <ChevronRightIcon />
            </IconButton>
            <Box height="100%" overflow="auto" sx={{ ...customScrollbar }}>
              {sidePanelContent}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

DashboardActivityMap.propTypes = {
  onMapLoad: PropTypes.func,
  isOpenSidePanel: PropTypes.bool,
  sidePanelContent: PropTypes.node,
  onSidePanelClose: PropTypes.func,
  isCompactView: PropTypes.bool,
  onClickFullscreen: PropTypes.func,
  renderFloatingComponents: PropTypes.func,
  filterProps: PropTypes.object,
  onMapUpdate: PropTypes.func,
  selectedAction: PropTypes.string,
  onChangeSelectedAction: PropTypes.func,
  mapActionsPopupState: PropTypes.object,
  mapSettingsProps: PropTypes.object,
  mapRefreshKey: PropTypes.string
};

export default DashboardActivityMap;
