import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Switch, Typography } from '@esgian/esgianui';
import DashboardActivityMapDrawerHeader from './DashboardActivityMapDrawerHeader';

const DashboardMapSettings = ({
  onClose,
  showPorts,
  onShowPortsChange,
  showVesselsNames,
  onShowVesselsNamesChange,
  showVesselsStatus,
  onShowVesselsStatusChange,
  showVessels,
  onShowVesselsChange
}) => {
  const settingsSections = [
    {
      sectionTitle: 'Port',
      settings: [
        {
          label: 'Show port',
          name: 'showPort',
          value: showPorts,
          onChange: (e) => onShowPortsChange(e.target.checked)
        }
      ]
    },
    {
      sectionTitle: 'Vessel',
      settings: [
        {
          label: 'Show vessels',
          name: 'showVessels',
          value: showVessels,
          onChange: (e) => onShowVesselsChange(e.target.checked)
        },
        {
          label: 'Show vessel name',
          name: 'showVesselName',
          value: showVesselsNames,
          onChange: (e) => onShowVesselsNamesChange(e.target.checked)
        },
        {
          label: 'Show vessel status',
          name: 'showVesselStatus',
          value: showVesselsStatus,
          onChange: (e) => onShowVesselsStatusChange(e.target.checked)
        }
      ]
    }
  ];

  return (
    <Box display="flex" flexDirection="column" gap={2} p={2}>
      <DashboardActivityMapDrawerHeader title="Map setting" onClose={onClose} />
      {settingsSections.map((section) => (
        <Box key={section.sectionTitle}>
          <Typography>{section.sectionTitle}: </Typography>
          <ul>
            {section.settings.map((setting) => (
              <li key={setting.name}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <FormLabel>{setting.label}</FormLabel>
                  <Switch
                    disabled={!setting?.onChange}
                    checked={setting.value}
                    onChange={setting?.onChange}
                  />
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  );
};

DashboardMapSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  showPorts: PropTypes.bool,
  onShowPortsChange: PropTypes.func,
  showVesselsNames: PropTypes.bool,
  onShowVesselsNamesChange: PropTypes.func,
  showVesselsStatus: PropTypes.bool,
  onShowVesselsStatusChange: PropTypes.func,
  showVessels: PropTypes.bool,
  onShowVesselsChange: PropTypes.func
};

export default DashboardMapSettings;
