import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function OwnerMultiSelect({
  handleChange,
  placeholder,
  selectedOwners,
  ownersList,
  disabled,
  getOptionDisabled,
  error,
  limitTags,
  helperText,
  id,
  width,
  loading,
  label
}) {
  const [tempValues, setTempValues] = useState([]);
  const [owners, setOwners] = useState([]);
  let { customScrollbar } = useTheme();

  useEffect(() => {
    setTempValues([...selectedOwners]);
  }, [selectedOwners]);

  // Sort list by owner name to make it easier to navigate list.
  useEffect(() => {
    if (ownersList) {
      let sortedOwners = [...ownersList].sort(compare);
      sortedOwners[0] = { ...sortedOwners[0], ...{ first: true } };
      setOwners(sortedOwners);
    }
  }, [ownersList]);

  const handleClick = (option, checked) => {
    if (!option) {
      setTempValues(checked);
    } else {
      if (!checked) {
        setTempValues((prev) => [...prev, option]);
      } else {
        setTempValues((prev) => [...prev.filter((item) => item.id !== option.id)]);
      }
    }
  };

  return (
    <Autocomplete
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      componentsProps={{
        paper: {
          sx: {
            width: width || '350px'
          }
        }
      }}
      id={'owners-select'}
      disabled={disabled || loading}
      isOptionEqualToValue={(option, value) => {
        if (owners.length) {
          return option.id === value.id;
        }
        return false;
      }}
      onClose={() => {
        handleChange([...tempValues]);
      }}
      value={owners?.length ? tempValues : []}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        return (
          <React.Fragment key={`own-${option.id}`}>
            {option?.first && (
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'own-check-all-btn'}
                    onClick={() => handleClick(null, owners)}
                    variant={'contained'}
                    color={'primary'}>
                    Check all
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'clear-owns-btn'}
                    onClick={() => handleClick(null, [])}
                    variant={'outlined'}
                    color={'secondary'}>
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}
            {!option?.all && (
              <MenuItem
                key={option.id}
                value={option.id}
                onClick={() => handleClick(option, selected)}>
                <ListItemIcon>
                  <Checkbox size={'small'} color="primary" checked={selected} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={option.name} />
              </MenuItem>
            )}
          </React.Fragment>
        );
      }}
      sx={{ width: width || '250px' }}
      multiple
      onChange={(event, value, reason) => {
        if (reason === 'clear') {
          handleChange([]);
        }
      }}
      options={owners}
      limitTags={limitTags}
      getOptionLabel={(option) => option.name}
      renderTags={(value) => {
        if (value.length === owners.length) {
          return 'All';
        }
        let i = 0;
        return value.map(({ name }) => {
          if (!name) {
            return;
          }
          if (i === limitTags) {
            i++;
            return `+${value.length - limitTags}`;
          } else if (i < limitTags) {
            i++;
            return (
              <Chip
                size={'small'}
                key={`chip-${name}`}
                label={
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'clip',
                      textOverflow: 'inherit'
                    }}>
                    {name}
                  </span>
                }
              />
            );
          }
        });
      }}
      getOptionDisabled={getOptionDisabled}
      filterOptions={(owners, state) => {
        return owners.filter(
          ({ name, ownerShortName, id }) =>
            name?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            ownerShortName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        );
      }}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: width || '250px' }}
          id={id}
          fullWidth
          label={label || 'Owner*'}
          error={error}
          helperText={helperText}
          placeholder={selectedOwners?.length >= 1 ? '' : placeholder}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}

OwnerMultiSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  limitTags: PropTypes.number,
  width: PropTypes.string,
  selectedOwners: PropTypes.arrayOf(PropTypes.object),
  ownersList: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string
};

OwnerMultiSelect.defaultProps = {
  selectedOwners: [],
  ownersList: [],
  getOptionDisabled: null,
  disabled: false,
  placeholder: '',
  error: false,
  helperText: null,
  limitTags: 1,
  id: 'owners-select',
  loading: false,
  label: 'Owner*'
};

export default OwnerMultiSelect;
