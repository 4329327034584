import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdvancedFilterDrawer, FilterSlider, Grid } from '@esgian/esgianui';
import {
  OPERATOR_MAX_FLEET_SIZE,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MIN_BUILD_YEAR
} from '@constants';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { getOprFleetCiiFilters } from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { resetOprFleetCiiAdvFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';

function OperatorFleetCiiRatingAdvancedFilters({
  advancedOpen,
  setAdvancedOpen,
  updateFilterValue
}) {
  const [filters, setFilters] = useState({});
  const { isRoRo, segment } = useSegment();
  const dispatch = useDispatch();
  const fleetCiiRatingFilters = useSelector(getOprFleetCiiFilters);
  const { minCapacity, maxCapacity, minFleetSize, maxFleetSize, minBuildYear, maxBuildYear } =
    filters;

  const handleClearClick = () => {
    dispatch(resetOprFleetCiiAdvFilters(segment));
    setFilters(fleetCiiRatingFilters);
    setAdvancedOpen(false);
  };

  const handleApplyClick = () => {
    updateFilterValue(filters);
    setAdvancedOpen(false);
  };

  const updateTempFilters = (payload) => {
    setFilters({ ...filters, ...payload });
  };

  useEffect(() => {
    setFilters({ ...fleetCiiRatingFilters });
  }, [fleetCiiRatingFilters]);

  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            step={10}
            value={[minFleetSize, maxFleetSize]}
            marks={[
              { value: 0, label: 0 },
              { value: OPERATOR_MAX_FLEET_SIZE, label: OPERATOR_MAX_FLEET_SIZE }
            ]}
            max={OPERATOR_MAX_FLEET_SIZE}
            min={0}
            title={'Fleet size'}
            onChange={(val) => updateTempFilters({ minFleetSize: val[0], maxFleetSize: val[1] })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            step={100}
            marks={[
              { value: 0, label: 0 },
              {
                value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT,
                label: isRoRo
                  ? `${VESSEL_MAX_CAPACITY / 1000}k`
                  : `${VESSEL_MAX_CAPACITY_DWT / 1000}k`
              }
            ]}
            value={[minCapacity, maxCapacity]}
            max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT}
            min={0}
            title={isRoRo ? 'Vessel capacity' : 'Deadweight'}
            onChange={(val) => updateTempFilters({ minCapacity: val[0], maxCapacity: val[1] })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            marks={[
              { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
              { value: moment().year(), label: moment().year() }
            ]}
            value={[minBuildYear, maxBuildYear]}
            max={moment().year()}
            min={VESSEL_MIN_BUILD_YEAR}
            title={'Build year'}
            onChange={(val) => updateTempFilters({ minBuildYear: val[0], maxBuildYear: val[1] })}
          />
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

OperatorFleetCiiRatingAdvancedFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  updateFilterValue: PropTypes.func.isRequired
};

OperatorFleetCiiRatingAdvancedFilters.defaultProps = {
  advancedOpen: false
};

export default OperatorFleetCiiRatingAdvancedFilters;
