import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  ListItem,
  TextField
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function MultiSelectDropdown({
  handleChange,
  placeholder,
  selectedOptions = [],
  options,
  disabled,
  getOptionDisabled,
  error,
  limitTags = 1,
  helperText,
  width,
  label
}) {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  let { customScrollbar } = useTheme();
  // Sort list in alphabetic order to make it easier to navigate.
  useEffect(() => {
    if (options) {
      let sorted = [...options].sort(compare);
      sorted[0] = { ...sorted[0], ...{ first: true } };
      setDropdownOptions(sorted);
    }
  }, [options]);

  return (
    <Autocomplete
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      componentsProps={{
        paper: {
          sx: {
            width: width || '350px'
          }
        }
      }}
      id={'multi-select'}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        if (dropdownOptions.length) {
          return option.id === value.id;
        }
        return false;
      }}
      value={dropdownOptions?.length ? selectedOptions : []}
      disableCloseOnSelect
      onChange={(_, selectedOptions) => handleChange(selectedOptions)}
      renderOption={(props, option, { selected }) => {
        return (
          <React.Fragment key={`opr-${option.id}`}>
            {option?.first && (
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'opr-check-all-btn'}
                    onClick={() => handleChange(dropdownOptions)}
                    variant={'contained'}
                    color={'primary'}>
                    Check all
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'clear-oprs-btn'}
                    onClick={() => handleChange([])}
                    variant={'outlined'}
                    color={'secondary'}>
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}
            {!option?.all && (
              <ListItem {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </ListItem>
            )}
          </React.Fragment>
        );
      }}
      sx={{ width: width || '250px' }}
      multiple
      options={dropdownOptions}
      limitTags={limitTags}
      getOptionLabel={(option) => option.name}
      renderTags={(value) => {
        if (value.length === dropdownOptions.length) {
          return 'All';
        }
        let i = 0;
        return value.map(({ name }) => {
          if (!name) {
            return;
          }
          if (i === limitTags) {
            i++;
            return `+${value.length - limitTags}`;
          } else if (i < limitTags) {
            i++;
            return (
              <Chip
                size={'small'}
                key={`chip-${name}`}
                label={
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'clip',
                      textOverflow: 'inherit'
                    }}>
                    {name}
                  </span>
                }
              />
            );
          }
        });
      }}
      getOptionDisabled={getOptionDisabled}
      filterOptions={(options, state) => {
        return options.filter(
          ({ name, id }) =>
            name?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        );
      }}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: width || '250px' }}
          id={'id-multi-select'}
          fullWidth
          label={label}
          error={error}
          helperText={helperText}
          placeholder={selectedOptions?.length >= 1 ? '' : placeholder}
          {...params}
        />
      )}
    />
  );
}

MultiSelectDropdown.propTypes = {
  handleChange: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  limitTags: PropTypes.number,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.string,
  label: PropTypes.string
};

export default MultiSelectDropdown;
