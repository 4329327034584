import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConsistencyChart } from '@components/Charts';
import { useTheme } from '@hooks';

function ServiceLevelConsistencyChart({ performanceData, loading, fileName }) {
  const [costumeOptions, setCostumeOptions] = useState({});
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const {
    theme: {
      palette: {
        charts: { twoColorsContrast }
      }
    }
  } = useTheme();

  useEffect(() => {
    if (performanceData) {
      const {
        segmentPerformance: {
          segmentFrequencyResult: {
            frequencyStats: { consistencyScore }
          },
          numberOfOperatorsInSegment
        },
        operatorPerformance
      } = performanceData;
      const { operatorFrequencyResult: ofr } = operatorPerformance;
      const operatorFrequencyResult = ofr[0];
      const { frequencyStats, operatorLongName } = operatorFrequencyResult;
      const categories = [];
      const tempData = [];
      if (numberOfOperatorsInSegment > 1) {
        categories.push('Segment Average');
        tempData.push(consistencyScore);
      }
      categories.push(operatorLongName);
      tempData.push(frequencyStats.consistencyScore);
      const data = [{ data: tempData }];

      setCategories(categories);
      setSeries(data);
      setCostumeOptions({
        colors: numberOfOperatorsInSegment > 1 ? twoColorsContrast : [twoColorsContrast[1]],
        fill: { type: 'solid' },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 0,
            horizontal: true,
            dataLabels: {
              position: 'top'
            }
          }
        }
      });
    }
  }, [performanceData]);

  return (
    <ConsistencyChart
      loading={loading}
      series={series}
      categories={categories}
      costumeOptions={costumeOptions}
      fileName={fileName}
    />
  );
}

ServiceLevelConsistencyChart.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired
};

ServiceLevelConsistencyChart.defaultProps = {
  performanceData: null,
  loading: false
};

export default ServiceLevelConsistencyChart;
