import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@esgian/esgianui';
import { CountryOverviewMap } from '@components/Maps';

function CountriesOverviewSection({ countriesData, loading }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CountryOverviewMap countriesData={countriesData} loading={loading} />
      </Grid>
    </Grid>
  );
}

CountriesOverviewSection.propTypes = {
  countriesData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

CountriesOverviewSection.defaultProps = {
  loading: false,
  countriesData: []
};

export default CountriesOverviewSection;
