import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { getTableCellRenderCII } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const defaultColumns = [
  {
    name: 'vesselName',
    label: 'Vessel name',
    options: {
      sort: true,
      customBodyRender: (value, rowIndex) => {
        const { rowData } = rowIndex;
        return value !== null ? (
          <ProfileLink name={value} profile={'vessel'} id={rowData[1]} />
        ) : (
          '--'
        );
      }
    }
  },
  {
    name: 'imo',
    label: 'IMO',
    options: {
      filterType: 'textField',
      sort: true
    }
  },
  {
    name: 'operatorId',
    options: { display: false, download: false, viewColumns: false, filter: false }
  }
];

function OperatorCiiRatingTable({ fileName, loading, ciiRatingData, height }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const yearColumns = useMemo(() => {
    if (!ciiRatingData) return [];
    return Object.keys(ciiRatingData?.operatorYearlyCIIRating).map((year) => {
      return {
        name: year,
        label: year + ' CII rating',
        options: {
          sort: true,
          setCellProps: () => {
            return {};
          },
          customBodyRender: (value) => {
            return getTableCellRenderCII(value, theme);
          }
        }
      };
    });
  }, [ciiRatingData]);

  let tableData = useMemo(() => {
    if (!ciiRatingData) return [];
    const { assetYearlyCIIRating } = ciiRatingData;
    return assetYearlyCIIRating?.map((vessel) => {
      let yearCiiData = {};
      vessel?.yearsCIIRating?.forEach(({ year, ciiRating }) => {
        yearCiiData[year] = ciiRating;
      });
      return { ...vessel, ...yearCiiData };
    });
  }, [ciiRatingData]);

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: true,
    download: checkUserHasAccess(user, true),
    tableBodyMaxHeight: height,
    downloadOptions: {
      filename: fileName
    },
    print: checkUserHasAccess(user, true),
    viewColumns: false,
    pagination: true,
    elevation: 0,
    toolbar: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100]
  };

  return (
    <Table
      loading={loading}
      mode={theme.mode}
      data={tableData?.length ? tableData : []}
      columns={[...defaultColumns, ...yearColumns]}
      options={options}
    />
  );
}

OperatorCiiRatingTable.propTypes = {
  ciiRatingData: PropTypes.object,
  loading: PropTypes.bool,
  height: PropTypes.string,
  fileName: PropTypes.string.isRequired
};

OperatorCiiRatingTable.defaultProps = {
  ciiRatingData: null,
  loading: false,
  height: '80vh'
};

export default OperatorCiiRatingTable;
