import { Paper, Stack, Typography } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React from 'react';
import { getGeneralSlice } from '@store/features';
import { useSelector } from 'react-redux';
import { formatToDecimalPlaces } from '@helpers';
import { useTimezone } from '@hooks/useTimezone';

const StatusIndicatorDot = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

StatusIndicatorDot.propTypes = {
  color: PropTypes.string
};

const VoyageSummaryOverviewFloatingWindow = ({
  summaryOverview,
  voyageTransitDetails,
  isOngoingVoyage
}) => {
  const { numPortsVisited, departurePort, arrivalPort } = summaryOverview ?? {};
  const { themeMode } = useSelector(getGeneralSlice);
  const { getTimeZoneDisplay } = useTimezone();

  const lastReportDate =
    voyageTransitDetails?.transitDetails?.[voyageTransitDetails?.transitDetails?.length - 1]
      ?.timeStamp;

  const nextPortName = summaryOverview?.nextPortCall?.portName ?? '-';
  const nextPortEta = summaryOverview?.nextPortCall?.eta
    ? getTimeZoneDisplay(summaryOverview?.nextPortCall?.eta)
    : '-';
  return (
    <Paper
      sx={{
        zIndex: 20,
        width: 'max-content',
        background: themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)',
        p: '0.5rem 0rem'
      }}>
      <Stack pl={2} pr={2} spacing={1} id={'ongoing-voyage-info'}>
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
            <Typography variant={'body2'} color="primary.contrastText">
              <StatusIndicatorDot color={'#0E9EFF'} /> Origin:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {departurePort}
            </Typography>
          </Stack>
        )}
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
            <Typography variant={'body2'} color="primary.contrastText">
              <StatusIndicatorDot color={'#FF9800'} /> Destination:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {arrivalPort}
            </Typography>
          </Stack>
        )}
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
            <Typography variant={'body2'} color="primary.contrastText">
              Transit Time:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {formatToDecimalPlaces(voyageTransitDetails?.transitDays, 2)} days
            </Typography>
          </Stack>
        )}
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Typography variant={'body2'} color="primary.contrastText">
            {!isOngoingVoyage ? 'Intermediate ' : ''}Port calls:
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {numPortsVisited}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Typography variant={'body2'} color="primary.contrastText">
            {isOngoingVoyage ? 'Last reported at:' : 'Distance (NM):'}
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {isOngoingVoyage
              ? lastReportDate
                ? getTimeZoneDisplay(lastReportDate)
                : null
              : formatToDecimalPlaces(voyageTransitDetails?.totalDistance, 2)}
          </Typography>
        </Stack>
        {isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
            <Typography variant={'body2'} color="primary.contrastText">
              Next Port Call:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {nextPortName}
            </Typography>
          </Stack>
        )}
        {isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
            <Typography variant={'body2'} color="primary.contrastText">
              ETA:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {nextPortEta}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default VoyageSummaryOverviewFloatingWindow;

VoyageSummaryOverviewFloatingWindow.propTypes = {
  summaryOverview: PropTypes.object,
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  isOngoingVoyage: PropTypes.bool
};
