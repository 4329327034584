import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import {
  OperatorServiceFrequencyChart,
  PerformanceScore,
  TransitTimePerformanceChart,
  ServiceLevelConsistencyChart,
  ServiceFrequencyTable,
  TransitTimePerformanceOverview
} from '@components/Sections/ShipAnalytics/OperatorPerformanceSection';
import { formatPeriodDisplay, getDateList } from '@helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getOperatorPerformanceFilters } from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
function PerformanceVsSegment({ performanceData, renderOverview, loading }) {
  const {
    subSection,
    startDate,
    endDate,
    selectedPortLoad,
    selectedOperator,
    selectedPortDischarge
  } = useSelector(getOperatorPerformanceFilters);

  const getFileName = useCallback(
    (key) => {
      const operatorShortName = selectedOperator?.operatorShortName;
      const CodePOL = selectedPortLoad?.locode;
      const CodePOD = selectedPortDischarge?.locode;
      return `${operatorShortName}-${key}-${CodePOL}-${CodePOD}-${moment(startDate).format(
        'MMYY'
      )}-${moment(endDate).format('MMYY')}`;
    },
    [performanceData]
  );
  const dateRangeList = useMemo(() => {
    return getDateList(startDate, endDate);
  }, [startDate, endDate]);

  if (!renderOverview) {
    return (
      <>
        <Grid item xs={12}>
          {subSection === 1 && (
            <OperatorServiceFrequencyChart
              loading={loading}
              performanceData={performanceData}
              dateRangeList={dateRangeList}
              fileName={getFileName('service-freq')}
            />
          )}
          {subSection === 2 && (
            <ServiceLevelConsistencyChart
              loading={loading}
              performanceData={performanceData}
              fileName={getFileName('service-consistency')}
            />
          )}
          {subSection === 3 && (
            <TransitTimePerformanceChart
              loading={loading}
              performanceData={performanceData}
              dateRangeList={dateRangeList}
              fileName={getFileName('transit-time')}
            />
          )}
          {subSection === 4 && (
            <PerformanceScore loading={loading} performanceData={performanceData} />
          )}
        </Grid>
      </>
    );
  }
  if ([1, 2, 3].includes(subSection)) {
    return (
      <Grid item xs={12}>
        <Paper variant={'outlined'} sx={{ p: subSection === 3 ? 2 : 0, maxWidth: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack sx={{ p: 2 }}>
                <Typography variant={'h6'}>
                  Service Level - {subSection === 3 ? 'Transit Time' : 'Frequency'} Analysis
                </Typography>
                <Typography color={'text.secondary'} variant={'body2'}>
                  From {formatPeriodDisplay(startDate, endDate)}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {(subSection === 1 || subSection === 2) && (
                <ServiceFrequencyTable
                  dateRangeList={dateRangeList}
                  performanceData={performanceData}
                />
              )}
              {subSection === 3 && (
                <TransitTimePerformanceOverview
                  dateRangeList={dateRangeList}
                  performanceData={performanceData}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return <></>;
}

PerformanceVsSegment.propTypes = {
  loading: PropTypes.bool,
  performanceData: PropTypes.object,
  renderOverview: PropTypes.bool
};

PerformanceVsSegment.defaultProps = {
  loading: false,
  performanceData: {},
  renderOverview: false
};

export default PerformanceVsSegment;
