import moment from 'moment';
import React from 'react';
import { renderToString } from 'react-dom/server';
import mapboxgl from 'mapbox-gl';
import { formatToDecimalPlaces } from '@helpers';

export const getShipRotation = (currentPosition, voyage) => {
  if (!currentPosition || !voyage) return;
  if (!currentPosition || !voyage) return;
  const currentIndex = voyage.findIndex((pos) => pos === currentPosition);
  const prevPosition = currentIndex > 0 ? voyage[currentIndex - 1] : currentPosition;
  const nextPosition =
    currentIndex < voyage.length - 1 ? voyage[currentIndex + 1] : currentPosition;

  const prevLng = prevPosition.lon;
  // const prevLat = prevPosition.lat;
  const currentLng = currentPosition.lon;
  const currentLat = currentPosition.lat;
  const nextLng = nextPosition.lon;
  const nextLat = nextPosition.lat;

  const y = Math.sin(((nextLng - prevLng) * Math.PI) / 180) * Math.cos((nextLat * Math.PI) / 180);
  const x =
    Math.cos((currentLat * Math.PI) / 180) * Math.sin(((currentLng - prevLng) * Math.PI) / 180) -
    Math.sin((currentLat * Math.PI) / 180) *
      Math.cos((nextLat * Math.PI) / 180) *
      Math.cos(((nextLng - prevLng) * Math.PI) / 180);

  const brng = Math.atan2(y, x);
  const rotation = ((brng * 180) / Math.PI + 360) % 360;

  return rotation;
};

export const createShipIcon = () => {
  const shipSvgContent = `
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <circle cx="6" cy="6" r="5.5" fill="#EF5350" stroke="white"/>
</svg>
      `;

  return `data:image/svg+xml;base64,${btoa(shipSvgContent)}`;
};

/**
 * international Date Line
 * When a line crosses the international Date Line then it will draw a line across the whole globe. This converts the data
 * so that it's one consistent line.
 */
function processCoordinates(coordinates) {
  var lines = [];
  var currentLine = [];

  for (var i = 0; i < coordinates.length - 1; i++) {
    currentLine.push(coordinates[i]);

    if (Math.abs(coordinates[i][0] - coordinates[i + 1][0]) > 180) {
      var midPoint = (coordinates[i][1] + coordinates[i + 1][1]) / 2;

      if (coordinates[i][0] > 0) {
        currentLine.push([180, midPoint]);
        lines.push(currentLine);
        currentLine = [[-180, midPoint]];
      } else {
        currentLine.push([-180, midPoint]);
        lines.push(currentLine);
        currentLine = [[180, midPoint]];
      }
    }
  }
  currentLine.push(coordinates[coordinates.length - 1]);
  lines.push(currentLine);

  return lines;
}

export const getRouteSource = (routes) => {
  const features = routes.flatMap(({ route, color }) => {
    // Convert each route's coordinates and create features
    const routeCoordinates = processCoordinates(route.map(({ lon, lat }) => [lon, lat]));

    // Create features for each segment of the route
    return routeCoordinates.map((coordinates) => ({
      type: 'Feature',
      properties: {
        color
      },
      geometry: {
        type: 'LineString',
        coordinates
      }
    }));
  });

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  };
};

export const getRouteLayer = (routes, options) => {
  return {
    id: options?.id ?? 'route',
    type: 'line',
    source: getRouteSource(routes),
    layout: {
      'line-join': 'bevel',
      'line-cap': 'round',
      visibility: options?.visibility ?? 'visible'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 2
    }
  };
};

export const getLayerConfig = (sourceId, options) => {
  return {
    id: sourceId,
    type: 'line',
    source: sourceId,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: options?.visibility ?? 'visible'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': options?.lineWidth ?? 2,
      ...(options?.lineDashArray && { 'line-dasharray': options.lineDashArray }),
      ...(options?.opacity && { 'line-opacity': options.opacity })
    }
  };
};

export const applyLayerPaintProperties = (mapInstance, layerId, properties) => {
  properties.lineWidth && mapInstance.setPaintProperty(layerId, 'line-width', properties.lineWidth);
  properties.lineColor && mapInstance.setPaintProperty(layerId, 'line-color', properties.lineColor);
  properties.lineOpacity &&
    mapInstance.setPaintProperty(layerId, 'line-opacity', properties.lineOpacity);
  properties.lineDasharray &&
    mapInstance.setPaintProperty(layerId, 'line-dasharray', properties.lineDasharray);
};

export const getPopUpContent = (port) => {
  const {
    portName,
    arrivalDate,
    arrivalDraught,
    imoDraught,
    percentArrivalDraught,
    percentDepartureDraught,
    departureDate,
    departureDraught,
    portTimeDays,
    portWaitingTimeDays,
    vesselName
  } = port;
  const popupHtmlContent = renderToString(
    <div
      style={{
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '0.25rem',
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'column',
        gap: '8px',
        color: 'black',
        width: '240px'
      }}
      id="port-popup">
      <strong style={{ marginBottom: '10px' }}>{portName}</strong>
      {vesselName && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px'
          }}>
          <span>Vessel:</span>
          <span>{vesselName}</span>
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px'
        }}>
        <span>ATA</span>
        <span>{moment(arrivalDate).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <span>Arrival Draught/</span>
          <span>{formatToDecimalPlaces(arrivalDraught, 1)} m/</span>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <span>Max. Draught:</span>
          <span>
            {formatToDecimalPlaces(imoDraught, 1)}m (
            {formatToDecimalPlaces(percentArrivalDraught, 1)}%)
          </span>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px'
        }}>
        <span>ATD</span>
        <span>{moment(departureDate).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <div style={{}}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <span>Departure Draught/</span>
          <span>{formatToDecimalPlaces(departureDraught, 1)} m/</span>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <span>Max. Draught:</span>
          <span>
            {formatToDecimalPlaces(imoDraught, 1)}m (
            {formatToDecimalPlaces(percentDepartureDraught, 1)}%)
          </span>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px'
        }}>
        <span>Port Time:</span>
        <span>{formatToDecimalPlaces(portTimeDays, 2)} days</span>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px'
        }}>
        <span>Port Waiting Time:</span>
        <span>{formatToDecimalPlaces(portWaitingTimeDays, 2)} days</span>
      </div>
    </div>
  );

  return popupHtmlContent;
};

export const generatePulsingDotLayerId = (prefix) => `${prefix}-layer-with-pulsing-dot`;

export const putMarkerOnMap = (map, pulsingDot, long, lat, prefix, isVisible = true) => {
  const pulsingLayerId = generatePulsingDotLayerId(prefix);

  if (!map.hasImage(`${prefix}-pulsing-dot`)) {
    map.addImage(`${prefix}-pulsing-dot`, pulsingDot, { pixelRatio: 2 });
  }
  if (map.getLayer(pulsingLayerId)) {
    map.removeLayer(pulsingLayerId);
  }
  if (map.getSource(`${prefix}-dot-point`)) {
    map.removeSource(`${prefix}-dot-point`);
  }

  map.addSource(`${prefix}-dot-point`, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [long, lat]
          }
        }
      ]
    }
  });

  map.addLayer({
    id: pulsingLayerId,
    type: 'symbol',
    source: `${prefix}-dot-point`,
    layout: {
      'icon-image': `${prefix}-pulsing-dot`,
      visibility: isVisible ? 'visible' : 'none'
    }
  });
};

export const createPulsingMarker = (map, color, shouldPulse = true) => {
  const size = 80;

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
    // Create a render method based on shouldPulse
    render: createRenderFunction(map, color, shouldPulse)
  };

  return pulsingDot;
};

// Factory function to create the render method
const createRenderFunction = (map, color, shouldPulse) => {
  return function () {
    const size = 80;
    const context = this.context;
    context.clearRect(0, 0, this.width, this.height);

    if (shouldPulse) {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;
      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;

      // Draw the outer circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();

      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = color;
      context.strokeStyle = 'white';
      context.lineWidth = 4 * (1 - t);
      context.fill();
      context.stroke();
    } else {
      const radius = (size / 2) * 0.3;

      // Draw a static circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = color;
      context.strokeStyle = 'white';
      context.lineWidth = 4;
      context.fill();
      context.stroke();
    }

    this.data = context.getImageData(0, 0, this.width, this.height).data;
    map.triggerRepaint();
    return true;
  };
};

export const removeMarkerFromMap = (map, prefix) => {
  if (map.getLayer(`${prefix}-layer-with-pulsing-dot`)) {
    map.removeLayer(`${prefix}-layer-with-pulsing-dot`);
  }
  if (map.getSource(`${prefix}-dot-point`)) {
    map.removeSource(`${prefix}-dot-point`);
  }
  if (map.hasImage(`${prefix}-pulsing-dot`)) {
    map.removeImage(`${prefix}-pulsing-dot`);
  }
};

export const getMarkers = (portCollection, mapInstance, options) => {
  const { portsColor } = options || {};
  const markers = [];
  portCollection.forEach((singlePort, index) => {
    const { portLat, portLon } = singlePort;
    const markerElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    markerElement.setAttribute('width', '16');
    markerElement.setAttribute('height', '16');
    markerElement.setAttribute('viewBox', '0 0 16 16');

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttribute('cx', '8');
    circle.setAttribute('cy', '8');
    circle.setAttribute('r', '4');
    circle.setAttribute('fill', portsColor ?? '#80DFEB');
    if (index === 0 || index === portCollection.length - 1) {
      circle.setAttribute('stroke', index === 0 ? '#0E9EFF40' : '#FF980040');
      circle.setAttribute('stroke-width', '4');
    }

    markerElement.appendChild(circle);

    const popupHtmlContent = getPopUpContent(singlePort);

    const popup = new mapboxgl.Popup({
      anchor: 'bottom',
      className: 'mapbox-custom-popup-priority'
    })
      .setHTML(popupHtmlContent)
      .setMaxWidth('500px');

    const marker = new mapboxgl.Marker({
      element: markerElement
    }).setLngLat([portLon, portLat]);

    marker.getElement().addEventListener('mouseenter', () => {
      popup.addTo(mapInstance);
    });

    marker.getElement().addEventListener('mouseleave', () => {
      popup.remove();
    });
    marker.setPopup(popup);
    markers.push(marker);
  });
  return markers;
};

export const createMapPopup = (popupText, lon, lat) => {
  const popup = new mapboxgl.Popup({
    className: 'mapbox-custom-popup-text',
    closeButton: false,
    closeOnMove: false,
    closeOnClick: false
  })
    .setMaxWidth('none')
    .setHTML(
      renderToString(
        <span style={{ padding: ' 0 6px' }} className="background-color: rgba(255, 255, 255, 0.5);">
          {popupText}
        </span>
      )
    )
    .setLngLat(new mapboxgl.LngLat(lon, lat));

  return popup;
};

export const attachMarkers = (markers, mapInstance) =>
  markers?.forEach((marker) => marker.addTo(mapInstance));

export const removeMarkers = (markers) => markers?.forEach((marker) => marker.remove());

export const toggleLayerVisibility = (mapInstance, layerId, isVisible) => {
  if (!mapInstance) {
    return;
  }
  const hasRouteLayer = mapInstance.getLayer(layerId);
  if (!hasRouteLayer) {
    return;
  }
  mapInstance.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
};

export const calculateProgressFromDateRange = (currentDate, startDate, endDate) => {
  const current = moment(currentDate);
  const start = moment(startDate);
  const end = moment(endDate);

  const total = end.diff(start, 'day');
  const progress = end.diff(current, 'day');

  return (progress / total) * 100;
};

export const attachRouteLayer = (mapInstance, routes, routeId, isVisible) => {
  mapInstance.addSource(routeId, getRouteSource(routes));
  mapInstance.addLayer(
    getLayerConfig(routeId, {
      visibility: isVisible ? 'visible' : 'none'
    })
  );
};
