import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConsistencyChart } from '@components/Charts';
function sortByConsistencyScore(a, b) {
  if (a?.operatorStats?.consistencyScore > b?.operatorStats?.consistencyScore) {
    return -1;
  }
  if (a?.operatorStats?.consistencyScore < b?.operatorStats?.consistencyScore) {
    return 1;
  }
  return 0;
}
function PortCallConsistencyChart({ loading, selectedOperators, portActivityData, fileName }) {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (portActivityData) {
      let operatorNames = [];
      let data = [];
      const { operatorResults } = portActivityData;
      const sortedOperatorResults = [...operatorResults].sort(sortByConsistencyScore);

      sortedOperatorResults.map(({ operatorLongName, operatorStats }) => {
        operatorNames.push(operatorLongName);
        data.push(operatorStats?.consistencyScore || 0);
      });
      setCategories(operatorNames);
      setSeries([{ name: 'consistency', data: data }]);
    }
  }, [selectedOperators, portActivityData]);

  return (
    <ConsistencyChart
      categories={categories}
      series={series}
      loading={loading}
      fileName={fileName}
    />
  );
}

PortCallConsistencyChart.propTypes = {
  selectedOperators: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  portActivityData: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

PortCallConsistencyChart.defaultProps = {
  selectedOperators: [],
  loading: false,
  portActivityData: null
};

export default PortCallConsistencyChart;
