import React, { useMemo } from 'react';
import {
  InputAdornment,
  IconButton,
  TextField,
  SearchIcon,
  Autocomplete,
  CloseIcon,
  CircularProgress
} from '@esgian/esgianui';
import { getTransparentBackground } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import PropTypes from 'prop-types';
import { useTheme } from '@hooks';
import { VESSEL_SEARCH_ITEM_TYPE } from '../DashboardActivityMapSection';
import { useSegment } from '@hooks';

const DashboardActivityMapVesselSearchBox = ({
  vesselsLatestPosition,
  selectedSearchOptionId,
  selectedSearchOptionType,
  onSelectSearchOption,
  onClearSearchValue,
  operatorVesselHierarchy,
  ownerOperatorVesselHierarchy,
  isLoading,
  lookupLocations,
  lookupPorts
}) => {
  const themeMode = useSelector(getThemeMode);
  const { customScrollbar } = useTheme();
  const { isRoRo } = useSegment();

  const options = useMemo(() => {
    const operatorOptions =
      operatorVesselHierarchy?.map((item) => ({
        id: item.id,
        label: item.name,
        type: VESSEL_SEARCH_ITEM_TYPE.OPERATOR
      })) || [];

    const ownerOptions = !isRoRo
      ? []
      : ownerOperatorVesselHierarchy?.map((item) => ({
          id: item.id,
          label: item.name,
          type: VESSEL_SEARCH_ITEM_TYPE.OWNER
        })) || [];

    const vesselOptions =
      vesselsLatestPosition?.map((item) => ({
        id: item.imo,
        label: item.vesselName,
        type: VESSEL_SEARCH_ITEM_TYPE.VESSEL
      })) || [];

    const countryOptions =
      lookupLocations
        ?.filter(({ type }) => type === 'Country')
        .map(({ id, name }) => ({
          id,
          label: name,
          type: VESSEL_SEARCH_ITEM_TYPE.COUNTRY
        })) || [];

    const portOptions =
      lookupPorts?.map(({ id, portName }) => ({
        id,
        label: portName,
        type: VESSEL_SEARCH_ITEM_TYPE.PORT
      })) || [];

    return [
      ...operatorOptions,
      ...ownerOptions,
      ...vesselOptions,
      ...countryOptions,
      ...portOptions
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [
    operatorVesselHierarchy,
    ownerOperatorVesselHierarchy,
    vesselsLatestPosition,
    lookupLocations,
    isRoRo,
    VESSEL_SEARCH_ITEM_TYPE.OPERATOR,
    VESSEL_SEARCH_ITEM_TYPE.OWNER,
    VESSEL_SEARCH_ITEM_TYPE.VESSEL,
    VESSEL_SEARCH_ITEM_TYPE.COUNTRY,
    VESSEL_SEARCH_ITEM_TYPE.PORT,
    lookupPorts
  ]);

  const selectedSearchOption = useMemo(() => {
    return options.find(
      (option) => option.id === +selectedSearchOptionId && option.type === selectedSearchOptionType
    );
  }, [selectedSearchOptionId, selectedSearchOptionType, options]);

  const isClearIconDisabled = useMemo(() => {
    return isLoading || !selectedSearchOptionId || !selectedSearchOptionType;
  }, [isLoading, selectedSearchOptionId, selectedSearchOptionType]);

  const filterOptions = (options, { inputValue }) => {
    const searchValue = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue) ||
        option.id.toString().toLowerCase().includes(searchValue) ||
        option.type.toLowerCase().includes(searchValue)
    );
  };

  return (
    <Autocomplete
      forcePopupIcon={false}
      value={selectedSearchOption}
      disableClearable
      onChange={(e, selectedOption) => onSelectSearchOption(selectedOption)}
      options={options}
      getOptionLabel={(option) => option?.label}
      loading={isLoading}
      disabled={isLoading}
      ListboxProps={{ sx: { ...customScrollbar } }}
      isOptionEqualToValue={(option, value) =>
        option?.id === value?.id && option?.type === value?.type
      }
      filterOptions={filterOptions}
      noOptionsText={isLoading ? 'Loading...' : 'No matching data found'}
      size="small"
      renderOption={(props, option) => (
        <li {...props} key={`${option.id}-${option.type}`}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
            <div>{option.label}</div>
            <div style={{ fontSize: '0.7rem', color: 'gray', textTransform: 'uppercase' }}>
              {option.type}
            </div>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            isRoRo
              ? 'Search by Operator/Owner/Destination/Vessel/Port'
              : 'Search by Operator/Destination/Vessel/Port'
          }
          variant="outlined"
          fullWidth
          disabled={isLoading}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {isLoading ? (
                  <>
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  </>
                ) : (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => {
                          onClearSearchValue?.();
                        }}
                        disabled={isClearIconDisabled}>
                        <CloseIcon
                          disabled={isClearIconDisabled}
                          sx={{ color: isClearIconDisabled ? 'gray' : undefined }}
                        />
                      </IconButton>
                    </InputAdornment>
                  </>
                )}
              </>
            ),
            style: {
              borderRadius: '1.5rem',
              backgroundColor: getTransparentBackground(themeMode)
            }
          }}
        />
      )}
    />
  );
};

DashboardActivityMapVesselSearchBox.propTypes = {
  vesselsLatestPosition: PropTypes.arrayOf(PropTypes.object),
  selectedSearchOptionId: PropTypes.string,
  selectedSearchOptionType: PropTypes.string,
  onSelectSearchOption: PropTypes.func,
  onClearSearchValue: PropTypes.func,
  operatorVesselHierarchy: PropTypes.array,
  ownerOperatorVesselHierarchy: PropTypes.array,
  isLoading: PropTypes.bool,
  lookupLocations: PropTypes.array,
  lookupPorts: PropTypes.array
};

export default DashboardActivityMapVesselSearchBox;
