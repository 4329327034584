import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';

function OperatorPortShareSubTable({ subTableHeader, data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {typeof subTableHeader === 'string' ? (
          <Typography bold id={'port-calls-header'}>
            {subTableHeader}
          </Typography>
        ) : (
          subTableHeader
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Paper variant={'outlined'} sx={{ p: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography color={'text.secondary'} variant={'body2'} bold>
                Operator
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color={'text.secondary'} variant={'body2'} bold>
                Share[%]
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {data.map(({ operatorShortName, share, operatorId }, i) => (
              <React.Fragment key={operatorId}>
                <Grid item xs={6}>
                  <ProfileLink id={operatorId} profile={'operator'} name={operatorShortName} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>{share?.toFixed(2)}%</Typography>
                </Grid>
                {i !== data?.length - 1 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

OperatorPortShareSubTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  subTableHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

OperatorPortShareSubTable.defaultProps = {
  data: []
};

export default OperatorPortShareSubTable;
