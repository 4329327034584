import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import moment from 'moment';

function MapFiltersDisplay({
  newPortsOnly,
  setNewPortsOnly,
  showPortMarkers,
  setShowPortMarkers,
  selectedQuarter
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack sx={{ p: 2 }} spacing={2}>
          <Stack>
            <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
              Port markers
            </Typography>
            <Stack direction={'row'}>
              <Button
                disableElevation
                fullWidth
                onClick={() => setShowPortMarkers(false)}
                sx={{ borderRadius: '4px 0px 0px 4px' }}
                variant={showPortMarkers === false ? 'contained' : 'outlined'}>
                OFF
              </Button>
              <Button
                disableElevation
                fullWidth
                onClick={() => setShowPortMarkers(true)}
                sx={{ borderRadius: '0px 4px 4px 0px' }}
                variant={showPortMarkers === true ? 'contained' : 'outlined'}>
                ON
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
              Port display
            </Typography>
            <RadioGroup
              value={newPortsOnly}
              onChange={({ target }) => setNewPortsOnly(target.value === 'true')}>
              <FormControlLabel
                value={false}
                control={<Radio size={'small'} />}
                label={<Typography variant={'body2'}>Show All</Typography>}
              />
              <FormControlLabel
                value={true}
                control={<Radio size={'small'} />}
                label={
                  <TextWithTooltipIcon
                    tooltipText={`Display only port that were visited in ${moment(
                      selectedQuarter,
                      '[Q]Q YYYY'
                    ).format('[Q]Q YYYY')} and not in ${moment(selectedQuarter, 'Q YYYY')
                      .subtract(1, 'quarter')
                      .format('[Q]Q YYYY')}`}
                    label={'Only new port calls'}
                  />
                }
              />
            </RadioGroup>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

MapFiltersDisplay.propTypes = {
  newPortsOnly: PropTypes.bool,
  setNewPortsOnly: PropTypes.func.isRequired,
  showPortMarkers: PropTypes.bool,
  setShowPortMarkers: PropTypes.func.isRequired,
  selectedQuarter: PropTypes.string.isRequired
};

MapFiltersDisplay.defaultProps = {
  newPortsOnly: false,
  showPortMarkers: false
};

export default MapFiltersDisplay;
