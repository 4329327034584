import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConsistencyChart } from '@components/Charts';

function sortByConsistencyScore(a, b) {
  if (a?.capacityStats?.consistencyScore > b?.capacityStats?.consistencyScore) {
    return -1;
  }
  if (a?.capacityStats?.consistencyScore < b?.capacityStats?.consistencyScore) {
    return 1;
  }
  return 0;
}

function CeuConsistencyChart({ loading, portServiceData, fileName }) {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (portServiceData) {
      const { frequencyResults } = portServiceData;
      const sortedTransitResults = [...frequencyResults].sort(sortByConsistencyScore);
      setCategories(sortedTransitResults.map(({ operatorLongName }) => operatorLongName));
      setSeries([
        { data: sortedTransitResults.map(({ capacityStats }) => capacityStats.consistencyScore) }
      ]);
    }
  }, [portServiceData]);

  return (
    <ConsistencyChart
      categories={categories}
      series={series}
      loading={loading}
      fileName={fileName}
    />
  );
}

CeuConsistencyChart.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

CeuConsistencyChart.defaultProps = {
  loading: false,
  portServiceData: null
};

export default CeuConsistencyChart;
