import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

const ButtonWithIcon = ({ isLoading, onClick, label, isDisabled, startIcon }) => {
  const themeMode = useSelector(getThemeMode);

  return (
    <Button
      sx={{ color: themeMode ? 'white' : 'black' }}
      disabled={isLoading || isDisabled}
      startIcon={isLoading ? <CircularProgress size={20} /> : startIcon}
      onClick={onClick}
      variant="outlined">
      {label}
    </Button>
  );
};

ButtonWithIcon.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.node
};

export default ButtonWithIcon;
