import { useQuery } from '@tanstack/react-query';
import { getLatestPortCalls } from '@api';
import { GEOGRAPHICAL_TYPES, DEFAULT_NUM_PORT_CALLS } from '@constants';

export const useLatestPortCalls = (imo) => {
  const { data: latestPortCalls, isPending: isLoadingLatestPortCalls } = useQuery({
    queryKey: ['vesselLatestPortCalls', imo],
    queryFn: ({ signal }) =>
      getLatestPortCalls(
        {
          geographicalType: GEOGRAPHICAL_TYPES.ALL,
          numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
          imos: [imo]
        },
        signal
      ),
    enabled: !!imo
  });

  return {
    latestPortCalls,
    isLoadingLatestPortCalls
  };
};
