import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Stack, Divider } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { OperatorCiiRatingTable, OperatorCiiRatingChart } from './index';
import { getOperatorCiiRatingData } from '@api/Emissions';
import AccreditationStatementModal from '@components/Modals/AccreditationStatementModal';
import { OperatorCIIDisclaimer } from '@components';
import { TextWithTooltipIcon } from '@components';
import { useSegment } from '@hooks';
import { OPERATOR_PROFILE_FLEET_CII_RATING_TEXT } from '@constants/tooltips';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';

function OperatorCiiRatingSection({ operatorShortName, operatorLongName }) {
  const [operatorCiiRatingData, setOperatorCiiRatingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const params = useParams();
  const { isRoRo, segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getOprCiiRatingData = async (operatorId) => {
      try {
        setLoading(true);
        await getOperatorCiiRatingData(
          {
            OperatorId: operatorId,
            SegmentTypeId: segment.id,
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        ).then((result) => {
          setOperatorCiiRatingData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getOprCiiRatingData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [commercialCategoryType]);

  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Stack>
                  <TextWithTooltipIcon
                    label={<Typography variant={'h6'}>Fleet CII rating</Typography>}
                    tooltipText={OPERATOR_PROFILE_FLEET_CII_RATING_TEXT}
                  />
                  <Typography color={'text.secondary'} variant={'body2'}>
                    {operatorLongName}
                  </Typography>
                </Stack>
                <Divider />
                <OperatorCIIDisclaimer setShowStatement={setShowStatement} />
                <OperatorCiiRatingChart
                  fileName={`${operatorShortName}-cii-rating`}
                  loading={loading}
                  ciiRatingData={operatorCiiRatingData}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <OperatorCiiRatingTable
                fileName={`${operatorShortName}-cii-rating`}
                ciiRatingData={operatorCiiRatingData}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </>
  );
}

OperatorCiiRatingSection.propTypes = {
  operatorShortName: PropTypes.string,
  operatorLongName: PropTypes.string
};

OperatorCiiRatingSection.defaultProps = {
  operatorShortName: '',
  operatorLongName: ''
};

export default OperatorCiiRatingSection;
