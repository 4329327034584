import { useQuery } from '@tanstack/react-query';
import { getVesselsPositions } from '@api';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';

const QUERY_KEYS = {
  VesselsInformation: 'vesselInformationByFilter',
  VesselsLatestPosition: 'vesselsLatestPosition'
};

export const useVesselsLatestPosition = (vessels) => {
  const { segment } = useSelector(getGeneralSlice);
  const { data: vesselsLatestPosition, isLoading: isLoadingVesselsLatestPosition } = useQuery({
    queryKey: [QUERY_KEYS.VesselsLatestPosition, segment.id],
    queryFn: ({ signal }) =>
      getVesselsPositions(
        vessels.map(({ id }) => id),
        signal
      ),
    enabled: vessels.length > 0
  });

  return {
    vesselsLatestPosition,
    isLoadingVesselsLatestPosition
  };
};
