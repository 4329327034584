import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  SearchIcon,
  Skeleton,
  Typography
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks/useTheme';

function sortCountry(a, b) {
  if (a.countryName < b.countryName) {
    return -1;
  }
  if (a.countryName > b.countryName) {
    return 1;
  }
  return 0;
}

function CountryListDisplay({ loading, map, lookupCountries }) {
  const [query, setQuery] = useState('');
  const [countryList, setCountryList] = useState([]);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (query !== '') {
      setCountryList(
        lookupCountries
          .filter(({ countryName }) => {
            return countryName
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(query.toLowerCase());
          })
          ?.sort(sortCountry)
      );
    } else {
      setCountryList(lookupCountries?.sort(sortCountry));
    }
  }, [lookupCountries, query]);

  const handleCountryHover = (name) => {
    map.current.getCanvas().style.cursor = 'pointer';
    map.current?.setFilter('cfh', ['==', 'name', name]);
    map.current?.setFilter('cb', ['==', 'name', name]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '0px'
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              padding: '16px 16px 16px 16px',
              zIndex: 10,
              backgroundColor: (theme) => theme.palette.background.paper
            }}>
            <OutlinedInput
              disabled={loading}
              fullWidth
              value={query}
              onChange={({ target }) => setQuery(target.value)}
              inputProps={{ style: { padding: '8px' } }}
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              variant={'outlined'}
              placeholder={'Search country...'}
            />
          </Grid>

          {(!!countryList?.length || loading) && (
            <Grid item xs={12} sx={{ pt: '0px !important' }}>
              <List
                sx={{
                  p: 0,
                  width: '100%',
                  bgColor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: `calc(80vh - 72px)`,
                  '& ul': { padding: 0 },
                  ...customScrollbar
                }}>
                {loading &&
                  Array.apply(null, { length: 12 }).map((e, i) => (
                    <React.Fragment key={`skeleton-${i}`}>
                      <ListItem
                        sx={{
                          backgroundColor: ({ palette }) =>
                            i % 2 === 0 ? palette.background.paper : palette.background.default
                        }}>
                        <ListItemText>
                          <Skeleton width={'100%'} />
                        </ListItemText>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                {!loading &&
                  countryList?.map(({ countryName, id, isoAlpha2 }, i) => (
                    <React.Fragment key={`${id}-${i}`}>
                      <ListItem
                        onMouseEnter={() => handleCountryHover(countryName)}
                        onMouseLeave={() => handleCountryHover('')}
                        sx={{
                          backgroundColor: ({ palette }) =>
                            i % 2 === 0 ? palette.background.paper : palette.background.default
                        }}>
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={
                            <ProfileLink
                              countryCode={isoAlpha2}
                              profile={'country'}
                              name={countryName}
                              id={id}
                            />
                          }
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
              </List>
            </Grid>
          )}
          {!countryList?.length && !loading && (
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography>No matching search results</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

CountryListDisplay.propTypes = {
  loading: PropTypes.bool,
  map: PropTypes.object,
  lookupCountries: PropTypes.arrayOf(PropTypes.object)
};

CountryListDisplay.defaultProps = {
  loading: false,
  map: null,
  lookupCountries: []
};

export default CountryListDisplay;
