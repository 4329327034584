import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function compare(a, b) {
  if (a.operatorLongName < b.operatorLongName) {
    return -1;
  }
  if (a.operatorLongName > b.operatorLongName) {
    return 1;
  }
  return 0;
}

function OperatorMultiSelect({
  handleChange,
  placeholder,
  selectedOperators,
  operatorsList,
  disabled,
  getOptionDisabled,
  error,
  limitTags,
  helperText,
  id,
  width,
  loading,
  label
}) {
  const [tempValues, setTempValues] = useState([]);
  const [, setOpen] = useState(false);
  const [operators, setOperators] = useState([]);
  let { customScrollbar } = useTheme();

  useEffect(() => {
    setTempValues([...selectedOperators]);
  }, [selectedOperators]);

  // Sort list by company name to make it easier to navigate list.
  useEffect(() => {
    if (operatorsList) {
      let sortedOperators = [...operatorsList].sort(compare);
      sortedOperators[0] = { ...sortedOperators[0], ...{ first: true } };
      setOperators(sortedOperators);
    }
  }, [operatorsList]);

  const handleClick = (option, checked) => {
    if (!option) {
      setTempValues(checked);
    } else {
      if (!checked) {
        setTempValues((prev) => [...prev, option]);
      } else {
        setTempValues((prev) => [...prev.filter((item) => item.id !== option.id)]);
      }
    }
  };

  return (
    <Autocomplete
      onOpen={() => {
        setOpen(true);
      }}
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      componentsProps={{
        paper: {
          sx: {
            width: width || '350px'
          }
        }
      }}
      id={'operators-select'}
      disabled={disabled || loading}
      isOptionEqualToValue={(option, value) => {
        if (operators.length) {
          return option.id === value.id;
        }
        return false;
      }}
      onClose={() => {
        handleChange([...tempValues]);
        setOpen(false);
      }}
      value={operators?.length ? tempValues : []}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        return (
          <React.Fragment key={`opr-${option.id}`}>
            {option?.first && (
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'opr-check-all-btn'}
                    onClick={() => handleClick(null, operators)}
                    variant={'contained'}
                    color={'primary'}>
                    Check all
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'clear-oprs-btn'}
                    onClick={() => handleClick(null, [])}
                    variant={'outlined'}
                    color={'secondary'}>
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}
            {!option?.all && (
              <MenuItem
                key={option.id}
                value={option.id}
                onClick={() => handleClick(option, selected)}>
                <ListItemIcon>
                  <Checkbox size={'small'} color="primary" checked={selected} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={option.operatorLongName}
                />
              </MenuItem>
            )}
          </React.Fragment>
        );
      }}
      sx={{ width: width || '250px' }}
      multiple
      onChange={(event, value, reason) => {
        if (reason === 'clear') {
          handleChange([]);
        }
      }}
      options={operators}
      limitTags={limitTags}
      getOptionLabel={(option) => option.operatorLongName}
      renderTags={(value) => {
        if (value.length === operators.length) {
          return 'All';
        }
        let i = 0;
        return value.map(({ operatorLongName }) => {
          if (!operatorLongName) {
            return;
          }
          if (i === limitTags) {
            i++;
            return `+${value.length - limitTags}`;
          } else if (i < limitTags) {
            i++;
            return (
              <Chip
                size={'small'}
                key={`chip-${operatorLongName}`}
                label={
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'clip',
                      textOverflow: 'inherit'
                    }}>
                    {operatorLongName}
                  </span>
                }
              />
            );
          }
        });
      }}
      getOptionDisabled={getOptionDisabled}
      filterOptions={(operators, state) => {
        return operators.filter(
          ({ operatorLongName, operatorShortName, id }) =>
            operatorLongName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            operatorShortName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        );
      }}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: width || '250px' }}
          id={id}
          fullWidth
          label={label || 'Operator*'}
          error={error}
          helperText={helperText}
          placeholder={selectedOperators?.length >= 1 ? '' : placeholder}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}

OperatorMultiSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  limitTags: PropTypes.number,
  width: PropTypes.string,
  selectedOperators: PropTypes.arrayOf(PropTypes.object),
  operatorsList: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string
};

OperatorMultiSelect.defaultProps = {
  selectedOperators: [],
  operatorsList: [],
  getOptionDisabled: null,
  disabled: false,
  placeholder: '',
  error: false,
  helperText: null,
  limitTags: 1,
  id: 'operators-select',
  loading: false,
  label: 'Operator*'
};

export default OperatorMultiSelect;
