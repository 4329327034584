import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { MAP_STYLE_LIGHT_MODE, MAP_STYLE_DEEP_DARK_MODE, MAPBOX_API_TOKEN } from '@constants';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const MapComponent = React.memo(({ mapContainerRef, onMapLoad, themeMode, onMapCreated }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.remove();
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      zoom: 1
    });

    mapRef.current = map;
    onMapCreated?.(map);

    map.on('load', () => {
      onMapLoad?.(map);
    });

    return () => map.remove();
  }, []);

  return null;
});

MapComponent.displayName = 'MapComponent';

MapComponent.propTypes = {
  mapContainerRef: PropTypes.object.isRequired,
  onMapLoad: PropTypes.func,
  themeMode: PropTypes.bool,
  onMapCreated: PropTypes.func
};

export default MapComponent;
