import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, threeColors }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: false
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val.toFixed(2);
      }
    },
    colors: [twoColorsContrast[1], threeColors[0], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      labels: { rotateAlways: true }
    },
    yaxis: [
      {
        min: 0,
        title: {
          text: 'Avg. Speed[kn]'
        },
        decimalsInFloat: 0
      }
    ],
    stroke: {
      width: [2, 2, 3],
      curve: 'straight',
      dashArray: [0, 0, 5]
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 2,
      hover: {
        size: 5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'center'
        }
      }
    },

    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};
function VesselAvgSpeedChart({ vesselAvgSpeedData, loading, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { categories = [], series = [] } = useMemo(() => {
    if (!vesselAvgSpeedData?.imoAverageMonthlySpeed) return {};

    return {
      categories: vesselAvgSpeedData?.imoAverageMonthlySpeed?.map(({ monthStr }) => monthStr),
      series: [
        {
          name: 'Average Speed',
          type: 'bar',
          data: vesselAvgSpeedData?.imoAverageMonthlySpeed?.map(
            ({ monthlyAvgSpeed }) => monthlyAvgSpeed
          )
        },
        {
          name: 'Segment Avg Speed',
          type: 'line',
          data: vesselAvgSpeedData?.segmentTypeIdAverageMonthlySpeed
            ? vesselAvgSpeedData?.segmentTypeIdAverageMonthlySpeed?.map(
                ({ monthlyAvgSpeed }) => monthlyAvgSpeed
              )
            : []
        },
        {
          name: 'Trendline',
          type: 'line',
          data: vesselAvgSpeedData?.polyFitAverageMonthSpeed
            ? vesselAvgSpeedData?.polyFitAverageMonthSpeed?.map((polyFitAverageMonthSpeed) =>
                polyFitAverageMonthSpeed > 0 ? polyFitAverageMonthSpeed : 0
              )
            : []
        }
      ]
    };
  }, [vesselAvgSpeedData]);

  const options = useMemo(() => {
    if (!categories?.length) return {};
    return getOptions(categories, theme, user, fileName);
  }, [theme, categories]);

  return (
    <ApexChart loading={loading} height={480} options={options || {}} type={'bar'} data={series} />
  );
}
VesselAvgSpeedChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  vesselAvgSpeedData: PropTypes.object
};
VesselAvgSpeedChart.defaultProps = {
  vesselAvgSpeedData: null,
  loading: false
};
export default VesselAvgSpeedChart;
