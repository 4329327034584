import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import DashboardActivityMapMySave from './DashboardActivityMapMySave';
import DashboardActivityMapFilter from './DashboardActivityMapFilter';
import DashboardActivityMapMapSettings from './DashboardActivityMapSettings';

export const ACTIVITY_MAP_ACTIONS = {
  INSIGHT: 'insight',
  FILTER: 'filter',
  MY_SAVE: 'my-save',
  MAP_SETTINGS: 'map-settings',
  INBOUND_VESSELS_FILTER: 'inbound-vessels-filter'
};

const DashboardActivityMapDrawer = ({ selectedAction, onClose, filterProps, mapSettingsProps }) => {
  const renderDrawerContent = () => {
    switch (selectedAction) {
      case ACTIVITY_MAP_ACTIONS.MY_SAVE:
        return <DashboardActivityMapMySave onClose={onClose} />;
      case ACTIVITY_MAP_ACTIONS.FILTER:
        return <DashboardActivityMapFilter onClose={onClose} {...filterProps} />;
      case ACTIVITY_MAP_ACTIONS.MAP_SETTINGS:
        return <DashboardActivityMapMapSettings onClose={onClose} {...mapSettingsProps} />;
      case ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER:
        return (
          <DashboardActivityMapFilter
            onClose={onClose}
            {...filterProps}
            isInboundVesselsFiltering
          />
        );
      default:
        return null;
    }
  };

  return <Box height="100%">{renderDrawerContent()}</Box>;
};

DashboardActivityMapDrawer.propTypes = {
  selectedAction: PropTypes.string,
  onClose: PropTypes.func,
  insightProps: PropTypes.object,
  filterProps: PropTypes.object,
  mapSettingsProps: PropTypes.object
};

export default DashboardActivityMapDrawer;
