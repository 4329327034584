import React from 'react';
import PropTypes from 'prop-types';
import { Box, DashboardInfoBox } from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import { formatNumberReadable } from '@helpers';

const getFormattedValue = (value) => {
  if (value === undefined || value === null) {
    return '--';
  }
  return formatNumberReadable(Number(value).toFixed(0));
};

const SimulatedVoyageEmissionSummary = ({
  transitDays,
  etsCost,
  co2eTn,
  co2eIntensity,
  co2Tn,
  cii
}) => {
  const infoBoxes = [
    {
      label: 'Transit time (days)',
      value: getFormattedValue(transitDays),
      tooltip: 'Transit time (days)'
    },
    {
      label: 'ETS cost (€)',
      value: getFormattedValue(etsCost),
      tooltip: 'Total EU ETS cost corresponding to the CO₂ emission for the voyage.'
    },
    {
      label: 'CO₂e (t)',
      value: getFormattedValue(co2eTn),
      tooltip:
        'Tonnes of CO₂e emission for the simulated voyage. CO₂ equivalents (CO₂e) means the number of metric tons of CO₂ emissions with the same global warming potential as one metric ton of another greenhouse gas. As per ISO 14083 CO₂e Includes CO₂, CH4, CFCs,HFCs,NF3,N2O,PFCs,SF6,SO2F2'
    },
    {
      label: 'CO₂e intensity (kgCO2e/t.km)',
      value: getFormattedValue(co2eIntensity),
      tooltip:
        'CO₂e (kg) emitted per ton cargo weight carried per kilometer traveled in the simulated voyage.'
    },
    {
      label: 'CO₂ (t)',
      value: getFormattedValue(co2Tn),
      tooltip: 'Tonnes of Carbon Dioxide emitted for the simulated voyage'
    },
    {
      label: 'CII',
      value: cii ?? '--',
      tooltip: 'CII'
    }
  ];

  return (
    <Box display="flex" gap={2}>
      {infoBoxes.map((infoBox) => (
        <Box key={infoBox.label} flex={1}>
          <DashboardInfoBox
            value={infoBox.value}
            header={
              <TextWithTooltipIcon
                labelBold
                labelVariant="subtitle1"
                label={infoBox.label}
                tooltipText={infoBox.tooltip}
              />
            }
            subheader={infoBox.subheader}
            paperSx={{ height: '100%' }}
          />
        </Box>
      ))}
    </Box>
  );
};

SimulatedVoyageEmissionSummary.propTypes = {
  transitDays: PropTypes.number,
  etsCost: PropTypes.number,
  co2eTn: PropTypes.number,
  co2eIntensity: PropTypes.number,
  co2Tn: PropTypes.number,
  cii: PropTypes.string
};

export default SimulatedVoyageEmissionSummary;
