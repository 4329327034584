import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, TextField } from '@esgian/esgianui';

const MAX_GROUP_NAME_LENGTH = 40;

const CreateVoyageGroupPopup = ({
  open,
  setModalOpen,
  voyageGroupToEdit,
  setVoyageGroupToEdit,
  existingGroups,
  isSubmitDisabled,
  onSubmit,
  onClose
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [input, setInput] = useState('');

  useEffect(() => {
    if (voyageGroupToEdit?.name) {
      setInput(voyageGroupToEdit.name);
    }
  }, [voyageGroupToEdit?.name]);

  return (
    <Modal
      title={voyageGroupToEdit ? 'Edit Voyage Group' : 'Create a new group'}
      open={open}
      handleClose={() => {
        if (voyageGroupToEdit) {
          setVoyageGroupToEdit(undefined);
        }
        setModalOpen(false);
      }}>
      <Box display="flex" gap={2} flexDirection="column">
        <TextField
          label={voyageGroupToEdit ? 'Edit group name' : 'Create a new group'}
          autoComplete={'off'}
          type={'search'}
          placeholder={''}
          value={input}
          error={!!errorMessage}
          helperText={errorMessage}
          fullWidth
          onChange={(e) => {
            const groupName = e.target.value;
            let errorMessage = '';

            setInput(groupName);

            const isGroupAlreadyExist = existingGroups
              .filter((g) => g.id !== voyageGroupToEdit?.id)
              .some(
                (singleGroup) =>
                  singleGroup.name.trim().toLowerCase() === groupName.trim().toLowerCase()
              );
            if (isGroupAlreadyExist) {
              errorMessage = `A Group with the name "${groupName.trim()}" already exists`;
            }
            if (groupName.trim().length >= MAX_GROUP_NAME_LENGTH) {
              errorMessage = `Group name should be less than ${MAX_GROUP_NAME_LENGTH} characters`;
            }
            setErrorMessage(errorMessage);
          }}
        />
        <Box display="flex" gap={2} justifyContent="center">
          <Button
            variant="text"
            onClick={() => {
              if (voyageGroupToEdit) {
                setVoyageGroupToEdit(undefined);
              }
              setModalOpen(false);
              setErrorMessage('');
              setInput('');
              onClose && onClose();
            }}>
            Close
          </Button>
          <Button
            disabled={
              isSubmitDisabled ||
              !!errorMessage ||
              input.trim() === '' ||
              input === voyageGroupToEdit?.name
            }
            onClick={() => {
              onSubmit(input, () => {
                setInput('');
              });
            }}
            style={{ width: '100px' }}>
            {voyageGroupToEdit ? 'Save' : 'Create'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

CreateVoyageGroupPopup.propTypes = {
  open: PropTypes.bool,
  setModalOpen: PropTypes.func,
  voyageGroupToEdit: PropTypes.object,
  setVoyageGroupToEdit: PropTypes.func,
  existingGroups: PropTypes.array,
  isSubmitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default CreateVoyageGroupPopup;
