import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const MapPlaceholder = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const themeMode = useSelector(getThemeMode);

  useEffect(() => {
    if (mapRef.current) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      zoom: 1
    });

    mapRef.current = map;

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.setStyle(themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE);
  }, [themeMode]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MapPlaceholder;
