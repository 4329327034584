import React from 'react';
import mapboxgl from 'mapbox-gl';
import { DATE_TIME_FORMAT_DAY_OF } from '@constants';
import mapboxGlAnimatedPopup from 'mapbox-gl-animated-popup/dist/mapbox-gl-animated-popup';
import { PortIcon } from '@components';
import { renderToString } from 'react-dom/server';

export const getTransparentBackground = (themeMode) => {
  return themeMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
};

export const getShipIcon = (speed) => {
  if (speed >= 0 && speed < 1) return 'icon-ship-10';
  if (speed > 1 && speed <= 3) return 'icon-ship-0';
  return 'icon-ship-port';
};

const getPopupBackground = (themeMode) => {
  return themeMode ? 'background: rgba(11,26,46,0.8)' : 'background: rgba(255, 255, 255, 0.8)';
};

export const flyToTheLocation = (mapInstance, lat, lon) => {
  mapInstance.flyTo({ center: [lon, lat], zoom: 5, duration: 2000 });
};

const createMarkerElement = (speed, heading, course) => {
  const el = document.createElement('div');
  const markerSpan = document.createElement('span');
  const rotate = heading || course;

  markerSpan.style.cssText = `
    height: 15px;
    width: 15px;
    cursor: pointer;
    font-size: 15px;   
    background-image: url("/assets/images/${getShipIcon(speed)}.png");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotateZ(${rotate}deg);
  `;

  markerSpan.className = getShipIcon(speed);
  el.className = 'ship-marker';
  el.appendChild(markerSpan);

  return el;
};

const createPortMarkerElement = () => {
  const el = document.createElement('div');
  const markerSpan = document.createElement('span');

  markerSpan.style.cssText = `
    height: 15px;
    width: 15px;
    cursor: pointer;
    font-size: 15px;   
    position: absolute;
  `;

  const portIconContainer = document.createElement('div');
  portIconContainer.innerHTML = renderToString(<PortIcon />);
  markerSpan.appendChild(portIconContainer);

  markerSpan.className = 'icon-port';
  el.className = 'port-marker';
  el.appendChild(markerSpan);

  return el;
};

const createPopupContent = (background, vesselName, speed, timeZoneDisplay, destination) => {
  return `${background}
    <span style="color: #fff;font-family: 'Nato Sans', sans-serif;font-size: 14px">${vesselName}</span></div> 
    <div style="padding: 8px">
      <div style="padding-bottom: 5px;"><span style="font-family: 'Nato Sans', sans-serif;font-size: 12px">  <b>Speed:</b> ${speed} Knots </span></div>
      <div style="padding-bottom: 5px;"><span style="font-family: 'Nato Sans', sans-serif;font-size: 12px">  <b>ETA:</b> ${timeZoneDisplay} </span></div>
      <div style="padding-bottom: 5px;"><span style="font-family: 'Nato Sans', sans-serif;font-size: 12px">  <b>Destination:</b> ${destination}</span></div>
    </div>
  </div></div>`;
};

const createNamePopup = (name, lon, lat) => {
  return new mapboxgl.Popup({
    className: 'mapbox-custom-popup-text',
    closeButton: false,
    closeOnMove: false,
    closeOnClick: false
  })
    .setMaxWidth('none')
    .setHTML(
      renderToString(
        <span style={{ padding: '0 6px' }} className="background-color: rgba(255, 255, 255, 0.5);">
          {name}
        </span>
      )
    )
    .setLngLat([lon, lat]);
};

export const createVesselMarkers = (
  map,
  vessels,
  themeMode,
  getTimeZoneDisplay,
  onClick,
  showVesselsNames
) => {
  if (!map) return;

  const popupOptions = {
    closeButton: false,
    closeOnClick: false,
    openingAnimation: {
      duration: 500,
      easing: 'easeOutElastic',
      transform: 'scale'
    },
    closingAnimation: {
      duration: 0,
      easing: 'easeInBack',
      transform: 'scale'
    }
  };

  const background = `<div style="display: flex; 
    flex-direction: column; ${getPopupBackground(themeMode)}"> 
    <div style="
    box-shadow: 0 3px 2px -1px rgb(0 0 0 / 20%);
    background: #007BAF; 
    padding: 8px">`;

  return (
    vessels?.map((singleVessel) => {
      const { lat, long: lon, vesselName, speed, heading, course, eta, destination } = singleVessel;
      const el = createMarkerElement(speed, heading, course);
      const popup = new mapboxGlAnimatedPopup(popupOptions);
      const timeZoneDisplay = getTimeZoneDisplay(eta, DATE_TIME_FORMAT_DAY_OF);

      el.addEventListener('click', () => {
        onClick(singleVessel);
      });

      el.addEventListener('mouseenter', () => {
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat];

        while (Math.abs(lon - coordinates[0]) > 180) {
          coordinates[0] += lon > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML(createPopupContent(background, vesselName, speed, timeZoneDisplay, destination))
          .addTo(map);
      });

      el.addEventListener('mouseleave', () => {
        popup.remove();
      });

      const marker = new mapboxgl.Marker(el).setLngLat([lon, lat]).setPopup(popup).addTo(map);
      if (showVesselsNames) {
        createNamePopup(vesselName, lon, lat).setOffset(new mapboxgl.Point(0, -4)).addTo(map);
      }
      return marker;
    }) || []
  );
};

export const createPortMarkers = (map, ports) => {
  if (!map) return;

  return (
    ports?.map((port) => {
      const { cpointlat: lat, cpointlon: lon, portName } = port;
      const el = createPortMarkerElement();
      const popup = createNamePopup(portName, lon, lat);
      const marker = new mapboxgl.Marker(el).setLngLat([lon, lat]).addTo(map);

      el.addEventListener('mouseenter', () => {
        popup.setOffset(new mapboxgl.Point(0, -4)).addTo(map);
      });

      el.addEventListener('mouseleave', () => {
        popup.remove();
      });

      return marker;
    }) || []
  );
};

export const getVesselNamePopup = (vesselName, lon, lat) => {
  return createNamePopup(vesselName, lon, lat);
};
