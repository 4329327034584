import React from 'react';
import { Grid, Typography } from '@esgian/esgianui';
import GeneralNavigation from '@components/Sections/ShipAnalytics/FaqSection/Modules/GeneralNavigation';
import ActivityMap from '@components/Sections/ShipAnalytics/FaqSection/Modules/ActivityMap';
import PortActivity from '@components/Sections/ShipAnalytics/FaqSection/Modules/PortActivity';
import PortServiceLevel from '@components/Sections/ShipAnalytics/FaqSection/Modules/PortServiceLevel';
import OperatorPerformance from '@components/Sections/ShipAnalytics/FaqSection/Modules/OperatorPerformance';

function Modules() {
  return (
    <Grid item xs={12} container id={'modules'} spacing={2}>
      <Grid item xs={12} id={'general-navigation'}>
        <Typography gutterBottom variant={'h5'}>
          3. Modules
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <GeneralNavigation />
      </Grid>
      <Grid item xs={12}>
        <ActivityMap />
      </Grid>
      <Grid item xs={12}>
        <PortActivity />
      </Grid>
      <Grid item xs={12}>
        <PortServiceLevel />
      </Grid>
      <Grid item xs={12}>
        <OperatorPerformance />
      </Grid>
    </Grid>
  );
}

Modules.propTypes = {};

Modules.defaultProps = {};

export default Modules;
