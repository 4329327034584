import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Button, ButtonGroup, Grid, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getAnnotations = (
  withAnnotations,
  operatorAvg,
  segmentAvg,
  theme,
  numberOfOperatorsInSegment
) => {
  if (!withAnnotations) {
    return {};
  }
  let annotations = [
    {
      y: operatorAvg,
      strokeDashArray: 5,
      borderColor: '#6bc2c3',
      label: {
        position: 'left',
        textAnchor: 'start',
        borderColor: '#6bc2c3',
        style: {
          color: theme.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
          background: 'rgba(107,194,195,0.5)'
        },
        text: 'Operator Avg.'
      }
    }
  ];
  if (numberOfOperatorsInSegment > 1) {
    annotations.push({
      y: segmentAvg,
      borderColor: '#5db1f0',
      strokeDashArray: 5,
      label: {
        borderColor: '#5db1f0',
        textAnchor: 'end',
        style: {
          color: `${theme.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)'}`,
          background: 'rgba(93,177,240,0.5)'
        },
        text: 'Segment Avg.'
      }
    });
  }
  return {
    position: 'front',
    yaxis: annotations
  };
};

const getDataLabelColor = (dataPointIndex, segData, operatorData) => {
  if (segData[dataPointIndex]?.segmentFrequencyAverage > operatorData[dataPointIndex]?.portCalls) {
    return '#f25c54';
  }
  return '#30d072';
};
const getMainChartDefOptions = (
  user,
  dateRangeList,
  theme,
  withAnnotations = false,
  operatorAvg,
  segmentAvg,
  segData,
  operatorData,
  max,
  numberOfOperatorsInSegment,
  type,
  fileName
) => {
  return {
    chart: {
      background: 'transparent',
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },

    colors: theme.palette.charts.twoColorsNeutral,
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true
      },
      shared: true,
      intersect: false
    },
    annotations: getAnnotations(
      withAnnotations,
      operatorAvg,
      segmentAvg,
      theme,
      numberOfOperatorsInSegment
    ),
    dataLabels: {
      enabled: true,
      offsetY: type === 'bar' ? -20 : -10,
      style: {
        fontSize: '12px',
        colors: [
          function ({ dataPointIndex }) {
            return getDataLabelColor(dataPointIndex, segData, operatorData);
          }
        ]
      },
      formatter: function (val, index) {
        const { dataPointIndex, seriesIndex } = index;
        if (numberOfOperatorsInSegment === 1) {
          return;
        }
        if (seriesIndex === 1) {
          return;
        }
        let operatorVal = operatorData[dataPointIndex]?.portCalls;
        let segmentVal = segData[dataPointIndex]?.segmentFrequencyAverage;
        const diff = operatorVal - segmentVal;
        let percent = ((diff / segmentVal) * 100).toFixed(1);
        percent = !isNaN(percent) ? percent : 0;
        return `${diff.toFixed(1)} (${percent}%)`;
      }
    },
    fill:
      type === 'bar'
        ? { opacity: 1 }
        : {
            opacity: [1, 0.4],
            type: ['solid', 'gradient'],
            gradient: {
              shade: 'light',
              type: 'vertical',
              opacityFrom: 0.6,
              opacityTo: 0.2,
              stops: [0, 100]
            }
          },
    stroke: {
      width: 2,
      colors: type === 'bar' ? 'transparent' : undefined,
      curve: 'smooth'
    },
    markers: {
      size: 3
    },
    theme: {
      mode: theme.mode
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '50%',
        barHeight: '80%',
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: dateRangeList
    },
    legend: { position: 'top', horizontalAlign: 'left' },
    yaxis: {
      min: 0,
      max: max,
      labels: {
        formatter: (value) => {
          return value?.toFixed(1) || 0;
        }
      }
    }
  };
};

function OperatorServiceFrequencyChart({ loading, performanceData, dateRangeList, fileName }) {
  const [type, setType] = useState('bar');
  const [optionsBar, setOptionsBar] = useState({});
  const [optionsLine, setOptionsLine] = useState({});
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      return;
    }
    const { segmentPerformance, operatorPerformance } = performanceData;
    let { segmentFrequencyResult, numberOfOperatorsInSegment } = segmentPerformance;
    let { operatorFrequencyResult } = operatorPerformance;
    operatorFrequencyResult = operatorFrequencyResult[0];
    if (!operatorFrequencyResult || !segmentFrequencyResult) {
      return;
    }
    let { operatorLongName, operatorMonthlyFrequencyResults, frequencyStats, frequencyMax } =
      operatorFrequencyResult;
    let {
      segmentOperatorMonthlyPerformance: segmentFrequency,
      frequencyStats: segmentStats,
      segmentFrequencyMax
    } = segmentFrequencyResult;
    let operatorSeries = operatorMonthlyFrequencyResults.map(({ portCalls }) => portCalls);
    let operatorData = { name: operatorLongName, type: type, data: operatorSeries };

    let segmentSeries =
      segmentFrequency.map(({ segmentFrequencyAverage }) => segmentFrequencyAverage) || [];

    let segmentData = {
      name: 'Segment',
      type: type === 'bar' ? 'bar' : 'area',
      data: segmentSeries
    };
    let max = frequencyMax;
    if (frequencyMax < segmentFrequencyMax) {
      max = segmentFrequencyMax;
    }
    max += 2;
    setOptionsBar(
      getMainChartDefOptions(
        user,
        dateRangeList,
        theme,
        true,
        frequencyStats?.average || 0,
        segmentStats?.average || 0,
        segmentFrequency ? segmentFrequency : [],
        operatorMonthlyFrequencyResults,
        max,
        numberOfOperatorsInSegment,
        'bar',
        fileName
      )
    );
    setOptionsLine(
      getMainChartDefOptions(
        user,
        dateRangeList,
        theme,
        true,
        frequencyStats?.average,
        segmentStats?.average,
        segmentFrequency,
        operatorMonthlyFrequencyResults,
        max,
        numberOfOperatorsInSegment,
        'line',
        fileName
      )
    );
    if (numberOfOperatorsInSegment !== 1) {
      setSeries([operatorData, segmentData]);
    } else {
      setSeries([operatorData]);
    }
  }, [performanceData, theme, dateRangeList, type]);

  return (
    <Grid container spacing={2}>
      {!loading && (
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
            <Typography>VIEW BY:</Typography>
            <ButtonGroup disableElevation>
              <Button
                onClick={() => setType('bar')}
                variant={type === 'bar' ? 'contained' : 'outlined'}>
                Bar Chart
              </Button>
              <Button
                onClick={() => setType('line')}
                variant={type === 'line' ? 'contained' : 'outlined'}>
                Line Chart
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        {type === 'bar' && (
          <ApexChart
            options={optionsBar || {}}
            type={'bar'}
            loading={loading}
            data={series}
            height={480}
            fileName={fileName}
          />
        )}
        {type === 'line' && (
          <ApexChart
            options={optionsLine || {}}
            type={'line'}
            loading={loading}
            data={series}
            height={480}
            fileName={fileName}
          />
        )}
      </Grid>
    </Grid>
  );
}

OperatorServiceFrequencyChart.propTypes = {
  performanceData: PropTypes.object,
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired
};

OperatorServiceFrequencyChart.defaultProps = {
  performanceData: null,
  dateRangeList: [],
  loading: false
};

export default OperatorServiceFrequencyChart;
